import React, { useState } from 'react';
import { ISelectCatalogButtonProps } from './SelectCatalogButton.types';
import DeleteButton from 'components/DeleteButton';
import { ExistingEntryDetailsContent } from 'components/CatalogEntryDetailsContent';

export const SelectCatalogButton: React.FC<ISelectCatalogButtonProps> = ({
  onClick,
  onDelete,
  catalogEntry,
  editable,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  if (!onDelete) {
    return <></>;
  }
  return (
    <>
      <span className={`catalog-entry-tooltip ${editable ? 'is-editing' : ''}`}>
        <span className={`tooltip-core ${isVisible ? 'visible' : ''}`}>
          <button
            onClick={(e) => {
              if (!catalogEntry?.signature && editable) {
                onClick(e);
              } else {
                setIsVisible((prevState) => !prevState);
              }
            }}
          >
            {catalogEntry?.signature
              ? `${catalogEntry?.signature}: ${catalogEntry?.title}`
              : 'Katalogeintrag auswählen'}
          </button>
          <div className="catalog-entry-tooltip-content shadow">
            <span>
              {
                <div>
                  {catalogEntry && (
                    <ExistingEntryDetailsContent edit_mode={false} catalogEntry={catalogEntry} />
                  )}
                </div>
              }
              <span className="catalog-entry-tooltip-edit-buttons">
                <button onClick={() => setIsVisible(false)}>Schließen</button>
                <span>
                  {editable && (
                    <span>
                      <button onClick={(e) => editable && onClick(e)}>
                        {catalogEntry?.signature
                          ? 'Katalogeintrag ändern'
                          : 'Katalogeintrag auswählen'}
                      </button>
                    </span>
                  )}
                </span>
              </span>
            </span>
          </div>
        </span>

        {editable && catalogEntry && (
          <DeleteButton
            title="Entfernen"
            onDelete={() => {
              onDelete();
              setIsVisible(false);
            }}
          >
            Katalogeintrag entfernen
          </DeleteButton>
        )}
      </span>
    </>
  );
};
