import * as entryActions from 'actions/EntryActions';
import { CatalogList } from 'components/CatalogList';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { CatalogCategory } from 'typings/models';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

const mapStateToProps = (state: State, ownProps) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['versionId']);
  return {
    ...ownProps,
    projectversion: selectors.getProjectVersion(state, versionId),
    catalogs: selectors.getCatalogsByProjectVersion(state, versionId),
    edit_mode: state.loadingStates.edit_mode,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  onAddCatalog: (projectversionId: number, title: string, category: CatalogCategory) =>
    dispatch(entryActions.createCatalog(projectversionId, title, category)),
  onDeleteCatalog: (id: number) => dispatch(entryActions.deleteCatalog(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogList));
