import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { EntryShort, ProjectVersion } from 'typings/models';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  currentPath: string;
  entry: EntryShort;
  isLoading: boolean;
  projectversion: ProjectVersion;
  onHideFlyout: () => void;
};

const ListItem: React.FunctionComponent<Props> = (props: Props) => {
  const { entry, isLoading, currentPath, projectversion, onHideFlyout } = props;
  const active = currentPath.includes(`/entry/${entry.id}/`);
  return (
    <Link
      className={active ? 'active' : ''}
      to={
        isLoading
          ? '#'
          : `/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/base`
      }
      onClick={active ? onHideFlyout : () => {}}
    >
      {entry.signature ? (
        <span className="room-id"> {entry.signature}</span>
      ) : (
        '' || entry.title || '(neuer Eintrag)'
      )}
      {entry.title ? <span className="light"> {entry.title}</span> : ''}
      {isLoading && (
        <span style={{ marginLeft: 8 }}>
          <SimpleLoader size="tiny" isWhite />
        </span>
      )}
    </Link>
  );
};

export default ListItem;
