import PermissionSelect from 'components/PermissionSelect';
import UserSelect from 'components/UserSelect';
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { CopyProjectActionMembers } from 'typings/actions';
import { ModalType, PermissionLevel } from 'typings/enums';
import { User, UserShort } from 'typings/models';

type Props = {
  projectVersionId: number;
  userSearchResult: UserShort[];
  isSearchingUsers: boolean;
  ownUser: User;
  users: User[];
  onCopy: (title: string, members: CopyProjectActionMembers[]) => void;
  onSearchUser: (query: string) => void;
  onReturnToDashboard: () => void;
  onShowModal: (type: ModalType, title: string, content: any) => void;
  onHideModal: () => void;
  onGetUsers: () => void;
  copyHandler?: () => void;
};

type Users = {
  normalized: CopyProjectActionMembers;
  name: string;
};

const CopyProject = (props: Props) => {
  const {
    projectVersionId,
    isSearchingUsers,
    ownUser,
    userSearchResult,
    users,
    onSearchUser,
    onCopy,
    onReturnToDashboard,
    onGetUsers,
    onShowModal,
    onHideModal,
  } = props;

  const [title, setTitle] = React.useState<string>('');
  const [request, setRequest] = React.useState<Users[]>([]);

  const [user, setUser] = React.useState<UserShort>();
  const [permission, setPermission] = React.useState<PermissionLevel | undefined>();

  const isSaveDisabled = !title;

  const copyHandler = () => {
    onCopy(
      title,
      request.map((r) => r.normalized)
    );
    onHideModal();
    onReturnToDashboard();
  };

  React.useEffect(() => {
    const versionUsers =
      users &&
      users
        .filter((u) => {
          return u.projectversions.filter((p) => p.id === projectVersionId).length === 1;
        })
        .map((u) => ({
          id: u.id,
          permission: u.projectversions.find((p) => p.id === projectVersionId)?.permissions ?? 0,
          name: `${u.academic_title} ${u.first_name} ${u.last_name}`,
        }));

    versionUsers &&
      request.length < 2 &&
      setRequest(
        versionUsers.map(
          (vu) =>
          ({
            normalized: {
              id: vu.id,
              permission: vu.permission,
            },
            name: vu.name,
          } as Users)
        )
      );
  }, [users]);

  React.useEffect(() => {
    onGetUsers();
  }, []);

  const selectUser = (user: UserShort) => {
    setUser(user);
  };

  const addUser = () => {
    user &&
      setRequest([
        ...request,
        {
          normalized: { id: user.id, permission: permission?.valueOf() ?? 0 },
          name: `${user.academic_title} ${user.first_name} ${user.last_name}`.trim(),
        },
      ]);
    setUser(undefined);
  };

  const getPermission = (id: number): string => {
    switch (id) {
      case 0:
        return 'Lesen';
      case 1:
        return 'Schreiben';
      case 2:
        return 'Administrieren';
    }
    return '';
  };

  const removeUser = (id: number) => {
    setRequest(request.filter((r) => r.normalized.id !== id));
  };

  return (
    <DocumentTitle title={`Projektversion anlegen`}>
      <div className="plain-page project-settings">
        <h2 style={{ margin: '45px 0 15px' }}>Projektversion anlegen</h2>
        <input
          className="required"
          required={true}
          type="text"
          placeholder="Name für neue Version*"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="add-member-container" style={{ paddingBottom: user ? '40px' : 'initial' }}>
          <div>
            <UserSelect
              isLoading={isSearchingUsers}
              users={userSearchResult.filter(
                (u) => !request.map((r) => r.normalized.id).includes(u.id)
              )}
              onSearch={onSearchUser}
              onSelectUser={selectUser}
            />
            <PermissionSelect onChange={setPermission} />
          </div>
          {user && <button onClick={addUser}>Nutzer Hinzufügen</button>}
        </div>
        <table className="room-data">
          <tbody>
            {ownUser && (
              <tr>
                <th>
                  {`${ownUser.academic_title} ${ownUser.first_name} ${ownUser.last_name}`.trim()}
                </th>
                <td>Administrieren</td>
                <td></td>
              </tr>
            )}
            {request
              .filter((r) => r.normalized.id !== ownUser.id)
              .map((r) => {
                return (
                  <tr key={r.normalized.id}>
                    <th>{r.name}</th>
                    <td>{getPermission(r.normalized.permission)}</td>
                    <td>
                      <button
                        className="tertiary delete-button"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        onClick={(_e) => removeUser(r.normalized.id)}
                      >
                        Löschen
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <button
          className="big"
          disabled={isSaveDisabled}
          onClick={() =>
            onShowModal(
              ModalType.WARNING,
              'Warnung',
              <ConfirmModal {...props} copyHandler={copyHandler}></ConfirmModal>
            )
          }
        >
          Projektversion anlegen
        </button>
      </div>
    </DocumentTitle>
  );
};

const ConfirmModal = (props: Props) => (
  <div>
    <p>
      Sind Sie sicher, dass Sie eine neue Version anlegen wollen? Die aktuelle Version wird
      hierdurch unbearbeitbar.
    </p>
    <button style={{ marginTop: '35px', backgroundColor: '#224466' }} onClick={props.copyHandler}>
      Bestätigen
    </button>
  </div>
);

export default CopyProject;
