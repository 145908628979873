import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as entryActions from 'actions/EntryActions';
import DepartmentDetails from 'components/DepartmentDetails';

import * as loadingStatesActions from 'actions/LoadingStates';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';
import { UpsertDepartmentEntry } from '../typings/models';

type Props = {
  match: {
    params: {
      entryId: string;
      projectId: string;
      departmentId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { entryId, departmentId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'departmentId',
    'versionId',
  ]);

  return {
    canAdminDepartmentEntry: selectors.canAdminDepartmentEntry(state, departmentId),
    canWriteDepartmentEntry: selectors.canWriteDepartmentEntry(state, departmentId),
    canAdminProjectVersion: selectors.canAdminEntry(state, versionId),
    creatingDepartmentEntry: state.loadingStates.creating_department_entry,
    department: selectors.getDepartment(state, departmentId),
    editMode: state.loadingStates.edit_mode,
    entry: selectors.getEntry(state, entryId),
    interval_next_maintenance: selectors.getIntervalNextMaintenance(state),
    interval_next_revision: selectors.getIntervalNextRevision(state),
    measure_classifications: selectors.getMeasureClassifications(state),
    measure_prioritizations: selectors.getMeasurePrioritizations(state),
    user: selectors.getUser(state),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const {
    match: {
      params: { departmentId, projectId, entryId },
    },
  } = ownProps;
  return {
    onCreateDepartmentEntry: (data: UpsertDepartmentEntry) =>
      dispatch(entryActions.createDepartmentEntry(data, entryId, departmentId)),
    onDeleteDepartmentEntry: (departmentEntryId: number) =>
      dispatch(
        entryActions.deleteDepartmentEntry(projectId, entryId, departmentId, departmentEntryId)
      ),
    onResetLoadingState: (key: string) => dispatch(loadingStatesActions.reset(key)),
    onMoveDepartmentEntry: (departmentEntryId: number, direction: 'up'|'down') => dispatch(entryActions.moveDepartmentEntry(departmentEntryId, direction)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DepartmentDetails));
