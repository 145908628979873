import posed from 'react-pose';

export const Parent = posed.ul({
  closed: {
    opacity: 0,
    transition: { duration: 200 },
  },
  open: {
    opacity: 1,
    staggerChildren: 30,
  },
});

export const Child = posed.li({
  closed: { y: -10, opacity: 0 },
  open: { y: 0, opacity: 1 },
});
