import React from 'react';
import { Link } from 'react-router-dom';
import { ProjectVersion } from 'typings/models';

interface ISuccessProps {
  projectversion: ProjectVersion;
  type?: 'pdf' | 'cad';
  assignedRooms?: string[];
  createdRooms?: string[];
}

const Success = ({ projectversion, assignedRooms, createdRooms }: ISuccessProps) => {
  return (
    <div className="plain-page">
      <h2>Import erfolgreich abgeschlossen</h2>
      {!!assignedRooms && (
        <>
          <h3>Zugeordnete Räume</h3>
          {assignedRooms.length === 0 ? (
            <div>(keine)</div>
          ) : (
            <ul>
              {assignedRooms.map((room) => (
                <li key={room}>{room}</li>
              ))}
            </ul>
          )}
        </>
      )}

      {!!createdRooms && (
        <>
          <h3>Erzeugte Räume</h3>
          {createdRooms.length === 0 ? (
            <div>(keine)</div>
          ) : (
            <ul>
              {createdRooms.map((room) => (
                <li key={room}>{room}</li>
              ))}
            </ul>
          )}
        </>
      )}

      <Link to={`/project/${projectversion.project.id}/version/${projectversion.id}/`}>
        zurück zum Projekt
      </Link>
    </div>
  );
};
export default Success;
