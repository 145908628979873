import * as React from 'react';
import { IProjectExportModalContentProps } from './ProjectExportModalContent.types';

const ProjectExportModalContent = (props: IProjectExportModalContentProps) => {
  const {
    enableNewFilenameCheck,
    projectExportHandler,
    onReturnToDashboard,
    onHideModal,
    children,
  } = props;
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const [generateNewFileNames, setGenerateNewFileName] = React.useState<boolean>(false);

  const onConfirmHandler = () => {
    generateNewFileNames ? projectExportHandler(generateNewFileNames) : projectExportHandler();
    onReturnToDashboard();
    onHideModal();
  };

  return (
    <div>
      {children}
      <div>
        {enableNewFilenameCheck && (
          <>
            <input
              type="radio"
              name="filename-strategy"
              id="new-filenames-deny"
              onChange={() => setGenerateNewFileName(false)}
              checked={!generateNewFileNames}
            />
            <label htmlFor={'new-filenames-deny'}>Dateibezeichnungen beibehalten</label>
            <br />
            <input
              type="radio"
              name="filename-strategy"
              id="new-filenames-confirm"
              onChange={() => setGenerateNewFileName(true)}
              checked={generateNewFileNames}
            />
            <label htmlFor={'new-filenames-confirm'}>Dateinamen automatisch neu generieren</label>
            <br />
            <br />
          </>
        )}
        <input
          type="checkbox"
          id="confirm-check"
          onChange={() => setConfirm(!confirm)}
          checked={confirm}
        />
        <label htmlFor={'confirm-check'}>Ich habe verstanden und möchte fortfahren.</label>
      </div>
      <button style={{ marginTop: '35px' }} disabled={!confirm} onClick={onConfirmHandler}>
        Fortfahren
      </button>
    </div>
  );
};

export default ProjectExportModalContent;
