import { DepartmentEntry, Entry, ProjectVersion } from 'typings/models';
import React from 'react';
import {
  IDropdownWithCountRendererProps,
  IEntryLink,
  IGetNavigationLinkProps,
  ILinkLists,
  INavigationLink,
} from './EntryNavigator.types';

export const getNavigationLinks = ({
  list,
  currentPath,
}: IGetNavigationLinkProps): INavigationLink => {
  const currentIndex = list.findIndex((item) => item.to === currentPath);
  return {
    next: currentIndex < list.length - 1 ? list[currentIndex + 1].to : currentPath,
    previous: currentIndex > 0 ? list[currentIndex - 1].to : currentPath,
  };
};

export const getEntryLabelsWithCount = (
  departmentEntries: DepartmentEntry[]
): Record<number, number> => {
  if (departmentEntries === null) {
    return {};
  }

  let innerCounter: Record<number, number> = {};
  return departmentEntries
    .filter((entry) => {
      if (entry !== null && entry.department !== null && entry.department.id !== null) {
        return !!(innerCounter = { ...innerCounter, [entry.department.id]: 0 });
      }
    })
    .reduce((counter: Record<number, number>, entry) => {
      counter[entry.department.id]++;
      return counter;
    }, innerCounter);
};

export const renderDropdownWithEntryCount = ({
  title,
  entries,
  fallbackUrl,
  pathname,
  onNavigate,
  departmentEntries,
}: IDropdownWithCountRendererProps): React.ReactNode => {
  const entryCounts = getEntryLabelsWithCount(departmentEntries);
  const updatedEntries = entries.map((entry) => ({
    ...entry,
    label: `${entry.label}${
      !!entry.id && !!entryCounts[entry.id] ? ` (${entryCounts[entry.id]})` : ''
    }`,
  }));
  return renderDropdown(title, updatedEntries, fallbackUrl, pathname, onNavigate);
};

export const renderDropdown = (
  title: string,
  entries: IEntryLink[],
  fallbackUrl: string,
  pathname: string,
  onNavigate: (url: string) => void
): React.ReactNode => {
  return (
    <select
      className="mousetrap"
      value={pathname}
      size={1}
      onChange={(e) => (e.target.value ? onNavigate(e.target.value) : onNavigate(fallbackUrl))}
    >
      <option value="">{title}</option>
      {entries.map((item, index) => (
        <option key={index} value={item.to}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

export const makeLinkLists = (
  projectversion: ProjectVersion | undefined,
  entry: Entry | undefined
): ILinkLists => {
  if (!projectversion || !entry) {
    return {
      base: {
        label: '',
        to: '',
      },
      departments: [],
      linkList: [],
      walls: [],
    };
  }

  const departmentHasEntries = (entryToCheck: Entry, departmentId: number) =>
    !!entryToCheck.department_entries &&
    entryToCheck.department_entries.filter(
      (departmentEntry) => departmentEntry.department.id === departmentId
    ).length > 0;

  const base = {
    label: 'Basis',
    to: `/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/base`,
  };
  const walls = entry.walls
    ? entry.walls.map((wall) => ({
        label: `Wand / Fläche: ${wall.token}`,
        to: `/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/wall/${wall.id}`,
      }))
    : [];

  const fc = projectversion.field_configurations.find(
    (configuration) => configuration.entry_type.id === entry.entry_type.id
  );

  const departments = fc
    ? fc.departments.map((department) => ({
        id: department.id,
        empty: !departmentHasEntries(entry, department.id),
        label: department.title,
        to: `/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/department/${department.id}`,
      }))
    : [];

  const linkList: IEntryLink[] = [base, ...walls, ...departments];

  return {
    base,
    departments,
    linkList,
    walls,
  };
};
