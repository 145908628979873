export const getNewEntry = (selectedProjectVersionId: string, entryId: number, history: Record<string, any>): string | undefined => {
  const historyEntry = Object.entries<Record<string, any>[]>(history)
    .filter(([, value]) => value.filter((innerValue) => innerValue.id === entryId).length > 0)
    ?.shift();

  return historyEntry?.[1].find(
    (value: Record<string, any>) =>
      value.projectversion.id === parseInt(selectedProjectVersionId)
  )?.id;
};

export const getUpdatedPathById = (basePath: string, entryId: string) => {
  const entryRegExp = new RegExp('/entry.*');
  return basePath.replace(entryRegExp, `/entry/${entryId}`);
};
