import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { ProjectVersion } from 'typings/models';

type Props = RouteComponentProps & {
  projectversion: ProjectVersion;
  isStaff: boolean;
  projectActive: boolean;
  projectExported: boolean;
  exportedBy?: string;
};

const ProjectLink = (props: Props) => {
  const { projectversion, isStaff, projectActive, projectExported, exportedBy } = props;
  const active = projectActive || isStaff
  return (
    <Link to={active ? `/project/${projectversion.project.id}/version/${projectversion.id}` : "#"} className={active ? "" : "disabled"}>
      {projectversion.project.title}
      {!projectActive && (
        <>
          <LockSvg />
          {!!projectExported && !!exportedBy && (
            <>
              <LockSvg className='text-on-hover' />
              <span>Exportiert von: {exportedBy}</span>
            </>
          )}
        </>
      )}
    </Link>
  );
};

const LockSvg = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    {/* Same "Lock"-SVG as in the edit button*/}
    <g fill="none" fillRule="evenodd">
      <path d="M7.6 10.4h8.8V7a4.4 4.4 0 1 0-8.8 0v3.4z" strokeWidth="1.2" />
      <path d="M5.5 10.5h13v10h-13z" />
    </g>
  </svg>
)

export default withRouter(ProjectLink);
