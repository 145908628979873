import * as React from 'react';
import { hotkeys } from 'config/hotkeys';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

const Help = () => (
  <DocumentTitle title="Hilfe">
    <div className="plain-page">
      <div className="top-right">
        <Link to="/dashboard/" className="button button-home">
          zurück
        </Link>
      </div>
      <h2>Hilfe</h2>
      <h3>Tastenkürzel</h3>
      <table>
        <thead>
          <tr>
            <th>Kürzel Windows</th>
            <th>Kürzel MacOS</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(hotkeys).map((hotkey, index) => (
            <tr key={index}>
              <td>{hotkey.hotkeyReadable}</td>
              <td>{hotkey.hotkeyReadable.replace('Ctrl', '⌘').replace('Alt', '⌥')}</td>
              <td>{hotkey.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </DocumentTitle>
);

export default Help;
