import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ProjectActionType } from 'actions/actionTypes';
import * as entryActions from 'actions/EntryActions';
import { showModal, showToast } from 'actions/GuiActions';
import * as projectActions from 'actions/ProjectActions';
import * as api from 'api/WebApi';
import { AddProjectMember } from 'typings/actions';
import { ModalType, ToastType } from 'typings/enums';
import { checkResponseStatus } from './utils';
import { getUserId } from 'reducers/app';
import { refetchUser } from 'actions/AuthActions';

type Answer = {
  message: any;
};

export function* workflowUploadFloormap(action) {
  const {
    payload,
    payload: { versionId, facilityLevel, files },
  } = action;

  let json: Answer = { message: null };
  try {
    yield put(projectActions.uploadingFloormap(payload));
    const response = yield call(api.uploadFloormap, versionId, facilityLevel, files);
    json = yield response.json();
    checkResponseStatus(response);
    yield put(projectActions.uploadedFloormap({ versionId, json }));
  } catch (error) {
    yield put(
      showToast('Beim Hochladen des Raumplans ist ein Fehler aufgetreten', {
        type: ToastType.ERROR,
      })
    );
    yield put(projectActions.uploadFloormapFailed(json.message));
  }
}

export function* workflowLoadFacilityGeoJSON(action) {
  const {
    payload: { facility_level_id },
  } = action;
  let json;
  try {
    const response = yield call(api.loadFacilityGeoJSON, facility_level_id);
    checkResponseStatus(response);
    json = yield response.json();
    yield put(projectActions.loadedFacilityGeoJSON(facility_level_id, json));
  } catch (errors) {
    yield put(projectActions.loadingFacilityGeoJSONFailed(facility_level_id, errors));
  }
}

export function* workflowImportFloormap(action) {
  const {
    payload,
    payload: { token, projectId, versionId, facility_level, rooms, creator, creationDate },
  } = action;

  yield put(projectActions.importingFloormap(payload));
  const response = yield call(api.importFloormap, token, versionId, facility_level, rooms, creator, creationDate);
  const json = yield response.json();
  yield put(projectActions.loadProject(versionId));
  yield put(entryActions.loadEntries(projectId));
  yield put(projectActions.importedFloormap({ json }));
  yield put(showToast('Der Raumplan wurde erfolgreich importiert.', { type: ToastType.SUCCESS }));
}

export function* workflowLoadProject(action) {
  const projectVersionId = parseInt(action.payload, 10);
  yield put(projectActions.loadingProject(projectVersionId));

  let json = null;
  try {
    const response = yield call(api.loadProjectVersion, projectVersionId);
    checkResponseStatus(response);
    json = yield response.json();
    yield put(projectActions.normalizeProject(json));
    yield put(projectActions.loadedProject(projectVersionId, json));
  } catch (errors) {
    yield put(
      showModal(ModalType.WARNING, `Konnte ProjektVersion ${projectVersionId} nicht laden`)
    );
    yield put(projectActions.loadProjectFailed({ id: projectVersionId, errors }));
  }
}

export function* workflowLoadFloormap(action) {
  const projectVersionId = parseInt(action.payload, 10);
  yield put(projectActions.loadingFloormap(projectVersionId));

  let json = null;
  try {
    const response = yield call(api.loadProjectVersion, projectVersionId);
    checkResponseStatus(response);
    json = yield response.json();
    yield put(projectActions.normalizeProject(json));
    yield put(projectActions.loadedFloormap(projectVersionId, json));
  } catch (errors) {
    yield put(
      showModal(
        ModalType.WARNING,
        `Konnte Karte für ProjektVersion ${projectVersionId} nicht laden`
      )
    );
    yield put(projectActions.loadFloormapFailed({ id: projectVersionId, errors }));
  }
}

export function* workflowAddProjectMember(action: AddProjectMember) {
  const {
    payload,
    payload: { versionId, userId, permissions },
  } = action;

  let json: Answer = { message: null };
  try {
    yield put(projectActions.addingProjectMember(payload));
    const response = yield call(api.addProjectMember, versionId, userId, permissions);
    json = yield response.json();
    checkResponseStatus(response);
    yield put(projectActions.addedProjectMember({ members: json, versionId }));
  } catch (error) {
    showToast('Konnte Mitarbeiter:in nicht hinzufügen', { type: ToastType.ERROR });
    yield put(projectActions.addProjectMemberFailed(payload));
  }
}

export function* workflowRemoveProjectMember(action) {
  const {
    payload,
    payload: { versionId, membershipId },
  } = action;

  let json: Answer = { message: null };
  try {
    yield put(projectActions.removingProjectMember(payload));
    const response = yield call(api.removeProjectMember, versionId, membershipId);
    json = yield response.json();
    checkResponseStatus(response);
    yield put(projectActions.removedProjectMember({ members: json, versionId }));
  } catch (error) {
    showToast('Konnte Mitarbeiter:in nicht entfernen', { type: ToastType.ERROR });
    yield put(projectActions.removeProjectMemberFailed(payload));
  }
}

export function* workflowExportProjectVersionPdf(action) {
  try {
    const response = yield call(api.exportProjectVersionPdf, action.payload);
    checkResponseStatus(response);
    yield put(
      showToast(
        'Der Raumbuch-Export wird erzeugt. Sie erhalten eine Benachrichtigung, sobald die Datei zum Download bereit steht. Je nach Raumbuchgröße kann die Erstellung des PDFs für den Download bis zu 2h dauern. Sie erhalten eine E-Mail mit dem PDF-Export, sobald der Download abgeschlossen ist.',
        { type: ToastType.SUCCESS, autoClose: 15000 }
      )
    );
  } catch (e) {
    yield put(
      showModal(
        ModalType.WARNING,
        `Beim Export ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Support: support@raumbuch-digital.de`
      )
    );
    return;
  }
}

export function* workflowInitiateProjectExport(action) {
  try {
    const response = yield call(api.initiateProjectExport, action.payload);
    checkResponseStatus(response);
    yield put(
      showToast(
        'Der Export-Prozess wird nun gestartet. Sie erhalten eine Benachrichtigung, sobald die Datei zum Download bereit steht.',
        { type: ToastType.SUCCESS }
      )
    );
  } catch (e) {
    yield put(
      showModal(
        ModalType.WARNING,
        `Beim Starten des Exports ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Support: support@raumbuch-digital.de`
      )
    );
    return;
  }
  const userId = yield select(getUserId)
  yield put(refetchUser(userId));
}

export function* workflowAbortProjectExport(action) {
  try {
    const response = yield call(api.abortProjectExport, action.payload);
    checkResponseStatus(response);
    yield put(
      showToast(
        'Der Export-Prozess wurde abgebrochen und der Initialzustand wiederhergestellt.',
        { type: ToastType.SUCCESS }
      )
    );
  } catch (e) {
    yield put(
      showModal(
        ModalType.WARNING,
        `Beim Abbrechen des Exports ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Support: support@raumbuch-digital.de`
      )
    );
    return;
  }
  const userId = yield select(getUserId)
  yield put(refetchUser(userId));
}

export function* workflowExportProject(action) {
  try {
    const response = yield call(api.exportProject, action.payload);
    checkResponseStatus(response);
    yield put(
      showToast(
        'Der Raumbuch-Export wird erzeugt. Sie erhalten eine Benachrichtigung, sobald die Datei zum Download bereit steht.',
        { type: ToastType.SUCCESS }
      )
    );
  } catch (e) {
    yield put(
      showModal(
        ModalType.WARNING,
        `Beim Export ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Support: support@raumbuch-digital.de`
      )
    );
    return;
  }
}

export function* workflowCopyProjectVersion(action) {
  yield put(projectActions.copyingProjectVersion());

  try {
    const response = yield call(api.copyProjectVersion, action.payload);
    checkResponseStatus(response);
    yield put(
      showToast(
        'Die neue Version wird nun angelegt. Sie erhalten eine Benachrichtigung, sobald diese zur Verfügung steht.',
        { type: ToastType.SUCCESS }
      )
    );
  } catch (e) {
    yield put(
      showModal(
        ModalType.WARNING,
        `Beim Kopieren ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an den Support: support@raumbuch-digital.de`
      )
    );
    return;
  }
  yield put(projectActions.copiedProjectVersion());
}
// Watchers

export function* watchUploadFloormap() {
  yield takeLatest(ProjectActionType.UPLOAD_FLOORMAP, workflowUploadFloormap);
}

export function* watchImportFloormap() {
  yield takeLatest(ProjectActionType.IMPORT_FLOORMAP, workflowImportFloormap);
}

export function* watchLoadProject() {
  yield takeLatest(ProjectActionType.LOAD_PROJECT, workflowLoadProject);
}

export function* watchLoadFloormap() {
  yield takeLatest(ProjectActionType.LOAD_FLOORMAP, workflowLoadFloormap);
}

export function* watchAddProjectMember() {
  yield takeLatest(ProjectActionType.ADD_PROJECT_MEMBER, workflowAddProjectMember);
}

export function* watchRemoveProjectMember() {
  yield takeLatest(ProjectActionType.REMOVE_PROJECT_MEMBER, workflowRemoveProjectMember);
}

export function* watchInitiateProjectExport() {
  yield takeLatest(ProjectActionType.INITIATE_PROJECT_EXPORT, workflowInitiateProjectExport);
}

export function* watchAbortProjectExport() {
  yield takeLatest(ProjectActionType.ABORT_PROJECT_EXPORT, workflowAbortProjectExport);
}

export function* watchExportProject() {
  yield takeLatest(ProjectActionType.EXPORT_PROJECT, workflowExportProject);
}

export function* watchExportProjectVersionPdf() {
  yield takeLatest(ProjectActionType.EXPORT_PROJECTVERSION_PDF, workflowExportProjectVersionPdf);
}

export function* watchLoadFacilityGeoJSON() {
  yield takeLatest(ProjectActionType.LOAD_FACILITY_GEOJSON, workflowLoadFacilityGeoJSON);
}

export function* watchCopyProjectVersion() {
  yield takeLatest(ProjectActionType.COPY_PROJECT, workflowCopyProjectVersion);
}
