import { schema } from 'normalizr';
import assetSchema from './asset_schema';
import userSchema from './user_schema';

const project = new schema.Entity('projects', {
  assets: [assetSchema],
  compositions: [new schema.Entity('compositions')],
  damage_intensity: [new schema.Entity('damage_intensities')],
  door_types: [new schema.Entity('door_types')],
  examination_type: [new schema.Entity('examination_types')],
  inscriptions: [new schema.Entity('inscriptions')],
  interval_next_maintenance: [new schema.Entity('interval_next_maintenance')],
  interval_next_revision: [new schema.Entity('interval_next_revision')],
  locations: [new schema.Entity('locations')],
  materials: [new schema.Entity('materials')],
  measure: [new schema.Entity('measures')],
  measure_prioritization: [new schema.Entity('measure_prioritization')],
  measure_classification: [new schema.Entity('measure_classification')],
  overgrowns: [new schema.Entity('overgrowns')],
  stabilities: [new schema.Entity('stabilities')],
  stability_result: [new schema.Entity('stability_results')],
  stratifications: [new schema.Entity('stratifications')],
  wall_construction_types: [new schema.Entity('wall_construction_types')],
  wall_surface_types: [new schema.Entity('wall_surface_types')],
  window_types: [new schema.Entity('window_types')],
  exported_by: userSchema,
});

export default project;
