import { connectRouter } from 'connected-react-router';
import defaultTo from 'lodash/defaultTo';
import find from 'lodash/find';
import { combineReducers } from 'redux';

import { AuthActionType } from 'actions/actionTypes';
import entrySchema from 'api/entry_schema';
import wallSchema from 'api/wall_schema';
import { denormalize } from 'normalizr';
import { Action } from 'typings/actions';
import { PermissionLevel, RequestStatus } from 'typings/enums';
import {
  Department,
  Entry,
  EntryShort,
  EntryType,
  Facility,
  FacilityLevel,
  FieldConfiguration,
  Modal,
  MovingAssetStatus,
  ProjectVersion,
  SearchedEntriesPayload,
  UploadingAssetStatus,
  User,
  UserShort,
  Wall,
} from 'typings/models';
import { State } from 'typings/state';
import authReducer, * as fromAuth from './auth';
import dashboardReducer, * as fromDashboard from './dashboard';
import entityReducer, * as fromEntities from './entities';
import entriesReducer, * as fromEntries from './entries';
import entrylistfilterReducer from './entrylistFilter';
import guiReducer, * as fromGui from './gui';
import loadingStatesReducer, * as fromLoadingStates from './loadingStates';
import notificationsReducer, * as fromNotification from './notifications';
import settingsReducer, * as fromSettings from './settings';
import tokenReducer, * as fromToken from './token';

const appReducer = (history) =>
  combineReducers<State | undefined, Action>({
    auth: authReducer,
    dashboard: dashboardReducer,
    entities: entityReducer,
    entries: entriesReducer,
    entrylistFilter: entrylistfilterReducer,
    gui: guiReducer,
    loadingStates: loadingStatesReducer,
    router: connectRouter(history) as any,
    settings: settingsReducer,
    token: tokenReducer,
    notifications: notificationsReducer,
  });

const roombookApp = (history) => (state: State, action) => {
  if (action.type === AuthActionType.LOGOUT) {
    return appReducer(history)(undefined, action);
  }
  return appReducer(history)(state, action);
};

export default roombookApp;

export const getFlyout = (state: State) => fromGui.getFlyout(state.gui);

export const getDepartment = (state: State, departmentId: number): Department | null =>
  fromEntities.getDepartment(state.entities, departmentId);

export const getWall = (state: State, wallId: number): Wall | null =>
  denormalize(state.entities.walls[wallId], wallSchema, state.entities);

export const getEntry = (state: State, entryId): Entry =>
  denormalize(state.entities.entries[entryId], entrySchema, state.entities);

export const getEntries = (
  state: State,
  projectVersionId?: number,
  performDenormalize = true
): EntryShort[] => {
  const entries = projectVersionId
    ? state.entries.list.results.filter((e) => e.projectversion === projectVersionId)
    : state.entries.list.results;
  return performDenormalize
    ? entries.map((entry) => denormalize(entry, entrySchema, state.entities))
    : entries;
};

export const getDoorTypes = (state: State) => fromEntities.getDoorTypes(state.entities);
export const getWindowTypes = (state: State) => fromEntities.getWindowTypes(state.entities);
export const getCatalog = (state: State, catalogId: number) =>
  fromEntities.getCatalog(state.entities, catalogId);
export const getCatalogsByProjectVersion = (state: State, projectVersionId: number) =>
  fromEntities.getCatalogsByProjectVersion(state.entities, projectVersionId);
export const getCatalogEntry = (state: State, catalogEntryId: number) =>
  fromEntities.getCatalogEnty(state.entities, catalogEntryId);
export const getCatalogEntries = (state: State, catalogId: number) =>
  fromEntities.getCatalogEntries(state.entities, catalogId);
export const getLocations = (state: State) => fromEntities.getLocations(state.entities);
export const getStabilities = (state: State) => fromEntities.getStabilities(state.entities);
export const getStratifications = (state: State) => fromEntities.getStratifications(state.entities);
export const getOvergrowns = (state: State) => fromEntities.getOvergrowns(state.entities);
export const getCompositions = (state: State) => fromEntities.getCompositions(state.entities);
export const getInscriptions = (state: State) => fromEntities.getInscriptions(state.entities);
export const getDamageIntensities = (state: State) =>
  fromEntities.getDamageIntensities(state.entities);
export const getMeasurePrioritizations = (state: State) =>
  fromEntities.getMeasurePrioritizations(state.entities);
export const getMeasureClassifications = (state: State) =>
  fromEntities.getMeasureClassifications(state.entities);
export const getMeasures = (state: State) => fromEntities.getMeasures(state.entities);
export const getExaminationTypes = (state: State) =>
  fromEntities.getExaminationTypes(state.entities);
export const getStabilityResults = (state: State) =>
  fromEntities.getStabilityResults(state.entities);
export const getMaterials = (state: State) => fromEntities.getMaterials(state.entities);
export const getIntervalNextMaintenance = (state: State) =>
  fromEntities.getIntervalNextMaintenance(state.entities);
export const getIntervalNextRevision = (state: State) =>
  fromEntities.getIntervalNextRevision(state.entities);

export const getWallConstructionTypes = (state: State) =>
  fromEntities.getWallConstructionTypes(state.entities);

export const getWallSurfaceTypes = (state: State) =>
  fromEntities.getWallSurfaceTypes(state.entities);

export const getFacilities = (state: State, versionId: number): Facility[] =>
  fromEntities.getFacilities(state.entities, versionId);

export const getFacilityLevels = (state: State, facilityId: number): FacilityLevel[] =>
  fromEntities.getFacilityLevels(state.entities, facilityId);

export const getProject = (state: State, projectId) =>
  fromEntities.getProject(state.entities, projectId);

export const getProjectVersion = (state: State, projectVersionId) =>
  fromEntities.getProjectVersion(state.entities, projectVersionId);

export const getUser = (state: State): User | undefined => {
  const userId = fromToken.getUserId(state.token);
  return userId ? fromAuth.getUser(state.auth, userId) : undefined;
};

export const getUsers = (state: State): User[] => {
  return fromAuth.getUsers(state.auth);
};

export const getToken = (state: State) => fromToken.getToken(state.token);
export const getUserId = (state: State) => fromToken.getUserId(state.token);

export const getPrivacyNoteVisible = (state: State) =>
  fromSettings.getPrivacyNoteVisible(state.settings);

export const getModal = (state: State): Modal | undefined => fromGui.getModal(state.gui);

export const getDashboard = (state: State) => fromDashboard.getDashboard(state.dashboard);

export const getEntryTypes = (state: State, versionId: number): EntryType[] =>
  fromEntities.getEntryTypes(state.entities, versionId);

export const getDepartments = (state: State, versionId: number): Department[] =>
  fromEntities.getDepartments(state.entities, versionId);

export const fieldInConfiguration = (
  state: State,
  versionId: number,
  entryType: number,
  fieldname: string
): boolean => {
  const fc = defaultTo(
    find(
      state.entities.field_configurations,
      (f) => f.projectversion === versionId && f.entry_type === entryType
    ),
    []
  );
  return fc.fields.includes(fieldname);
};

export const getFieldConfigurationByEntryType = (
  projectversion: ProjectVersion,
  entryType: number
): FieldConfiguration | null =>
  projectversion.field_configurations.find((fc) => fc.entry_type.id === entryType) || null;

export const getHasCatalogs = (projectVersion: ProjectVersion): Boolean =>
  !!projectVersion.field_configurations.find((fc) => fc.has_catalogs === true);

export const getSearchResults = (state: State): SearchedEntriesPayload[] =>
  fromEntries.getSearchResults(state.entries);

export const getFilteredFulltextResults = (state: State): SearchedEntriesPayload[] => {
  return getSearchResults(state);
};

export const canWriteDepartmentEntry = (state: State, departmentId: number) => {
  const departmentPermission = fromEntities.getDepartmentPermissions(state.entities, departmentId);
  return (
    departmentPermission === PermissionLevel.WRITE || departmentPermission === PermissionLevel.ADMIN
  );
};

export const canAdminDepartmentEntry = (state: State, departmentId: number) =>
  fromEntities.getDepartmentPermissions(state.entities, departmentId) === PermissionLevel.ADMIN;

export const canEditEntry = (state: State, versionId: number): boolean => {
  const projectPermissions = fromEntities.getProjectVersionPermissions(state.entities, versionId);
  return (
    projectPermissions === PermissionLevel.WRITE || projectPermissions === PermissionLevel.ADMIN
  );
};

export const canAdminEntry = (state: State, versionId: number): boolean =>
  fromEntities.getProjectVersionPermissions(state.entities, versionId) === PermissionLevel.ADMIN;

export const canEditAnyDepartment = (state: State, versionId: number): boolean =>
  fromEntities.canEditAnyDepartment(state.entities, versionId);

export const canEditProject = (state: State, versionId: number) =>
  fromEntities.canEditProject(state.entities, versionId);

export const canEditAnything = (state: State, versionId: number): boolean =>
  fromEntities.canEditAnything(state.entities, versionId);

export const getAssetUploads = (state: State): UploadingAssetStatus =>
  fromLoadingStates.getAssetUploads(state.loadingStates);

export const getAssetMovings = (state: State): MovingAssetStatus =>
  fromLoadingStates.getAssetMovings(state.loadingStates);

export const loggingIn = (state: State): boolean => fromAuth.loggingIn(state.auth);
export const loginFailed = (state: State): boolean => fromAuth.loginFailed(state.auth);

export const getUserSearchResult = (state: State): UserShort[] =>
  fromAuth.getUserSearchResult(state.auth);

export const isSearchingUsers = (state: State): boolean =>
  fromLoadingStates.isSearchingUsers(state.loadingStates);

export const isAddingProjectMember = (state: State): boolean =>
  fromLoadingStates.isAddingProjectMember(state.loadingStates);

export const getPasswordResetRequestStatus = (state: State): RequestStatus =>
  fromLoadingStates.getPasswordResetRequestStatus(state.loadingStates);

export const getPasswordSetRequestStatus = (state: State): RequestStatus =>
  fromLoadingStates.getPasswordSetRequestStatus(state.loadingStates);

export const getNotificationsShown = (state: State) => fromGui.getNotificationsShown(state.gui);

export const getNotifications = (state: State) =>
  fromNotification.getNotifications(state.notifications);
export const getUnreadNotificationsNumber = (state: State) =>
  fromNotification.getUnreadNotificationsNumber(state.notifications);

export const getAssetMarkedToMove = (state: State): number | null =>
  fromEntities.getAssetMarkedToMove(state.entities);
