export enum ModalType {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum PermissionLevel {
  READ = 0,
  WRITE = 1,
  ADMIN = 2,
}

export enum LoadRequestState {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export enum RequestStatus {
  NONE,
  REQUESTED,
  REQUEST_GRANTED,
  REQUEST_FAILED,
}

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warning',
  ERROR = 'error',
}

export enum ToastPosition {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right',
}
