import { CatalogEntryList } from 'components/CatalogEntryList';
import { NewCatalogEntryForm } from 'components/NewCatalogEntryForm';
import { InlineEditSelect, InlineEditText } from 'containers/InlineEditContainer';
import React from 'react';
import { catalogCategoryChoices, catalogCategoryOptions } from 'typings/models';
import { IExistingCatalogDetailsContentProps } from './ExistingCatalogDetailsContent.types';

const ExistingCatalogDetailsContent: React.FC<IExistingCatalogDetailsContentProps> = ({
  catalog,
  catalogEntries = [],
  edit_mode,
  newCatalogEntry,
  onChangeNewCatalogEntry,
  onDeleteCatalogEntry,
  onSelectCatalogEntry,
  onSubmitNewCatalogEntry,
}) => (
  <>
    <table className="catalog-entry-list">
      <tbody>
        <tr>
          <td colSpan={3}>
            <h4>Kataloginformationen</h4>
          </td>
        </tr>
        <tr>
          <th>Titel</th>
          <td>
            <InlineEditText
              entity="catalogs.title"
              entity_id={catalog.id}
              value={catalog.title}
              editable={edit_mode}
            />
          </td>
        </tr>
        <tr>
          <th>Kategorie</th>
          <td>
            <InlineEditSelect
              entity="catalogs.category"
              entity_id={catalog.id}
              value={{
                id: catalog.category,
                title: catalogCategoryOptions[catalog.category ?? 'None'],
              }}
              editable={edit_mode}
              choices={catalogCategoryChoices.filter(([value]) => value !== 'None')}
              prevent_empty
              hint="z.B. Fenster"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <>
      {newCatalogEntry && (
        <div className="catalog-details-content-children">
          <h4>Neuer Katalogeintrag</h4>
          <NewCatalogEntryForm
            value={newCatalogEntry}
            onChange={onChangeNewCatalogEntry}
            onSubmit={onSubmitNewCatalogEntry}
          />
        </div>
      )}
    </>
    {catalog && !!catalogEntries.length && (
      <CatalogEntryList
        catalogEntries={catalogEntries}
        onDeleteCatalogEntry={(id) => onDeleteCatalogEntry?.(id)}
        onSelectCatalogEntry={(id) => onSelectCatalogEntry?.(id)}
        editable={edit_mode}
      />
    )}
  </>
);

export default ExistingCatalogDetailsContent;
