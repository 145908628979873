import posed from 'react-pose';

const SlideInTop = posed.div({
  hidden: { y: -10, opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 200 },
    y: 0,
  },
});

export default SlideInTop;
