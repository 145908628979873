enum EntryActionType {
  LOAD_ENTRIES = 'entry/LOAD_ENTRIES',
  LOADING_ENTRIES = 'entry/LOADING_ENTRIES',
  LOADED_ENTRIES = 'entry/LOADED_ENTRIES',
  LOAD_ENTRIES_FAILED = 'entry/LOAD_ENTRIES_FAILED',
  NORMALIZE_ENTRY = 'entry/NORMALIZE_ENTRY',

  CREATE_ENTRY = 'entry/CREATE_ENTRY',
  CREATING_ENTRY = 'entry/CREATING_ENTRY',
  CREATED_ENTRY = 'entry/CREATED_ENTRY',
  CREATE_ENTRY_FAILED = 'entry/CREATE_ENTRY_FAILED',

  LOAD_ENTRY = 'entry/LOAD_ENTRY',
  LOAD_ENTRY_FAILED = 'entry/LOAD_ENTRY_FAILED',
  LOADING_ENTRY = 'entry/LOADING_ENTRY',
  LOADED_ENTRY = 'entry/LOADED_ENTRY',
  DELETE_ENTRY = 'entry/DELETE_ENTRY',
  DELETED_ENTRY = 'entry/DELETED_ENTRY',

  SAVE_ENTITY_FIELD = 'entry/SAVE_ENTITY_FIELD',
  SAVED_ENTITY_FIELD = 'entry/SAVED_ENTITY_FIELD',
  EDITING_ENTITY_FIELD = 'entry/EDITING_ENTITY_FIELD',
  CANCEL_EDIT_ENTITY_FIELD = 'entry/CANCEL_EDIT_ENTITY_FIELD',

  CREATE_WALL = 'entry/CREATE_WALL',
  CREATING_WALL = 'entry/CREATING_WALL',
  CREATED_WALL = 'entry/CREATED_WALL',
  CREATE_WALL_FAILED = 'entry/CREATE_WALL_FAILED',
  DELETE_WALL = 'entry/DELETE_WALL',
  DELETED_WALL = 'entry/DELETED_WALL',

  CREATE_DOOR = 'entry/CREATE_DOOR',
  CREATING_DOOR = 'entry/CREATING_DOOR',
  CREATED_DOOR = 'entry/CREATED_DOOR',
  CREATE_DOOR_FAILED = 'entry/CREATE_DOOR_FAILED',
  DELETE_DOOR = 'entry/DELETE_DOOR',
  DELETED_DOOR = 'entry/DELETED_DOOR',

  CREATE_FINDING = 'entry/CREATE_FINDING',
  CREATING_FINDING = 'entry/CREATING_FINDING',
  CREATED_FINDING = 'entry/CREATED_FINDING',
  CREATE_FINDING_FAILED = 'entry/CREATE_FINDING_FAILED',
  DELETE_FINDING = 'entry/DELETE_FINDING',
  DELETED_FINDING = 'entry/DELETED_FINDING',

  CREATE_ENTRY_FINDING = 'entry/CREATE_ENTRY_FINDING',
  CREATING_ENTRY_FINDING = 'entry/CREATING_ENTRY_FINDING',
  CREATED_ENTRY_FINDING = 'entry/CREATED_ENTRY_FINDING',
  CREATE_ENTRY_FINDING_FAILED = 'entry/CREATE_ENTRY_FINDING_FAILED',
  DELETE_ENTRY_FINDING = 'entry/DELETE_ENTRY_FINDING',
  DELETED_ENTRY_FINDING = 'entry/DELETED_ENTRY_FINDING',

  CREATE_WINDOW = 'entry/CREATE_WINDOW',
  CREATING_WINDOW = 'entry/CREATING_WINDOW',
  CREATED_WINDOW = 'entry/CREATED_WINDOW',
  CREATE_WINDOW_FAILED = 'entry/CREATE_WINDOW_FAILED',
  DELETE_WINDOW = 'entry/DELETE_WINDOW',
  DELETED_WINDOW = 'entry/DELETED_WINDOW',

  CREATE_DEPARTMENT_ENTRY = 'entry/CREATE_DEPARTMENT_ENTRY',
  CREATING_DEPARTMENT_ENTRY = 'entry/CREATING_DEPARTMENT_ENTRY',
  CREATED_DEPARTMENT_ENTRY = 'entry/CREATED_DEPARTMENT_ENTRY',
  CREATE_DEPARTMENT_ENTRY_FAILED = 'entry/CREATE_DEPARTMENT_ENTRY_FAILED',
  DELETE_DEPARTMENT_ENTRY = 'entry/DELETE_DEPARTMENT_ENTRY',
  DELETING_DEPARTMENT_ENTRY = 'entry/DELETING_DEPARTMENT_ENTRY',
  DELETED_DEPARTMENT_ENTRY = 'entry/DELETED_DEPARTMENT_ENTRY',
  DELETE_DEPARTMENT_ENTRY_FAILED = 'entry/DELETE_DEPARTMENT_ENTRY_FAILED',

  LOAD_CHOICES = 'entry/LOAD_CHOICES',
  LOADING_CHOICES = 'entry/LOADING_CHOICES',
  LOADED_CHOICES = 'entry/LOADED_CHOICES',
  LOAD_CHOICES_FAILED = 'entry/LOAD_CHOICES_FAILED',

  LOAD_ENTRY_HISTORY = 'entry/LOAD_ENTRY_HISTORY',
  LOADING_ENTRY_HISTORY = 'entry/LOADING_ENTRY_HISTORY',
  LOADED_ENTRY_HISTORY = 'entry/LOADED_ENTRY_HISTORY',
  LOAD_ENTRY_HISTORY_FAILED = 'entry/LOAD_ENTRY_HISTORY_FAILED',

  CREATE_CATALOG = 'entry/CREATE_CATALOG',
  CREATING_CATALOG = 'entry/CREATING_CATALOG',
  CREATED_CATALOG = 'entry/CREATED_CATALOG',
  CREATE_CATALOG_FAILED = 'entry/CREATING_CATALOG_FAILED',

  DELETE_CATALOG = 'entry/DELETE_CATALOG',
  DELETING_CATALOG = 'entry/DELETING_CATALOG',
  DELETED_CATALOG = 'entry/DELETED_CATALOG',
  DELETE_CATALOG_FAILED = 'entry/DELETE_CATALOG_FAILED',

  CREATE_CATALOG_ENTRY = 'entry/CREATE_CATALOG_ENTRY',
  CREATING_CATALOG_ENTRY = 'entry/CREATING_CATALOG_ENTRY',
  CREATED_CATALOG_ENTRY = 'entry/CREATED_CATALOG_ENTRY',
  CREATE_CATALOG_ENTRY_FAILED = 'entry/CREATE_CATALOG_ENTRY_FAILED',

  DELETE_CATALOG_ENTRY = 'entry/DELETE_CATALOG_ENTRY',
  DELETING_CATALOG_ENTRY = 'entry/DELETING_CATALOG_ENTRY',
  DELETED_CATALOG_ENTRY = 'entry/DELETED_CATALOG_ENTRY',
  DELETE_CATALOG_ENTRY_FAILED = 'entry/DELETE_CATALOG_ENTRY_FAILED',

  MOVE_DEPARTMENT_ENTRY = 'entry/MOVE_DEPARTMENT_ENTRY',
  MOVING_DEPARTMENT_ENTRY = 'entry/MOVING_DEPARTMENT_ENTRY',
  MOVED_DEPARTMENT_ENTRY  = 'entry/MOVED_DEPARTMENT_ENTRY',
  MOVE_DEPARTMENT_ENTRY_FAILED = 'entry/MOVE_DEPARTMENT_ENTRY_FAILED',
}

export default EntryActionType;
