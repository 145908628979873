import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import * as entryActions from 'actions/EntryActions';
import EntryDetails from 'components/EntryDetails/EntryDetails';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

const mapStateToProps = (state: State, ownProps) => {
  const { entryId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);
  const entry = selectors.getEntry(state, entryId);
  return {
    entry,
    entryId,
    loadingEntry: state.loadingStates.loading_entry,
    projectversion: selectors.getProjectVersion(state, versionId),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps) => {
  const { entryId } = getIntsFromParams(ownProps.match.params, ['entryId']);
  return {
    onLoadEntry: () => dispatch(entryActions.loadEntry(entryId)),
    onLoadEntryHistory: (uuid: string) =>
      dispatch(entryActions.loadEntryHistory(uuid)),
    onNavigate: (url) => dispatch(push(url)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryDetails));
