import flatMap from 'lodash/flatMap';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import { denormalize, normalize } from 'normalizr';
import { combineActions, handleActions } from 'redux-actions';

import { AssetActionType, EntryActionType, ProjectActionType } from 'actions/actionTypes';
import { initialState } from './entities.initial';

import assetSchema from 'api/asset_schema';
import departmentEntrySchema from 'api/departmententry_schema';
import doorSchema from 'api/door_schema';
import entrySchema from 'api/entry_schema';
import facilityLevelSchema from 'api/facilitylevel_schema';
import facilitySchema from 'api/facility_schema';
import projectVersionSchema from 'api/projectversion_schema';
import projectSchema from 'api/project_schema';
import wallSchema from 'api/wall_schema';
import windowSchema from 'api/window_schema';

import * as actions from 'typings/actions';
import { PermissionLevel } from 'typings/enums';
import {
  Catalog,
  CatalogEntry,
  Department,
  Facility,
  FacilityLevel,
  IdTitle,
} from 'typings/models';
import { EntityState } from 'typings/state';
import { sortByTitle } from 'utils/objects';

import catalogEntrySchema from 'api/catalog_entry_schema';
import { default as catalogSchema, default as catalog_schema } from 'api/catalog_schema';
import entryfindingSchema from 'api/entryfinding_schema';
import findingSchema from 'api/finding_schema';
import assetsReducer from './assets';
import catalogReducer from './catalogs';
import departmentEntriesReducer from './department_entries';
import entriesReducer from './entries';
import projectsReducer from './projects';
import wallsReducer from './walls';
import catalog_entry_schema from 'api/catalog_entry_schema';

const entitiesReducer = handleActions<EntityState, actions.Action>(
  {
    [combineActions(
      AssetActionType.UPLOADED_ASSET,
      AssetActionType.DELETED_ASSET,
      AssetActionType.MOVED_ASSET,
      AssetActionType.MOVED_ASSET_TO_ENTITY,
      AssetActionType.MARK_ASSET_TO_MOVE
    )]: (state: EntityState, action: actions.Action) => assetsReducer(state, action),

    [combineActions(
      EntryActionType.CREATED_DEPARTMENT_ENTRY,
      EntryActionType.DELETED_DEPARTMENT_ENTRY,
      EntryActionType.MOVED_DEPARTMENT_ENTRY
    )]: (state: EntityState, action: actions.Action) => departmentEntriesReducer(state, action),

    [combineActions(
      EntryActionType.NORMALIZE_ENTRY,
      EntryActionType.CREATED_ENTRY_FINDING,
      EntryActionType.DELETED_ENTRY_FINDING,
      EntryActionType.LOADED_ENTRY_HISTORY
    )]: (state: EntityState, action: actions.Action) => entriesReducer(state, action),

    [combineActions(
      ProjectActionType.LOADING_PROJECT,
      ProjectActionType.NORMALIZE_PROJECT,
      ProjectActionType.ADDED_PROJECT_MEMBER,
      ProjectActionType.REMOVED_PROJECT_MEMBER
    )]: (state: EntityState, action: actions.Action) => projectsReducer(state, action),

    [combineActions(
      EntryActionType.CREATED_WALL,
      EntryActionType.DELETED_WALL,
      EntryActionType.CREATED_DOOR,
      EntryActionType.DELETED_DOOR,
      EntryActionType.CREATED_WINDOW,
      EntryActionType.DELETED_WINDOW,
      EntryActionType.CREATED_FINDING,
      EntryActionType.DELETED_FINDING
    )]: (state: EntityState, action: actions.Action) => wallsReducer(state, action),

    [combineActions(
      EntryActionType.CREATED_CATALOG,
      EntryActionType.DELETED_CATALOG,
      EntryActionType.CREATED_CATALOG_ENTRY,
      EntryActionType.DELETED_CATALOG_ENTRY
    )]: (state: EntityState, action: actions.Action) => catalogReducer(state, action),

    [ProjectActionType.LOADED_FACILITY_GEOJSON]: (
      state: EntityState,
      action: actions.LoadedFacilityGeoJSONAction
    ): EntityState => {
      const { facility_level_id, value } = action.payload;
      const newState = { ...state };
      newState.facility_levels[facility_level_id].geoJSON = value;
      return newState;
    },

    [EntryActionType.SAVED_ENTITY_FIELD]: (
      state: EntityState,
      action: actions.SavedEntityFieldAction
    ): EntityState => {
      const { entity_name, entity_id, json } = action.payload;
      if (
        ![
          'assets',
          'entries',
          'walls',
          'doors',
          'findings',
          'catalogs',
          'catalog_entries',
          'windows',
          'department_entries',
          'projects',
          'projectversions',
          'entryfindings',
        ].includes(entity_name)
      ) {
        return state;
      }

      const newState = { ...state };

      if (newState[entity_name]) {
        const schemas = {
          assets: assetSchema,
          department_entries: departmentEntrySchema,
          catalogs: catalogSchema,
          catalog_entries: catalogEntrySchema,
          doors: doorSchema,
          entries: entrySchema,
          findings: findingSchema,
          projects: projectSchema,
          projectversions: projectVersionSchema,
          walls: wallSchema,
          windows: windowSchema,
          entryfindings: entryfindingSchema,
        };
        const schema = schemas[entity_name];
        const mergedState = merge({}, newState, normalize(json, schemas[entity_name]).entities);
        return {
          ...mergedState,
          [entity_name]: {
            ...state[entity_name],
            [entity_id]: normalize(json, schema).entities[entity_name]?.[entity_id],
          },
        };
      }
      return newState;
    },
  },
  { ...initialState }
);

export default entitiesReducer;

export const getFacility = (state: EntityState, facilityId) => state.facilities[facilityId];
export const getFacilityLevel = (state: EntityState, facilityLevelId) =>
  state.facility_levels[facilityLevelId];

export const getIdTitle = (state: EntityState, stateKey: string, id: number): IdTitle => {
  return state[stateKey][id];
};

export const getDepartments = (state: EntityState, versionId: number): Department[] => {
  const projectversion = denormalize(state.projectversions[versionId], projectVersionSchema, state);
  if (!projectversion) {
    return [];
  }
  return uniqBy(
    flatMap(
      Object.keys(projectversion.field_configurations).map(
        (key) => projectversion.field_configurations[key].departments
      )
    ),
    (item) => item.id
  );
};

export const getFacilities = (state: EntityState, versionId: number): Facility[] => {
  return Object.values(state.facilities)
    .map((facility) => denormalize(facility, facilitySchema, state))
    .filter((f) => f.projectversion === versionId);
};

export const getFacilityLevels = (state: EntityState, facilityId: number): FacilityLevel[] => {
  return Object.values(state.facility_levels)
    .map((fl) => denormalize(fl, facilityLevelSchema, state))
    .filter((fl) => fl.facility === facilityId);
};

export const getCatalog = (state: EntityState, catalogId: number): Catalog =>
  denormalize(state.catalogs[catalogId], catalog_schema, state);

export const getCatalogEnty = (state: EntityState, catalogEntryId: number) => {
  const catalogEntry = Object.values(state.catalog_entries).find(
    (catalogEntry) => catalogEntry.id === catalogEntryId
  );

  if (!catalogEntry) return null;

  return denormalize(catalogEntry, catalog_entry_schema, state);
};

export const getCatalogsByProjectVersion = (
  state: EntityState,
  projectVersionId: number
): Catalog[] =>
  Object.values(state.catalogs)
    .filter((catalog) => catalog.projectversion === projectVersionId)
    .map((catalog) => getCatalog(state, catalog.id));

export const getCatalogEntries = (state: EntityState, catalogId: number): CatalogEntry[] =>
  sortByTitle(Object.values(state.catalog_entries).filter((obj) => obj.catalog === catalogId)).map(
    (obj) => ({ ...state.catalog_entries[obj.id] })
  );

export const getDepartment = (state: EntityState, departmentId: number): Department | null =>
  state.departments[departmentId] || null;

export const getDoorTypes = (state: EntityState) =>
  sortByTitle(Object.values(state.door_types)).map((obj) => [obj.id, obj.title]);

export const getWindowTypes = (state: EntityState) =>
  sortByTitle(Object.values(state.window_types)).map((obj) => [obj.id, obj.title]);

export const getLocations = (state: EntityState) =>
  sortByTitle(Object.values(state.locations)).map((obj) => [obj.id, obj.title]);

export const getMaterials = (state: EntityState) =>
  sortByTitle(Object.values(state.materials)).map((obj) => [obj.id, obj.title]);

export const getStabilities = (state: EntityState) =>
  sortByTitle(Object.values(state.stabilities)).map((obj) => [obj.id, obj.title]);

export const getStratifications = (state: EntityState) =>
  sortByTitle(Object.values(state.stratifications)).map((obj) => [obj.id, obj.title]);

export const getOvergrowns = (state: EntityState) =>
  sortByTitle(Object.values(state.overgrowns)).map((obj) => [obj.id, obj.title]);

export const getCompositions = (state: EntityState) =>
  sortByTitle(Object.values(state.compositions)).map((obj) => [obj.id, obj.title]);

export const getInscriptions = (state: EntityState) =>
  sortByTitle(Object.values(state.inscriptions)).map((obj) => [obj.id, obj.title]);

export const getDamageIntensities = (state: EntityState) =>
  sortByTitle(Object.values(state.damage_intensities)).map((obj) => [obj.id, obj.title]);

export const getMeasures = (state: EntityState) =>
  sortByTitle(Object.values(state.measures)).map((obj) => [obj.id, obj.title]);

export const getExaminationTypes = (state: EntityState) =>
  sortByTitle(Object.values(state.examination_types)).map((obj) => [obj.id, obj.title]);

export const getStabilityResults = (state: EntityState) =>
  sortByTitle(Object.values(state.stability_results)).map((obj) => [obj.id, obj.title]);

export const getMeasureClassifications = (state: EntityState) =>
  sortByTitle(Object.values(state.measure_classification)).map((obj) => [obj.id, obj.title]);

export const getMeasurePrioritizations = (state: EntityState) =>
  sortByTitle(Object.values(state.measure_prioritization)).map((obj) => [obj.id, obj.title]);

export const getIntervalNextMaintenance = (state: EntityState) =>
  sortByTitle(Object.values(state.interval_next_maintenance)).map((obj) => [obj.id, obj.title]);

export const getIntervalNextRevision = (state: EntityState) =>
  sortByTitle(Object.values(state.interval_next_revision)).map((obj) => [obj.id, obj.title]);

export const getWallConstructionTypes = (state: EntityState) =>
  sortByTitle(Object.values(state.wall_construction_types)).map((obj) => [obj.id, obj.title]);

export const getWallSurfaceTypes = (state: EntityState) =>
  sortByTitle(Object.values(state.wall_surface_types)).map((obj) => [obj.id, obj.title]);

export const getDepartmentPermissions = (state: EntityState, departmentId): PermissionLevel => {
  if (!state.departments) {
    return 0;
  }
  const department = state.departments[departmentId];
  return department ? department.permissions.id : 0;
};
