import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import * as entryActions from 'actions/EntryActions';
import * as guiActions from 'actions/GuiActions';
import * as loadingStatesActions from 'actions/LoadingStates';
import EntryBase from 'components/EntryBase';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

type Props = {
  match: {
    params: {
      entryId: string;
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { entryId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);

  const projectVersion = selectors.getProjectVersion(state, versionId);
  const entry = selectors.getEntry(state, entryId);
  const editMode = state.loadingStates.edit_mode;
  return {
    administerable: editMode && selectors.canAdminEntry(state, versionId),
    compositions: selectors.getCompositions(state),
    creatingEntryfinding: state.loadingStates.creating_entry_finding,
    creatingWall: state.loadingStates.creating_wall,
    damage_intensities: selectors.getDamageIntensities(state),
    editMode,
    editable: editMode && selectors.canEditEntry(state, versionId),
    entries: selectors.getEntries(state, versionId),
    entry,
    entry_has_findings:
      projectVersion &&
      selectors.getFieldConfigurationByEntryType(projectVersion, entry?.entry_type?.id)
        ?.entries_have_findings,
    facilities: selectors.getFacilities(state, versionId).map((obj) => [obj.id, obj.title]),
    facility_levels: (entry && entry.facility
      ? selectors.getFacilityLevels(state, entry.facility.id)
      : []
    ).map((obj) => [obj.id, obj.title]),
    fieldVisible: (fieldname: string) =>
      selectors.fieldInConfiguration(state, versionId, entry ? entry.entry_type.id : 0, fieldname),
    inscriptions: selectors.getInscriptions(state),
    interval_next_maintenance: selectors.getIntervalNextMaintenance(state),
    interval_next_revision: selectors.getIntervalNextRevision(state),
    locations: selectors.getLocations(state),
    materials: selectors.getMaterials(state),
    measures: selectors.getMeasures(state),
    stability_results: selectors.getStabilityResults(state),
    examination_types: selectors.getExaminationTypes(state),
    overgrowns: selectors.getOvergrowns(state),
    projectversion: selectors.getProjectVersion(state, versionId),
    stabilities: selectors.getStabilities(state),
    stratifications: selectors.getStratifications(state),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { entryId, projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);
  return {
    onCreateEntryFinding: (token, description, notes, projectversion, entryId) =>
      dispatch(entryActions.createEntryFinding(token, description, notes, projectversion, entryId)),
    onCreateWall: (token, entry_id) => dispatch(entryActions.createWall(token, entry_id)),
    onDeleteEntry: () => dispatch(entryActions.deleteEntry(projectId, entryId, versionId)),
    onGotoEntity: (id) =>
      dispatch(push(`/project/${projectId}/version/${versionId}/entry/${id}/base`)),
    onNavigate: (url) => dispatch(push(url)),
    onShowFlyout: (facilityLevel: number) => dispatch(guiActions.showFlyout(facilityLevel)),
    onDeleteFinding: (finding_id) => dispatch(entryActions.deleteEntryFinding(finding_id)),
    onResetLoadingState: (key: string) => dispatch(loadingStatesActions.reset(key)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryBase));
