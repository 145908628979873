import DeleteButton from 'components/DeleteButton';
import ListTable from 'components/ListTable';
import Row from 'components/Row';
import SelectFileButton from 'components/SelectFileButton';
import { Asset } from 'typings/models';
import React from 'react';
import Dropzone from 'react-dropzone';

type Props = {
  files: Asset[];
  getDropRef: () => Dropzone;
  onDeleteAsset: (number) => void;
};

const DocumentList: React.FunctionComponent<Props> = (props: Props) => {
  const { files, getDropRef, onDeleteAsset } = props;
  return (
    <div className="dropzone--files">
      <SelectFileButton onSelectFile={() => getDropRef().open()} title="Weitere Datei hochladen" />
      <ListTable>
        {files.map((obj, index) => (
          <Row
            key={index}
            left={obj.original_filename}
            right={
              <DeleteButton
                title="Datei löschen"
                question="Datei wirklich löschen?"
                onDelete={() => onDeleteAsset(obj.id)}
              />
            }
          />
        ))}
      </ListTable>
    </div>
  );
};

export default DocumentList;
