import * as React from 'react';

import DeleteButton from 'components/DeleteButton';
import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import {
  InlineEditLongText,
  InlineEditSelect,
  InlineEditText,
} from 'containers/InlineEditContainer';
import { CatalogCategory, CatalogEntry, IdTitle, Window } from 'typings/models';
import SelectCatalogButton from 'components/SelectCatalogButton/SelectCatalogButtonContainer';

type Props = {
  windows: Window[];
  windowTypes: IdTitle[];
  editable: boolean;
  editMode: boolean;
  canAdminEntry: boolean;
  has_catalogs: boolean;
  onDeleteWindow: (number) => void;
  onSaveEntityField: (entityData: {
    entity_name: string;
    entity_id: number;
    fieldname?: string;
    value: any;
  }) => void;
  onFindCatalogEntry: (onSelectCatalogEntry: (catalogEntry: CatalogEntry) => void, catalogsFilterKey: CatalogCategory, preSelectedCatalogEntry?: number) => void;
};

const WindowList = (props: Props) => {
  const {
    windows,
    windowTypes,
    editable,
    editMode,
    has_catalogs,
    canAdminEntry,
    onDeleteWindow,
    onFindCatalogEntry,
    onSaveEntityField,
  } = props;
  return (
    <div>
      {windows ? (
        windows.map((window) => (
          <div key={window.id}>
            <table className="room-data">
              <tbody>
                <tr>
                  <th>Signatur</th>
                  <td>
                    <InlineEditText
                      entity="windows.token"
                      entity_id={window.id}
                      value={window.token}
                      editable={editable}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Fenster-Art</th>
                  <td>
                    <InlineEditSelect
                      entity="windows.type"
                      entity_id={window.id}
                      choices={windowTypes}
                      value={window.type}
                      editable={editable}
                      prevent_empty={true}
                    />
                  </td>
                </tr>
                {(editMode || window.catalog_entry) && has_catalogs && (
                  <tr>
                    <th>Katalogeintrag</th>
                    <td>
                      <SelectCatalogButton
                        editable={editMode}
                        catalogEntryId={window.catalog_entry}
                        onDelete={() =>
                          onSaveEntityField({
                            entity_id: window.id,
                            entity_name: 'windows',
                            fieldname: 'catalog_entry',
                            value: null,
                          })
                        }
                        onClick={() =>
                          onFindCatalogEntry((catalogEntry) =>
                            onSaveEntityField({
                              entity_id: window.id,
                              entity_name: 'windows',
                              fieldname: 'catalog_entry',
                              value: catalogEntry.id,
                            }),
                            "Window",
                            window.catalog_entry
                          )
                        }
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Beschreibung</th>
                  <td>
                    <InlineEditLongText
                      entity="windows.material"
                      entity_id={window.id}
                      value={window.material}
                      editable={editable}
                      title="Beschreibung"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {editMode && canAdminEntry && (
              <DeleteButton
                title="Fenster löschen"
                question={`Fenster ${window.token} wirklich löschen?`}
                onDelete={() => onDeleteWindow(window.id)}
              />
            )}
            {editMode && editable ? (
              <FileUploadContainer
                files={window.assets.filter((asset) => asset.category === 'image')}
                entity_name="entries.window"
                entity_id={window.id}
                display_mode="image"
                category="image"
              />
            ) : (
              <ImageGallery files={window.assets.filter((asset) => asset.category === 'image')} />
            )}
          </div>
        ))
      ) : (
        <span />
      )}
    </div>
  );
};

export default WindowList;
