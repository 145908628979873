import { EntryActionType, ProjectActionType, SearchActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { EntryState } from 'typings/state';
import { handleActions } from 'redux-actions';

import { SearchedEntriesPayload } from 'typings/models';

const initialState: EntryState = {
  choices: {},
  list: {
    count: null,
    results: [],
  },
  list_offset: 0,
  search_results: { results: [] },
};

const entriesReducer = handleActions<EntryState, actions.Action>(
  {
    [ProjectActionType.LOADING_PROJECT]: (): EntryState => {
      return { ...initialState };
    },

    [SearchActionType.SEARCH_ENTRIES]: (state: EntryState): EntryState => ({
      ...state,
      search_results: {
        results: [],
      }
    }),

    [SearchActionType.SEARCHED_ENTRIES]: (
      state: EntryState,
      action: actions.SearchedEntriesAction
    ): EntryState => ({
      ...state,
      search_results: action.payload,
    }),

    [SearchActionType.RESET_SEARCH_ENTRIES]: (state: EntryState): EntryState => ({
      ...state,
      search_results: {
        results: [],
      }
    }),

    [EntryActionType.LOADED_ENTRIES]: (
      state: EntryState,
      action: actions.LoadedEntriesAction
    ): EntryState => ({
      ...state,
      list: action.payload,
      list_offset: 0,
    }),

    [EntryActionType.DELETED_ENTRY]: (
      state: EntryState,
      action: actions.DeletedEntryAction
    ): EntryState => {
      const newList = { ...state.list };
      newList.results = newList.results.filter((e) => e.id !== parseInt(action.payload, 10));
      newList.count = newList.results.length;

      return { ...state, list: newList };
    },

    [EntryActionType.SAVED_ENTITY_FIELD]: (
      state: EntryState,
      action: actions.SavedEntityFieldAction
    ): EntryState => {
      const { entity_name, json } = action.payload;
      if (entity_name === 'entries') {
        const idx = state.list.results.findIndex((e) => e.id === json.id);
        const results = [...state.list.results];
        results[idx] = json;
        return {
          ...state,
          list: { ...state.list, results },
        };
      }
      return state;
    },

    [EntryActionType.LOADING_CHOICES]: (
      state: EntryState,
      action: actions.LoadingChoicesAction
    ): EntryState => {
      const choices = { ...state.choices };
      choices[action.payload] = 'LOADING';
      return {
        ...state,
        choices,
      };
    },

    [EntryActionType.LOADED_CHOICES]: (
      state: EntryState,
      action: actions.LoadedChoicesAction
    ): EntryState => {
      const choices = { ...state.choices };
      choices[action.payload.choices_name] = action.payload.json;
      return {
        ...state,
        choices,
      };
    },

    [EntryActionType.LOAD_CHOICES_FAILED]: (
      state: EntryState,
      action: actions.LoadChoicesFailedAction
    ): EntryState => {
      return {
        ...state,
        choices: {
          ...state.choices,
          [action.payload.choices_name]: 'FAILED',
        },
      };
    },
  },
  { ...initialState }
);

const getSearchResults = (state: EntryState): SearchedEntriesPayload[] => state.search_results.results;

export { entriesReducer as default, initialState, getSearchResults };
