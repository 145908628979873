enum ProjectActionType {
  LOAD_PROJECT = 'projects/LOAD_PROJECT',
  LOADING_PROJECT = 'projects/LOADING_PROJECT',
  LOADED_PROJECT = 'projects/LOADED_PROJECT',
  LOAD_PROJECT_FAILED = 'projects/LOAD_PROJECT_FAILED',
  NORMALIZE_PROJECT = 'projects/NORMALIZE_PROJECT',

  LOAD_FLOORMAP = 'projects/LOAD_FLOORMAP',
  LOADING_FLOORMAP = 'projects/LOADING_FLOORMAP',
  LOADED_FLOORMAP = 'projects/LOADED_FLOORMAP',
  LOAD_FLOORMAP_FAILED = 'projects/LOAD_FLOORMAP_FAILED',

  EXPORT_PROJECTVERSION_PDF = 'projects/EXPORT_PROJECTVERSION_PDF',

  EXPORT_PROJECT = 'projects/EXPORT_PROJECT',

  INITIATE_PROJECT_EXPORT = 'projects/INITIATE_PROJECT_EXPORT',
  ABORT_PROJECT_EXPORT = 'projects/ABORT_PROJECT_EXPORT',

  COPY_PROJECT = 'projects/COPY_PROJECT',
  COPYING_PROJECT = 'projects/COPYING_PROJECT',
  COPIED_PROJECT = 'projects/COPIED_PROJECT',

  LOAD_DASHBOARD = 'projects/LOAD_DASHBOARD',
  LOADING_DASHBOARD = 'projects/LOADING_DASHBOARD',
  LOADED_DASHBOARD = 'projects/LOADED_DASHBOARD',
  LOAD_DASHBOARD_FAILED = 'projects/LOAD_DASHBOARD_FAILED',

  LOAD_FACILITY_GEOJSON = 'projects/LOAD_FACILITY_GEOJSON',
  LOADED_FACILITY_GEOJSON = 'projects/LOADED_FACILITY_GEOJSON',
  LOAD_FACILITY_GEOJSON_FAILED = 'projects/LOAD_FACILITY_GEOJSON_FAILED',

  ADD_PROJECT_MEMBER = 'projects/ADD_PROJECT_MEMBER',
  ADDING_PROJECT_MEMBER = 'projects/ADDING_PROJECT_MEMBER',
  ADDED_PROJECT_MEMBER = 'projects/ADDED_PROJECT_MEMBER',
  ADD_PROJECT_MEMBER_FAILED = 'projects/ADD_PROJECT_MEMBER_FAILED',

  REMOVE_PROJECT_MEMBER = 'projects/REMOVE_PROJECT_MEMBER',
  REMOVING_PROJECT_MEMBER = 'projects/REMOVING_PROJECT_MEMBER',
  REMOVED_PROJECT_MEMBER = 'projects/REMOVED_PROJECT_MEMBER',
  REMOVE_PROJECT_MEMBER_FAILED = 'projects/REMOVE_PROJECT_MEMBER_FAILED',

  UPLOAD_FLOORMAP = 'projects/UPLOAD_FLOORMAP',
  UPLOAD_FLOORMAP_FAILED = 'projects/UPLOAD_FLOORMAP_FAILED',
  UPLOADING_FLOORMAP = 'projects/UPLOADING_FLOORMAP',
  UPLOADED_FLOORMAP = 'projects/UPLOADED_FLOORMAP',
  IMPORT_FLOORMAP = 'projects/IMPORT_FLOORMAP',
  IMPORTING_FLOORMAP = 'projects/IMPORTING_FLOORMAP',
  IMPORTED_FLOORMAP = 'projects/IMPORTED_FLOORMAP',
  RESET_FLOORMAP_IMPORT = 'projects/RESET_FLOORMAP_IMPORT',
}

export default ProjectActionType;
