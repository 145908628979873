import ExistingCatalogDetailsContent from 'components/ExistingCatalogDetailsContent/ExistingCatalogDetailsContent';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { CatalogEntry, SaveStatus } from 'typings/models';
import { usePrevious } from '../App/App.util';
import { ICatalogDetailsContainerProps } from './CatalogDetails.types';

const CatalogDetails: React.FC<ICatalogDetailsContainerProps> = ({
  onAddCatalogEntry,
  onDeleteCatalogEntry,
  catalog,
  history,
  edit_mode,
  catalogEntries,
  projectversion,
  saveStatusCatalogEntry,
  onResetCatalogEntryLoadingState,
}) => {
  const [newCatalogEntry, setNewCatalogEntry] = useState<Partial<CatalogEntry> | null>();
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (newCatalogEntry?.signature) {
      onAddCatalogEntry(
        catalog?.id,
        newCatalogEntry.signature,
        newCatalogEntry.title,
        newCatalogEntry.description
      );
    }
    e.preventDefault();
    setNewCatalogEntry(null);
  };

  const previousSaveStatusCatalogEntry =
    usePrevious<SaveStatus<CatalogEntry>>(saveStatusCatalogEntry);

  useEffect(() => {
    if (
      previousSaveStatusCatalogEntry?.status !== 'SAVED' &&
      saveStatusCatalogEntry?.status === 'SAVED'
    ) {
      onResetCatalogEntryLoadingState();
      history.push(
        `/project/${projectversion.project.id}/version/${projectversion.id}/catalog/${
          catalog.id
        }/catalogEntry/${saveStatusCatalogEntry!.values!.id}`
      );
    }
  }, [previousSaveStatusCatalogEntry, saveStatusCatalogEntry]);

  const handleSelectCatalogEntry = (id: number) => {
    history.push(
      `/project/${projectversion.project.id}/version/${projectversion.id}/catalog/${catalog.id}/catalogEntry/${id}`
    );
  };

  const handleGoBack = () => {
    history.push(`/project/${projectversion.project.id}/version/${projectversion.id}/catalogs/`);
  };

  return (
    <DocumentTitle title="Katalogdetails">
      <div className="plain-page font-normal project-settings">
        <h2>
          <span>Katalog:</span> {catalog.title}
        </h2>

        <div className="catalog-details-content">
          <div>
            <ExistingCatalogDetailsContent
              catalog={catalog}
              catalogEntries={catalogEntries}
              newCatalogEntry={newCatalogEntry}
              edit_mode={edit_mode}
              onChangeNewCatalogEntry={setNewCatalogEntry}
              onDeleteCatalogEntry={onDeleteCatalogEntry}
              onSelectCatalogEntry={handleSelectCatalogEntry}
              onSubmitNewCatalogEntry={handleSubmit}
            />
            <p className="catalog-dialog-action-container left mr-5">
              <button onClick={() => handleGoBack()} className="catalog-dialog-action-button">
                Zurück
              </button>
            </p>
            {!newCatalogEntry && edit_mode && (
              <p className="catalog-dialog-action-container right">
                <button
                  className="catalog-dialog-action-button"
                  onClick={() => setNewCatalogEntry({ title: '', signature: '', description: '' })}
                >
                  Neuen Katalogeintrag anlegen
                </button>
              </p>
            )}
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default CatalogDetails;
