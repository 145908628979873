import { ICatalogDialogDataState } from './CatalogDialog.types';

export const getModalTitle = (
  data: Omit<ICatalogDialogDataState, 'selectedCatalogId'> & { selectedCatalogTitle?: string }
) => {
  const { newCatalogEntry, newCatalog, selectedCatalogTitle } = data;

  if (newCatalogEntry) {
    return 'Katalogeintrag anlegen';
  }
  if (newCatalog) {
    return 'Katalog anlegen';
  }
  if (selectedCatalogTitle) {
    return `Katalog: ${selectedCatalogTitle}`;
  }
  return 'Kataloge';
};
