import { connect } from 'react-redux';

import UserDashboard from 'components/UserDashboard/UserDashboard';
import { getUser } from 'reducers/app';
import { State } from 'typings/state';

const mapStateToProps = (state: State) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(UserDashboard);
