import * as React from 'react';

import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import { InlineEditLongText } from 'containers/InlineEditContainer';
import { Wall } from 'typings/models';

type Props = {
  wall: Wall;
  edit_mode: boolean;
  editable: boolean;
};

const WallDataMiddle: React.FunctionComponent<Props> = (props: Props) => {
  const { wall, edit_mode, editable } = props;

  return (
    <div>
      {edit_mode || wall.assets.length ? (
        <div>
          <h4>Übersichtsbild</h4>
          {edit_mode && editable ? (
            <FileUploadContainer
              files={wall.assets}
              entity_name="entries.wall"
              entity_id={wall.id}
              display_mode="image"
              category="image"
            />
          ) : (
            <ImageGallery files={wall.assets} />
          )}
        </div>
      ) : (
        <span />
      )}
      {edit_mode || wall.description ? (
        <div>
          <h4>Beschreibung</h4>
          <InlineEditLongText
            entity="walls.description"
            entity_id={wall.id}
            value={wall.description}
            editable={editable}
            hint="z.B. 'Zweigeteilt: nördlicher Teil Bruchstein[..]'"
            title="Beschreibung"
          />
        </div>
      ) : null}
      {edit_mode || wall.construction_period ? (
        <div>
          <h4>Bauzeit</h4>
          <InlineEditLongText
            entity="walls.construction_period"
            entity_id={wall.id}
            value={wall.construction_period}
            editable={editable}
            hint="z.B Januar - März 1930"
            title="Bauzeit"
          />
        </div>
      ) : null}
    </div>
  );
};

export default WallDataMiddle;
