import * as React from 'react';
import { useEffect, useState } from 'react';

import { IExportProjectAccordionItem } from './ExportProjectOptions.types';

export const ExportProjectAccordionItem = ({
  entries,
  facilityLevel,
  roomIds,
  bulkToggleEntries,
  onChangeRoomId,
}: IExportProjectAccordionItem) => {
  const [allChildrenSelected, setAllChildrenSelected] = useState<boolean>(true);

  const entriesInFacilityLevel = facilityLevel
    ? entries.filter((entry) => entry.facility_level === facilityLevel.id)
    : entries.filter((entry) => entry.facility_level === null);

  const entryIdsInFacilityLevel = entriesInFacilityLevel.map((entry) => entry.id);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(e.target.value);
    onChangeRoomId(id);
  };

  useEffect(() => {
    setAllChildrenSelected(
      entryIdsInFacilityLevel.filter((entryId) => !roomIds.includes(entryId)).length === 0
    );
  }, [roomIds]);

  if (!entriesInFacilityLevel.length) {
    return <></>;
  }

  return (
    <div>
      <input
        type="checkbox"
        id={`facility-level-${facilityLevel ? facilityLevel.id : 'null'}`}
        checked={allChildrenSelected}
        onChange={() => bulkToggleEntries(!allChildrenSelected, entryIdsInFacilityLevel)}
      />
      <label htmlFor={`facility-level-${facilityLevel ? facilityLevel.id : 'null'}`}>
        <h5 style={{ display: 'inline' }}>
          {facilityLevel ? facilityLevel.title : 'Alle Elemente'}
        </h5>
      </label>
      <br />
      <div style={{ paddingLeft: '12px' }}>
        {entriesInFacilityLevel.map((entry) => (
          <span key={entry.id} style={{ display: 'inline-block', paddingRight: '12px' }}>
            <input
              type="checkbox"
              value={entry.id}
              id={'entry-' + entry.id}
              checked={roomIds.includes(entry.id)}
              onChange={onChange}
            />
            <label htmlFor={'entry-' + entry.id}>
              {entry.signature} - {entry.title}
            </label>
          </span>
        ))}
      </div>
    </div>
  );
};
