import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import * as entryActions from 'actions/EntryActions';
import * as guiActions from 'actions/GuiActions';
import * as notificationActions from 'actions/NotificationActions';
import NavBar from 'components/NavBar/NavBar';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { getIntsFromParams } from 'utils/objects';
import { ModalType } from 'typings/enums';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state, ownProps: Props) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['projectId', 'versionId']);
  const projectversion = selectors.getProjectVersion(state, versionId);
  return {
    canAdminEntry: selectors.canAdminEntry(state, versionId),
    canEditAnything: selectors.canEditAnything(state, versionId),
    canEditEntry: selectors.canEditEntry(state, versionId),
    creatingEntry: state.loadingStates.creating_entry,
    edit_mode: state.loadingStates.edit_mode,
    entryTypes: selectors.getEntryTypes(state, versionId),
    exporting: state.loadingStates.exporting,
    history: state.entities.history,
    privacyNoteVisible: selectors.getPrivacyNoteVisible(state),
    projectversion,
    user: selectors.getUser(state),
    notificationsOpen: selectors.getNotificationsShown(state),
    notifications: selectors.getNotifications(state),
    unreadNotificationsNumber: selectors.getUnreadNotificationsNumber(state),
    hasCatalogs: projectversion && selectors.getHasCatalogs(projectversion),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);

  return {
    onCreateEntry: (signature, entryType, title) =>
      dispatch(entryActions.createEntry(versionId, signature, entryType, title)),
    onDismissPrivacyNote: () => dispatch(guiActions.dismissPrivacyNote()),
    onGotoDashboard: () => dispatch(push('/dashboard/')),
    onGotoHelp: () => dispatch(push('/help/')),
    onGotoHome: () => !!versionId && dispatch(push(`/project/${projectId}/version/${versionId}/`)),
    onGotoSearch: () =>
      !!versionId && dispatch(push(`/project/${projectId}/version/${versionId}/search/`)),
    onHideFlyout: () => dispatch(guiActions.hideFlyout()),
    onLogout: () => dispatch(push('/logout/')),
    onSetEditMode: (val) => dispatch(guiActions.setEditMode(val)),
    onToggleEditMode: () => dispatch(guiActions.toggleEditMode()),
    onLoadNotifications: () => dispatch(notificationActions.loadNotifications()),
    onToggleNotifications: () => dispatch(guiActions.toggleNotifications()),
    onMarkNotificationRead: (id: number) => dispatch(notificationActions.markAsRead(id)),
    onMarkAllNotificationsRead: () => dispatch(notificationActions.markAllRead()),
    onShowModal: (type: ModalType, title: string, text: any) =>
      dispatch(guiActions.showModal(type, text, title)),
    onSwitchVersion: (path: string) => dispatch(push(path)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
