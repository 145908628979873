import * as React from 'react';
import { useEffect, useState } from 'react';

import { IExportProjectAccordion } from './ExportProjectOptions.types';
import { ExportProjectAccordionItem } from './ExportProjectAccordionItem';

export const ExportProjectAccordion = ({
  entries,
  facility,
  roomIds,
  bulkToggleEntries,
  onChangeRoomId,
}: IExportProjectAccordion) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [allChildrenSelected, setAllChildrenSelected] = useState<boolean>(true);

  const entryIdsInFacility = facility
    ? entries.filter((entry) => entry.facility === facility.id).map((entry) => entry.id)
    : entries.filter((entry) => entry.facility === null).map((entry) => entry.id);

  useEffect(() => {
    setAllChildrenSelected(entryIdsInFacility.filter((id) => !roomIds.includes(id)).length === 0);
  }, [roomIds]);

  const onToggleFacility = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation();
    bulkToggleEntries(!allChildrenSelected, entryIdsInFacility);
  };

  return (
    <div className={'accordion-item'}>
      <div
        className={'accordion-trigger'}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div>
          <input type="checkbox" checked={allChildrenSelected} onClick={onToggleFacility} />
          <h4 style={{ display: 'inline', marginLeft: '12px' }}>
            {facility ? facility.title : 'Weitere'}
          </h4>
        </div>
        <div className={`accordion-icon ${isActive ? 'is-active' : ''}`}></div>
      </div>
      <div className={`accordion-content ${isActive ? 'is-active' : ''}`}>
        {facility ? (
          facility.facility_levels.map((facilityLevel) => (
            <ExportProjectAccordionItem
              key={facilityLevel.id}
              entries={entries}
              facilityLevel={facilityLevel}
              roomIds={roomIds}
              bulkToggleEntries={bulkToggleEntries}
              onChangeRoomId={onChangeRoomId}
            />
          ))
        ) : (
          <ExportProjectAccordionItem
            entries={entries}
            roomIds={roomIds}
            bulkToggleEntries={bulkToggleEntries}
            onChangeRoomId={onChangeRoomId}
          />
        )}
      </div>
    </div>
  );
};
