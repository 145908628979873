import { DashboardActionType } from 'actions/actionTypes';
import {
  LoadDashboardAction,
  LoadDashboardFailedAction,
  LoadedDashboardAction,
  LoadingDashboardAction,
} from 'typings/actions';
import { DashboardResponse } from 'typings/models';
import { createAction } from 'redux-actions';

export const loadDashboard = createAction<LoadDashboardAction>(DashboardActionType.LOAD_DASHBOARD);
export const loadingDashboard = createAction<LoadingDashboardAction>(
  DashboardActionType.LOADING_DASHBOARD
);
export const loadedDashboard = createAction<LoadedDashboardAction, DashboardResponse>(
  DashboardActionType.LOADED_DASHBOARD,
  (values: DashboardResponse) => ({ values })
);

export const loadDashboardFailed = createAction<LoadDashboardFailedAction, any>(
  DashboardActionType.LOAD_DASHBOARD_FAILED,
  (errors?: any) => ({ errors })
);
