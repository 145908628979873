import * as React from 'react';

import Error from 'components/Error';
import { SaveError } from 'typings/models';

type Props = {
  errors?: SaveError;
  errorField: string;
};

const ErrorList = (props: Props) => {
  const { errors, errorField } = props;

  if (!errors || !errors[errorField]) {
    return null;
  }

  return (
    <div>
      {!!errors &&
        Object.keys(errors[errorField]).map((key, index) => (
          <Error key={index} message={errors[errorField][key]} />
        ))}
    </div>
  );
};

export default ErrorList;
