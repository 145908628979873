import React from 'react';
import { connect } from 'react-redux';
import { getCatalogEntry } from 'reducers/app';
import { State } from 'typings/state';
import { SelectCatalogButton } from './SelectCatalogButton';
import { ISelectCatalogButtonContainerProps } from './SelectCatalogButton.types';

const mapStateToProps = (state: State, ownProps: ISelectCatalogButtonContainerProps) => ({
  ...ownProps,
  catalogEntry: ownProps.catalogEntryId && getCatalogEntry(state, ownProps.catalogEntryId),
});

export default connect(
  mapStateToProps,
  null
)(SelectCatalogButton) as React.FC<ISelectCatalogButtonContainerProps>;
