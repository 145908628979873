import ErrorList from 'components/ErrorList';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { SaveStatus, Wall } from 'typings/models';
import React, { createRef, useEffect, useMemo, useState } from 'react';

type Props = {
  entryId: number;
  isCreatingWall: SaveStatus<Wall>;
  onCreate: (value: string, entryId: number) => void;
};

const CreateWall = ({ entryId, isCreatingWall, onCreate }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const textInput = createRef<HTMLInputElement>();
  const focus = () => !!textInput.current && textInput.current.focus();

  useEffect(() => {
    focus();
    if (isCreatingWall.status === 'SAVING') {
      setIsEditing(false);
    }
  }, [isCreatingWall.status, isEditing]);

  const create = useMemo(() => {
    return () => onCreate(value, entryId);
  }, [value, entryId]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      create();
    }
    if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  if (isCreatingWall.status === 'SAVING') {
    return <SimpleLoader />;
  }

  if (!isEditing) {
    return (
      <button className="primary add-button" onClick={() => setIsEditing(true)}>
        Wand hinzufügen
      </button>
    );
  }

  return (
    <div>
      {isCreatingWall.status === 'FAILED' && (
        <ErrorList errors={isCreatingWall.messages || {}} errorField="token" />
      )}
      <input
        ref={textInput}
        placeholder="Signatur"
        onKeyDown={handleKeyDown}
        type="text"
        onChange={(e) => setValue(e.currentTarget.value)}
      />
      <br />
      <button className="primary" onClick={create}>
        OK
      </button>
      <button className="secondary" onClick={() => setIsEditing(false)}>
        Abbrechen
      </button>
    </div>
  );
};

export default CreateWall;
