import { Entry, EntryShort, IdTitle } from 'typings/models';
import React from 'react';

type Props = {
  title: string;
  entries: EntryShort[];
  facilityLevel: IdTitle;
  currentEntry?: Entry;
  onGotoEntity: (number) => void;
};

const FlyoutRoomList: React.FunctionComponent<Props> = (props: Props) => {
  const { entries, currentEntry, title, facilityLevel, onGotoEntity } = props;
  const filteredEntries = entries.filter(
    (entry) => !!entry.facility_level && entry.facility_level === facilityLevel.id
  );

  return (
    <div id="plan" className="map-navigation-container">
      <h3>{title || 'nothing'}</h3>
      <ul>
        {filteredEntries.map((listentry) => (
          <li
            key={listentry.id}
            className={!!currentEntry && listentry.id === currentEntry.id ? 'active' : ''}
          >
            <a href="#" onClick={() => onGotoEntity(listentry.id)}>
              {listentry.signature}: {listentry.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FlyoutRoomList;
