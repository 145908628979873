import { CatalogEntryDetails } from 'components/CatalogEntryDetails';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as selectors from 'reducers/app';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

const mapStateToProps = (state: State, ownProps: any) => {
  const { catalogEntryId } = getIntsFromParams(ownProps.match.params, ['catalogEntryId']);
  return {
    catalogEntry: selectors.getCatalogEntry(state, catalogEntryId),
    edit_mode: state.loadingStates.edit_mode,
  };
};

export default withRouter(connect(mapStateToProps, null)(CatalogEntryDetails));
