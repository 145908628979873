import { ICatalogFormProps } from 'components/CatalogDialog/CatalogDialog.types';
import NewCatalogDetailsContent from 'components/NewCatalogDetailsContent';
import React from 'react';
import { Catalog } from 'typings/models';

export const NewCatalogForm: React.FC<ICatalogFormProps<Catalog>> = ({
  value,
  onChange,
  onSubmit,
}) => {
  return (
    <>
      <NewCatalogDetailsContent catalog={value} onChange={onChange} />
      <p className="catalog-dialog-action-container right">
        <button onClick={() => onChange(null)} className="catalog-dialog-action-button">
          Abbrechen
        </button>
      </p>
      <p className="catalog-dialog-action-container right mr-5">
        <button
          onClick={onSubmit}
          className="catalog-dialog-action-button"
          disabled={!value?.title || !value?.category || value?.category === 'None'}
        >
          Speichern
        </button>
      </p>
    </>
  );
};
