import * as React from 'react';

import { SlideInTop } from 'components/Animated';
import CreateWall from 'components/CreateWall';
import DeleteButton from 'components/DeleteButton';
import TileFloormapSmall from 'components/TileFloormapSmall';
import { InlineEditText } from 'containers/InlineEditContainer';
import {
  Entry,
  EntryFinding,
  EntryShort,
  IdTitle,
  ProjectVersion,
  SaveStatus,
  Wall,
} from 'typings/models';
import BaseDataLeft from './BaseDataLeft';
import BaseDataMiddle from './BaseDataMiddle';
import BaseDataRight from './BaseDataRight';

type Props = {
  administerable: boolean;
  compositions: IdTitle[];
  creatingWall: SaveStatus<Wall>;
  creatingEntryfinding: SaveStatus<EntryFinding>;
  damage_intensities: IdTitle[];
  editable: boolean;
  editMode: boolean;
  entries: EntryShort[];
  entry: Entry;
  entry_has_findings: boolean;
  examination_types: IdTitle[];
  facilities: IdTitle[];
  facility_levels: IdTitle[];
  inscriptions: IdTitle[];
  interval_next_maintenance: IdTitle[];
  interval_next_revision: IdTitle[];
  locations: IdTitle[];
  measures: IdTitle[];
  materials: IdTitle[];
  overgrowns: IdTitle[];
  projectversion: ProjectVersion;
  stabilities: IdTitle[];
  stability_results: IdTitle[];
  stratifications: IdTitle[];

  onCloseFloormap: () => void;
  onCreateWall: (value: string, entryId: number) => void;
  onDeleteEntry: (id: number) => void;
  onGotoEntity: (number) => void;
  onShowFlyout: (number) => void;
  fieldVisible: (fieldname: string) => boolean;
  onDeleteFinding: (finding_id: number) => void;
  onCreateEntryFinding: (token, description, notes, projectversion, entryId) => void;
  onResetLoadingState: (key: string) => void;
};

class EntryBase extends React.Component<Props> {
  public showFlyout = () => {
    const {
      onShowFlyout,
      entry: { facility_level },
    } = this.props;
    !!facility_level && onShowFlyout(facility_level);
  };

  public render() {
    const {
      editable,
      administerable,
      compositions,
      creatingWall,
      creatingEntryfinding,
      damage_intensities,
      editMode,
      entry,
      entry_has_findings,
      entries,
      examination_types,
      facilities,
      facility_levels,
      interval_next_maintenance,
      interval_next_revision,
      locations,
      materials,
      measures,
      overgrowns,
      inscriptions,
      stabilities,
      stability_results,
      stratifications,
      projectversion,
      fieldVisible,
      onCreateWall,
      onGotoEntity,
      onDeleteEntry,
      onDeleteFinding,
      onCreateEntryFinding,
      onResetLoadingState,
    } = this.props;
    const {
      floormap = null,
      floormap_type = null,
      geoJSON = undefined,
    } = entry.facility_level || {};

    return (
      <SlideInTop initialPose="hidden" pose="visible" className="flex-container vertical">
        <div className="room-title">
          <h2>
            <span>
              <InlineEditText
                entity="entries.signature"
                entity_id={entry.id}
                value={entry.signature}
                editable={editable}
              />
            </span>

            {fieldVisible('title') && !!entry.title ? (
              <span className="light">{entry.title}</span>
            ) : (
              <span />
            )}
          </h2>
          {editable && entry.entry_type.has_walls ? (
            <CreateWall onCreate={onCreateWall} entryId={entry.id} isCreatingWall={creatingWall} />
          ) : (
            <span />
          )}
          {administerable ? (
            <DeleteButton
              title="Eintrag löschen"
              question="Eintrag wirklich löschen?"
              onDelete={() => onDeleteEntry(entry.id)}
            />
          ) : (
            <span />
          )}
        </div>
        <div className="room-details flex-container horizontal">
          <div className="content-box">
            {!!floormap && !!floormap_type && (
              <>
                <TileFloormapSmall
                  entry={entry}
                  entries={entries}
                  floormapURL={floormap}
                  geoJSON={geoJSON}
                  onGotoEntity={onGotoEntity}
                />
                <button onClick={this.showFlyout}>{editMode ? 'Bearbeiten' : 'Vergrößern'}</button>
              </>
            )}
            <br />
            <BaseDataLeft
              entry={entry}
              projectversion={projectversion}
              facilities={facilities}
              facility_levels={facility_levels}
              editable={editable}
              fieldVisible={fieldVisible}
              examination_types={examination_types}
              stability_results={stability_results}
            />
          </div>
          <div className="content-box">
            <BaseDataMiddle
              entry={entry}
              editMode={editMode}
              editable={editable}
              fieldVisible={fieldVisible}
              locations={locations}
              materials={materials}
              stratifications={stratifications}
              inscriptions={inscriptions}
              compositions={compositions}
              stabilities={stabilities}
            />
          </div>
          <div className="content-box">
            <BaseDataRight
              damage_intensities={damage_intensities}
              interval_next_maintenance={interval_next_maintenance}
              interval_next_revision={interval_next_revision}
              overgrowns={overgrowns}
              measures={measures}
              entry={entry}
              entry_has_findings={entry_has_findings}
              editMode={editMode}
              editable={editable}
              projectversion={projectversion}
              fieldVisible={fieldVisible}
              onDeleteFinding={onDeleteFinding}
              creatingEntryfinding={creatingEntryfinding}
              onCreateEntryFinding={onCreateEntryFinding}
              onResetLoadingState={onResetLoadingState}
            />
          </div>
        </div>
      </SlideInTop>
    );
  }
}

export default EntryBase;
