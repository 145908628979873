import { connect } from 'react-redux';

import * as dashboardActions from 'actions/DashboardActions';
import ProjectDashboard from 'components/ProjectDashboard/ProjectDashboard';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['projectId', 'versionId']);

  return {
    dashboard: selectors.getDashboard(state),
    loadingDashboard: state.loadingStates.loading_dashboard,
    loadingProject: state.loadingStates.loading_projectversion,
    projectversion: selectors.getProjectVersion(state, versionId),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['projectId', 'versionId']);

  return {
    onLoadDashboard: () => dispatch(dashboardActions.loadDashboard(versionId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDashboard);
