import React from 'react';
import { InlineEditLongText, InlineEditText } from 'containers/InlineEditContainer';
import DocumentTitle from 'react-document-title';
import { ICatalogEntryDetailsProps } from './CatalogEntryDetails.types';
import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';

export const CatalogEntryDetails: React.FC<ICatalogEntryDetailsProps> = ({
  catalogEntry,
  edit_mode,
  history,
}) => {
  const images = catalogEntry.assets?.filter((asset) => asset.category === 'image') ?? [];
  const handleGoBack = () => {
    history.push(location.pathname.split('catalogEntry/')[0]);
  };
  return (
    <DocumentTitle title="Katalogeintrag">
      <div className="plain-page font-normal project-settings catalog-entry-details">
        <h2>
          Katalogeintrag:{' '}
          <span>
            <InlineEditText
              entity="catalog_entries.signature"
              entity_id={catalogEntry.id}
              value={catalogEntry.signature}
              editable={edit_mode}
              maxLength={35}
              hint="max. 35 Zeichen"
            />
          </span>
        </h2>
        <div>
          <div>
            <table className={`catalog-entry-details-table ${edit_mode ? 'editing' : ''}`}>
              <tbody>
                {((catalogEntry.title && !edit_mode) || edit_mode) && (
                  <tr>
                    <th>Titel</th>
                    <td>
                      <InlineEditText
                        entity="catalog_entries.title"
                        entity_id={catalogEntry.id}
                        value={catalogEntry.title}
                        maxLength={75}
                        editable={edit_mode}
                        hint="max. 75 Zeichen"
                      />
                    </td>
                  </tr>
                )}
                {((catalogEntry?.description && !edit_mode) || edit_mode) && (
                  <tr>
                    <th>Beschreibung</th>
                    <td>
                      <InlineEditLongText
                        entity="catalog_entries.description"
                        entity_id={catalogEntry.id}
                        value={catalogEntry.description}
                        editable={edit_mode}
                        hint="z.B. Eintragsbeschreibung"
                      />
                    </td>
                  </tr>
                )}
                {(!!images.length || edit_mode) && (
                  <tr>
                    <th>Übersichtsbild</th>
                    <td>
                      {edit_mode ? (
                        <FileUploadContainer
                          files={images}
                          entity_name="entries.catalogentry"
                          entity_id={catalogEntry.id}
                          display_mode="image"
                          category="image"
                        />
                      ) : (
                        <ImageGallery files={images} />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <p className="catalog-dialog-action-container left mr-5">
            <button onClick={() => handleGoBack()} className="catalog-dialog-action-button">
              Zurück
            </button>
          </p>
        </div>
      </div>
    </DocumentTitle>
  );
};
