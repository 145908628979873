import { Entry, LoadStatus, Project } from 'typings/models';

export interface IEntryListProps {
  project: Project;
  entries: Entry[];
  entryId: number;
  currentPath: string;
  loadingEntry: LoadStatus<Entry>;
  loadingEntries: LoadStatus<Entry>;
  onGotoEntry: (number) => void;
  onLoadEntries: () => void;
}

export const PAGESIZE = 10;
