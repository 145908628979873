import React from 'react';

import DeleteButton from 'components/DeleteButton';
import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import { InlineEditLongText, InlineEditText } from 'containers/InlineEditContainer';
import { CatalogCategory, CatalogEntry, Finding } from '../../typings/models';
import SelectCatalogButton from 'components/SelectCatalogButton/SelectCatalogButtonContainer';

type Props = {
  findings: Finding[];
  editable: boolean;
  editMode: boolean;
  canAdminEntry: boolean;
  has_catalogs: boolean;
  onDeleteFinding: (number) => void;
  onSaveEntityField: (entityData: {
    entity_name: string;
    entity_id: number;
    fieldname?: string;
    value: any;
  }) => void;
  onFindCatalogEntry: (
    onSelectCatalogEntry: (catalogEntry: CatalogEntry) => void,
    catalogsFilterKey: CatalogCategory,
    preSelectedCatalogEntry?: number
  ) => void;
};

const FindingList = (props: Props) => {
  const {
    findings,
    editable,
    editMode,
    canAdminEntry,
    has_catalogs,
    onDeleteFinding,
    onFindCatalogEntry,
    onSaveEntityField,
  } = props;
  return (
    <div>
      {findings ? (
        findings.map((finding) => (
          <div key={finding.id}>
            <table className="room-data">
              <tbody>
                <tr>
                  <th>Signatur</th>
                  <td>
                    <InlineEditText
                      entity="findings.token"
                      entity_id={finding.id}
                      value={finding.token}
                      editable={editable}
                    />
                  </td>
                </tr>
                {(editMode || finding.catalog_entry) && has_catalogs && (
                  <tr>
                    <th>Katalogeintrag</th>
                    <td>
                      <SelectCatalogButton
                        editable={editMode}
                        catalogEntryId={finding.catalog_entry}
                        onDelete={() =>
                          onSaveEntityField({
                            entity_id: finding.id,
                            entity_name: 'findings',
                            fieldname: 'catalog_entry',
                            value: null,
                          })
                        }
                        onClick={() =>
                          onFindCatalogEntry(
                            (catalogEntry) =>
                              onSaveEntityField({
                                entity_id: finding.id,
                                entity_name: 'findings',
                                fieldname: 'catalog_entry',
                                value: catalogEntry.id,
                              }),
                            "Finding",
                            finding.catalog_entry
                          )
                        }
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Beschreibung</th>
                  <td>
                    <InlineEditLongText
                      entity="findings.description"
                      entity_id={finding.id}
                      value={finding.description}
                      editable={editable}
                      title="Beschreibung"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Anmerkungen /&nbsp;Interpretation</th>
                  <td>
                    <InlineEditLongText
                      entity="findings.notes"
                      entity_id={finding.id}
                      value={finding.notes}
                      editable={editable}
                      title="Anmerkungen/Interpretation"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {editMode && canAdminEntry && (
              <DeleteButton
                title="Befund löschen"
                question={`Befund ${finding.token} wirklich löschen?`}
                onDelete={() => onDeleteFinding(finding.id)}
              />
            )}
            {editMode && editable ? (
              <FileUploadContainer
                files={finding.assets.filter((asset) => asset.category === 'image')}
                entity_name="entries.finding"
                entity_id={finding.id}
                display_mode="image"
                category="image"
              />
            ) : (
              <ImageGallery files={finding.assets.filter((asset) => asset.category === 'image')} />
            )}
          </div>
        ))
      ) : (
        <span />
      )}
    </div>
  );
};

export default FindingList;
