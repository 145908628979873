import { NormalizedNotification } from 'typings/models';

type NotificationLink = { label?: string; hyperlink: string; internal?: boolean };

export const getLinks = (notification: NormalizedNotification): NotificationLink[] => {
  if (!notification || !notification.data) {
    return [];
  }

  const {
    type,
    data: { project_id, entry_id, wall_id, entity_id, entity_name },
  } = notification;

  const entityLinks = {
    'entries.door': `/project/${project_id}/entry/${entry_id}/wall/${wall_id}`,
    'entries.wall': `/project/${project_id}/entry/${entry_id}/wall/${entity_id}`,
    'entries.window': `/project/${project_id}/entry/${entry_id}/wall/${wall_id}`,
    'entries.entry': `/project/${project_id}/entry/${entity_id}`,
  };

  const notificationTypeLinks = {
    IMPORT_FAILED: [
      {
        label: 'zum Eintrag',
        hyperlink: entityLinks[entity_name],
        internal: true,
      },
    ],
    IMPORT_SUCCESS: [
      {
        label: 'Import ansehen',
        hyperlink: entityLinks[entity_name],
        internal: true,
      },
    ],
  };

  const externalLinks = notification.links.map((link) => ({ ...link, internal: false }));

  return [...(notificationTypeLinks[type] || []), ...externalLinks];
};
