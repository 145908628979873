import { connect } from 'react-redux';

import * as authActions from 'actions/AuthActions';
import Logout from 'components/Logout';
import { Action } from 'typings/actions';

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  logout: () => dispatch(authActions.logout()),
});

export default connect(null, mapDispatchToProps)(Logout);
