import * as React from 'react';

import DeleteButton from 'components/DeleteButton';
import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import {
  InlineEditLongText,
  InlineEditSelect,
  InlineEditText,
} from 'containers/InlineEditContainer';
import { CatalogCategory, CatalogEntry, Door, IdTitle } from 'typings/models';
import SelectCatalogButton from 'components/SelectCatalogButton/SelectCatalogButtonContainer';

type Props = {
  doors: Door[];
  doorTypes: IdTitle[];
  editable: boolean;
  editMode: boolean;
  canAdminEntry: boolean;
  has_catalogs: boolean;
  onDeleteDoor: (number) => void;
  onSaveEntityField: (entityData: {
    entity_name: string;
    entity_id: number;
    fieldname?: string;
    value: any;
  }) => void;
  onFindCatalogEntry: (
    onSelectCatalogEntry: (catalogEntry: CatalogEntry) => void,
    catalogsFilterKey: CatalogCategory,
    preSelectedCatalogEntry?: number
  ) => void;
};

const DoorList = (props: Props) => {
  const {
    doors,
    doorTypes,
    editable,
    editMode,
    canAdminEntry,
    has_catalogs,
    onDeleteDoor,
    onSaveEntityField,
    onFindCatalogEntry,
  } = props;
  return (
    <div>
      {doors ? (
        doors.map((door) => (
          <div key={door.id}>
            <table className="room-data">
              <tbody>
                <tr>
                  <th>Signatur</th>
                  <td>
                    <InlineEditText
                      entity="doors.token"
                      entity_id={door.id}
                      value={door.token}
                      editable={editable}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Tür-Art</th>
                  <td>
                    <InlineEditSelect
                      entity="doors.type"
                      entity_id={door.id}
                      choices={doorTypes}
                      value={door.type}
                      editable={editable}
                      prevent_empty
                    />
                  </td>
                </tr>
                {(editMode || door.catalog_entry) && has_catalogs && (
                  <tr>
                    <th>Katalogeintrag</th>
                    <td>
                      <SelectCatalogButton
                        editable={editMode}
                        catalogEntryId={door.catalog_entry}
                        onDelete={() =>
                          onSaveEntityField({
                            entity_id: door.id,
                            entity_name: 'doors',
                            fieldname: 'catalog_entry',
                            value: null,
                          })
                        }
                        onClick={() =>
                          onFindCatalogEntry(
                            (catalogEntry) =>
                              onSaveEntityField({
                                entity_id: door.id,
                                entity_name: 'doors',
                                fieldname: 'catalog_entry',
                                value: catalogEntry.id,
                              }),
                            "Door",
                            door.catalog_entry
                          )
                        }
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <th>Beschreibung</th>
                  <td>
                    <InlineEditLongText
                      entity="doors.material"
                      entity_id={door.id}
                      value={door.material}
                      editable={editable}
                      title="Beschreibung"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {editMode && canAdminEntry && (
              <DeleteButton
                title="Tür löschen"
                question={`Tür ${door.token} wirklich löschen?`}
                onDelete={() => onDeleteDoor(door.id)}
              />
            )}
            {editMode && editable ? (
              <FileUploadContainer
                files={door.assets.filter((asset) => asset.category === 'image')}
                entity_name="entries.door"
                entity_id={door.id}
                display_mode="image"
                category="image"
              />
            ) : (
              <ImageGallery files={door.assets.filter((asset) => asset.category === 'image')} />
            )}
          </div>
        ))
      ) : (
        <span />
      )}
    </div>
  );
};

export default DoorList;
