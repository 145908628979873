import { schema } from 'normalizr';
import assetSchema from './asset_schema';
import doorSchema from './door_schema';
import findingSchema from './finding_schema';
import windowSchema from './window_schema';

const wallSchema = new schema.Entity('walls', {
  assets: [assetSchema],
  construction_types: [new schema.Entity('wall_construction_types')],
  doors: [doorSchema],
  findings: [findingSchema],
  surface_types: [new schema.Entity('wall_surface_types')],
  windows: [windowSchema],
});

export default wallSchema;
