import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as entryActions from 'actions/EntryActions';
import * as loadingStatesActions from 'actions/LoadingStates';
import { hideModal, showModal, updateModalTitle } from 'actions/GuiActions';

import WallDetails from 'components/WallDetails/WallDetails';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';
import { ModalType } from 'typings/enums';

type Props = {
  match: {
    params: {
      entryId: string;
      projectId: string;
      wallId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { entryId, wallId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'wallId',
    'versionId',
  ]);
  const projectVersion = selectors.getProjectVersion(state, versionId);
  const entry = selectors.getEntry(state, entryId);

  return {
    entry,
    canAdminEntry: selectors.canAdminEntry(state, versionId),
    canEditEntry: selectors.canEditEntry(state, versionId),
    creatingDoor: state.loadingStates.creating_door,
    creatingWindow: state.loadingStates.creating_window,
    creatingFinding: state.loadingStates.creating_finding,
    doorTypes: selectors.getDoorTypes(state),
    editMode: state.loadingStates.edit_mode,
    projectVersionId: projectVersion?.id,
    wall_has_findings:
      projectVersion &&
      selectors.getFieldConfigurationByEntryType(projectVersion, entry?.entry_type?.id)
        ?.walls_have_findings,
    has_catalogs:
      projectVersion &&
      selectors.getFieldConfigurationByEntryType(projectVersion, entry?.entry_type?.id)
        ?.has_catalogs,
    wall: selectors.getWall(state, wallId),
    wallConstructionTypes: selectors.getWallConstructionTypes(state),
    wallSurfaceTypes: selectors.getWallSurfaceTypes(state),
    windowTypes: selectors.getWindowTypes(state),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { entryId, projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);
  return {
    onCreateDoor: (token, text, type_id, wall_id, catalog_entry_id) =>
      dispatch(entryActions.createDoor(token, text, type_id, wall_id, catalog_entry_id)),
    onCreateWindow: (token, text, type_id, wall_id, catalog_entry_id) =>
      dispatch(entryActions.createWindow(token, text, type_id, wall_id, catalog_entry_id)),
    onCreateFinding: (token, description, notes, wall_id, catalog_entry_id) =>
      dispatch(entryActions.createFinding(token, description, notes, wall_id, catalog_entry_id)),
    onDeleteDoor: (door_id) => dispatch(entryActions.deleteDoor(door_id)),
    onDeleteWall: (wallId) =>
      dispatch(entryActions.deleteWall(projectId, entryId, wallId, versionId)),
    onDeleteWindow: (window_id) => dispatch(entryActions.deleteWindow(window_id)),
    onDeleteFinding: (finding_id) => dispatch(entryActions.deleteFinding(finding_id)),
    onResetLoadingState: (key: string) => dispatch(loadingStatesActions.reset(key)),
    onOpenModal: (content: any) => dispatch(showModal(ModalType.INFO, content, 'Kataloge')),
    onCloseModal: () => dispatch(hideModal()),
    onSaveEntityField: ({ entity_name, entity_id, fieldname, value }) =>
      dispatch(
        entryActions.saveEntityField({
          entity_id,
          entity_name,
          fieldname,
          value,
        })
      ),
    onUpdateModalTitle: (title: string) => dispatch(updateModalTitle(title)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WallDetails));
