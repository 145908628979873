import * as React from 'react';

type Props = {
  children: any;
  isError: boolean;
  WrapperComponent?: (children: any) => any;
  onRetry?: () => void;
};

const ErrorPage: React.FunctionComponent<Props> = (props: Props) => {
  const { children, WrapperComponent, isError, onRetry, ...rest } = props;
  if (!isError) {
    return children;
  }

  const Wrapper = (wrapperProps: any) => {
    return WrapperComponent ? (
      WrapperComponent(wrapperProps.children)
    ) : (
      <div className="errorpage" {...rest}>
        {wrapperProps.children}
      </div>
    );
  };
  return (
    <Wrapper>
      <h1>Ein Fehler ist aufgetreten</h1>
      {onRetry && <button onClick={() => onRetry()}>Erneut versuchen</button>}
    </Wrapper>
  );
};

export default ErrorPage;
