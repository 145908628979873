import { handleActions } from 'redux-actions';

import { AuthActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { TokenState } from 'typings/state';

const initialState: TokenState = {};

const tokenReducer = handleActions<TokenState, actions.Action>(
  {
    [AuthActionType.SET_TOKEN]: (
      state: TokenState,
      action: actions.SetTokenAction
    ): TokenState => ({
      ...state,
      token: action.payload.token,
      user_id: action.payload.user_id,
    }),
    [AuthActionType.CLEAR_TOKEN]: (state: TokenState): TokenState => ({
      ...state,
      token: undefined,
    }),
  },
  { ...initialState }
);

export default tokenReducer;

export const getToken = (state: TokenState) => state.token;
export const getUserId = (state: TokenState) => {
  return state.user_id;
};
