import { connect } from 'react-redux';
import { State } from 'typings/state';
import { Action } from 'typings/actions';
import NewPasswordForm from 'components/NewPasswordForm/NewPasswordForm';
import * as accountActions from 'actions/AccountActions';
import * as selectors from 'reducers/app';

type Props = {
  location: {
    search: string;
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  // because this Page is not loaded via React-Router, we don't have access to URL-params via props.match.params
  // this workaround works as long as there is only a single param, which there is at the time of implementation
  let token = ownProps.location.search;
  const eqSignPos = token.indexOf('=');
  token = token.substr(eqSignPos + 1);
  return {
    token,
    requestStatus: selectors.getPasswordSetRequestStatus(state),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  onSubmit: (password: string, token: string) =>
    dispatch(accountActions.settingNewPassword(password, token)),
  onReset: () => dispatch(accountActions.setNewPasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordForm);
