import { connect } from 'react-redux';

import { loadUser } from 'actions/AuthActions';
import { hideModal, loadPrivacyNoteVisible } from 'actions/GuiActions';
import App from 'components/App';
import { getModal, getToken, getUser, getUserId } from 'reducers/app';
import { State } from 'typings/state';

const mapStateToProps = (state: State) => ({
  modal: getModal(state),
  token: getToken(state),
  user: getUser(state),
  userId: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onHideModal: () => dispatch(hideModal()),
  onLoadPrivacyNoteVisible: () => dispatch(loadPrivacyNoteVisible()),
  onLoadUser: (userId: number) => dispatch(loadUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
