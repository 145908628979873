import { connect } from 'react-redux';

import * as entryActions from 'actions/EntryActions';
import {
  InlineEditBooleanWidget,
  InlineEditDateWidget,
  InlineEditFloatWidget,
  InlineEditIntegerWidget,
  InlineEditLongTextWidget,
  InlineEditSelectMultipleWidget,
  InlineEditSelectWidget,
  InlineEditTextWidget,
} from 'components/InlineEdit';
import { Action } from 'typings/actions';
import { State } from 'typings/state';

const mapStateToProps = (state: State) => {
  return {
    edit_mode: state.loadingStates.edit_mode,
    editing_entity_field: state.loadingStates.editing_entity_field,
    loaded_choices: state.entries.choices,
    loadingEntryHistory: state.loadingStates.loading_entry_history,
    history: state.entities.history,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => {
  return {
    onCancelEditEntityField: () => dispatch(entryActions.cancelEditEntityField()),
    onEditingEntityField: ({
      entity_name,
      entity_id,
      fieldname,
      status = 'EDITING',
      value = null,
      errors = null,
    }) =>
      dispatch(
        entryActions.editingEntityField({
          entity_id,
          entity_name,
          errors,
          fieldname,
          status,
          value,
        })
      ),
    onLoadChoices: (choices_name, choices_get_params) =>
      dispatch(entryActions.loadChoices(choices_name, choices_get_params)),
    onSaveEntityField: ({ entity_name, entity_id, fieldname, value }) =>
      dispatch(
        entryActions.saveEntityField({
          entity_id,
          entity_name,
          fieldname,
          value,
        })
      ),
  };
};

export const connected = connect(mapStateToProps, mapDispatchToProps);

export const InlineEditBoolean = connected(InlineEditBooleanWidget);
export const InlineEditDate = connected(InlineEditDateWidget);
export const InlineEditFloat = connected(InlineEditFloatWidget);
export const InlineEditInteger = connected(InlineEditIntegerWidget);
export const InlineEditLongText = connected(InlineEditLongTextWidget);
export const InlineEditSelect = connected(InlineEditSelectWidget);
export const InlineEditSelectMultiple = connected(InlineEditSelectMultipleWidget);
export const InlineEditText = connected(InlineEditTextWidget);
