import * as React from 'react';
import { useState, useEffect } from 'react';

import union from 'lodash/union';

import { IExportProjectOptions, exportOption } from './ExportProjectOptions.types';
import { ExportProjectAccordion } from './ExportProjectAccordion';
import { getTruncatedSearchString } from 'utils/strings';

const ExportProjectOptions = ({
  entries,
  facilities,
  versionId,
  query: initialQuery,
  results,
  countEntries,
  countWalls,
  countDepartmentEntries,
  onCloseModal,
  onExportProjectVersionPdf,
  onSearch,
}: IExportProjectOptions) => {
  const [query, setQuery] = useState<string>(initialQuery || '');
  const [exportOption, setExportOption] = useState<exportOption>(query ? 'SEARCH' : 'FULL');
  const [roomIds, setRoomIds] = useState<number[]>(entries.map((e) => e.id));
  const [includeLogo, setIncludeLogo] = useState<boolean>(false);
  const [excludeDepartmentEntries, setExcludeDepartmentEntries] = useState<boolean>(false);
  const [downscaleImageQuality, setDownscaleImageQuality] = useState<boolean>(false);

  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onChangeExportOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExportOption(e.target.value as exportOption);
  };

  const onExport = () => {
    switch (exportOption) {
      case 'FULL':
        onExportProjectVersionPdf(
          versionId,
          entries.map((e) => e.id),
          { includeLogo, excludeDepartmentEntries, downscaleImageQuality }
        );
        break;
      case 'SEARCH':
        onExportProjectVersionPdf(
          versionId,
          results?.filter((result) => result.url.includes('/base')).map((result) => result.id),
          {
            searchTerm: query,
            includeLogo,
            excludeDepartmentEntries,
            downscaleImageQuality,
            wallIds: results
              ?.filter((result) => result.url.includes('/wall'))
              .map((result) => result.id),
            departmentEntryIds: results
              ?.filter((result) => result.url.includes('/department'))
              .map((result) => result.id),
          }
        );
        break;
      case 'CUSTOM':
        onExportProjectVersionPdf(versionId, roomIds, {
          partialExport: true,
          includeLogo,
          excludeDepartmentEntries,
          downscaleImageQuality,
        });
        break;
    }
    onCloseModal();
  };

  const onChangeRoomId = (id: number) => {
    roomIds.includes(id)
      ? setRoomIds(roomIds.filter((rid) => rid !== id))
      : setRoomIds(roomIds.concat(id));
  };

  const bulkToggleEntries = (activate: boolean, ids: number[]) => {
    activate
      ? setRoomIds(union(roomIds, ids))
      : setRoomIds(roomIds.filter((id) => !ids.includes(id)));
  };

  useEffect(() => {
    onSearch(query);
  }, [query]);

  return (
    <div>
      <div className={'modal-input'}>
        <input
          type="radio"
          name="type"
          value="FULL"
          id={'export-full'}
          onChange={onChangeExportOption}
          checked={exportOption === 'FULL'}
        />
        <label htmlFor={'export-full'}>Vollständiger Export</label>
      </div>
      <div className={'modal-input'}>
        <input
          type="radio"
          name="type"
          value="SEARCH"
          id={'export-search'}
          onChange={onChangeExportOption}
          checked={exportOption === 'SEARCH'}
        />
        <label htmlFor={'export-search'}>Suchbegriff eingrenzen...</label>
        {exportOption === 'SEARCH' && (
          <span>
            <input type="text" onChange={onChangeQuery} value={query} />
            Suche: {results?.length || 0} Suchergebnisse
            {(countEntries > 300 || countWalls > 300 || countDepartmentEntries > 300) &&
              ` (über 300 ${getTruncatedSearchString(
                countEntries,
                countWalls,
                countDepartmentEntries
              )})`}
          </span>
        )}
      </div>
      <div className={'modal-input'}>
        <input
          type="radio"
          name="type"
          value="CUSTOM"
          id={'export-custom'}
          onChange={onChangeExportOption}
          checked={exportOption === 'CUSTOM'}
        />
        <label htmlFor={'export-custom'}>Zu exportierende Einträge wählen...</label>
        {exportOption === 'CUSTOM' && (
          <div>
            <div className={'accordion'}>
              {facilities.map((facility) => (
                <ExportProjectAccordion
                  key={facility.id}
                  entries={entries}
                  facility={facility}
                  roomIds={roomIds}
                  bulkToggleEntries={bulkToggleEntries}
                  onChangeRoomId={onChangeRoomId}
                />
              ))}
              <ExportProjectAccordion
                entries={entries}
                roomIds={roomIds}
                bulkToggleEntries={bulkToggleEntries}
                onChangeRoomId={onChangeRoomId}
              />
            </div>
          </div>
        )}
      </div>
      <div style={{ paddingTop: '20px' }}>
        <input
          type="checkbox"
          id={'include-logo-check'}
          onChange={() => setIncludeLogo(!includeLogo)}
          checked={includeLogo}
        />
        <label htmlFor={'include-logo-check'}>Logo in Export hinzufügen</label>
      </div>
      <div>
        <input
          type="checkbox"
          id={'department-entries-check'}
          onChange={() => setExcludeDepartmentEntries(!excludeDepartmentEntries)}
          checked={excludeDepartmentEntries}
        />
        <label htmlFor={'department-entries-check'}>Fachbereichseinträge überspringen</label>
      </div>
      <div>
        <input
          type="checkbox"
          id={'image-quality-check'}
          onChange={() => setDownscaleImageQuality(!downscaleImageQuality)}
          checked={downscaleImageQuality}
        />
        <label htmlFor={'image-quality-check'}>Bildqualität herabsetzen</label>
      </div>
      <button style={{ marginTop: '40px' }} onClick={onExport}>
        Export Starten
      </button>
    </div>
  );
};

export default ExportProjectOptions;
