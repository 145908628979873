import { schema } from 'normalizr';
import assetSchema from './asset_schema';
import departmentEntrySchema from './departmententry_schema';
import wallSchema from './wall_schema';
import entryTypeSchema from './entry_type_schema';
import findingSchema from './entryfinding_schema';

const entry = new schema.Entity('entries', {
  assets: [assetSchema],
  compositions: [new schema.Entity('compositions')],
  damage_intensity: new schema.Entity('damage_intensities'),
  department_entries: [departmentEntrySchema],
  entry_type: entryTypeSchema,
  examination_type: new schema.Entity('examination_types'),
  facility: new schema.Entity('facilities'),
  facility_level: new schema.Entity('facility_levels'),
  inscriptions: [new schema.Entity('inscriptions')],
  interval_next_maintenance: new schema.Entity('interval_next_maintenance'),
  interval_next_revision: new schema.Entity('interval_next_revision'),
  locations: [new schema.Entity('locations')],
  materials: [new schema.Entity('materials')],
  measure: new schema.Entity('measures'),
  overgrowns: [new schema.Entity('overgrowns')],
  stabilities: [new schema.Entity('stabilities')],
  stability_result: new schema.Entity('stability_results'),
  stratifications: [new schema.Entity('stratifications')],
  walls: [wallSchema],
  entryfindings: [findingSchema],
});

export default entry;
