import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import React from 'react';

type Props = {
  progress: number | null;
};

const LoadingPanel: React.FunctionComponent<Props> = (props: Props) => {
  const { progress } = props;
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 300,
        justifyContent: 'center',
      }}
    >
      <div style={{ marginBottom: 8 }}>
        <SimpleLoader size="huge" />
      </div>
      {progress !== null && <div>{progress} %</div>}
    </div>
  );
};

export default LoadingPanel;
