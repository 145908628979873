import React from 'react';

type Props = {
  html: string;
};

const Preview: React.FunctionComponent<Props> = (props: Props) => {
  const { html, ...rest } = props;
  return (
    <div
      id="plan"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
      style={{
        width: '60%',
      }}
      {...rest}
    />
  );
};

export default Preview;
