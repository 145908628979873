import { EntityState } from 'typings/state';

export const initialState: EntityState = {
  assets: {},
  assetsToMove: null,
  catalogs: {},
  catalog_entries: {},
  compositions: {},
  damage_intensities: {},
  department_entries: {},
  departments: {},
  door_types: {},
  doors: {},
  entries: {},
  entryfindings: {},
  examination_types: {},
  facilities: {},
  facility_levels: {},
  field_configurations: {},
  findings: {},
  inscriptions: {},
  interval_next_maintenance: {},
  interval_next_revision: {},
  locations: {},
  materials: {},
  measure_classification: {},
  measure_prioritization: {},
  measures: {},
  overgrowns: {},
  projectmember: {},
  projects: {},
  projectversions: {},
  stabilities: {},
  stability_results: {},
  stratifications: {},
  users: {},
  wall_construction_types: {},
  wall_surface_types: {},
  walls: {},
  window_types: {},
  windows: {},
  history: {},
};
