import React, { useEffect, useRef, useState } from 'react';

import { ITileFloormapProps, SUPPORTED_SHAPES } from './TileFloormapComposer.types';
import { Entry } from 'typings/models';

import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import {
  useAddCenterCirclesToMap,
  useAddEventListeners,
  useAddHoverEvents,
  useAddShapesToMap,
  useAddTooltips,
  useAddTooltipsOnZoom,
  useCreateCenterCircles,
  useHandleActiveItem,
  useMountMap,
  usePopulateShapes,
} from './TileFloormap.utils';

export const TileFloormap = ({
  entry,
  entries,
  editMode,
  facilityLevel,
  onClose,
  onGotoEntity,
  onSaveEntityField,
  onSetEditMode,
  onSetTitle,
}: ITileFloormapProps) => {
  const tileLayer = L.tileLayer(facilityLevel?.floormap!!, {
    noWrap: true,
    bounds: [
      [-256, 0],
      [0, 256],
    ],
    maxZoom: 4,
    minZoom: 2,
    subdomains: '',
    attribution: `${facilityLevel?.floormap_creator || ''} ${
      facilityLevel?.floormap_creation_date || ''
    }`,
  });

  const mapRef = useRef<L.Map | null>(null);
  const shapeRef = useRef<Map<number, L.Polygon | L.CircleMarker>>(new Map());
  const circleRef = useRef<Map<number, L.CircleMarker>>(new Map());
  const drawRef = useRef<any | null>(null);
  const activeCircleRef = useRef<[number, L.CircleMarker] | null>(null);
  const tooltipRef = useRef<Map<number, L.Tooltip>>(new Map());

  const [mapInstance, setMapInstance] = useState<L.Map | null>(null);
  const [polygons, setPolygons] = useState<Map<number, L.Polygon>>(new Map());
  const [circles, setCircles] = useState<Map<number, L.CircleMarker>>(new Map());
  const [activeCircle, setActiveCircle] = useState<[number, L.CircleMarker] | null>(null);
  const [points, setPoints] = useState<Map<number, L.CircleMarker>>(new Map());
  const [tooltips, setTooltips] = useState<Map<number, L.Tooltip>>(new Map());

  useMountMap(mapRef, 'plan', tileLayer, 'LARGE', setMapInstance);
  usePopulateShapes(
    mapInstance,
    shapeRef,
    onGotoEntity,
    setPolygons,
    setPoints,
    facilityLevel?.geoJSON,
    entry?.id
  );

  const [isDrawing, setIsDrawing] = useState<SUPPORTED_SHAPES | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [lastFeature, setLastFeature] = useState<any | null>(null);

  const [entityBeingEdited] = useState<Entry | undefined>(
    facilityLevel?.id === entry?.facility_level?.id ? entry : undefined
  );

  useAddShapesToMap(mapInstance, polygons, points);
  useHandleActiveItem(mapInstance, polygons, points, activeCircleRef, setActiveCircle, entry?.id);
  useCreateCenterCircles(mapInstance, polygons, circleRef, setCircles, onGotoEntity, entry?.id);
  useAddCenterCirclesToMap(mapInstance, circles, activeCircle);
  useAddTooltips(points, circles, activeCircle, tooltipRef, setTooltips, entries);

  useAddHoverEvents(polygons, points, circles, activeCircle, entry?.id, entries, onSetTitle);
  useAddTooltipsOnZoom(mapInstance, points, circles, activeCircle, tooltips, entry?.id);
  useAddEventListeners(polygons, tooltips, onGotoEntity, onSetTitle, entries, entry?.id);

  useEffect(() => {
    if (isDrawing) {
      onSetTitle(
        `${entityBeingEdited?.entry_type.title} wird verortet: ${entityBeingEdited?.signature}`
      );
      shapeRef.current.forEach((p: L.Polygon | L.CircleMarker) => {
        p.removeFrom(mapRef.current!!);
      });
      circleRef.current.forEach((c: L.CircleMarker) => {
        c.removeFrom(mapRef.current!!);
      });
    } else {
      onSetTitle('');
      shapeRef.current.forEach((p: L.Polygon | L.CircleMarker) => {
        p.addTo(mapRef.current!!);
      });
      circleRef.current.forEach((c: L.CircleMarker) => {
        c.addTo(mapRef.current!!);
      });
    }
  }, [isDrawing]);

  useEffect(() => {
    setIsDeleteDialogOpen(false);
    setIsDrawing(null);
  }, [editMode]);

  useEffect(() => {
    if (editMode && entityBeingEdited && isDrawing && mapRef) {
      var fg = L.featureGroup();
      mapRef.current!!.on('pm:create', (e) => {
        drawRef.current = e;
        const geoJSON = fg.addLayer(e.layer).toGeoJSON();
        // @ts-ignore
        geoJSON.features[0].properties = { type: 'room', id: entityBeingEdited?.id };
        // @ts-ignore
        setLastFeature(geoJSON.features[0]);
      });
      mapRef.current!!.pm.enableDraw(isDrawing, {
        snappable: true,
        snapDistance: 20,
        tooltips: false,
      });
    }
    return () => {
      mapRef.current!!.pm.disableDraw();
      mapRef.current!!.removeEventListener('pm:drawstart');
    };
  }, [editMode, entityBeingEdited, isDrawing, lastFeature]);

  useEffect(() => {
    if (lastFeature) {
      mapRef.current!!.pm.disableDraw();
    }
  }, [lastFeature]);

  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  const saveFeature = () => {
    // in a future implementation needs to check type of entityBeingEdited
    if (entityBeingEdited && entityBeingEdited.facility_level?.id && onSaveEntityField) {
      onSaveEntityField({
        entity_id: entityBeingEdited.id,
        entity_name: 'entries',
        fieldname: 'feature',
        value: lastFeature ? JSON.stringify(lastFeature) : null,
        facility_level_id: entityBeingEdited.facility_level?.id,
      });
    }
    setLastFeature(null);
    onSetEditMode(false);
    onClose && onClose();
  };

  const discardFeature = () => {
    mapRef.current?.removeLayer(drawRef.current.layer);
    setLastFeature(null);
  };

  const activateDrawMode = (shape: SUPPORTED_SHAPES) => {
    setIsDrawing(shape);
  };

  const deactivateDrawMode = () => {
    setIsDrawing(null);
  };

  const styles = {
    width: 'auto',
    height: 'auto',
    padding: 0,
    backgroundColor: 'white',
  };

  return (
    <div className="map-container">
      <div style={{ flexGrow: 1 }}>
        <div id="confirm-save">
          {lastFeature && (
            <>
              <p>Möchten Sie die Änderungen übernehmen?</p>
              <button id="btn-confirm" onClick={saveFeature}>
                Übernehmen
              </button>
              <button id="btn-deny" onClick={discardFeature}>
                Verwerfen
              </button>
            </>
          )}
          {isDeleteDialogOpen && (
            <>
              <p>Möchten Sie das Objekt wirklich löschen?</p>
              <button id="btn-confirm" onClick={saveFeature}>
                Löschen
              </button>
              <button id="btn-deny" onClick={cancelDelete}>
                Abbrechen
              </button>
            </>
          )}
        </div>
        <div id={'plan'} className="map-navigation-map" style={styles}>
          {isDrawing ? (
            <div className="toolbox">
              <button className="cancel" onClick={deactivateDrawMode}>
                Hinzufügen beenden
              </button>
            </div>
          ) : (
            editMode &&
            entityBeingEdited && (
              <div className="toolbox">
                <button
                  className="edit-object-btn add"
                  onClick={() => activateDrawMode('Polygon')}
                  style={{ visibility: isDeleteDialogOpen || isDrawing ? 'hidden' : 'visible' }}
                >
                  {'Raumumriss hinzufügen'}
                </button>
                <button
                  className="edit-object-btn add"
                  onClick={() => activateDrawMode('CircleMarker')}
                  style={{ visibility: isDeleteDialogOpen || isDrawing ? 'hidden' : 'visible' }}
                >
                  {'Punkt hinzufügen'}
                </button>
                <button
                  className="edit-object-btn delete"
                  onClick={() => setIsDeleteDialogOpen(!isDeleteDialogOpen)}
                  style={{ visibility: isDeleteDialogOpen || isDrawing ? 'hidden' : 'visible' }}
                >
                  {'Objekt löschen'}
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
