import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import roombookApp from 'reducers/app';
import {
  watchCreateEntry,
  watchCreateEntryFinding,
  watchDeleteEntry,
  watchDeleteEntryFinding,
  watchLoadChoices,
  watchLoadEntries,
  watchLoadEntry,
  watchLoadEntryHistory,
  watchSavedEntityField,
  watchSaveEntityField,
} from 'sagas/EntrySagas';

import {
  watchCreateDoor,
  watchCreateFinding,
  watchCreateWall,
  watchCreateWindow,
  watchDeleteDoor,
  watchDeleteFinding,
  watchDeleteWall,
  watchDeleteWindow,
} from 'sagas/WallSagas';

import {
  watchDeleteAsset,
  watchMoveAsset,
  watchMoveAssetToEntity,
  watchUploadAssets,
} from 'sagas/AssetSagas';
import { watchLoadUser, watchLogin, watchSearchUser, watchLoadUsers } from 'sagas/AuthSagas';
import { watchLoadDashboard } from 'sagas/DashboardSagas';
import { watchCreateDepartmentEntry, watchDeleteDepartmentEntry, watchMoveDepartmentEntry } from 'sagas/DepartmentSagas';
import {
  watchDismissPrivacyNote,
  watchLoadPrivacyNoteVisible,
  watchShowToast,
} from 'sagas/GuiSagas';
import {
  watchAbortProjectExport,
  watchAddProjectMember,
  watchCopyProjectVersion,
  watchExportProject,
  watchExportProjectVersionPdf,
  watchImportFloormap,
  watchInitiateProjectExport,
  watchLoadProject,
  watchRemoveProjectMember,
  watchUploadFloormap,
} from 'sagas/ProjectSagas';
import { watchSearchEntries } from 'sagas/SearchSagas';
import { watchRequestPasswordReset, watchSetNewPassword } from 'sagas/AccountSagas';
import {
  watchLoadNotifications,
  watchMarkAllNotificationsRead,
  watchMarkNotificationRead,
} from 'sagas/NotificationSagas';

import {
  watchCreateCatalog,
  watchCreateCatalogEntry,
  watchDeleteCatalog,
  watchDeleteCatalogEntry,
} from 'sagas/CatalogSagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);

const middleware = [sagaMiddleware, routerMiddleware];

const persistConfig = {
  debug: process.env.NODE_ENV === 'development',
  key: 'root',
  storage,
  whitelist: ['token'],
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const persistedReducer = persistReducer(persistConfig, roombookApp(history));

export const configureStore = () => {
  const store = createStore(
    persistedReducer,
    {},
    compose(
      applyMiddleware(...middleware),
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );

  const persistor = persistStore(store);

  // Run sagas
  sagaMiddleware.run(watchExportProjectVersionPdf);
  sagaMiddleware.run(watchExportProject);
  sagaMiddleware.run(watchInitiateProjectExport);
  sagaMiddleware.run(watchAbortProjectExport);
  sagaMiddleware.run(watchCopyProjectVersion);

  sagaMiddleware.run(watchLoadEntries);
  sagaMiddleware.run(watchLoadEntry);
  sagaMiddleware.run(watchLoadEntryHistory);
  sagaMiddleware.run(watchCreateEntry);
  sagaMiddleware.run(watchDeleteEntry);
  sagaMiddleware.run(watchSaveEntityField);
  sagaMiddleware.run(watchSavedEntityField);
  sagaMiddleware.run(watchSearchEntries);

  sagaMiddleware.run(watchCreateEntryFinding);
  sagaMiddleware.run(watchDeleteEntryFinding);

  sagaMiddleware.run(watchLogin);
  sagaMiddleware.run(watchLoadUser);
  sagaMiddleware.run(watchLoadUsers);
  sagaMiddleware.run(watchSearchUser);

  sagaMiddleware.run(watchLoadChoices);

  sagaMiddleware.run(watchCreateWall);
  sagaMiddleware.run(watchDeleteWall);

  sagaMiddleware.run(watchCreateDoor);
  sagaMiddleware.run(watchDeleteDoor);

  sagaMiddleware.run(watchCreateFinding);
  sagaMiddleware.run(watchDeleteFinding);

  sagaMiddleware.run(watchCreateWindow);
  sagaMiddleware.run(watchDeleteWindow);

  sagaMiddleware.run(watchMoveDepartmentEntry);
  sagaMiddleware.run(watchCreateDepartmentEntry);
  sagaMiddleware.run(watchDeleteDepartmentEntry);

  sagaMiddleware.run(watchUploadAssets);
  sagaMiddleware.run(watchMoveAssetToEntity);
  sagaMiddleware.run(watchMoveAsset);
  sagaMiddleware.run(watchDeleteAsset);

  sagaMiddleware.run(watchUploadFloormap);
  sagaMiddleware.run(watchImportFloormap);
  sagaMiddleware.run(watchLoadProject);
  sagaMiddleware.run(watchAddProjectMember);
  sagaMiddleware.run(watchRemoveProjectMember);

  sagaMiddleware.run(watchDismissPrivacyNote);
  sagaMiddleware.run(watchLoadPrivacyNoteVisible);

  sagaMiddleware.run(watchLoadDashboard);
  sagaMiddleware.run(watchShowToast);

  sagaMiddleware.run(watchLoadNotifications);
  sagaMiddleware.run(watchMarkNotificationRead);
  sagaMiddleware.run(watchMarkAllNotificationsRead);

  sagaMiddleware.run(watchRequestPasswordReset);
  sagaMiddleware.run(watchSetNewPassword);

  sagaMiddleware.run(watchCreateCatalog);
  sagaMiddleware.run(watchCreateCatalogEntry);
  sagaMiddleware.run(watchDeleteCatalog);
  sagaMiddleware.run(watchDeleteCatalogEntry);

  return { store, persistor };
};
