import { call, put, takeLatest } from 'redux-saga/effects';

import { loadDashboard } from 'api/WebApi';
import { checkResponseStatus } from './utils';

import { DashboardActionType } from 'actions/actionTypes';
import { showModal } from 'actions/GuiActions';
import { ModalType } from 'typings/enums';
import {
  loadDashboardFailed,
  loadedDashboard,
  loadingDashboard,
} from '../actions/DashboardActions';

export function* workflowLoadDashboard(action) {
  yield put(loadingDashboard());

  try {
    const response = yield call(loadDashboard, action.payload);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(loadedDashboard(json));
  } catch (error) {
    yield put(showModal(ModalType.WARNING, `Konnte Übersicht nicht laden`));
    yield put(loadDashboardFailed(error));
  }
}

// Watchers

export function* watchLoadDashboard() {
  yield takeLatest(DashboardActionType.LOAD_DASHBOARD, workflowLoadDashboard);
}
