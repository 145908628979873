import React from 'react';

import { IAssetUploadErrorProps } from './AssetUploadError.types';


const AssetUploadError = ({filename, message}: IAssetUploadErrorProps) => {
  return (
    <>
      <p>
        Fehler beim Datei-Upload: "{filename}". Überspringe Upload.
      </p>
      <p>
        Fehlerursache: {message}
      </p>
    </>
  );
};

export default AssetUploadError;
