import { connect } from 'react-redux';

import * as authActions from 'actions/AuthActions';
import LoginForm from 'components/LoginForm';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';

const mapStateToProps = (state: State) => ({
  loggingIn: selectors.loggingIn(state),
  loginFailed: selectors.loginFailed(state),
  messages: state.auth.messages || [],
});

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  onSubmit: (email, password) => dispatch(authActions.login(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
