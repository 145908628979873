import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import DeleteButton from 'components/DeleteButton';
import RoomTitle from 'components/RoomTitle';
import WallDataLeft from './WallDataLeft';
import WallDataMiddle from './WallDataMiddle';
import WallDataRight from './WallDataRight';

import CatalogDialogContainer from 'containers/CatalogDialogContainer';
import { InlineEditText } from 'containers/InlineEditContainer';
import {
  CatalogCategory,
  CatalogEntry,
  Door,
  Entry,
  Finding,
  IdTitle,
  SaveStatus,
  Wall,
  Window,
} from 'typings/models';

type Props = RouteComponentProps<any> & {
  canEditEntry: boolean;
  canAdminEntry: boolean;
  creatingDoor: SaveStatus<Door>;
  creatingWindow: SaveStatus<Window>;
  creatingFinding: SaveStatus<Finding>;
  doorTypes: IdTitle[];
  editMode: boolean;
  entry: Entry;
  projectVersionId: number;
  windowTypes: IdTitle[];
  wall?: Wall;
  wall_has_findings?: boolean;
  has_catalogs: boolean;
  wallConstructionTypes: IdTitle[];
  wallSurfaceTypes: IdTitle[];
  onCreateDoor: (
    token: string,
    value: string,
    doorType: number | null,
    wallId: number,
    catalogEntryId: number | null
  ) => void;
  onDeleteDoor: (number) => void;
  onCreateFinding: (
    token: string,
    description: string,
    notes: string | null,
    wallId: number,
    catalogEntryId: number | null
  ) => void;
  onDeleteFinding: (number: number) => void;
  onCreateWindow: (
    token: string,
    value: string,
    windowType: number | null,
    wallId: number,
    catalogEntryId: number | null
  ) => void;
  onDeleteWindow: (number) => void;
  onDeleteWall: (number) => void;
  onResetLoadingState: (key: string) => void;
  onOpenModal: (content: any) => void;
  onCloseModal: () => void;
  onUpdateModalTitle: (title: string) => void;
  onSaveEntityField: (entityData: {
    entity_name: string;
    entity_id: number;
    fieldname?: string;
    value: any;
  }) => void;
};

const WallDetails: React.FunctionComponent<Props> = (props: Props) => {
  const {
    doorTypes,
    windowTypes,
    creatingDoor,
    creatingFinding,
    creatingWindow,
    entry,
    has_catalogs,
    wall,
    canAdminEntry,
    canEditEntry,
    editMode,
    projectVersionId,
    wall_has_findings = false,
    wallConstructionTypes,
    wallSurfaceTypes,
    onCloseModal,
    onCreateDoor,
    onCreateWindow,
    onDeleteDoor,
    onDeleteWall,
    onDeleteWindow,
    onCreateFinding,
    onDeleteFinding,
    onResetLoadingState,
    onOpenModal,
    onSaveEntityField,
    onUpdateModalTitle,
  } = props;

  React.useEffect(() => {
    if (!editMode) {
      onCloseModal();
    }
  }, [editMode, onCloseModal]);

  if (!wall) {
    return <span>Wall not found</span>;
  }

  const handleOpenSelectCatalogDialog = (
    onAssignCatalog: (catalogEntry: CatalogEntry) => void,
    catalogsFilterKey: CatalogCategory,
    preSelectedCatalogEntry?: number,
  ) => {
    onOpenModal(
      <CatalogDialogContainer
        preSelectedCatalogEntryId={preSelectedCatalogEntry}
        projectVersionId={projectVersionId}
        onTitleChange={onUpdateModalTitle}
        onSelectCatalogEntry={({ value }) => {
          onAssignCatalog(value);
          onCloseModal();
        }}
        catalogsFilterKey={catalogsFilterKey}
      />
    );
  };

  return (
    <DocumentTitle
      title={`${entry.signature} – Wand ${wall.token}${wall.title ? ` (${wall.title})` : ''} `}
    >
      <div className="room-content flex-container vertical">
        <div className="room-title">
          {!!entry.title && <RoomTitle title={entry.title} signature={entry.signature} />}
          <h3>
            Wand:&nbsp;
            <InlineEditText
              entity="walls.token"
              entity_id={wall.id}
              value={wall.token}
              editable={canEditEntry}
            />
          </h3>

          {editMode && canAdminEntry ? (
            <DeleteButton
              title="Wand löschen"
              question="Wand wirklich löschen?"
              onDelete={() => onDeleteWall(wall?.id)}
            />
          ) : null}
        </div>
        <div className="room-details flex-container horizontal">
          <div className="content-box">
            <WallDataLeft
              wall={wall}
              edit_mode={editMode}
              editable={canEditEntry}
              wallConstructionTypes={wallConstructionTypes}
              wallSurfaceTypes={wallSurfaceTypes}
            />
          </div>
          <div className="content-box">
            <WallDataMiddle wall={wall} edit_mode={editMode} editable={canEditEntry} />
          </div>
          <div className="content-box">
            <WallDataRight
              wall={wall}
              has_findings={wall_has_findings}
              has_catalogs={has_catalogs}
              doorTypes={doorTypes}
              windowTypes={windowTypes}
              creating_door={creatingDoor}
              creating_window={creatingWindow}
              edit_mode={editMode}
              editable={canEditEntry}
              canAdminEntry={canAdminEntry}
              onCreateDoor={onCreateDoor}
              onDeleteDoor={onDeleteDoor}
              onCreateWindow={onCreateWindow}
              onDeleteWindow={onDeleteWindow}
              onResetLoadingState={onResetLoadingState}
              creating_finding={creatingFinding}
              onCreateFinding={onCreateFinding}
              onDeleteFinding={onDeleteFinding}
              onFindCatalogEntry={handleOpenSelectCatalogDialog}
              onSaveEntityField={onSaveEntityField}
            />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default withRouter(WallDetails);
