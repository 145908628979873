import * as React from 'react';

import InlineEditBaseWidget, { BaseProps } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: string | null;
};

class InlineEditLongTextWidget extends InlineEditBaseWidget<Props> {
  protected refElement = React.createRef<HTMLTextAreaElement>();
  protected canFullscreen = true;

  public shouldSave = (e: React.KeyboardEvent<HTMLElement>) =>
    e.nativeEvent.ctrlKey && e.key === 'Enter';

  protected getValueReadable = () => {
    const { value } = this.props;
    return value
      ? value.split('\n').map((item, index) => (
        <span key={index}>
          {item}
          <br />
        </span>
      ))
      : '';
  };

  protected getEditWidget = (value?: string) => {
    const defaultValue =
      this.props.editing_entity_field && this.props.editing_entity_field.value
        ? String(this.props.editing_entity_field.value)
        : this.props.value || '';

    return (
      <span>
        <textarea
          rows={8}
          ref={this.refElement}
          data-original={this.props.value}
          defaultValue={value ?? defaultValue}
          onKeyDown={this.handleKeyDown}
        />{' '}
        {this.props.suffix}
      </span>
    );
  };
}

export default InlineEditLongTextWidget;
