import { EntryShort } from 'typings/models';
import React from 'react';

type Props = {
  entries: EntryShort[];
  defaultValue?: number;
  onSelect: (e: React.FormEvent<HTMLSelectElement>) => void;
};

const EntrySelect: React.FunctionComponent<Props> = (props: Props) => {
  const { entries, defaultValue, onSelect } = props;
  return (
    <select defaultValue={String(defaultValue) || ''} onChange={onSelect}>
      <option value="">-- Eintrag auswählen --</option>
      {entries.map((entry) => (
        <option key={entry.id} value={entry.id}>
          {entry.signature}
          {!!entry.title && ` - ${entry.title}`}
        </option>
      ))}
    </select>
  );
};

export default EntrySelect;
