export const getProjectIdFromURL = (url: string): number | null => {
  const prefix = '/project/';
  if (!url.startsWith(prefix)) {
    return null;
  }
  const prefixLength = prefix.length;
  const delimiter = url.indexOf('/', prefixLength);
  const projectId = url.substring(prefixLength, delimiter === -1 ? url.length : delimiter);
  return isNaN(parseInt(projectId)) ? null : parseInt(projectId);
};

export const getProjectVersionIdFromURL = (url: string): number | null => {
  const regExp = '/version/([0-9]*)';
  const res = url.match(regExp);
  return res ? parseInt(res[1]) : null;
};

export const getSearchQueryFromURL = (url: string): string | undefined => {
  const URLSearchConst = '/search/';
  // We don't expect any unique identifiers ever to be called "search"
  return url.indexOf(URLSearchConst) === -1
    ? undefined
    : url.substring(url.indexOf(URLSearchConst) + URLSearchConst.length);
};
