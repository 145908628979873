import * as React from 'react';
import uniqueId from 'lodash/uniqueId';
import { isTouchscreen } from 'utils/device';

type Props = {
  title?: string;
  onSelectFile: (e: React.SyntheticEvent) => void;
  onCapturePhoto?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SelectFileButton: React.FunctionComponent<Props> = (props: Props) => {
  const deviceIsTouchScreen = isTouchscreen();

  const { title, onSelectFile, onCapturePhoto } = props;
  const elemid = uniqueId('capture-');
  return (
    <div>
      <button className="primary" onClick={onSelectFile}>
        {title || 'Datei auswählen'}
      </button>
      {!!onCapturePhoto && deviceIsTouchScreen && (
        <div>
          <label
            className="button primary"
            htmlFor={elemid}
            style={{ float: 'right', cursor: 'pointer' }}
          >
            Foto aufnehmen
          </label>
          <input
            onChange={(e) => !!onCapturePhoto && onCapturePhoto(e)}
            type="file"
            accept="image/*"
            capture="environment"
            id={elemid}
            style={{ display: 'none' }}
          />
        </div>
      )}
    </div>
  );
};
export default SelectFileButton;
