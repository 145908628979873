import React, { useEffect } from 'react';

import { ILogoutProps } from './Logout.types';

const Logout = ({ logout }: ILogoutProps) => {
  useEffect(() => {
    localStorage.removeItem('token');
    logout();
  }, []);
  return <div>Melde ab...</div>;
};

export default Logout;
