import { Facility, IdTitle } from 'typings/models';
import React from 'react';

type Props = {
  facility: Facility;
  currentLevel?: IdTitle;
  onSetFacilityLevel: (FacilityLevel) => void;
};

const FacilityListItem: React.FunctionComponent<Props> = (props: Props) => {
  const { facility, currentLevel, onSetFacilityLevel } = props;
  return (
    <li key={facility.id}>
      <span className="map-navigation-list-building-name">{facility.title}</span>
      <ul>
        {facility.facility_levels.map((level) => (
          <li key={level.id}>
            <a
              href="#"
              className={!!currentLevel && currentLevel.id === level.id ? 'active' : ''}
              onClick={() => onSetFacilityLevel(level)}
            >
              {level.title}
            </a>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default FacilityListItem;
