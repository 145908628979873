import { all, call, put, spawn, takeLatest } from 'redux-saga/effects';

import { AssetActionType } from 'actions/actionTypes';
import {
  deletedAsset,
  moveAssetToEntityFailed,
  movedAsset,
  movedAssetToEntity,
  movingAsset,
  movingAssetToEntity,
  uploadAsset,
  uploadAssetFailed,
  uploadedAsset,
  uploadingAsset,
} from 'actions/AssetActions';
import { showModal, showToast } from 'actions/GuiActions';
import * as api from 'api/WebApi';
import {
  MoveAssetAction,
  MoveAssetToEntityAction,
  UploadAssetAction,
  UploadAssetsAction,
} from 'typings/actions';
import { ModalType, ToastType } from 'typings/enums';
import { AssetCategory } from 'typings/models';
import { checkResponseStatus } from './utils';
import AssetUploadError from 'components/AssetUploadError';

export function* workflowUploadAsset(action: UploadAssetAction) {
  const { entity_name, entity_id, category, field_name, file } = action.payload;

  interface IPayload {
    category: AssetCategory;
    entity_id: number;
    entity_name: string;
    field_name: string | undefined;
    uploadKey: string;
  }

  const basePayload: IPayload = {
    category,
    entity_id,
    entity_name,
    field_name,
    uploadKey: file.uploadKey,
  };

  try {
    const filename = file.file.name;
    if (!!filename && filename.split('.').pop() === 'zip') {
      basePayload.category = 'archive';
    }

    yield put(uploadingAsset(basePayload));
    const response = yield call(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      api.uploadAsset,
      entity_name,
      entity_id,
      category,
      file.file,
      field_name
    );
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(
      uploadedAsset({
        ...basePayload,
        response: json,
      })
    );
    if (basePayload.category === 'archive') {
      yield put(
        showToast(
          'Das Archiv wurde hochgeladen, und der Archiv-Import gestartet.\n Wir benachrichtigen Sie, sobald der Import abgeschlossen ist.',
          { type: ToastType.SUCCESS }
        )
      );
    }
  } catch (e: any) {
    const res = yield e.response.json()
    yield put(
      showModal(
        ModalType.WARNING,
        AssetUploadError({filename: file.file.name, message: res.message})
      )
    );
    yield put(uploadAssetFailed(basePayload));
  }
}

export function* workflowUploadAssets(action: UploadAssetsAction) {
  const { entity_name, entity_id, field_name, category, files } = action.payload;

  yield all(
    files.map((file) =>
      spawn(
        workflowUploadAsset,
        uploadAsset({
          category,
          entity_id,
          entity_name,
          field_name,
          file,
        })
      )
    )
  );
}

export function* workflowMoveAssetToEntity(action: MoveAssetToEntityAction) {
  const { assetId, entityName, entityId, fieldName } = action.payload;
  try {
    yield put(movingAssetToEntity(assetId, entityName, entityId, fieldName));
    const response = yield call(api.moveAssetToEntity, assetId, entityName, entityId, fieldName);
    const json = yield response.json();
    yield put(movedAssetToEntity(assetId, entityName, entityId, fieldName, json));
  } catch (e) {
    yield put(showModal(ModalType.WARNING, `Fehler beim Verschieben`));
    yield put(moveAssetToEntityFailed(assetId, entityName, entityId, fieldName));
  }
}

export function* workflowMoveAsset(action: MoveAssetAction) {
  const { assetId, toPosition } = action.payload;
  try {
    yield put(movingAsset(assetId, toPosition));
    const response = yield call(api.moveAsset, assetId, toPosition);
    const json = yield response.json();
    yield put(movedAsset(assetId, toPosition, json));
  } catch (e) {
    yield put(showModal(ModalType.WARNING, `Fehler beim Umsortieren`));
    yield put(uploadAssetFailed(assetId, toPosition));
  }
}

export function* workflowDeleteAsset(action) {
  const { entity_name, entity_id, asset_id } = action.payload;

  yield call(api.deleteAsset, asset_id);
  yield put(
    deletedAsset({
      asset_id,
      entity_id,
      entity_name,
    })
  );
}

// Watchers

export function* watchUploadAssets() {
  yield takeLatest(AssetActionType.UPLOAD_ASSETS, workflowUploadAssets);
}

export function* watchMoveAssetToEntity() {
  yield takeLatest(AssetActionType.MOVE_ASSET_TO_ENTITY, workflowMoveAssetToEntity);
}

export function* watchMoveAsset() {
  yield takeLatest(AssetActionType.MOVE_ASSET, workflowMoveAsset);
}

export function* watchDeleteAsset() {
  yield takeLatest(AssetActionType.DELETE_ASSET, workflowDeleteAsset);
}
