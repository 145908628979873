import { call, put, takeLatest } from 'redux-saga/effects';

import { EntryActionType } from 'actions/actionTypes';
import * as entryActions from 'actions/EntryActions';
import * as api from 'api/WebApi';
import {
  CreateCatalogAction,
  CreateCatalogEntryAction,
  DeleteCatalogAction,
  DeleteCatalogEntryAction,
} from 'typings/actions';
import { checkResponseStatus } from './utils';

export function* workflowCreateCatalog(action: CreateCatalogAction) {
  const {
    payload,
    payload: { title, projectVersionId, category },
  } = action;
  yield put(entryActions.creatingCatalog());

  try {
    const response = yield call(api.createCatalog, projectVersionId, title, category);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(entryActions.createdCatalog(json));
  } catch (error: any) {
    const json = yield error.response.json();
    yield put(entryActions.createCatalogFailed({ errors: json, values: payload }));
  }
}

export function* workflowCreateCatalogEntry(action: CreateCatalogEntryAction) {
  const {
    payload,
    payload: { catalogId, title, signature, description },
  } = action;
  yield put(entryActions.creatingCatalogEntry());

  try {
    const response = yield call(api.createCatalogEntry, catalogId, title, signature, description);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(entryActions.createdCatalogEntry(json));
  } catch (error: any) {
    const json = yield error.response.json();
    yield put(entryActions.createCatalogEntryFailed({ errors: json, values: payload }));
  }
}

export function* workflowDeleteCatalog(action: DeleteCatalogAction) {
  yield put(entryActions.deletingCatalog());

  try {
    const response = yield call(api.deleteCatalog, action.payload);
    checkResponseStatus(response);
    yield put(entryActions.deletedCatalog(action.payload));
  } catch (error: any) {
    const json = yield error.response.json();
    yield put(entryActions.deleteCatalogFailed({ errors: json, values: action.payload }));
  }
}

export function* workflowDeleteCatalogEntry(action: DeleteCatalogEntryAction) {
  yield put(entryActions.deletingCatalogEntry());

  try {
    const response = yield call(api.deleteCatalogEntry, action.payload);
    checkResponseStatus(response);
    yield put(entryActions.deletedCatalogEntry(action.payload));
  } catch (error: any) {
    const json = yield error.response.json();
    yield put(entryActions.deleteCatalogEntryFailed({ errors: json, values: action.payload }));
  }
}

export function* watchCreateCatalog() {
  yield takeLatest(EntryActionType.CREATE_CATALOG, workflowCreateCatalog);
}

export function* watchDeleteCatalog() {
  yield takeLatest(EntryActionType.DELETE_CATALOG, workflowDeleteCatalog);
}

export function* watchCreateCatalogEntry() {
  yield takeLatest(EntryActionType.CREATE_CATALOG_ENTRY, workflowCreateCatalogEntry);
}

export function* watchDeleteCatalogEntry() {
  yield takeLatest(EntryActionType.DELETE_CATALOG_ENTRY, workflowDeleteCatalogEntry);
}
