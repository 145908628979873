import * as React from 'react';
import _ from 'lodash';
import {
  InlineEditFloat,
  InlineEditSelect,
  InlineEditText,
  InlineEditLongText,
} from 'containers/InlineEditContainer';

import { Entry, IdTitle, ProjectVersion } from 'typings/models';

type Props = {
  entry: Entry;
  editable: boolean;
  facilities: IdTitle[];
  facility_levels: IdTitle[];
  examination_types: IdTitle[];
  stability_results: IdTitle[];
  projectversion: ProjectVersion;

  fieldVisible: (fieldname: string) => boolean;
};

const BaseDataLeft = (props: Props) => {
  const {
    fieldVisible,
    entry,
    projectversion,
    facilities,
    facility_levels,
    editable,
    stability_results,
    examination_types,
  } = props;

  const entry_type_choices = projectversion.field_configurations.map((fc) => [
    fc.entry_type.id,
    fc.entry_type.title,
  ]);
  const entry_type = entry_type_choices.find((e) => e[0] === entry.entry_type.id);
  const entry_type_title = entry_type ? entry_type[1] : '--';

  return (
    <>
      <table className="room-data">
        <tbody>
          {fieldVisible('title') && (editable || entry.title) && (
            <tr>
              <th>Titel</th>
              <td>
                <InlineEditText
                  entry={entry}
                  entity="entries.title"
                  entity_id={entry.id}
                  value={entry.title}
                  editable={editable}
                  hint="z.B. Küche"
                  historyKey="title"
                />
              </td>
            </tr>
          )}
          {fieldVisible('previous_room_numbers') && (entry.previous_room_numbers || editable) && (
            <tr>
              <th>Vorherige Raumnummern</th>
              <td>
                <InlineEditText
                  entry={entry}
                  entity="entries.previous_room_numbers"
                  entity_id={entry.id}
                  value={entry.previous_room_numbers}
                  editable={editable}
                  historyKey="previous_room_numbers"
                />
              </td>
            </tr>
          )}

          {fieldVisible('entry_type') && (editable || entry.entry_type) && (
            <tr>
              <th>Eintragstyp</th>
              <td>
                <InlineEditSelect
                  entry={entry}
                  entity="entries.entry_type"
                  entity_id={entry.id}
                  choices={entry_type_choices}
                  value={{
                    id: entry.entry_type,
                    title: entry_type_title,
                  }}
                  editable={editable}
                  prevent_empty
                  historyKey="entry_type"
                />
              </td>
            </tr>
          )}

          {fieldVisible('appreciation') &&
            (editable || (_.isFinite(entry.appreciation?.id) && entry.appreciation?.title)) && (
              <tr>
                <th>Denkmalpfl. Würdigung</th>
                <td>
                  <InlineEditSelect
                    entry={entry}
                    entity="entries.appreciation"
                    entity_id={entry.id}
                    choices={[
                      [0, 'Hoch'],
                      [1, 'Mittel'],
                      [2, 'Niedrig'],
                    ]}
                    value={entry.appreciation}
                    editable={editable}
                    historyKey="appreciation.title"
                  />
                </td>
              </tr>
            )}

          {fieldVisible('facility') && (editable || entry.facility) && (
            <tr>
              <th>Einrichtung</th>
              <td>
                <InlineEditSelect
                  entry={entry}
                  entity="entries.facility"
                  entity_id={entry.id}
                  choices={facilities}
                  value={entry.facility}
                  editable={editable}
                  hint="z.B. Haupthaus"
                  historyKey="facility.title"
                />
              </td>
            </tr>
          )}
          {fieldVisible('facility_level') && (editable || entry.facility_level) && (
            <tr>
              <th>Einrichtungs-Ebene</th>
              <td>
                <InlineEditSelect
                  entry={entry}
                  entity="entries.facility_level"
                  entity_id={entry.id}
                  choices={facility_levels}
                  value={entry.facility_level}
                  editable={editable}
                  hint="z.B. 1. Stock"
                  historyKey="facility_level.title"
                />
              </td>
            </tr>
          )}
          {fieldVisible('length') &&
            fieldVisible('width') &&
            (editable || _.isFinite(entry.length) || _.isFinite(entry.width)) && (
              <tr>
                <th>Länge/Breite</th>
                <td>
                  <InlineEditFloat
                    entry={entry}
                    entity="entries.length"
                    entity_id={entry.id}
                    value={entry.length}
                    editable={editable}
                    hint="z.B. 6,5"
                    suffix="m"
                    historyKey="length"
                  />
                  {_.isFinite(entry.length) && _.isFinite(entry.width) && (
                    <span>&nbsp;&times;&nbsp;</span>
                  )}
                  <InlineEditFloat
                    entry={entry}
                    entity="entries.width"
                    entity_id={entry.id}
                    value={entry.width}
                    editable={editable}
                    hint="z.B. 5,9"
                    suffix="m"
                    historyKey="width"
                  />
                </td>
              </tr>
            )}
          {fieldVisible('size') && (editable || _.isFinite(entry.size)) && (
            <tr>
              <th>Raumgröße</th>
              <td>
                <InlineEditFloat
                  entry={entry}
                  entity="entries.size"
                  entity_id={entry.id}
                  value={entry.size}
                  editable={editable}
                  hint="z.B. 20,5"
                  suffix="m²"
                  historyKey="size"
                />
              </td>
            </tr>
          )}
          {fieldVisible('height') && (editable || _.isFinite(entry.height)) && (
            <tr>
              <th>Raumhöhe</th>
              <td>
                <InlineEditFloat
                  entry={entry}
                  entity="entries.height"
                  entity_id={entry.id}
                  value={entry.height}
                  editable={editable}
                  hint="z.B. 3,4"
                  suffix="m"
                  historyKey="height"
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {(fieldVisible('examiner') ||
        fieldVisible('examination_date') ||
        fieldVisible('examination_type') ||
        fieldVisible('stability_result') ||
        fieldVisible('stability_examination_content')) &&
        (editable ||
          entry.examiner ||
          entry.examination_date ||
          entry.examination_type ||
          entry.stability_result ||
          entry.stability_examination_content) && (
          <>
            <h4>Hinweise zur Standsicherheitsprüfung</h4>
            <table>
              <tbody>
                {fieldVisible('examiner') && (entry.examiner || editable) && (
                  <tr style={{ borderBottom: 'none' }}>
                    <th>Prüfer:in</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.examiner"
                        entity_id={entry.id}
                        value={entry.examiner}
                        editable={editable}
                        hint="z.B. Name"
                        historyKey="examiner"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('examination_date') && (entry.examination_date || editable) && (
                  <tr>
                    <th>Prüfdatum</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.examination_date"
                        entity_id={entry.id}
                        value={entry.examination_date}
                        editable={editable}
                        hint="z.B. 01.01.2021"
                        historyKey="examination_date"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('examination_type') && (entry.examination_type || editable) && (
                  <tr>
                    <th>Art der Prüfung</th>
                    <td>
                      <InlineEditSelect
                        entry={entry}
                        entity="entries.examination_type"
                        entity_id={entry.id}
                        choices={examination_types}
                        value={entry.examination_type}
                        editable={editable}
                        hint="z.B. Sichtprüfung"
                        historyKey="examination_type"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('stability_result') && (entry.stability_result || editable) && (
                  <tr>
                    <th>Ergebnis</th>
                    <td>
                      <InlineEditSelect
                        entry={entry}
                        entity="entries.stability_result"
                        entity_id={entry.id}
                        choices={stability_results}
                        value={entry.stability_result}
                        editable={editable}
                        hint="z.B. Sichtprüfung"
                        historyKey="stability_result"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('stability_examination_content') &&
                  (entry.stability_examination_content || editable) && (
                    <tr>
                      <td colSpan={2}>
                        <InlineEditLongText
                          entry={entry}
                          entity="entries.stability_examination_content"
                          entity_id={entry.id}
                          value={entry.stability_examination_content}
                          editable={editable}
                          hint="z.B. Beschreibung"
                          historyKey="stability_examination_content"
                        />
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </>
        )}
    </>
  );
};

export default BaseDataLeft;
