export const hotkeys = {
  ENTRY_BACK: {
    description: 'Im Eintrag nach links navigieren',
    hotkey: 'mod+shift+left',
    hotkeyReadable: 'Ctrl + Shift + links',
  },
  ENTRY_FORWARD: {
    description: 'Im Eintrag nach rechts navigieren',
    hotkey: 'mod+shift+right',
    hotkeyReadable: 'Ctrl + Shift + rechts',
  },
  GOTO_DASHBOARD: {
    description: 'Zurück zur Projektliste',
    hotkey: 'mod+alt+d',
    hotkeyReadable: 'Ctrl + Alt + d',
  },
  GOTO_HELP: {
    description: 'Diese Hilfe aufrufen',
    hotkey: 'mod+alt+h',
    hotkeyReadable: 'Ctrl + Alt + h',
  },
  GOTO_HOME: {
    description: 'Zur Projektübersicht',
    hotkey: 'mod+alt+r',
    hotkeyReadable: 'Ctrl + Alt + r',
  },
  GOTO_SEARCH: {
    description: 'Suche öffnen',
    hotkey: 'mod+shift+f',
    hotkeyReadable: 'Ctrl + Shift + f',
  },
  LOGOUT: {
    description: 'Abmelden',
    hotkey: 'mod+alt+o',
    hotkeyReadable: 'Ctrl + Alt + o',
  },
  NEXT_ENTRY: {
    description: 'Nächster Eintrag',
    hotkey: 'mod+shift+down',
    hotkeyReadable: 'Ctrl + Shift + unten',
  },
  PREV_ENTRY: {
    description: 'Vorheriger Eintrag',
    hotkey: 'mod+shift+up',
    hotkeyReadable: 'Ctrl + Shift + hoch',
  },
  TOGGLE_EDIT_MODE: {
    description: 'Bearbeitungsmodus umstellen',
    hotkey: 'mod+e',
    hotkeyReadable: 'Ctrl + e',
  },
  CLOSE_FLYOUT: {
    description: 'Flyout schließen',
    hotkey: 'esc',
    hotkeyReadable: 'Escape',
  },
};
