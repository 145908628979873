import { NewCatalogEntryDetailsContent } from 'components/CatalogEntryDetailsContent';
import { CatalogEntryList } from 'components/CatalogEntryList';
import { CatalogListContent } from 'components/CatalogListContent';
import NewCatalogDetailsContent from 'components/NewCatalogDetailsContent';
import React, { useEffect, useState } from 'react';
import { Catalog, CatalogEntry, SaveStatus } from 'typings/models';
import { ICatalogDialogDataState, ICatalogDialogProps } from './CatalogDialog.types';
import { getModalTitle } from './CatalogDialog.util';
import { usePrevious } from '../App/App.util';
import { useHistory } from 'react-router-dom';

export const CatalogDialog = ({
  projectVersionId,
  onAddCatalog,
  onAddCatalogEntry,
  onSelectCatalogEntry,
  onDeleteCatalogEntry,
  onDeleteCatalog,
  onResetCatalogEntryLoadingState,
  onTitleChange,
  catalogs,
  edit_mode,
  entity_id,
  entity_name,
  fieldname,
  preSelectedCatalogEntry,
  saveStatusCatalogEntry,
}: ICatalogDialogProps) => {
  const history = useHistory();
  const [{ selectedCatalogId, newCatalog, newCatalogEntry }, setState] =
    useState<ICatalogDialogDataState>({
      selectedCatalogId: catalogs.find(
        (catalog) =>
          !!catalog.catalog_entries?.find(
            (catalogEntry) => catalogEntry.id === preSelectedCatalogEntry?.id
          )
      )?.id,
    });

  const handleSetNewCatalogEntry = (value?: Partial<CatalogEntry>) => {
    setState((previousState) => ({ ...previousState, newCatalogEntry: value }));
  };

  const handleSetNewCatalog = (value?: Partial<Catalog>) => {
    setState((previousState) => ({ ...previousState, newCatalog: value }));
  };

  const previousSaveStatusCatalogEntry =
    usePrevious<SaveStatus<CatalogEntry>>(saveStatusCatalogEntry);

  useEffect(() => {
    if (
      previousSaveStatusCatalogEntry?.status !== 'SAVED' &&
      saveStatusCatalogEntry?.status === 'SAVED'
    ) {
      onResetCatalogEntryLoadingState();
    }
  }, [previousSaveStatusCatalogEntry, saveStatusCatalogEntry]);

  const handleAddCatalogEntry: React.FormEventHandler<HTMLFormElement> = (e) => {
    onAddCatalogEntry(
      selectedCatalogId,
      newCatalogEntry?.title,
      newCatalogEntry?.signature,
      newCatalogEntry?.description
    );
    e.preventDefault();
    handleSetNewCatalogEntry(undefined);
  };

  const handleAddCatalog: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (newCatalog) {
      onAddCatalog(projectVersionId, newCatalog.title, newCatalog.category);
    }
    e.preventDefault();
    handleSetNewCatalog(undefined);
  };

  useEffect(() => {
    onTitleChange(
      getModalTitle({
        selectedCatalogTitle: catalogs.find((catalog) => catalog.id === selectedCatalogId)?.title,
        newCatalog,
        newCatalogEntry,
      })
    );
  }, [onTitleChange, selectedCatalogId, newCatalog, newCatalogEntry]);

  return (
    <>
      {newCatalog || newCatalogEntry ? (
        <div>
          {newCatalog && (
            <>
              <NewCatalogDetailsContent catalog={newCatalog} onChange={handleSetNewCatalog} />
              <p className="catalog-dialog-action-container left mr-5">
                <button
                  onClick={() => handleSetNewCatalog(undefined)}
                  className="catalog-dialog-action-button"
                >
                  Abbrechen
                </button>
              </p>
              <p className="catalog-dialog-action-container right">
                <button
                  type="button"
                  onClick={handleAddCatalog}
                  className="catalog-dialog-action-button"
                  disabled={
                    !newCatalog.title || !newCatalog.category || newCatalog.category === 'None'
                  }
                >
                  Speichern
                </button>
              </p>
            </>
          )}
          {Number.isFinite(selectedCatalogId) && newCatalogEntry && edit_mode && (
            <div>
              <form id="create-catalog-entry-form" onSubmit={handleAddCatalogEntry}>
                <NewCatalogEntryDetailsContent
                  catalogEntry={newCatalogEntry}
                  onChange={handleSetNewCatalogEntry}
                />
              </form>
              <p className="catalog-dialog-action-container left mr-5">
                <button
                  onClick={() => handleSetNewCatalogEntry(undefined)}
                  className="catalog-dialog-action-button"
                >
                  Abbrechen
                </button>
              </p>
              <p className="catalog-dialog-action-container right">
                <button
                  type="submit"
                  form="create-catalog-entry-form"
                  className="catalog-dialog-action-button"
                  disabled={!newCatalogEntry.title || !newCatalogEntry.signature}
                >
                  Speichern
                </button>
              </p>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="catalog-edialog-display-content">
            {!Number.isFinite(selectedCatalogId) && (
              <table className="catalog-entry-list">
                <CatalogListContent
                  edit_mode={false}
                  onSelectCatalog={(id) =>
                    setState((previousState) => ({
                      ...previousState,
                      selectedCatalogId: catalogs.find((catalog) => catalog.id === id)?.id,
                    }))
                  }
                  catalogs={catalogs}
                  onDeleteCatalog={onDeleteCatalog}
                />
              </table>
            )}
            {Number.isFinite(selectedCatalogId) && (
              <CatalogEntryList
                catalogEntries={
                  catalogs.find((catalog) => catalog.id === selectedCatalogId)?.catalog_entries
                }
                onDeleteCatalogEntry={onDeleteCatalogEntry}
                onSelectCatalogEntry={(id) => {
                  const currentCatalog = catalogs.find(
                    (catalog) => catalog.id === selectedCatalogId
                  );
                  const catalogEntry = currentCatalog?.catalog_entries.find(
                    (entry) => entry.id === id
                  );
                  catalogEntry &&
                    onSelectCatalogEntry({
                      entity_name,
                      entity_id,
                      fieldname,
                      value: catalogEntry,
                    });
                }}
                editable={false}
              />
            )}
          </div>
          {Number.isFinite(selectedCatalogId) && (
            <p className="catalog-dialog-action-container left mr-5">
              <button
                className="catalog-dialog-action-button"
                onClick={() => Number.isFinite(selectedCatalogId) && setState({})}
              >
                Zurück
              </button>
            </p>
          )}
          {edit_mode && (
            <p className="catalog-dialog-action-container right">
              <button
                className="catalog-dialog-action-button"
                onClick={() => {
                  if (Number.isFinite(selectedCatalogId)) {
                    handleSetNewCatalogEntry({ title: '', signature: '', description: '' });
                  } else {
                    handleSetNewCatalog({ title: '', category: 'None' });
                  }
                }}
              >
                {Number.isFinite(selectedCatalogId)
                  ? 'Neuen Katalogeintrag anlegen'
                  : 'Neuen Katalog anlegen'}
              </button>
            </p>
          )}
        </>
      )}
    </>
  );
};
