import { IdTitle } from 'typings/models';
import React from 'react';

type Props = {
  options: IdTitle[];
  allowEmpty?: boolean;
  onSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const IdTitleSelect: React.FunctionComponent<Props> = (props: Props) => {
  const { allowEmpty, options, onSelect } = props;
  return (
    <select size={1} onChange={onSelect}>
      {!!allowEmpty && <option value="">--- Bitte wählen ---</option>}
      {options.map((entryType) => (
        <option key={entryType.id} value={entryType.id}>
          {entryType.title}
        </option>
      ))}
    </select>
  );
};

IdTitleSelect.defaultProps = {
  allowEmpty: true,
};

export default IdTitleSelect;
