import * as React from 'react';

type Props = {
  index: number;
  total: number;
  onPrev: () => void;
  onNext: () => void;
};

const ImageNavigation = (props: Props) => {
  const { onPrev, onNext, index, total } = props;

  return (
    <div className="image-navigation">
      {index + 1} / {total}
      <div>
        <button className="previous-image" onClick={onPrev} disabled={index === 0}>
          vorheriges
        </button>
        <button className="next-image" onClick={onNext} disabled={index === total - 1}>
          nächstes
        </button>
      </div>
    </div>
  );
};

export default ImageNavigation;
