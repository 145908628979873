import React, { useEffect } from 'react';

import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { LoadRequestState } from 'typings/enums';

import { IProjectWrapperProps } from './ProjectWrapper.types';

const ProjectWrapper = ({
  projectId,
  versionId,
  onLoadProject,
  loadingProject,
  projectversion,
  children,
}: IProjectWrapperProps) => {
  useEffect(() => {
    onLoadProject();
  }, [projectId, versionId]);

  if (!projectversion || loadingProject.status === LoadRequestState.LOADING) {
    return (
      <div className="app-container flex-container vertical">
        <SimpleLoader size="huge" center text="Lade Projekt..." />
      </div>
    );
  }
  return children;
};

export default ProjectWrapper;
