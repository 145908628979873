import * as React from 'react';
import _ from 'lodash';

import DocumentGallery from 'components/DocumentGallery';
import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import {
  InlineEditLongText,
  InlineEditSelectMultiple,
  InlineEditText,
} from 'containers/InlineEditContainer';
import { Entry, IdTitle } from 'typings/models';

type Props = {
  editMode: boolean;
  editable: boolean;
  inscriptions: IdTitle[];
  locations: IdTitle[];
  materials: IdTitle[];
  compositions: IdTitle[];
  stabilities: IdTitle[];
  stratifications: IdTitle[];
  entry: Entry;
  fieldVisible: (fieldname: string) => boolean;
};

const BaseDataMiddle = (props: Props) => {
  const {
    fieldVisible,
    entry,
    editMode,
    editable,
    locations,
    materials,
    stratifications,
    inscriptions,
    stabilities,
    compositions,
  } = props;

  const { assets: rawAssets = [] } = entry;
  const assets = rawAssets.filter((asset) => !asset.field_name);
  const equipmentAssets = rawAssets.filter(
    (asset) => asset.category === 'image' && asset.field_name === 'equipment'
  );
  return (
    <div>
      {(editable || !!assets.filter((asset) => asset.category === 'image').length) && (
        <div>
          <h4>Übersichtsbild</h4>
          {editMode && editable ? (
            <FileUploadContainer
              files={assets.filter((asset) => asset.category === 'image')}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
            />
          ) : (
            <ImageGallery files={assets.filter((asset) => asset.category === 'image')} />
          )}
        </div>
      )}
      {(editable || !!assets.filter((asset) => asset.category === 'document').length) && (
        <div className="documents-list">
          <h4>Dokumente</h4>
          {editMode && editable ? (
            <FileUploadContainer
              accept={'application/pdf'}
              files={assets.filter((asset) => asset.category === 'document')}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="list"
              category="document"
              className="document-upload"
            />
          ) : (
            <DocumentGallery files={assets.filter((asset) => asset.category === 'document')} />
          )}
        </div>
      )}

      {(fieldVisible('description') ||
        fieldVisible('locations') ||
        fieldVisible('stabilities') ||
        fieldVisible('inscriptions') ||
        fieldVisible('stratifications') ||
        fieldVisible('earliest_year_of_death') ||
        fieldVisible('materials') ||
        fieldVisible('storage_location') ||
        fieldVisible('last_editing') ||
        fieldVisible('name_person') ||
        fieldVisible('manufacturer') ||
        fieldVisible('inscription_text') ||
        fieldVisible('compositions')) &&
        (editable ||
          entry.description ||
          (_.isArray(entry.locations) && !!entry.locations?.length) ||
          (_.isArray(entry.stabilities) && !!entry.stabilities?.length) ||
          (_.isArray(entry.inscriptions) && !!entry.inscriptions?.length) ||
          (_.isArray(entry.stratifications) && !!entry.stratifications?.length) ||
          entry.earliest_year_of_death ||
          (_.isArray(entry.materials) && !!entry.materials?.length) ||
          entry.storage_location ||
          entry.last_editing ||
          entry.name_person ||
          entry.manufacturer ||
          entry.inscription_text ||
          (_.isArray(entry.compositions) && !!entry.compositions?.length)) && (
          <div>
            <h4>Beschreibung und Material</h4>

            {fieldVisible('description') && (editable || entry.description) && (
              <InlineEditLongText
                entry={entry}
                entity="entries.description"
                entity_id={entry.id}
                value={entry.description}
                editable={editable}
                hint="z.B. 'Bruchsteinmauerwerk; wohl in Teilen noch ehem. Klostermauer'"
                title="Beschreibung und Material"
                historyKey="description"
              />
            )}

            <table className="room-data">
              <tbody>
                {fieldVisible('locations') &&
                  (editable || (_.isArray(entry.locations) && !!entry.locations?.length)) && (
                    <tr>
                      <th>Standort / Umfeld</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.locations"
                          entity_id={entry.id}
                          choices={locations}
                          value={entry.locations}
                          editable={editable}
                          historyKey="locations"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('stabilities') &&
                  (editable || (_.isArray(entry.stabilities) && !!entry.stabilities?.length)) && (
                    <tr>
                      <th>Standsicherheit</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.stabilities"
                          entity_id={entry.id}
                          choices={stabilities}
                          value={entry.stabilities}
                          editable={editable}
                          historyKey="stabilities"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('compositions') &&
                  (editable || (_.isArray(entry.compositions) && !!entry.compositions?.length)) && (
                    <tr>
                      <th>Gestaltung, Teilung</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.compositions"
                          entity_id={entry.id}
                          choices={compositions}
                          value={entry.compositions}
                          editable={editable}
                          historyKey="compositions"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('inscriptions') &&
                  (editable || (_.isArray(entry.inscriptions) && !!entry.inscriptions?.length)) && (
                    <tr>
                      <th>Inschrift, Fassung</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.inscriptions"
                          entity_id={entry.id}
                          choices={inscriptions}
                          value={entry.inscriptions}
                          editable={editable}
                          historyKey="inscriptions"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('stratifications') &&
                  (editable ||
                    (_.isArray(entry.stratifications) && !!entry.stratifications?.length)) && (
                    <tr>
                      <th>Körnung / Sedimentierung</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.stratifications"
                          entity_id={entry.id}
                          choices={stratifications}
                          value={entry.stratifications}
                          editable={editable}
                          historyKey="stratifications"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('materials') &&
                  (editable || (_.isArray(entry.materials) && !!entry.materials?.length)) && (
                    <tr>
                      <th>Material</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.materials"
                          entity_id={entry.id}
                          choices={materials}
                          value={entry.materials}
                          editable={editable}
                          historyKey="materials"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('earliest_year_of_death') &&
                  (editable || entry.earliest_year_of_death) && (
                    <tr>
                      <th>Frühestes Sterbejahr</th>
                      <td>
                        <InlineEditText
                          entry={entry}
                          entity="entries.earliest_year_of_death"
                          entity_id={entry.id}
                          value={entry.earliest_year_of_death}
                          editable={editable}
                          hint="Fr. Sterbejahr, (mutmaßl. Herstellungsjahr)"
                          historyKey="earliest_year_of_death"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('storage_location') && (editable || entry.storage_location) && (
                  <tr>
                    <th>Lagerort</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.storage_location"
                        entity_id={entry.id}
                        value={entry.storage_location}
                        editable={editable}
                        hint="Lagerort Original/ Bruchstück"
                        historyKey="storage_location"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('last_editing') && (editable || entry.last_editing) && (
                  <tr>
                    <th>letzte Bearbeitung</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.last_editing"
                        entity_id={entry.id}
                        value={entry.last_editing}
                        editable={editable}
                        hint="letzte Bearbeitung"
                        historyKey="last_editing"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('name_person') && (editable || entry.name_person) && (
                  <tr>
                    <th>Name</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.name_person"
                        entity_id={entry.id}
                        value={entry.name_person}
                        editable={editable}
                        hint="Name Person"
                        historyKey="name_person"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('manufacturer') && (editable || entry.manufacturer) && (
                  <tr>
                    <th>Hersteller</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.manufacturer"
                        entity_id={entry.id}
                        value={entry.manufacturer}
                        editable={editable}
                        hint="Hersteller"
                        historyKey="manufacturer"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('inscription_text') && (editable || entry.inscription_text) && (
                  <tr>
                    <th>Inschrift</th>
                    <td>
                      <InlineEditLongText
                        entry={entry}
                        entity="entries.inscription_text"
                        entity_id={entry.id}
                        value={entry.inscription_text}
                        editable={editable}
                        hint="Inschrift-Text"
                        historyKey="inscription_text"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      {fieldVisible('status_report') && (editable || entry.status_report) && (
        <>
          <h4>Zustandserfassung</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.status_report"
            entity_id={entry.id}
            value={entry.status_report}
            editable={editable}
            historyKey="status_report"
            hint="z.B. 'Zustandserfassung...'"
            title="Zustandserfassung"
          />
        </>
      )}
      {fieldVisible('equipment') && (editable || entry.equipment || equipmentAssets.length > 0) && (
        <>
          <h4>Ausstattung</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.equipment"
            entity_id={entry.id}
            value={entry.equipment}
            editable={editable}
            historyKey="equipment"
            hint="z.B. 'Ausstattung...'"
            title="Ausstattung"
          />

          <div style={{ marginBottom: 16 }} />
          {editMode && editable ? (
            <FileUploadContainer
              files={equipmentAssets}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
              field_name="equipment"
            />
          ) : (
            <ImageGallery files={equipmentAssets} />
          )}
        </>
      )}
    </div>
  );
};

export default BaseDataMiddle;
