import { Asset } from 'typings/models';
import * as React from 'react';
import Lightbox from 'react-image-lightbox';

import ImageNavigation from 'components/ImageNavigation';
import ListTable from 'components/ListTable';
import Row from 'components/Row';
import { nl2br } from 'utils/strings';

type Props = {
  files: Asset[];
  className?: string;
};

type State = {
  index: number;
  lightboxIsOpen: boolean;
};

class ImageGallery extends React.Component<Props, State> {
  public state = {
    index: 0,
    lightboxIsOpen: false,
  };

  public openLightbox = (event) => {
    event.preventDefault();
    this.setState({ lightboxIsOpen: true });
  };

  public closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };

  public nextImage = () => {
    const { files = [] } = this.props;
    if (this.state.index < files.length - 1) {
      this.setState({ index: this.state.index + 1 });
    }
  };

  public prevImage = () => {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 });
    }
  };

  public componentDidUpdate() {
    if (this.state.index >= this.props.files.length && this.state.index > 0) {
      this.setState({
        index: 0,
      });
    }
  }

  public render() {
    const { files = [] } = this.props;
    if (!files.length || this.state.index >= files.length) {
      return <span />;
    }
    const file = files[this.state.index];

    return (
      <div className={this.props.className}>
        {files.length > 1 ? (
          <div>
            <ImageNavigation
              onPrev={this.prevImage}
              onNext={this.nextImage}
              index={this.state.index}
              total={files.length}
            />
          </div>
        ) : null}
        {this.state.lightboxIsOpen && (
          <Lightbox
            mainSrc={file.big}
            imageTitle={file.description}
            prevSrc={this.state.index > 0 ? files[this.state.index - 1].small : ''}
            nextSrc={this.state.index < files.length - 1 ? files[this.state.index + 1].small : ''}
            onCloseRequest={this.closeLightbox}
            onMovePrevRequest={this.prevImage}
            onMoveNextRequest={this.nextImage}
          />
        )}
        <span className="lightbox-trigger" onClick={this.openLightbox}>
          {/* eslint-disable-next-line react/no-string-refs */}
          <img ref="img" src={files[this.state.index].medium} />
        </span>
        <ListTable>
          <Row left="Beschreibung" right={nl2br(file.description)} />
          <Row left="Aufnahmedatum" right={file.shooting_date} />
          {file.author && <Row left="Autor:in" right={file.author} />}
          <Row left="Dateiname" right={file.original_filename} />
        </ListTable>
      </div>
    );
  }
}

export default ImageGallery;
