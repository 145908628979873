import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import * as entryActions from 'actions/EntryActions';
import * as guiActions from 'actions/GuiActions';
import ProjectFrame from 'components/ProjectFrame';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { FacilityLevel } from 'typings/models';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';
import { filterEntries } from 'utils/entries';
import { IFilter } from 'typings/state/entryfilterlist';

type Props = {
  location: {
    pathname: string;
  };
  match: {
    params: {
      entryId: string;
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const {
    location: { pathname },
  } = ownProps;
  const { entryId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);

  const filters: IFilter = {
    department: state.entrylistFilter.department,
    facilityLevel: state.entrylistFilter.facility_level,
    objectType: state.entrylistFilter.object_type,
  };

  return {
    currentPath: pathname,
    departmentFilter: state.entrylistFilter.department,
    departments: selectors.getDepartments(state, versionId),
    editMode: state.loadingStates.edit_mode,
    entries: filterEntries(selectors.getEntries(state, versionId, false), filters),
    entryId,
    entry: selectors.getEntry(state, entryId),
    entryTypes: selectors.getEntryTypes(state, versionId),
    facilityLevelFilter: state.entrylistFilter.facility_level,
    flyout: selectors.getFlyout(state),
    history: state.entities.history,
    isOnSearchPage: pathname.includes('/search/'),
    loadingEntries: state.loadingStates.loading_entries,
    loadingEntry: state.loadingStates.loading_entry,
    objectTypeFilter: state.entrylistFilter.object_type,
    projectversion: selectors.getProjectVersion(state, versionId ? versionId : null),
    projectEntries: selectors.getEntries(state, undefined, false),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);

  return {
    onClearFilters: () => dispatch(guiActions.clearFilters()),
    onDisableEditMode: () => dispatch(guiActions.disableEditMode()),
    onGotoEntity: (id, otherVersionId) =>
      dispatch(
        push(`/project/${projectId}/version/${otherVersionId ?? versionId}/entry/${id}/base`)
      ),
    onGotoSearch: (text) =>
      dispatch(
        push(`/project/${projectId}/version/${versionId}/search/${encodeURIComponent(text)}`)
      ),
    onHideFlyout: () => dispatch(guiActions.hideFlyout()),
    onLoadEntries: () => dispatch(entryActions.loadEntries(projectId)),
    onSaveEntityField: ({ entity_name, entity_id, fieldname, value, facility_level_id }) =>
      dispatch(
        entryActions.saveEntityField({
          entity_id,
          entity_name,
          fieldname,
          value,
          facility_level_id,
        })
      ),
    onSetDepartmentFilter: (facilityLevel) =>
      dispatch(guiActions.setDepartmentFilter(facilityLevel)),
    onSetEditMode: (val: boolean) => dispatch(guiActions.setEditMode(val)),
    onSetFacilityLevelFilter: (facilityLevel) =>
      dispatch(guiActions.setFacilityLevelFilter(facilityLevel)),
    onSetObjectTypeFilter: (objectType) => dispatch(guiActions.setObjectTypeFilter(objectType)),
    onShowFlyout: (initialFacilityLevel?: FacilityLevel, initialRoom?: number) =>
      dispatch(guiActions.showFlyout(initialFacilityLevel, initialRoom)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectFrame));
