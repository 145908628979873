import React from 'react';
import { ErrorBoundaryState } from 'components/ErrorBoundary/ErrorBoundary.types';
import { sendErrorData } from 'api/WebApi';

class ErrorBoundary extends React.Component<React.PropsWithChildren<any>, ErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, showDetails: false };
    this.toggleDetails = this.toggleDetails.bind(this);
  }

  public componentDidCatch(error: Error) {
    this.setState({ error });
    console.log(error);
    sendErrorData({ message: error.message, name: error.name, stack: error.stack });
  }

  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="errorpage">
          <h1>Ein Fehler ist aufgetreten</h1>
          <p>
            Bitte laden Sie die Seite neu. Sollte der Fehler erneut erscheinen, wenden Sie sich
            bitte an <a href="mailto:support@raumbuch-digital.de">support@raumbuch-digital.de.</a>
          </p>
          {this.state.error !== null && (
            <div style={{ maxWidth: '70%' }}>
              <p
                style={{
                  color: '#d41',
                  cursor: 'pointer',
                  textAlign: 'center',
                  marginBottom: '15px',
                }}
                onClick={this.toggleDetails}
              >
                {this.state.error.name}: {this.state.error.message}
              </p>
              {this.state.showDetails && <p>{this.state.error.stack}</p>}
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
