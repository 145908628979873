import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import FileUploadContainer from 'containers/FileUploadContainer';
import {
  InlineEditBoolean,
  InlineEditInteger,
  InlineEditLongText,
  InlineEditSelect,
  InlineEditText,
} from 'containers/InlineEditContainer';
import ImageGallery from '../ImageGallery';

import CreateDepartmentEntry from 'components/CreateDepartmentEntry';
import DeleteButton from 'components/DeleteButton';
import RoomTitle from 'components/RoomTitle';
import {
  Department,
  DepartmentEntry,
  Entry,
  IdTitle,
  SaveStatus,
  UpsertDepartmentEntry,
  User,
} from 'typings/models';
import Headline from 'components/Headline';
import DocumentGallery from 'components/DocumentGallery';
import { formatDateReadable } from 'utils/date';
import { isBoolean } from 'lodash';

type Props = RouteComponentProps<any> & {
  department?: Department;
  editMode: boolean;
  entry: Entry;
  user: User;
  canWriteDepartmentEntry: boolean;
  canAdminDepartmentEntry: boolean;
  canAdminProjectVersion: boolean;
  creatingDepartmentEntry: SaveStatus<DepartmentEntry>;
  measure_classifications: IdTitle[];
  measure_prioritizations: IdTitle[];
  onCreateDepartmentEntry: (data: UpsertDepartmentEntry) => void;
  onDeleteDepartmentEntry: (departmentEntryId: number) => void;
  onResetLoadingState: (key: string) => void;
  onMoveDepartmentEntry: (departmentEntryId: number, direction: 'up' | 'down') => void;
};

const DepartmentDetails = (props: Props) => {
  const {
    canWriteDepartmentEntry,
    canAdminDepartmentEntry,
    canAdminProjectVersion,
    creatingDepartmentEntry,
    department,
    entry,
    editMode,
    measure_classifications,
    measure_prioritizations,
    user,
    onCreateDepartmentEntry,
    onDeleteDepartmentEntry,
    onResetLoadingState,
    onMoveDepartmentEntry,
  } = props;

  if (!department) {
    return <span>Department not found</span>;
  }

  const getValueReadable = (value: string) => {
    return value
      ? value.split('\n').map((item, index) => (
          <span key={index}>
            {item}
            <br />
          </span>
        ))
      : '';
  };

  return (
    <DocumentTitle title={`${entry.signature} – ${department.title}`}>
      <div className="room-content flex-container vertical">
        <div className="room-title">
          {!!entry.title && <RoomTitle title={entry.title} signature={entry.signature} />}
          <h3>Fachbereich: {department.title}</h3>
          {editMode && canWriteDepartmentEntry ? (
            <CreateDepartmentEntry
              onReset={() => onResetLoadingState('creating_department_entry')}
              onCreate={onCreateDepartmentEntry}
              department={department}
              measure_classifications={measure_classifications}
              measure_prioritizations={measure_prioritizations}
              creatingDepartmentEntry={creatingDepartmentEntry}
            />
          ) : (
            <span />
          )}
        </div>
        <div className="room-details flex-container horizontal departmententry-container">
          {!!entry.department_entries &&
            entry.department_entries
              .filter((departmentEntry) => departmentEntry.department.id === department.id)
              .sort((a, b) => b.position - a.position)
              .map((departmentEntry: DepartmentEntry, index) => {
                const hasAssets = departmentEntry.assets.length > 0;
                const { created_by } = departmentEntry;
                const userCanWriteDepartmentEntry =
                  canWriteDepartmentEntry && created_by.id === user.id;
                return (
                  <div key={departmentEntry.id}>
                    <Headline
                      key={departmentEntry.created_at}
                      title={`${created_by.academic_title ? `${created_by.academic_title} ` : ''}${
                        created_by.first_name
                      }`}
                      subtitle={created_by.last_name}
                      metaText={formatDateReadable(departmentEntry.created_at)}
                      onMoveDepartmentEntryUp={
                        index === 0 || !canWriteDepartmentEntry
                          ? undefined
                          : () => onMoveDepartmentEntry(departmentEntry.id, 'up')
                      }
                      onMoveDepartmentEntryDown={
                        index === entry.department_entries?.length!! - 1 || !canWriteDepartmentEntry
                          ? undefined
                          : () => onMoveDepartmentEntry(departmentEntry.id, 'down')
                      }
                    />
                    <div className="departmententry-content">
                      <div>
                        {userCanWriteDepartmentEntry ? (
                          <InlineEditText
                            entity="department_entries.title"
                            entity_id={departmentEntry.id}
                            value={departmentEntry.title}
                            editable
                            title="Überschrift"
                          />
                        ) : (
                          <div>
                            <b>{getValueReadable(departmentEntry.title)}</b>
                          </div>
                        )}
                      </div>
                      <div>
                        {(hasAssets || editMode) && (
                          <div className="content-box left">
                            {editMode && userCanWriteDepartmentEntry ? (
                              <>
                                <h4>Bilder</h4>
                                <FileUploadContainer
                                  files={departmentEntry.assets.filter(
                                    (asset) => asset.category === 'image'
                                  )}
                                  entity_name="entries.departmententry"
                                  entity_id={departmentEntry.id}
                                  display_mode="image"
                                  category="image"
                                />
                              </>
                            ) : departmentEntry.assets.filter((asset) => asset.category === 'image')
                                ?.length ? (
                              <ImageGallery
                                className="department-entry-image-gallery"
                                files={departmentEntry.assets.filter(
                                  (asset) => asset.category === 'image'
                                )}
                              />
                            ) : null}
                            {((editMode && userCanWriteDepartmentEntry) ||
                              !!departmentEntry.assets.filter(
                                (asset) => asset.category === 'document'
                              ).length) && (
                              <div className="documents-list">
                                {editMode && userCanWriteDepartmentEntry ? (
                                  <>
                                    <h4>Dokumente</h4>
                                    <FileUploadContainer
                                      files={departmentEntry.assets.filter(
                                        (asset) => asset.category === 'document'
                                      )}
                                      entity_name="entries.departmententry"
                                      entity_id={departmentEntry.id}
                                      display_mode="list"
                                      category="document"
                                      className="document-upload"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <h3>Dateien</h3>
                                    <DocumentGallery
                                      files={departmentEntry.assets.filter(
                                        (asset) => asset.category === 'document'
                                      )}
                                    />
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {!department?.preset_concept_of_measures ? (
                        <div className="content-box left">
                          <div className="departmententry-text-entry">
                            {userCanWriteDepartmentEntry ? (
                              <InlineEditLongText
                                entity="department_entries.content"
                                entity_id={departmentEntry.id}
                                value={departmentEntry.content}
                                editable
                                title="Beschreibung"
                              />
                            ) : (
                              <div key={departmentEntry.id}>
                                {getValueReadable(departmentEntry.content)}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          {userCanWriteDepartmentEntry ? (
                            <div className="content-box left">
                              <table className="room-data">
                                <tbody>
                                  {(departmentEntry.interval_next_maintenance || editMode) && (
                                    <tr>
                                      <th>Zyklus d. nächsten Wartung</th>
                                      <td>
                                        <InlineEditLongText
                                          entity="department_entries.interval_next_maintenance"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.interval_next_maintenance}
                                          title="Zyklus d. nächsten Wartung"
                                          historyKey="interval_next_maintenance"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(departmentEntry.interval_next_revision || editMode) && (
                                    <tr>
                                      <th>Zyklus d. nächsten Revision</th>
                                      <td>
                                        <InlineEditLongText
                                          entity="department_entries.interval_next_revision"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.interval_next_revision}
                                          title="Zyklus d. nächsten Revision"
                                          historyKey="interval_next_revision"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(departmentEntry.measure_prioritization || editMode) && (
                                    <tr>
                                      <th>Maßnahmenpriorisierung</th>
                                      <td>
                                        <InlineEditSelect
                                          entity="department_entries.measure_prioritization"
                                          entity_id={departmentEntry.id}
                                          choices={measure_prioritizations}
                                          value={departmentEntry.measure_prioritization}
                                          title="Maßnahmenpriorisierung"
                                          historyKey="measure_prioritization"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(departmentEntry.measure_classification || editMode) && (
                                    <tr>
                                      <th>Maßnahmenklassifizierung</th>
                                      <td>
                                        <InlineEditSelect
                                          entity="department_entries.measure_classification"
                                          entity_id={departmentEntry.id}
                                          choices={measure_classifications}
                                          value={departmentEntry.measure_classification}
                                          title="Maßnahmenklassifizierung"
                                          historyKey="measure_classification"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(departmentEntry.measure || editMode) && (
                                    <tr>
                                      <th>Maßnahme</th>
                                      <td>
                                        <InlineEditLongText
                                          entity="department_entries.measure"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.measure}
                                          title="Maßnahme"
                                          historyKey="measure"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(isBoolean(departmentEntry.decomposition_needed) ||
                                    editMode) && (
                                    <tr>
                                      <th>Abbau (in Werkstatt) nötig</th>
                                      <td>
                                        <InlineEditBoolean
                                          entity="department_entries.decomposition_needed"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.decomposition_needed}
                                          editable={editMode}
                                          title="Abbau (in Werkstatt) nötig"
                                          historyKey="decomposition_needed"
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(!!departmentEntry.hours_construction_deconstruction ||
                                    editMode) && (
                                    <tr>
                                      <th>Std. Ab- & Aufbau</th>
                                      <td>
                                        <InlineEditInteger
                                          entity="department_entries.hours_construction_deconstruction"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.hours_construction_deconstruction}
                                          suffix="h"
                                          historyKey="hours_construction_deconstruction"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(!!departmentEntry.effort_measure || editMode) && (
                                    <tr>
                                      <th>Aufwand Maßnahme (geschätzt)</th>
                                      <td>
                                        <InlineEditInteger
                                          entity="department_entries.effort_measure"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.effort_measure}
                                          suffix="h"
                                          historyKey="effort_measure"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                  {(departmentEntry.special_measures || editMode) && (
                                    <tr>
                                      <th>Besonderheiten / Sondermaßnahmen</th>
                                      <td>
                                        <InlineEditLongText
                                          entity="department_entries.special_measures"
                                          entity_id={departmentEntry.id}
                                          value={departmentEntry.special_measures}
                                          historyKey="special_measures"
                                          editable={editMode}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="content-box left">
                              <table className="room-data">
                                <tbody>
                                  {departmentEntry.interval_next_maintenance && (
                                    <tr>
                                      <th>Zyklus d. nächsten Wartung</th>
                                      <td>{departmentEntry.interval_next_maintenance}</td>
                                    </tr>
                                  )}
                                  {departmentEntry.interval_next_revision && (
                                    <tr>
                                      <th>Zyklus d. nächsten Revision</th>
                                      <td>{departmentEntry.interval_next_revision}</td>
                                    </tr>
                                  )}
                                  {departmentEntry.measure_prioritization && (
                                    <tr>
                                      <th>Maßnahmenpriorisierung</th>
                                      <td>{departmentEntry.measure_prioritization.title}</td>
                                    </tr>
                                  )}
                                  {departmentEntry.measure_classification && (
                                    <tr>
                                      <th>Maßnahmenklassifizierung</th>
                                      <td>{departmentEntry.measure_classification.title}</td>
                                    </tr>
                                  )}
                                  {departmentEntry.measure && (
                                    <tr>
                                      <th>Maßnahme</th>
                                      <td>{departmentEntry.measure}</td>
                                    </tr>
                                  )}
                                  {isBoolean(departmentEntry.decomposition_needed) && (
                                    <tr>
                                      <th>Abbau (in Werkstatt) nötig</th>
                                      <td>
                                        {departmentEntry.decomposition_needed ? 'Ja' : 'Nein'}
                                      </td>
                                    </tr>
                                  )}
                                  {!!departmentEntry.hours_construction_deconstruction && (
                                    <tr>
                                      <th>Std. Ab- & Aufbau</th>
                                      <td>{departmentEntry.hours_construction_deconstruction}</td>
                                    </tr>
                                  )}
                                  {!!departmentEntry.effort_measure && (
                                    <tr>
                                      <th>Aufwand Maßnahme (geschätzt)</th>
                                      <td>{departmentEntry.effort_measure}</td>
                                    </tr>
                                  )}
                                  {departmentEntry.special_measures && (
                                    <tr>
                                      <th>Besonderheiten / Sondermaßnahmen</th>
                                      <td>{departmentEntry.special_measures}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </>
                      )}
                      <div>
                        {editMode &&
                        (canAdminDepartmentEntry ||
                          canAdminProjectVersion ||
                          departmentEntry.created_by.id === user.id) ? (
                          <DeleteButton
                            title="Fachbereichs-Eintrag löschen"
                            question="Fachbereichs-Eintrag wirklich löschen?"
                            onDelete={() => onDeleteDepartmentEntry(departmentEntry.id)}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default withRouter(DepartmentDetails);
