import NotificationActionType from 'actions/actionTypes/notifications';

import { call, put, take, takeLatest, delay, race, select } from 'redux-saga/effects';

import * as api from 'api/WebApi';
import { checkResponseStatus } from './utils';

import {
  normalizeNotification,
  loadingNotifications,
  loadedNotifications,
  loadNotificationsFailed,
  stopWatcher,
  markingAsRead,
  markAsReadFailed,
  markedAsRead,
  markingAllRead,
  markedAllRead,
  markAllReadFailed,
} from 'actions/NotificationActions';
import { getNotifications } from 'reducers/notifications';
import { refetchUser } from 'actions/AuthActions';
import { getUserId } from 'reducers/app';

function* loadNotificationsPeriodically() {
  while (true) {
    yield call(workflowLoadNotifications);
    yield delay(15000);
  }
}

export function* workflowLoadNotifications() {
  yield put(loadingNotifications());
  let json = null;
  try {
    const oldState = yield select(getNotifications)
    const response = yield call(api.loadNotifications);
    checkResponseStatus(response);
    json = yield response.json();
    yield put(normalizeNotification(json));
    const newState = yield select(getNotifications)
    if (JSON.stringify(oldState) !== JSON.stringify(newState) && Object.keys(oldState[0]).length > 0
      && Object.values(newState[0]).find((e: any) => e.type === 'COPY SUCCESS') !== undefined){
        const userId = yield select(getUserId)
        yield put(refetchUser(userId));
    }
    yield put(loadedNotifications(json));
  } catch (errors) {
    yield put(loadNotificationsFailed());
    yield put(stopWatcher());
  }
}

export function* workflowMarkNotificationRead(action) {
  const { payload } = action;
  yield put(markingAsRead());
  try {
    const response = yield call(api.markNotificationRead, payload);
    checkResponseStatus(response);
    yield put(markedAsRead(payload));
  } catch (errors) {
    yield put(markAsReadFailed());
  }
}

export function* workflowMarkAllNotificationsRead() {
  yield put(markingAllRead());
  try {
    const response = yield call(api.markAllNotificationsRead);
    checkResponseStatus(response);
    yield put(markedAllRead());
  } catch (errors) {
    yield put(markAllReadFailed());
  }
}

// Watchers
export function* watchLoadNotifications() {
  while (true) {
    yield take(NotificationActionType.LOAD_NOTIFICATIONS);
    yield race([call(loadNotificationsPeriodically), take(NotificationActionType.STOP_WATCHER)]);
  }
}

export function* watchMarkNotificationRead() {
  yield takeLatest(NotificationActionType.MARK_AS_READ, workflowMarkNotificationRead);
}

export function* watchMarkAllNotificationsRead() {
  yield takeLatest(NotificationActionType.MARK_ALL_READ, workflowMarkAllNotificationsRead);
}
