import * as React from 'react';
import omit from 'lodash/omit';
import Dropzone from 'react-dropzone';
import ErrorPage from 'components/ErrorPage';
import FacilityLevelSelect from 'components/FacilityLevelSelect/FacilityLevelSelect';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import LayerForm from './LayerForm';
import Preview from './Preview';
import Success from 'components/ImportFloormap/Success';
import {
  ExistingEntry,
  IImportFloormapProps,
  NewEntry,
  RoomUpload,
} from 'components/ImportFloormap/ImportFloormap.types';
import {
  getFacilityLevel,
  getFilteredEntries,
  getModalContent,
  validate,
} from 'components/ImportFloormap/ImportFloormap.util';
const ImportFloormap: React.FC<IImportFloormapProps> = ({
  entries,
  entryTypes,
  facilityLevels,
  hasLoadEntriesError,
  hasUploadError,
  importDone,
  importResult,
  isLoadingEntries,
  isUploading,
  isImporting,
  layers,
  onImportFloormap,
  onLoadEntries,
  onResetFloormapImport,
  onShowModal,
  onUploadFloormap,
  preview,
  projectversion,
  token,
  type,
}: IImportFloormapProps) => {
  const [facilityLevel, setFacilityLevel] = React.useState<number | null>(null);
  const [rooms, setRooms] = React.useState<RoomUpload>({});
  const [creator, setCreator] = React.useState<string>('');
  const [date, setDate] = React.useState<string>('');
  const dropzone = React.useRef();
  const importPossible = !!facilityLevel && !!token;

  React.useEffect(() => {
    onLoadEntries();
    return onResetFloormapImport;
  }, []);

  React.useEffect(() => {
    if (facilityLevels && facilityLevels.length) {
      setFacilityLevel(facilityLevels[0].id);
    }
  }, [facilityLevels]);

  const filteredEntries = React.useMemo(
    () => getFilteredEntries(facilityLevel, entries),
    [facilityLevel, entries]
  );

  const facilityLevelRecord = React.useMemo(
    () => getFacilityLevel(projectversion, facilityLevel) || null,
    [projectversion, facilityLevel]
  );

  const onDrop = (files) => !!facilityLevel && onUploadFloormap(files, facilityLevel);
  const resetImport = () => {
    setCreator('');
    setDate('');
    setRooms({});
    setFacilityLevel(null);
    onResetFloormapImport();
  };

  const startImport = () => {
    if (type === 'pdf') {
      !!token && !!projectversion && onImportFloormap(token, facilityLevel, {}, creator, date);
      return;
    }
    const [newRooms, hasValidationErrors] = validate(rooms);
    setRooms(newRooms);

    if (!hasValidationErrors) {
      // tslint:disable-next-line:no-unused-expression
      !!token && !!projectversion && onImportFloormap(token, facilityLevel, rooms, creator, date);
    } else {
      onShowModal(...getModalContent());
    }
  };

  const removeRoom = (layerName: string) => setRooms((state) => omit(state, layerName));
  // eslint-disable-next-line @typescript-eslint/ban-types
  const setEntry = (layerName: string, entry: NewEntry | ExistingEntry | {}) =>
    setRooms((state) => ({ ...state, [layerName]: omit(entry, 'error') }));

  if (isLoadingEntries || isUploading || isImporting) {
    return (
      <div className="plain-page" style={{ marginTop: '200px' }}>
        <SimpleLoader size={'huge'} center />
      </div>
    );
  }

  if (importDone && importResult) {
    return (
      <Success
        projectversion={projectversion}
        assignedRooms={importResult.assigned_rooms}
        createdRooms={importResult.created_rooms}
      />
    );
  }

  return (
    <div className="plain-page">
      <ErrorPage isError={hasLoadEntriesError} onRetry={onLoadEntries}>
        <h2 style={{ marginTop: '1.5em' }}>Raumplan importieren</h2>
        {!!facilityLevel && (
          <p>
            <button className="secondary" onClick={resetImport}>
              Zurücksetzen
            </button>
          </p>
        )}
        {hasUploadError && <div className="error">Datei konnte nicht verarbeitet werden</div>}

        <input
          style={{ marginTop: '20px' }}
          type="text"
          onChange={(e) => setCreator(e.currentTarget.value)}
          value={creator}
          placeholder="Ersteller:in"
        />
        <input
          type="text"
          onChange={(e) => setDate(e.currentTarget.value)}
          value={date}
          placeholder="Erstellungsdatum"
        />

        {!(isUploading || isImporting) && (
          <div>
            <h3>In folgende Einrichtungs-Ebene importieren:</h3>
            {!!facilityLevel && facilityLevelRecord ? (
              <span>
                {!!facilityLevelRecord.facility && facilityLevelRecord.facility.title} -{' '}
                {!!facilityLevelRecord.level && facilityLevelRecord.level.title}
              </span>
            ) : (
              <FacilityLevelSelect
                currentValue={facilityLevel || undefined}
                allowEmpty={true}
                facilities={projectversion.facilities}
                change={setFacilityLevel}
              />
            )}
          </div>
        )}

        {facilityLevel && !token && (
          <Dropzone
            accept=".dxf, .dwg, .pdf"
            ref={dropzone.current}
            className="dropzone"
            onDrop={onDrop}
          >
            {isUploading || isImporting ? (
              <SimpleLoader />
            ) : (
              <div>
                <div>
                  <strong>Raumplan-Import</strong>
                </div>
                Hier klicken oder Datei hier hinziehen
                <br />
                Akzeptierte Datentypen: .pdf, .dxf, .dwg
              </div>
            )}
          </Dropzone>
        )}

        {token && !isUploading && !isImporting ? (
          <div className="roomplan-import-container">
            <h3>Vorschau</h3>
            <Preview html={preview} />

            {!!layers && (
              <>
                <h3>Ebenen</h3>
                {layers.map((layer) => (
                  <LayerForm
                    key={layer}
                    layer={layer}
                    rooms={rooms}
                    entryTypes={entryTypes}
                    entries={filteredEntries}
                    setEntry={setEntry}
                    removeRoom={removeRoom}
                  />
                ))}
              </>
            )}
            <button className="big" disabled={!importPossible} onClick={startImport}>
              Import starten
            </button>
          </div>
        ) : null}
      </ErrorPage>
    </div>
  );
};
export default ImportFloormap;
