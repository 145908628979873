import { handleActions } from 'redux-actions';

import { GuiActionType, ProjectActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { EntrylistFilterState } from 'typings/state';

const initialState: EntrylistFilterState = {
  department: null,
  facility_level: null,
  list_offset: 0,
  object_type: null,
};

const entrylistfilterReducer = handleActions<EntrylistFilterState, actions.Action>(
  {
    [ProjectActionType.LOADING_PROJECT]: (): EntrylistFilterState => {
      return { ...initialState };
    },

    [GuiActionType.CLEAR_ENTRYLIST_FILTERS]: () => ({ ...initialState }),
    [GuiActionType.ENTRYLIST_UP]: (state: EntrylistFilterState): EntrylistFilterState => ({
      ...state,
      list_offset: state.list_offset - 10,
    }),

    [GuiActionType.ENTRYLIST_DOWN]: (state: EntrylistFilterState): EntrylistFilterState => ({
      ...state,
      list_offset: state.list_offset + 10,
    }),

    [GuiActionType.SET_OBJECT_TYPE_FILTER]: (
      state: EntrylistFilterState,
      action: actions.SetObjectTypeFilterAction
    ): EntrylistFilterState => ({
      ...state,
      list_offset: 0,
      object_type: action.payload ? parseInt(action.payload, 10) : undefined,
    }),

    [GuiActionType.SET_FACILITY_LEVEL_FILTER]: (
      state: EntrylistFilterState,
      action: actions.SetFacilityLevelFilterAction
    ): EntrylistFilterState => ({
      ...state,
      facility_level: action.payload ? parseInt(action.payload, 10) : undefined,
      list_offset: 0,
    }),

    [GuiActionType.SET_DEPARTMENT_FILTER]: (
      state: EntrylistFilterState,
      action: actions.SetDepartmentFilterAction
    ): EntrylistFilterState => ({
      ...state,
      department: action.payload ? parseInt(action.payload, 10) : undefined,
      list_offset: 0,
    }),
  },
  { ...initialState }
);

export default entrylistfilterReducer;
