import * as React from 'react';

type Props = {
  center?: boolean;
  size?: 'tiny' | 'small' | 'medium' | 'big' | 'huge';
  isWhite?: boolean;
  text?: string;
};

const defaultProps: Props = {
  center: false,
  isWhite: false,
  size: 'medium',
};

const SimpleLoader: React.FunctionComponent<Props> = (props: Props) => {
  const { size, isWhite, center, text } = props;
  const displaySize = {
    big: 30,
    huge: 40,
    medium: 24,
    small: 20,
    tiny: 12,
  }[size!]; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const spinner = (
    <svg
      viewBox="0 0 100 100"
      width={displaySize}
      height={displaySize}
      color={isWhite ? '#fff' : '#000'}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={isWhite ? '#fff' : '#000'}
        strokeWidth="10"
        r="40"
        strokeDasharray="200"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
  return (
    <div className={`vertical ${center ? 'center' : ''}`}>
      {spinner}
      {!!text && <p style={{ marginTop: 8 }}>{text}</p>}
    </div>
  );
};

SimpleLoader.defaultProps = defaultProps;

export default SimpleLoader;
