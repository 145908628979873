import merge from 'lodash/merge';
import { denormalize, normalize } from 'normalizr';
import { handleActions } from 'redux-actions';

import { ProjectActionType } from 'actions/actionTypes';

import projectVersionSchema from 'api/projectversion_schema';
import projectSchema from 'api/project_schema';

import * as actions from 'typings/actions';
import { PermissionLevel } from 'typings/enums';
import {
  Department,
  EntryType,
  NormalizedFieldConfiguration,
  Project,
  ProjectVersion,
} from 'typings/models';
import { EntityState } from 'typings/state';
import { initialState } from './entities.initial';
import { canAdminEntry, canEditEntry } from './entries';

const projectsReducer = handleActions<EntityState, actions.Action>(
  {
    [ProjectActionType.LOADING_PROJECT]: (): EntityState => {
      return { ...initialState };
    },

    [ProjectActionType.NORMALIZE_PROJECT]: (
      state: EntityState,
      action: actions.NormalizeProjectAction
    ): EntityState => {
      return merge({}, state, normalize(action.payload, projectVersionSchema).entities);
    },

    [ProjectActionType.ADDED_PROJECT_MEMBER]: (
      state: EntityState,
      action: actions.AddedProjectMember
    ): EntityState => {
      const {
        payload: { members, versionId },
      } = action;
      const projectversion = denormalize(
        state.projectversions[versionId],
        projectVersionSchema,
        state
      );
      projectversion.members = members;

      return {
        ...state,
        ...normalize(projectversion, projectVersionSchema).entities,
      };
    },

    [ProjectActionType.REMOVED_PROJECT_MEMBER]: (
      state: EntityState,
      action: actions.RemovedProjectMember
    ): EntityState => {
      const {
        payload: { members, versionId },
      } = action;
      const projectversion = denormalize(
        state.projectversions[versionId],
        projectVersionSchema,
        state
      );
      projectversion.members = members;

      return {
        ...state,
        ...normalize(projectversion, projectVersionSchema).entities,
      };
    },
  },
  { ...initialState }
);

export default projectsReducer;

export const getProjects = (state: EntityState) => Object.values(state.projects);

export const getProject = (
  state: EntityState,
  projectId: number
): Project | null =>
  state.projects[projectId]
    ? denormalize(state.projects[projectId], projectSchema, state)
    : null;

export const getProjectVersion = (
  state: EntityState,
  projectVersionId: number
): ProjectVersion | null =>
  state.projectversions[projectVersionId]
    ? denormalize(state.projectversions[projectVersionId], projectVersionSchema, state)
    : null;

export const getProjectVersionPermissions = (
  state: EntityState,
  versionId
): PermissionLevel | null => {
  if (!state.projectversions) {
    return null;
  }
  const projectversion = state.projectversions[versionId];
  if (!projectversion) {
    return null;
  }
  return projectversion.permissions.id;
};

export const getEntryTypes = (state: EntityState, versionId: number): EntryType[] => {
  const projectversion = denormalize(state.projectversions[versionId], projectVersionSchema, state);
  if (!projectversion) {
    return [];
  }
  return Object.keys(projectversion.field_configurations).map(
    (key) => projectversion.field_configurations[key].entry_type
  );
};

export const canEditAnyDepartment = (state: EntityState, versionId): boolean => {
  if (!state.projectversions || !state.field_configurations) {
    return false;
  }

  const projectversion = denormalize(state.projectversions[versionId], projectVersionSchema, state);
  if (!projectversion) {
    return false;
  }

  const departments: Department[] = Array.from(
    new Set(
      projectversion.field_configurations.reduce(
        (acc, field_configuration: NormalizedFieldConfiguration) => {
          return [...acc, ...field_configuration.departments];
        },
        []
      )
    )
  );

  const value = departments.reduce((acc: boolean, department: Department): boolean => {
    return acc || department.permissions.id > 0;
  }, false);

  return value;
};

export const canEditProject = (state: EntityState, versionId: number) =>
  canAdminEntry(state, versionId);

export const canEditAnything = (state: EntityState, versionId: number): boolean =>
  !!versionId && (canEditEntry(state, versionId) || canEditAnyDepartment(state, versionId));

export const getAssetMarkedToMove = (state: EntityState): number | null => state.assetsToMove;
