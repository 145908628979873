import React, { Component } from 'react';
import EntryFilterBox from './EntryFilterBox';
import EntryList from './EntryList';
import Flyout from './Flyout';
import { IProjectFrameProps } from './types/ProjectFrame.types';

class Left extends Component<IProjectFrameProps> {
  public onSetFilter = (type: 'objecttype' | 'facilitylevel' | 'department', value: number) => {
    switch (type) {
      case 'objecttype':
        return this.props.onSetObjectTypeFilter(value);
      case 'facilitylevel':
        return this.props.onSetFacilityLevelFilter(value);
      case 'department':
        return this.props.onSetDepartmentFilter(value);
    }
  };

  public toggleFlyout = () => {
    const { flyout, onShowFlyout, onHideFlyout } = this.props;
    !flyout ? onShowFlyout() : onHideFlyout();
  };

  public render() {
    const {
      currentPath,
      projectversion,
      departments,
      editMode,
      entries,
      projectEntries,
      entry,
      entryId,
      entryTypes,
      isOnSearchPage,
      flyout,
      objectTypeFilter,
      facilityLevelFilter,
      departmentFilter,
      loadingEntries,
      loadingEntry,
      history,
      onGotoSearch,
      onClearFilters,
      onLoadEntries,
      onGotoEntity,
      onHideFlyout,
      onSaveEntityField,
      onSetEditMode,
    } = this.props;

    return (
      <nav className="room-nav flex-container vertical">
        <div>
          <div className="map-navigation-swicher">
            {!!projectversion && (
              <a
                style={{ userSelect: 'none' }}
                onClick={this.toggleFlyout}
                className={flyout ? 'active' : ''}
              >
                Kartennavigation
              </a>
            )}
          </div>
          {!!flyout && (
            <Flyout
              editMode={editMode}
              entries={entries}
              projectEntries={projectEntries}
              entry={entry}
              projectversion={projectversion}
              initialFacilityLevel={flyout.initialFacilityLevel}
              history={history}
              onGotoEntity={onGotoEntity}
              onHide={onHideFlyout}
              onSaveEntityField={onSaveEntityField}
              onSetEditMode={onSetEditMode}
            />
          )}

          {!!projectversion && (
            <EntryFilterBox
              projectversion={projectversion}
              entryTypes={entryTypes}
              departments={departments}
              isOnSearchPage={isOnSearchPage}
              objectTypeFilter={objectTypeFilter}
              facilityLevelFilter={facilityLevelFilter}
              departmentFilter={departmentFilter}
              onSetObjectTypeFilter={(value) => this.onSetFilter('objecttype', value)}
              onSetFacilityLevelFilter={(value) => this.onSetFilter('facilitylevel', value)}
              onSetDepartmentFilter={(value) => this.onSetFilter('department', value)}
              onGotoSearch={onGotoSearch}
              onClearFilters={onClearFilters}
            />
          )}
          <EntryList
            projectversion={projectversion}
            entries={entries}
            entryId={entryId}
            currentPath={currentPath}
            loadingEntry={loadingEntry}
            loadingEntries={loadingEntries}
            onGotoEntry={onGotoEntity}
            onLoadEntries={onLoadEntries}
            onHideFlyout={onHideFlyout}
          />
        </div>
      </nav>
    );
  }
}

export default Left;
