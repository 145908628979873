import { connect } from 'react-redux';
import { State } from 'typings/state';
import { Action } from 'typings/actions';
import ResetPasswordForm from 'components/ResetPasswordForm';
import * as selectors from 'reducers/app';
import * as accountActions from 'actions/AccountActions';

const mapStateToProps = (state: State) => ({
  requestStatus: selectors.getPasswordResetRequestStatus(state),
});

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  onSubmit: (email: string) => dispatch(accountActions.requestingPasswordReset(email)),
  onReset: () => dispatch(accountActions.requestPasswordResetReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
