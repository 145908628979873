import React, { useState, useEffect, useMemo } from 'react';
import ErrorList from 'components/ErrorList';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { ICreateFindingProps } from './CreateFinding.types';
import SelectCatalogButton from 'components/SelectCatalogButton/SelectCatalogButtonContainer';
import { CatalogEntry } from 'typings/models';

const CreateFinding = ({
  parentId,
  onCreate,
  onReset,
  isCreatingFinding,
  has_catalogs,
  onFindCatalogEntry,
}: ICreateFindingProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [token, setToken] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [catalogEntry, setCatalogEntry] = useState<CatalogEntry>();
  const ref = React.createRef<HTMLInputElement>();
  const focus = () => !!ref.current && ref.current.focus();

  useEffect(() => {
    !isEditing && onReset();
  }, [isEditing]);

  useEffect(() => {
    if (isCreatingFinding?.status === 'SAVED') {
      setDescription(undefined);
      setToken(undefined);
      setNotes(undefined);
      setCatalogEntry(undefined);
      focus();
    }
  }, [isCreatingFinding?.status]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      create();
    }
    if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const create = useMemo(() => {
    return () =>
      onCreate(token || '', description || '', notes || '', parentId, catalogEntry?.id || null);
  }, [token, description, notes, parentId, catalogEntry]);

  if (isCreatingFinding?.status === 'SAVING') {
    return <SimpleLoader />;
  } else if (isEditing) {
    return (
      <div>
        <ErrorList errors={isCreatingFinding?.messages} errorField="token" />
        <input
          defaultValue={undefined}
          placeholder="Signatur"
          type="text"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setToken(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
        {onFindCatalogEntry && has_catalogs && (
          <div className="create-entity-select-catalog-button-container">
            <SelectCatalogButton
              editable={true}
              onClick={() => onFindCatalogEntry(setCatalogEntry, "Finding")}
              onDelete={() => setCatalogEntry(undefined)}
              catalogEntryId={catalogEntry?.id}
            />
          </div>
        )}
        <ErrorList errors={isCreatingFinding?.messages} errorField="type" />
        <textarea
          defaultValue={undefined}
          placeholder="Beschreibung"
          onKeyDown={handleKeyDown}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            setDescription(e.currentTarget.value)
          }
        />
        <ErrorList errors={isCreatingFinding?.messages} errorField="description" />
        <textarea
          defaultValue={undefined}
          placeholder="Anmerkungen/Interpretation"
          onKeyDown={handleKeyDown}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setNotes(e.currentTarget.value)}
        />
        <br />
        <button className="primary" onClick={create}>
          OK
        </button>
        <button className="secondary" onClick={() => setIsEditing(false)}>
          Abbrechen
        </button>
      </div>
    );
  }
  return (
    <button className="primary add-button" onClick={() => setIsEditing(true)}>
      Befund hinzufügen
    </button>
  );
};

export default CreateFinding;
