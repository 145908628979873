import * as React from 'react';

import DepartmentSelect from 'components/DepartmentSelect/DepartmentSelect';
import FacilityLevelSelect from 'components/FacilityLevelSelect/FacilityLevelSelect';
import FreetextFilter from 'components/FreetextFilter/FreetextFilter';
import ObjectTypeFilter from 'components/ObjectTypeFilter/ObjectTypeFilter';
import ResetFilters from 'components/ResetFilters/ResetFilters';
import { Department, EntryType, ProjectVersion } from 'typings/models';

type Props = {
  projectversion: ProjectVersion;
  departments: Department[];
  entryTypes: EntryType[];
  isOnSearchPage: boolean;
  objectTypeFilter?: number;
  facilityLevelFilter?: number;
  departmentFilter?: number;
  onClearFilters: () => void;
  onGotoSearch: (string) => void;
  onSetObjectTypeFilter: (number) => void;
  onSetFacilityLevelFilter: (number) => void;
  onSetDepartmentFilter: (number) => void;
};

const EntryFilterBox: React.FunctionComponent<Props> = (props: Props) => {
  const {
    isOnSearchPage,
    projectversion,
    departments,
    entryTypes,
    objectTypeFilter,
    facilityLevelFilter,
    departmentFilter,
    onClearFilters,
    onGotoSearch,
    onSetObjectTypeFilter,
    onSetFacilityLevelFilter,
    onSetDepartmentFilter,
  } = props;
  return (
    <div className="entry-filter-box">
      {!isOnSearchPage && (
        <React.Fragment>
          <h1>Suche</h1>
          <FreetextFilter onKeyUpHandler={onGotoSearch} />
        </React.Fragment>
      )}

      <h1>Auswahlfilter</h1>
      <ObjectTypeFilter
        entryTypes={entryTypes}
        change={onSetObjectTypeFilter}
        currentValue={objectTypeFilter}
      />
      <FacilityLevelSelect
        change={onSetFacilityLevelFilter}
        facilities={projectversion.facilities}
        currentValue={facilityLevelFilter}
      />
      <DepartmentSelect
        change={onSetDepartmentFilter}
        departments={departments}
        currentValue={departmentFilter}
      />
      <ResetFilters onReset={onClearFilters} />
    </div>
  );
};

export default EntryFilterBox;
