import { ProjectVersion } from 'typings/models';
import { permissionReadable } from 'utils/objects';
import React from 'react';

type Props = {
  projectversion: ProjectVersion;
};

const TableDisplay: React.FunctionComponent<Props> = (props: Props) => {
  const {
    projectversion: { members = [] },
  } = props;
  return (
    <table className="room-data">
      <tbody>
        {members.map((membership) => (
          <tr key={membership.id}>
            <th>
              {membership.user.last_name}, {membership.user.first_name}
            </th>
            <td>
              <a className="plain" href={`mailto:${membership.user.email}`}>
                {membership.user.email}
              </a>
            </td>
            <td>{permissionReadable(membership.permissions)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableDisplay;
