import { handleActions } from 'redux-actions';

import { AssetActionType } from 'actions/actionTypes';

import * as actions from 'typings/actions';
import { EntityState } from 'typings/state';
import { initialState } from './entities.initial';

export const stateKeyMap = {
  'entries.catalogentry': 'catalog_entries',
  'entries.departmententry': 'department_entries',
  'entries.door': 'doors',
  'entries.entry': 'entries',
  'entries.entryfinding': 'entryfindings',
  'entries.finding': 'findings',
  'entries.wall': 'walls',
  'entries.window': 'windows',
  'projects.project': 'projects',
};

const assetsReducer = handleActions<EntityState, actions.Action>(
  {
    [AssetActionType.UPLOADED_ASSET]: (
      state: EntityState,
      action: actions.UploadedAssetAction
    ): EntityState => {
      const {
        payload: {
          response,
          response: { content_type, id, object_id },
        },
      } = action;

      if (stateKeyMap[content_type]) {
        const newState = { ...state };
        newState.assets[id] = response;
        newState[stateKeyMap[content_type]][object_id].assets.push(id);
        return newState;
      }

      return state;
    },

    [AssetActionType.DELETED_ASSET]: (
      state: EntityState,
      action: actions.DeletedAssetAction
    ): EntityState => {
      const {
        payload: { entity_name, entity_id, asset_id },
      } = action;
      const newState = { ...state };

      if (stateKeyMap[entity_name]) {
        const stateKey = stateKeyMap[entity_name];
        newState[stateKey][entity_id].assets = newState[stateKey][entity_id].assets.filter(
          (asset) => asset !== asset_id
        );
        delete newState.assets[asset_id];
        return newState;
      }
      return state;
    },

    [AssetActionType.MOVED_ASSET]: (
      state: EntityState,
      action: actions.MovedAssetAction
    ): EntityState => {
      const {
        payload: {
          json: { content_type, object_id, assets },
        },
      } = action;
      const newState = { ...state };
      if (stateKeyMap[content_type]) {
        newState[stateKeyMap[content_type]][object_id].assets = assets;
      }
      return newState;
    },

    [AssetActionType.MOVED_ASSET_TO_ENTITY]: (
      state: EntityState,
      action: actions.MovedAssetToEntityAction
    ): EntityState => {
      const {
        payload: {
          json: { content_type, object_id, id },
        },
      } = action;
      const newState = { ...state };
      newState.assetsToMove = null;
      const asset = newState.assets[id];
      const stateKey = stateKeyMap[asset.content_type];
      newState.assets[id] = action.payload.json;
      if (asset.object_id !== object_id) {
        newState[stateKey][asset.object_id].assets = newState[stateKey][
          asset.object_id
        ].assets.filter((assetListItem) => assetListItem !== id);
        newState[stateKeyMap[content_type]][object_id].assets.push(id);
      }
      return newState;
    },

    [AssetActionType.MARK_ASSET_TO_MOVE]: (
      state: EntityState,
      action: actions.MarkAssetToMoveAction
    ): EntityState => {
      const {
        payload: { assetId },
      } = action;
      const newState = { ...state };
      newState.assetsToMove = assetId;
      return newState;
    },
  },
  { ...initialState }
);

export default assetsReducer;
