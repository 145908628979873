import DeleteButton from 'components/DeleteButton';
import React from 'react';
import { catalogCategoryOptions } from 'typings/models';
import { ICatalogListProps } from './CatalogListContent.types';

export const CatalogListContent = ({
  catalogs,
  edit_mode,
  onSelectCatalog,
  onDeleteCatalog,
}: ICatalogListProps) => (
  <tbody>
    {catalogs.map((catalog) => {
      return (
        <tr
          className="catalog-list-row"
          key={catalog.id}
          onClick={() => onSelectCatalog(catalog.id)}
        >
          <td>{catalog.title}</td>
          <td>{catalogCategoryOptions[catalog.category]}</td>
          {edit_mode && (
            <td>
              <div style={{ float: 'right' }} onClick={(e) => e.stopPropagation()}>
                <DeleteButton onDelete={() => onDeleteCatalog(catalog.id)} />
              </div>
            </td>
          )}
        </tr>
      );
    })}
  </tbody>
);
