import { EntryActionType } from 'actions/actionTypes';
import { createAction } from 'redux-actions';
import * as actions from 'typings/actions';
import { CatalogCategory, DepartmentEntry, Entry, UpsertDepartmentEntry } from 'typings/models';

export const loadEntries = createAction<actions.LoadEntriesAction, any>(
  EntryActionType.LOAD_ENTRIES
);
export const loadingEntries = createAction<actions.LoadingEntriesAction>(
  EntryActionType.LOADING_ENTRIES
);
export const loadedEntries = createAction<actions.LoadedEntriesAction, any>(
  EntryActionType.LOADED_ENTRIES
);

export const loadEntriesFailed = createAction<actions.LoadEntriesFailedAction, any>(
  EntryActionType.LOAD_ENTRIES_FAILED,
  (errors?: any) => ({ errors })
);

export const createEntry = createAction<actions.CreateEntryAction, number, string, string, string>(
  EntryActionType.CREATE_ENTRY,
  (versionId, signature, entryType, title) => ({ versionId, signature, entryType, title })
);
export const creatingEntry = createAction<actions.CreatingEntryAction>(
  EntryActionType.CREATING_ENTRY
);
export const createdEntry = createAction<actions.CreatedEntryAction>(EntryActionType.CREATED_ENTRY);
export const loadEntry = createAction<actions.LoadEntryAction, number>(EntryActionType.LOAD_ENTRY);
export const createEntryFailed = createAction<actions.CreateEntryFailedAction, any>(
  EntryActionType.CREATE_ENTRY_FAILED
);

export const loadingEntry = createAction<actions.LoadingEntryAction, number>(
  EntryActionType.LOADING_ENTRY
);

export const loadedEntry = createAction<actions.LoadedEntryAction, number, any>(
  EntryActionType.LOADED_ENTRY,
  (id?: number, values?: Entry) => ({ id, values })
);

export const loadEntryFailed = createAction<actions.LoadEntryFailedAction, number, any>(
  EntryActionType.LOAD_ENTRY_FAILED,
  (id?: number, errors?: any) => ({ id, errors })
);

export const deleteEntry = createAction<actions.DeleteEntryAction, number, number, number>(
  EntryActionType.DELETE_ENTRY,
  (project_id: number, entry_id: number, versionId: number) => ({
    entry_id,
    project_id,
    versionId,
  })
);

export const deletedEntry = createAction<actions.DeletedEntryAction, string>(
  EntryActionType.DELETED_ENTRY
);

export const normalizeEntry = createAction<actions.NormalizeEntryAction, any>(
  EntryActionType.NORMALIZE_ENTRY
);

export const createWall = createAction<actions.CreateWallAction, string, number>(
  EntryActionType.CREATE_WALL,
  (token, entry_id) => ({
    entry_id,
    token,
  })
);

export const creatingWall = createAction<actions.CreatingWallAction>(EntryActionType.CREATING_WALL);
export const createdWall = createAction<actions.CreatedWallAction, any>(
  EntryActionType.CREATED_WALL
);
export const createWallFailed = createAction<actions.CreateWallFailedAction, any>(
  EntryActionType.CREATE_WALL_FAILED
);

export const deleteWall = createAction<actions.DeleteWallAction, number, number, number, number>(
  EntryActionType.DELETE_WALL,
  (project_id, entry_id, wall_id, version_id) => ({
    entry_id,
    project_id,
    wall_id,
    version_id,
  })
);
export const deletedWall = createAction<actions.DeletedWallAction, number, number, number, number>(
  EntryActionType.DELETED_WALL
);

export const createDoor = createAction<
  actions.CreateDoorAction,
  string,
  string,
  number,
  number,
  number
>(EntryActionType.CREATE_DOOR, (token, material, type, wall, catalogEntryId) => ({
  material,
  token,
  type,
  wall,
  catalogEntryId,
}));
export const creatingDoor = createAction<actions.CreatingDoorAction>(EntryActionType.CREATING_DOOR);
export const createdDoor = createAction<actions.CreatedDoorAction, any>(
  EntryActionType.CREATED_DOOR
);
export const createDoorFailed = createAction<actions.CreateDoorFailedAction, any, any>(
  EntryActionType.CREATE_DOOR_FAILED
);
export const deleteDoor = createAction<actions.DeleteDoorAction, number>(
  EntryActionType.DELETE_DOOR
);
export const deletedDoor = createAction<actions.DeletedDoorAction, number>(
  EntryActionType.DELETED_DOOR
);

export const createFinding = createAction<
  actions.CreateFindingAction,
  string,
  string,
  string,
  number,
  number
>(EntryActionType.CREATE_FINDING, (token, description, notes, wall, catalogEntryId) => ({
  token,
  description,
  notes,
  wall,
  catalogEntryId,
}));

export const creatingFinding = createAction<actions.CreatingFindingAction>(
  EntryActionType.CREATING_FINDING
);
export const createdFinding = createAction<actions.CreatedFindingAction, any>(
  EntryActionType.CREATED_FINDING
);
export const createFindingFailed = createAction<actions.CreateFindingFailedAction, any, any>(
  EntryActionType.CREATE_FINDING_FAILED
);
export const deleteFinding = createAction<actions.DeleteFindingAction, number>(
  EntryActionType.DELETE_FINDING
);
export const deletedFinding = createAction<actions.DeletedFindingAction, number>(
  EntryActionType.DELETED_FINDING
);

export const createEntryFinding = createAction<
  actions.CreateEntryFindingAction,
  string,
  string,
  string,
  string,
  number
>(EntryActionType.CREATE_ENTRY_FINDING, (token, description, notes, projectversion, entry) => ({
  token,
  description,
  notes,
  projectversion,
  entry,
}));

export const creatingEntryFinding = createAction<actions.CreatingEntryFindingAction>(
  EntryActionType.CREATING_ENTRY_FINDING
);
export const createdEntryFinding = createAction<actions.CreatedEntryFindingAction, number, any>(
  EntryActionType.CREATED_ENTRY_FINDING,
  (entry, entryfinding) => ({ entry, entryfinding })
);
export const createEntryFindingFailed = createAction<
  actions.CreateEntryFindingFailedAction,
  any,
  any
>(EntryActionType.CREATE_ENTRY_FINDING_FAILED);
export const deleteEntryFinding = createAction<actions.DeleteEntryFindingAction, number>(
  EntryActionType.DELETE_ENTRY_FINDING
);
export const deletedEntryFinding = createAction<actions.DeletedEntryFindingAction, number>(
  EntryActionType.DELETED_ENTRY_FINDING
);

export const createWindow = createAction<
  actions.CreateWindowAction,
  string,
  string,
  number,
  number,
  number
>(EntryActionType.CREATE_WINDOW, (token, material, type, wall, catalogEntryId) => ({
  material,
  token,
  type,
  wall,
  catalogEntryId,
}));

export const creatingWindow = createAction<actions.CreatingWindowAction>(
  EntryActionType.CREATING_WINDOW
);
export const createdWindow = createAction<actions.CreatedWindowAction, any>(
  EntryActionType.CREATED_WINDOW
);
export const createWindowFailed = createAction<actions.CreateWindowFailedAction, any, any>(
  EntryActionType.CREATE_WINDOW_FAILED
);
export const deleteWindow = createAction<actions.DeleteDoorAction, number>(
  EntryActionType.DELETE_WINDOW
);
export const deletedWindow = createAction<actions.DeletedDoorAction, number>(
  EntryActionType.DELETED_WINDOW
);

export const createDepartmentEntry = createAction<
  actions.CreateDepartmentEntryAction,
  UpsertDepartmentEntry,
  number,
  number
>(EntryActionType.CREATE_DEPARTMENT_ENTRY, (data, entry_id, department_id) => ({
  data,
  department_id,
  entry_id,
}));
export const creatingDepartmentEntry = createAction<actions.CreatingDepartmentEntryAction>(
  EntryActionType.CREATING_DEPARTMENT_ENTRY
);
export const createDepartmentEntryFailed = createAction<
  actions.CreateDepartmentEntryFailedAction,
  any,
  any
>(EntryActionType.CREATE_DEPARTMENT_ENTRY_FAILED);

export const createdDepartmentEntry = createAction<actions.CreatedDepartmentEntryAction, any>(
  EntryActionType.CREATED_DEPARTMENT_ENTRY
);
export const deleteDepartmentEntry = createAction<actions.DeleteDepartmentEntryAction, number>(
  EntryActionType.DELETE_DEPARTMENT_ENTRY,
  (projectId, entryId, departmentId, departmentEntryId) => ({
    departmentEntryId,
    departmentId,
    entryId,
    projectId,
  })
);

export const deletedDepartmentEntry = createAction<
  actions.DeletedDepartmentEntryAction,
  number,
  number
>(EntryActionType.DELETED_DEPARTMENT_ENTRY, (entryId, departmentEntryId) => ({
  departmentEntryId,
  entryId,
}));

export const deleteDepartmentEntryFailed = createAction<
  actions.DeleteDepartmentEntryFailedAction,
  number
>(EntryActionType.DELETE_DEPARTMENT_ENTRY_FAILED);

export const deletingDepartmentEntry = createAction<actions.DeletingDepartmentEntryAction, number>(
  EntryActionType.DELETING_DEPARTMENT_ENTRY
);

export const deletingDepartmentEntryFailed = createAction<
  actions.DeleteDepartmentEntryFailedAction,
  number
>(EntryActionType.DELETE_DEPARTMENT_ENTRY_FAILED);

export const loadChoices = createAction<actions.LoadChoicesAction, string, string>(
  EntryActionType.LOAD_CHOICES,
  (choices_name, choices_get_params) => ({ choices_name, choices_get_params })
);

export const loadingChoices = createAction<actions.LoadingChoicesAction, string>(
  EntryActionType.LOADING_CHOICES
);

export const loadedChoices = createAction<actions.LoadedChoicesAction, string, any>(
  EntryActionType.LOADED_CHOICES
);

export const loadChoicesFailed = createAction<actions.LoadChoicesFailedAction, string, string>(
  EntryActionType.LOAD_CHOICES_FAILED,
  (choices_name, choices_get_params?) => ({
    choices_get_params,
    choices_name,
  })
);

export const saveEntityField = createAction<
  actions.SaveEntityFieldAction,
  string,
  number,
  string,
  any,
  number
>(
  EntryActionType.SAVE_ENTITY_FIELD,
  ({ entity_name, entity_id, fieldname, value, facility_level_id }) => ({
    entity_id,
    entity_name,
    errors: null,
    fieldname,
    status: 'SAVING',
    value,
    facility_level_id,
  })
);

export const editingEntityField = createAction<
  actions.EditingEntityFieldAction,
  'SAVING' | 'EDITING',
  string,
  number,
  string,
  string | number,
  any
>(
  EntryActionType.EDITING_ENTITY_FIELD,
  ({ entity_name, entity_id, fieldname, status = 'EDITING', value = null, errors = null }) => ({
    entity_id,
    entity_name,
    errors,
    fieldname,
    status,
    value,
  })
);
export const savedEntityField = createAction<
  actions.SavedEntityFieldAction,
  'SAVING',
  string,
  number,
  string,
  string | number,
  any,
  any,
  number
>(EntryActionType.SAVED_ENTITY_FIELD);

export const cancelEditEntityField = createAction<actions.CancelEditEntityFieldAction>(
  EntryActionType.CANCEL_EDIT_ENTITY_FIELD
);

export const loadEntryHistory = createAction<actions.LoadEntryHistoryAction, string>(
  EntryActionType.LOAD_ENTRY_HISTORY,
  (uuid) => ({
    uuid,  
  })
);

export const loadingEntryHistory = createAction<actions.LoadingEntryHistoryAction, string>(
  EntryActionType.LOADING_ENTRY_HISTORY,
  (uuid) => ({
    uuid,
  })
);

export const loadedEntryHistory = createAction<actions.LoadedEntryHistoryAction, string, any>(
  EntryActionType.LOADED_ENTRY_HISTORY,
  (uuid, json) => ({
    uuid,
    json,
  })
);

export const loadEntryHistoryFailed = createAction<
  actions.LoadEntryHistoryFailedAction,
  string,
  any
>(EntryActionType.LOAD_ENTRY_HISTORY_FAILED, (projectId, signature) => ({
  projectId,
  signature,
}));

// #############################################################################################
// Catalog
// #############################################################################################

export const createCatalog = createAction<
  actions.CreateCatalogAction,
  number,
  string,
  CatalogCategory
>(
  EntryActionType.CREATE_CATALOG,
  (projectVersionId: number, title: string, category: CatalogCategory) => ({
    projectVersionId,
    title,
    category,
  })
);

export const creatingCatalog = createAction<actions.CreatingCatalogAction>(
  EntryActionType.CREATING_CATALOG
);

export const createdCatalog = createAction<actions.CreatedCatalogAction>(
  EntryActionType.CREATED_CATALOG
);

export const createCatalogFailed = createAction<actions.CreateCatalogFailedAction, any>(
  EntryActionType.CREATE_CATALOG_FAILED
);

export const deleteCatalog = createAction<actions.DeleteCatalogAction, number>(
  EntryActionType.DELETE_CATALOG,
  (catalogId: number) => catalogId
);

export const deletedCatalog = createAction<actions.DeletedCatalogAction, number>(
  EntryActionType.DELETED_CATALOG
);

export const deletingCatalog = createAction<actions.DeletingCatalogAction, number>(
  EntryActionType.DELETING_CATALOG
);

export const deleteCatalogFailed = createAction<actions.DeleteCatalogFailedAction, number>(
  EntryActionType.DELETE_CATALOG_FAILED
);

export const deletingCatalogFailed = createAction<actions.DeleteCatalogFailedAction, number>(
  EntryActionType.DELETE_CATALOG_FAILED
);

// #############################################################################################
// Catalog Entry
// #############################################################################################

export const creatingCatalogEntry = createAction<actions.CreatingCatalogEntryAction>(
  EntryActionType.CREATING_CATALOG_ENTRY
);
export const createCatalogEntry = createAction<
  actions.CreateCatalogEntryAction,
  number,
  string,
  string,
  string
>(
  EntryActionType.CREATE_CATALOG_ENTRY,
  (catalogId: number, title: string, signature: string, description: string) => ({
    catalogId,
    title,
    signature,
    description,
  })
);

export const createdCatalogEntry = createAction<actions.CreatedCatalogEntryAction>(
  EntryActionType.CREATED_CATALOG_ENTRY
);

export const createCatalogEntryFailed = createAction<actions.CreateCatalogEntryFailedAction, any>(
  EntryActionType.CREATE_CATALOG_ENTRY_FAILED
);

export const deleteCatalogEntry = createAction<actions.DeleteCatalogEntryAction, number>(
  EntryActionType.DELETE_CATALOG_ENTRY
);

export const deletingCatalogEntry = createAction<actions.DeletingCatalogAction, number>(
  EntryActionType.DELETING_CATALOG_ENTRY
);

export const deletedCatalogEntry = createAction<actions.DeletedCatalogEntryAction, number>(
  EntryActionType.DELETED_CATALOG_ENTRY
);

export const deleteCatalogEntryFailed = createAction<
  actions.DeleteCatalogEntryFailedAction,
  number
>(EntryActionType.DELETE_CATALOG_ENTRY_FAILED);

export const moveDepartmentEntry = createAction<actions.MoveDepartmentEntryAction, number, 'up'|'down'>(
  EntryActionType.MOVE_DEPARTMENT_ENTRY,
  (departmentEntryId, direction) => ({ departmentEntryId, direction })
);

export const movingDepartmentEntry = createAction<actions.MovingAssetAction, number, 'up'|'down'>(
  EntryActionType.MOVING_DEPARTMENT_ENTRY,
  (departmentEntryId, direction) => ({ departmentEntryId, direction })
);

export const movedDepartmentEntry = createAction<actions.MovedAssetAction, number, 'up'|'down', {department_entries: DepartmentEntry[]}>(
  EntryActionType.MOVED_DEPARTMENT_ENTRY,
  (departmentEntryId, direction, json) => ({ departmentEntryId, direction, json })
);

export const moveDepartmentEntryFailed = createAction<actions.MoveAssetFailedAction, number, 'up'|'down'>(
  EntryActionType.MOVE_DEPARTMENT_ENTRY_FAILED,
  (departmentEntryId, direction) => ({ departmentEntryId, direction })
);