import { EntryShort } from 'typings/models';
import { IFilter } from 'typings/state/entryfilterlist';

export const filterEntries = (entries: EntryShort[], filter: IFilter): EntryShort[] => {
  return entries
    .filter((item) => !filter.objectType || item.entry_type === filter.objectType)
    .filter(
      (item) =>
        !filter.department || (item.departments || []).map((d) => d.id).includes(filter.department)
    )
    .filter(
      (item) => !filter.facilityLevel || item.facility_level === parseInt(filter.facilityLevel, 10)
    );
};
