import * as entryActions from 'actions/EntryActions';
import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'reducers/app';

import { Action } from 'typings/actions';
import { Catalog, CatalogCategory } from 'typings/models';
import { State } from 'typings/state';
import { CatalogDialog } from '../components/CatalogDialog/CatalogDialog';
import {
  ICatalogDialogBaseProps,
  ICatalogDialogContainerProps,
  ICatalogDialogDispatchProps,
} from 'components/CatalogDialog/CatalogDialog.types';
import * as loadingStateActions from '../actions/LoadingStates';

const mapStateToProps = (
  state: State,
  { preSelectedCatalogEntryId, catalogsFilterKey, ...ownProps }: ICatalogDialogContainerProps
): ICatalogDialogBaseProps => {
  return {
    ...ownProps,
    catalogsFilterKey,
    saveStatusCatalogEntry: state.loadingStates.creating_catalog_entry,
    preSelectedCatalogEntry:
      preSelectedCatalogEntryId !== undefined
        ? selectors.getCatalogEntry(state, preSelectedCatalogEntryId)
        : undefined,
    edit_mode: state.loadingStates.edit_mode,
    catalogs: selectors
      .getCatalogsByProjectVersion(state, ownProps.projectVersionId)
      .filter((c: Catalog) => c.category === catalogsFilterKey),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void): ICatalogDialogDispatchProps => ({
  onAddCatalog: (projectVersion?: number, title?: string, category?: CatalogCategory) =>
    dispatch(entryActions.createCatalog(projectVersion, title, category)),
  onAddCatalogEntry: (catalog?: number, title?: string, signature?: string, description?: string) =>
    dispatch(entryActions.createCatalogEntry(catalog, title, signature, description)),
  onResetCatalogEntryLoadingState: () =>
    dispatch(loadingStateActions.reset('creating_catalog_entry')),
  onDeleteCatalog: (catalogId: number) => {
    dispatch(entryActions.deleteCatalog(catalogId));
  },
  onDeleteCatalogEntry: (catalogEntryId: number) => {
    dispatch(entryActions.deleteCatalogEntry(catalogEntryId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogDialog) as React.FC<ICatalogDialogContainerProps>;
