import { call, put, takeLatest } from 'redux-saga/effects';

import { EntryActionType } from 'actions/actionTypes';
import {
  createdDepartmentEntry,
  createDepartmentEntryFailed,
  creatingDepartmentEntry,
  deletedDepartmentEntry,
  deleteDepartmentEntryFailed,
  deletingDepartmentEntry,
  movedDepartmentEntry,
  moveDepartmentEntryFailed,
  movingDepartmentEntry,
} from 'actions/EntryActions';
import { showModal } from 'actions/GuiActions';
import * as api from 'api/WebApi';
import { ModalType } from 'typings/enums';
import { checkResponseStatus } from 'sagas/utils';
import { MoveDepartmentEntryAction } from 'typings/actions';

export function* workflowCreateDepartmentEntry(action) {
  yield put(creatingDepartmentEntry());

  try {
    const response = yield call(
      api.createDepartmentEntry,
      action.payload.data,
      action.payload.entry_id,
      action.payload.department_id
    );
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(createdDepartmentEntry(json));
  } catch (error) {
    try {
      // @ts-ignore
      const json = yield error.response.json();
      yield put(createDepartmentEntryFailed({ errors: json, values: action.payload }));
    } catch (e) {
      yield put(
        showModal(ModalType.WARNING, `Server-Fehler: Konnte Fachbereichseintrag nicht erstellen`)
      );
      yield put(createDepartmentEntryFailed({ errors: error }));
    }
  }
}

export function* workflowDeleteDepartmentEntry(action) {
  const {
    payload: { entryId, departmentEntryId },
  } = action;
  yield put(deletingDepartmentEntry(departmentEntryId));
  try {
    yield call(api.deleteDepartmentEntry, departmentEntryId);
    yield put(deletedDepartmentEntry(entryId, departmentEntryId));
  } catch (errors) {
    yield put(showModal(ModalType.WARNING, `Konnte Fachbereichseintrag nicht löschen`));
    yield put(deleteDepartmentEntryFailed({ errors }));
  }
}

export function* workflowMoveDepartmentEntry(action: MoveDepartmentEntryAction) {
  const { departmentEntryId, direction } = action.payload;
  try {
    yield put(movingDepartmentEntry(departmentEntryId, direction));
    const response = yield call(api.moveDepartmentEntry, departmentEntryId, direction);
    const json = yield response.json();
    yield put(movedDepartmentEntry(departmentEntryId, direction, json));
  } catch (e) {
    yield put(showModal(ModalType.WARNING, `Fehler beim Umsortieren`));
    yield put(moveDepartmentEntryFailed(departmentEntryId, direction));
  }
}

// Watchers
export function* watchMoveDepartmentEntry() {
  yield takeLatest(EntryActionType.MOVE_DEPARTMENT_ENTRY, workflowMoveDepartmentEntry);
}

export function* watchCreateDepartmentEntry() {
  yield takeLatest(EntryActionType.CREATE_DEPARTMENT_ENTRY, workflowCreateDepartmentEntry);
}

export function* watchDeleteDepartmentEntry() {
  yield takeLatest(EntryActionType.DELETE_DEPARTMENT_ENTRY, workflowDeleteDepartmentEntry);
}
