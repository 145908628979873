import { PermissionLevel } from 'typings/enums';
import { IdTitle } from 'typings/models';
import sortBy from 'lodash/sortBy';
import zipObject from 'lodash/zipObject';

export const getIntsFromParams = (params: { [key: string]: string }, keys: string[]) =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: parseInt(params[key], 10),
    }),
    zipObject(keys, 0)
  );

export const permissionReadable = (permission: PermissionLevel): string => {
  return {
    [PermissionLevel.READ]: 'Lesen',
    [PermissionLevel.WRITE]: 'Schreiben',
    [PermissionLevel.ADMIN]: 'Administrieren',
  }[permission];
};

// TODO - Deepcopy
// eslint-disable-next-line @typescript-eslint/ban-types
export const deepCopy = (object: object) => {
  return JSON.parse(JSON.stringify(object));
};

export const sortByTitle = (items: IdTitle[]): IdTitle[] =>
  sortBy(items, (item) => item.title.toLowerCase());
