import identity from 'lodash/identity';
import * as React from 'react';

type Props = {
  total: number;
  offset: number;
  page_size: number;
  onPageDown: () => void;
};

const PageDownLink = (props: Props) => {
  const { onPageDown, total, offset, page_size } = props;
  const is_enabled = offset + page_size < total;
  return <a onClick={is_enabled ? onPageDown : identity}> </a>;
};

export default PageDownLink;
