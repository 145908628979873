import React, { useEffect } from 'react';

import Left from './Left';
import { IProjectFrameProps } from './types/ProjectFrame.types';

const ProjectFrame = (props: IProjectFrameProps) => {
  useEffect(() => {
    props.onDisableEditMode();
    props.onLoadEntries();
  }, []);

  return (
    <div className="app-content flex-container horizontal">
      <Left {...props} />
      <div className="outer-container flex-container horizontal">{props.children}</div>
    </div>
  );
};

export default ProjectFrame;
