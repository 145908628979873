import * as React from 'react';

type Props = {
  onKeyUpHandler: (string) => void;
};

class FreetextFilter extends React.Component<Props> {
  public change = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onKeyUpHandler(e.currentTarget.value);
  };

  public render() {
    return <input placeholder="Freitext" type="text" onChange={this.change} />;
  }
}

export default FreetextFilter;
