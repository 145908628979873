import { CatalogListContent } from 'components/CatalogListContent';
import { NewCatalogForm } from 'components/NewCatalogForm';
import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Catalog } from 'typings/models';
import { ICatalogListProps } from './CatalogList.types';

export const CatalogList = ({
  catalogs,
  edit_mode,
  history,
  projectversion,
  onAddCatalog,
  onDeleteCatalog,
}: ICatalogListProps) => {
  const [newCatalog, setNewCatalog] = useState<Partial<Catalog> | null>();
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { title, category } = newCatalog || {};
    if (projectversion && title && category) {
      onAddCatalog(projectversion.id, title, category);
    }
    e.preventDefault();
    setNewCatalog(null);
  };
  return (
    <DocumentTitle title="Kataloge">
      <div className="plain-page font-normal project-settings">
        <h2>
          <span>Kataloge</span>
        </h2>
        {newCatalog && (
          <div style={{ maxWidth: '30%', paddingBottom: 100, paddingTop: 30 }}>
            <h4 style={{ paddingTop: 0, width: '100%' }}>Neuer Katalogeintrag</h4>
            <NewCatalogForm value={newCatalog} onChange={setNewCatalog} onSubmit={handleSubmit} />
          </div>
        )}
        <div style={{ maxWidth: '70%', margin: '0.5em 0 2em' }}>
          <table>
            <thead>
              <tr style={{ borderBottom: '2px solid #887' }}>
                <th style={{ paddingLeft: '10px' }}>Titel</th>
                <th>Kategorie</th>
              </tr>
            </thead>
            <CatalogListContent
              catalogs={catalogs}
              edit_mode={edit_mode}
              onDeleteCatalog={onDeleteCatalog}
              onSelectCatalog={(id) =>
                projectversion &&
                history?.push(
                  `/project/${projectversion.project.id}/version/${projectversion.id}/catalog/${id}`
                )
              }
            />
          </table>
          {!newCatalog && edit_mode && (
            <p className="catalog-dialog-action-container right">
              <button
                className="catalog-dialog-action-button"
                onClick={() => setNewCatalog({ title: '', category: 'None' })}
              >
                Neuen Katalog anlegen
              </button>
            </p>
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};
