enum NotificationActionType {
  LOAD_NOTIFICATIONS = 'notifications/LOAD_NOTIFICATIONS',
  LOADING_NOTIFICATIONS = 'notifications/LOADING_NOTIFICATIONS',
  LOADED_NOTIFICATIONS = 'notifications/LOADED_NOTIFICATIONS',
  LOAD_NOTIFICATIONS_FAILED = 'notifications/LOAD_NOTIFICATIONS_FAILED',
  NORMALIZE_NOTIFICATION = 'notifications/NORMALIZE_NOTIFICATION',
  STOP_WATCHER = 'notifications/STOP_WATCHER',

  MARK_AS_READ = 'notifications/MARK_AS_READ',
  MARKING_AS_READ = 'notifications/MARKING_AS_READ',
  MARKED_AS_READ = 'notifications/MARKED_AS_READ',
  MARK_AS_READ_FAILED = 'notifications/MARK_AS_READ_FAILED',

  MARK_ALL_READ = 'notifications/MARK_ALL_READ',
  MARKING_ALL_READ = 'notifications/MARKING_ALL_READ',
  MARKED_ALL_READ = 'notifications/MARKED_ALL_READ',
  MARK_ALL_READ_FAILED = 'notifications/MARK_ALL_READ_FAILED',
}

export default NotificationActionType;
