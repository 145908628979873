import { call, put, takeLatest, take } from 'redux-saga/effects';
import { AccountActionType } from 'actions/actionTypes';
import { RequestingPasswordResetAction, SettingNewPasswordAction } from 'typings/actions';
import * as api from 'api/WebApi';
import {
  requestedPasswordReset,
  requestPasswordResetFailed,
  setNewPassword,
  setNewPasswordFailed,
} from 'actions/AccountActions';
import { checkResponseStatus } from './utils';

export function* workflowRequestPasswordReset(action: RequestingPasswordResetAction) {
  const { email } = action.payload;
  try {
    const response = yield call(api.requestPasswordReset, email);
    checkResponseStatus(response);
    yield put(requestedPasswordReset());
  } catch (e) {
    yield put(requestPasswordResetFailed({ errors: e }));
  }
}

export function* workflowSetNewPassword(action: SettingNewPasswordAction) {
  const { password, token } = action.payload;
  try {
    const response = yield call(api.setNewPasswordViaToken, password, token);
    checkResponseStatus(response);
    yield put(setNewPassword());
  } catch (e) {
    yield put(setNewPasswordFailed({ errors: e }));
  }
}

export function* watchRequestPasswordReset() {
  yield takeLatest(AccountActionType.REQUESTING_PASSWORD_RESET, workflowRequestPasswordReset);
}

export function* watchSetNewPassword() {
  yield takeLatest(AccountActionType.SETTING_NEW_PASSWORD, workflowSetNewPassword);
}

export function* watchRequestPasswordResetReset() {
  yield take(AccountActionType.REQUEST_PASSWORD_RESET_RESET);
}

export function* watchSetNewPasswordReset() {
  yield take(AccountActionType.SET_NEW_PASSWORD_RESET);
}
