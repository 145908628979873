import * as React from 'react';

import CreateDoor from 'components/CreateDoor';
import CreateFinding from 'components/CreateFinding';
import CreateWindow from 'components/CreateWindow';
import DoorList from 'components/DoorList';
import WindowList from 'components/WindowList';
import { CatalogCategory, CatalogEntry, Door, Finding, IdTitle, SaveStatus, Wall, Window } from 'typings/models';
import FindingList from '../FindingList';

type Props = {
  edit_mode: boolean;
  doorTypes: IdTitle[];
  windowTypes: IdTitle[];
  canAdminEntry: boolean;
  editable: boolean;
  wall: Wall;
  has_findings: boolean;
  has_catalogs: boolean;
  creating_door: SaveStatus<Door>;
  creating_window: SaveStatus<Window>;
  creating_finding: SaveStatus<Finding>;
  onCreateDoor: (
    token: string,
    value: string,
    doorType: number | null,
    wallId: number,
    catalogEntryId: number | null
  ) => void;
  onCreateWindow: (
    token: string,
    value: string,
    windowType: number | null,
    wallId: number,
    catalogEntryId: number | null
  ) => void;
  onCreateFinding: (
    token: string,
    description: string,
    notes: string | null,
    wallId: number,
    catalogEntryId: number | null
  ) => void;
  onDeleteDoor: (number: number) => void;
  onDeleteWindow: (number: number) => void;
  onDeleteFinding: (number: number) => void;
  onResetLoadingState: (key: string) => void;
  onFindCatalogEntry: (onSelectCatalogEntry: (catalogEntry: CatalogEntry) => void, catalogsFilterKey: CatalogCategory, preSelectedCatalogEntry?: number) => void;
  onSaveEntityField: (entityData: {
    entity_name: string;
    entity_id: number;
    fieldname?: string;
    value: any;
  }) => void;
};
const WallDataRight: React.FunctionComponent<Props> = (props: Props) => {
  const {
    wall,
    doorTypes,
    windowTypes,
    edit_mode,
    canAdminEntry,
    editable,
    has_catalogs,
    has_findings,
    creating_door,
    creating_window,
    creating_finding,
    onCreateDoor,
    onDeleteDoor,
    onCreateWindow,
    onDeleteWindow,
    onCreateFinding,
    onDeleteFinding,
    onResetLoadingState,
    onFindCatalogEntry,
    onSaveEntityField,
  } = props;

  return (
    <div className="wall-data-right">
      {(edit_mode || !!wall.doors.length) && (
        <div>
          {(edit_mode || wall.doors) && <h4>Türen</h4>}
          {edit_mode && editable && (
            <CreateDoor
              onCreate={onCreateDoor}
              onReset={() => onResetLoadingState('creating_door')}
              wallId={wall.id}
              isCreatingDoor={creating_door}
              choices={doorTypes}
              onFindCatalogEntry={onFindCatalogEntry}
              has_catalogs={has_catalogs}
            />
          )}
          <DoorList
            canAdminEntry={canAdminEntry}
            editable={editable}
            doors={wall.doors}
            doorTypes={doorTypes}
            onDeleteDoor={onDeleteDoor}
            editMode={edit_mode}
            onFindCatalogEntry={onFindCatalogEntry}
            onSaveEntityField={onSaveEntityField}
            has_catalogs={has_catalogs}
          />
        </div>
      )}

      {(edit_mode || !!wall.windows.length) && (
        <div>
          {(edit_mode || wall.windows) && <h4>Fenster</h4>}
          {edit_mode && editable && (
            <CreateWindow
              onCreate={onCreateWindow}
              wallId={wall.id}
              isCreatingWindow={creating_window}
              choices={windowTypes}
              onReset={() => onResetLoadingState('creating_window')}
              onFindCatalogEntry={onFindCatalogEntry}
              has_catalogs={has_catalogs}
            />
          )}
          <WindowList
            windowTypes={windowTypes}
            canAdminEntry={canAdminEntry}
            editable={editable}
            windows={wall.windows}
            onDeleteWindow={onDeleteWindow}
            editMode={edit_mode}
            onFindCatalogEntry={onFindCatalogEntry}
            onSaveEntityField={onSaveEntityField}
            has_catalogs={has_catalogs}
          />
        </div>
      )}
      {has_findings && (edit_mode || !!wall.findings.length) && (
        <div>
          {(edit_mode || wall.findings) && <h4>Befunde</h4>}
          {edit_mode && editable && (
            <CreateFinding
              isCreatingFinding={creating_finding}
              onCreate={onCreateFinding}
              onReset={() => onResetLoadingState('creating_finding')}
              parentId={wall.id}
              onFindCatalogEntry={onFindCatalogEntry}
              has_catalogs={has_catalogs}
            />
          )}
          <FindingList
            findings={wall.findings}
            canAdminEntry={canAdminEntry}
            editable={editable}
            onDeleteFinding={onDeleteFinding}
            editMode={edit_mode}
            onFindCatalogEntry={onFindCatalogEntry}
            onSaveEntityField={onSaveEntityField}
            has_catalogs={has_catalogs}
          />
        </div>
      )}
    </div>
  );
};

export default WallDataRight;
