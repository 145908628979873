import * as React from 'react';
import _ from 'lodash';

import {
  InlineEditSelect,
  InlineEditSelectMultiple,
  InlineEditText,
} from 'containers/InlineEditContainer';
import { IdTitle, Wall } from 'typings/models';

type Props = {
  wall: Wall;
  wallConstructionTypes: IdTitle[];
  wallSurfaceTypes: IdTitle[];
  editable: boolean;
  edit_mode: boolean;
};

const WallDataLeft: React.FunctionComponent<Props> = (props: Props) => {
  const { wall, wallConstructionTypes, wallSurfaceTypes, editable, edit_mode } = props;

  return (
    <div>
      <table className="room-data">
        <tbody>
          {(wall.title || edit_mode) && (
            <tr>
              <th>Titel</th>
              <td>
                <InlineEditText
                  entity="walls.title"
                  entity_id={wall.id}
                  value={wall.title}
                  editable={editable}
                />
              </td>
            </tr>
          )}
          {((wall.direction?.id && wall.direction?.title) || edit_mode) && (
            <tr>
              <th>Blickrichtung</th>
              <td>
                <InlineEditSelect
                  entity="walls.direction"
                  entity_id={wall.id}
                  choices={[
                    ['N', 'Norden'],
                    ['NE', 'Nordosten'],
                    ['E', 'Osten'],
                    ['SE', 'Südosten'],
                    ['S', 'Süden'],
                    ['SW', 'Südwesten'],
                    ['W', 'Westen'],
                    ['NW', 'Nordwesten'],
                  ]}
                  value={wall.direction}
                  editable={editable}
                  hint="z.B. Südwesten"
                />
              </td>
            </tr>
          )}
          {((_.isArray(wall.construction_types) && !!wall.construction_types?.length) ||
            edit_mode) && (
            <tr>
              <th>Konstruktionstypen</th>
              <td>
                <InlineEditSelectMultiple
                  entity="walls.construction_types"
                  entity_id={wall.id}
                  choices={wallConstructionTypes}
                  value={wall.construction_types}
                  editable={editable}
                  hint="z.B. Mauerwerk"
                />
              </td>
            </tr>
          )}
          {((_.isArray(wall.surface_types) && !!wall.surface_types?.length) || edit_mode) && (
            <tr>
              <th>Oberflächen-Arten</th>
              <td>
                <InlineEditSelectMultiple
                  entity="walls.surface_types"
                  entity_id={wall.id}
                  choices={wallSurfaceTypes}
                  value={wall.surface_types}
                  editable={editable}
                  hint="z.B. Tapete"
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default WallDataLeft;
