import React from 'react';

import DeleteButton from '../DeleteButton';
import ImageGallery from '../ImageGallery';
import FileUploadContainer from '../../containers/FileUploadContainer';
import { InlineEditLongText, InlineEditText } from '../../containers/InlineEditContainer';
import { Entry, EntryFinding } from '../../typings/models';

type Props = {
  findings?: EntryFinding[] | null;
  entry: Entry;
  editable: boolean;
  editMode: boolean;
  onDeleteFinding: (number) => void;
};

const EntryFindingList = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { findings, entry, editable, editMode, onDeleteFinding } = props;

  return (
    <div>
      {findings ? (
        findings.map((finding) => (
          <div key={finding.id}>
            <table className="room-data">
              <tbody>
                <tr>
                  <th>Signatur</th>
                  <td>
                    <InlineEditText
                      entity="entryfindings.token"
                      entity_id={finding.id}
                      value={finding.token}
                      editable={editable}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Beschreibung</th>
                  <td>
                    <InlineEditLongText
                      entity="entryfindings.description"
                      entity_id={finding.id}
                      value={finding.description}
                      editable={editable}
                      title="Beschreibung"
                    />
                  </td>
                </tr>
                <tr>
                  <th>Anmerkungen /&nbsp;Interpretation</th>
                  <td>
                    <InlineEditLongText
                      entity="entryfindings.notes"
                      entity_id={finding.id}
                      value={finding.notes}
                      editable={editable}
                      title="Anmerkungen/Interpretation"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {editMode && editable && (
              <DeleteButton
                title="Befund löschen"
                question={`Befund ${finding.token} wirklich löschen?`}
                onDelete={() => onDeleteFinding(finding.id)}
              />
            )}
            {editMode && editable ? (
              <FileUploadContainer
                files={finding.assets.filter((asset) => asset.category === 'image')}
                entity_name="entries.entryfinding"
                entity_id={finding.id}
                display_mode="image"
                category="image"
              />
            ) : (
              <ImageGallery files={finding.assets.filter((asset) => asset.category === 'image')} />
            )}
          </div>
        ))
      ) : (
        <span />
      )}
    </div>
  );
};

export default EntryFindingList;
