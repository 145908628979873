import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';

import EntryNavigator from 'components/EntryNavigator/EntryNavigator';
import ErrorPage from 'components/ErrorPage';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { LoadRequestState } from 'typings/enums';

import { IEntryDetailsProps } from './EntryDetails.types';

const EntryDetails = ({
  entry,
  children,
  projectversion,
  loadingEntry,
  onLoadEntry,
  onLoadEntryHistory,
  onNavigate,
  entryId,
}: IEntryDetailsProps) => {
  useEffect(() => {
    onLoadEntry();
  }, [entryId]);

  useEffect(() => {
    entry && entry.signature.length >= 1 && onLoadEntryHistory(entry.uuid);
  }, [entry?.signature]);

  if (!entry || !loadingEntry.status || loadingEntry.status === LoadRequestState.LOADING) {
    return (
      <DocumentTitle title="Lade...">
        <SimpleLoader size="huge" center />
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title={`${entry.signature}${entry.title ? ` – ${entry.title}` : ''}`}>
      <ErrorPage isError={loadingEntry.status === LoadRequestState.FAILED} onRetry={onLoadEntry}>
        <div className="room-content flex-container vertical">
          {children}
          <EntryNavigator projectversion={projectversion} entry={entry} onNavigate={onNavigate} />
        </div>
      </ErrorPage>
    </DocumentTitle>
  );
};

export default EntryDetails;
