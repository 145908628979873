import { schema } from 'normalizr';
import assetSchema from './asset_schema';
import departmentSchema from './department_schema';
import userSchema from './user_schema';

const departmentEntrySchema = new schema.Entity('department_entries', {
  assets: [assetSchema],
  created_by: userSchema,
  measure_prioritization: new schema.Entity('measure_prioritization'),
  measure_classification: new schema.Entity('measure_classification'),
  department: departmentSchema,
});

export default departmentEntrySchema;
