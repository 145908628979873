import * as React from 'react';
import { isFinite, isArray } from 'lodash';

import EntryFindingList from 'components/EntryFindingList';
import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import {
  InlineEditInteger,
  InlineEditLongText,
  InlineEditSelect,
  InlineEditSelectMultiple,
  InlineEditText,
} from 'containers/InlineEditContainer';
import { Entry, EntryFinding, IdTitle, ProjectVersion, SaveStatus } from 'typings/models';
import CreateFinding from 'components/CreateFinding';

type Props = {
  creatingEntryfinding: SaveStatus<EntryFinding>;
  damage_intensities: IdTitle[];
  editMode: boolean;
  editable: boolean;
  entry: Entry;
  entry_has_findings: boolean;
  interval_next_maintenance: IdTitle[];
  interval_next_revision: IdTitle[];
  measures: IdTitle[];
  overgrowns: IdTitle[];
  projectversion: ProjectVersion;

  fieldVisible: (fieldname: string) => boolean;
  onDeleteFinding: (finding_id: number) => void;
  onCreateEntryFinding: (token, description, notes, projectversion, entryId) => void;
  onResetLoadingState: (key: string) => void;
};

const BaseDataRight = (props: Props) => {
  const {
    creatingEntryfinding,
    damage_intensities,
    entry,
    entry_has_findings,
    editable,
    editMode,
    interval_next_maintenance,
    fieldVisible,
    interval_next_revision,
    overgrowns,
    projectversion,
    onDeleteFinding,
    onCreateEntryFinding,
    onResetLoadingState,
  } = props;

  const onCreate = (token, description, notes, entryId) => {
    onCreateEntryFinding(token, description, notes, projectversion.id, entryId);
  };
  const { assets = [] } = entry;
  const floorAssets = assets.filter(
    (asset) => asset.category === 'image' && asset.field_name === 'floor'
  );

  const ceilingAssets = assets.filter(
    (asset) => asset.category === 'image' && asset.field_name === 'ceiling'
  );

  const stairsAssets = assets.filter(
    (asset) => asset.category === 'image' && asset.field_name === 'stairs'
  );

  const wallAssets = assets.filter(
    (asset) => asset.category === 'image' && asset.field_name === 'wall_text'
  );

  const openingAssets = assets.filter(
    (asset) => asset.category === 'image' && asset.field_name === 'opening'
  );

  return (
    <div>
      {(fieldVisible('damages') ||
        fieldVisible('damage_intensity') ||
        fieldVisible('shell_formation') ||
        fieldVisible('crack') ||
        fieldVisible('off_sanding') ||
        fieldVisible('overgrowns') ||
        fieldVisible('hours_construction_deconstruction') ||
        fieldVisible('vandalism') ||
        fieldVisible('scales')) &&
        (editable ||
          entry.damages ||
          entry.damage_intensity ||
          isFinite(entry.shell_formation) ||
          isFinite(entry.crack) ||
          isFinite(entry.off_sanding) ||
          (isArray(entry.overgrowns) && !!entry.overgrowns?.length) ||
          isFinite(entry.hours_construction_deconstruction) ||
          entry.vandalism ||
          isFinite(entry.scales)) && (
          <div>
            <h4>Schädigung</h4>
            <table className="room-data">
              <tbody>
                {fieldVisible('damage_intensity') && (editable || entry.damage_intensity) && (
                  <tr>
                    <th>Schadensintensität</th>
                    <td>
                      <InlineEditSelect
                        entry={entry}
                        entity="entries.damage_intensity"
                        entity_id={entry.id}
                        choices={damage_intensities}
                        value={entry.damage_intensity}
                        editable={editable}
                        title="Schadensintensität"
                        historyKey="damage_intensity"
                      />
                    </td>
                  </tr>
                )}

                {fieldVisible('off_sanding') && (editable || isFinite(entry.off_sanding)) && (
                  <tr>
                    <th>Absanden</th>
                    <td>
                      <InlineEditInteger
                        entry={entry}
                        entity="entries.off_sanding"
                        entity_id={entry.id}
                        value={entry.off_sanding}
                        editable={editable}
                        suffix="dm²"
                        historyKey="off_sanding"
                      />
                    </td>
                  </tr>
                )}

                {fieldVisible('overgrowns') &&
                  (editable || (isArray(entry.overgrowns) && !!entry.overgrowns?.length)) && (
                    <tr>
                      <th>Bewuchs</th>
                      <td>
                        <InlineEditSelectMultiple
                          entry={entry}
                          entity="entries.overgrowns"
                          entity_id={entry.id}
                          choices={overgrowns}
                          value={entry.overgrowns}
                          editable={editable}
                          historyKey="overgrowns"
                        />
                      </td>
                    </tr>
                  )}

                {fieldVisible('shell_formation') && (editable || isFinite(entry.shell_formation)) && (
                  <tr>
                    <th>Schalenbildung</th>
                    <td>
                      <InlineEditInteger
                        entry={entry}
                        entity="entries.shell_formation"
                        entity_id={entry.id}
                        value={entry.shell_formation}
                        editable={editable}
                        suffix="dm²"
                        historyKey="shell_formation"
                      />
                    </td>
                  </tr>
                )}

                {fieldVisible('scales') && (editable || isFinite(entry.scales)) && (
                  <tr>
                    <th>Schuppen/ Substanzverlust</th>
                    <td>
                      <InlineEditInteger
                        entry={entry}
                        entity="entries.scales"
                        entity_id={entry.id}
                        value={entry.scales}
                        editable={editable}
                        suffix="dm²"
                        historyKey="scales"
                      />
                    </td>
                  </tr>
                )}

                {fieldVisible('crack') && (editable || entry.crack) && (
                  <tr>
                    <th>Riss</th>
                    <td>
                      <InlineEditInteger
                        entry={entry}
                        entity="entries.crack"
                        entity_id={entry.id}
                        value={entry.crack}
                        editable={editable}
                        suffix="cm"
                        historyKey="crack"
                      />
                    </td>
                  </tr>
                )}

                {fieldVisible('vandalism') && (editable || entry.vandalism) && (
                  <tr>
                    <th>Vandalismus</th>
                    <td>
                      <InlineEditText
                        entry={entry}
                        entity="entries.vandalism"
                        entity_id={entry.id}
                        value={entry.vandalism}
                        editable={editable}
                        historyKey="vandalism"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {fieldVisible('damages') && (editable || entry.damages) && (
              <InlineEditLongText
                entry={entry}
                entity="entries.damages"
                entity_id={entry.id}
                value={entry.damages}
                editable={editable}
                hint="z.B. 'abblätternd…'"
                title="Schädigung"
                historyKey="damages"
              />
            )}
          </div>
        )}

      {(fieldVisible('interval_next_maintenance') ||
        fieldVisible('interval_next_revision') ||
        fieldVisible('effort_measure') ||
        fieldVisible('hours_construction_deconstruction') ||
        fieldVisible('special_measures')) &&
        (editable ||
          entry.interval_next_maintenance ||
          entry.interval_next_revision ||
          isFinite(entry.effort_measure) ||
          entry.special_measures) && (
          <div>
            <h4>Wartung</h4>
            <table className="room-data">
              <tbody>
                {fieldVisible('interval_next_maintenance') &&
                  (editable || entry.interval_next_maintenance) && (
                    <tr>
                      <th>Zyklus d. nächsten Wartung</th>
                      <td>
                        <InlineEditSelect
                          entry={entry}
                          entity="entries.interval_next_maintenance"
                          entity_id={entry.id}
                          choices={interval_next_maintenance}
                          value={entry.interval_next_maintenance}
                          editable={editable}
                          title="Zyklus d. nächsten Wartung"
                          historyKey="interval_next_maintenance"
                        />
                      </td>
                    </tr>
                  )}
                {fieldVisible('interval_next_revision') &&
                  (editable || entry.interval_next_revision) && (
                    <tr>
                      <th>Zyklus d. nächsten Revision</th>
                      <td>
                        <InlineEditSelect
                          entry={entry}
                          entity="entries.interval_next_revision"
                          entity_id={entry.id}
                          choices={interval_next_revision}
                          value={entry.interval_next_revision}
                          editable={editable}
                          title="Zyklus d. nächsten Revision"
                          historyKey="interval_next_revision"
                        />
                      </td>
                    </tr>
                  )}

                {fieldVisible('effort_measure') && (editable || isFinite(entry.effort_measure)) && (
                  <tr>
                    <th>Aufwand Maßnahme (geschätzt)</th>
                    <td>
                      <InlineEditInteger
                        entry={entry}
                        entity="entries.effort_measure"
                        entity_id={entry.id}
                        value={entry.effort_measure}
                        editable={editable}
                        suffix="h"
                        historyKey="effort_measure"
                      />
                    </td>
                  </tr>
                )}

                {fieldVisible('special_measures') && (editable || entry.special_measures) && (
                  <tr>
                    <th>Besonderheiten / Sondermaßnahmen</th>
                    <td>
                      <InlineEditLongText
                        entry={entry}
                        entity="entries.special_measures"
                        entity_id={entry.id}
                        value={entry.special_measures}
                        editable={editable}
                        historyKey="special_measures"
                      />
                    </td>
                  </tr>
                )}
                {fieldVisible('hours_construction_deconstruction') &&
                  (editable || isFinite(entry.hours_construction_deconstruction)) && (
                    <tr>
                      <th>Std. Ab- & Aufbau</th>
                      <td>
                        <InlineEditInteger
                          entry={entry}
                          entity="entries.hours_construction_deconstruction"
                          entity_id={entry.id}
                          value={entry.hours_construction_deconstruction}
                          editable={editable}
                          historyKey="hours_construction_deconstruction"
                        />
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        )}

      {fieldVisible('history_text') && (editable || entry.history_text) && (
        <div>
          <h4>Haus- und Baugeschichte, Nutzungen</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.history_text"
            entity_id={entry.id}
            value={entry.history_text}
            editable={editable}
            hint="z.B. 'Nutzung...'"
            title="Haus- und Baugeschichte, Nutzungen"
            historyKey="history_text"
          />
        </div>
      )}

      {fieldVisible('interpretation') && (editable || entry.interpretation) && (
        <div>
          <h4>Interpretation, Würdigung</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.interpretation"
            entity_id={entry.id}
            value={entry.interpretation}
            editable={editable}
            hint="z.B. 'Würdigung...'"
            title="Interpretation, Würdigung"
            historyKey="interpretation"
          />
        </div>
      )}

      {fieldVisible('ceiling') && (editable || entry.ceiling || ceilingAssets.length > 0) && (
        <div>
          <h4>Decke</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.ceiling"
            entity_id={entry.id}
            value={entry.ceiling}
            editable={editable}
            hint="z.B. 'Decke...'"
            title="Decke"
            historyKey="ceiling"
          />
          <div style={{ marginBottom: 16 }} />

          {editMode && editable ? (
            <FileUploadContainer
              files={ceilingAssets}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
              field_name="ceiling"
            />
          ) : (
            <ImageGallery files={ceilingAssets} />
          )}
        </div>
      )}

      {fieldVisible('floor') && (editable || entry.floor || floorAssets.length > 0) && (
        <div>
          <h4>Boden</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.floor"
            entity_id={entry.id}
            value={entry.floor}
            editable={editable}
            hint="z.B. 'Boden...'"
            title="Boden"
            historyKey="floor"
          />
          <div style={{ marginBottom: 16 }} />

          {editMode && editable ? (
            <FileUploadContainer
              files={floorAssets}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
              field_name="floor"
            />
          ) : (
            <ImageGallery files={floorAssets} />
          )}
        </div>
      )}

      {fieldVisible('stairs') && (editable || entry.stairs || stairsAssets.length > 0) && (
        <div>
          <h4>Treppe</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.stairs"
            entity_id={entry.id}
            value={entry.stairs}
            editable={editable}
            hint="z.B. 'Treppe...'"
            title="Treppe"
            historyKey="stairs"
          />
          <div style={{ marginBottom: 16 }} />

          {editMode && editable ? (
            <FileUploadContainer
              files={stairsAssets}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
              field_name="stairs"
            />
          ) : (
            <ImageGallery files={stairsAssets} />
          )}
        </div>
      )}

      {fieldVisible('wall_text') && (editable || entry.wall_text || wallAssets.length > 0) && (
        <div>
          <h4>Wände</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.wall_text"
            entity_id={entry.id}
            value={entry.wall_text}
            editable={editable}
            hint="z.B. 'Wand...'"
            title="Wand"
            historyKey="wall_text"
          />
          <div style={{ marginBottom: 16 }} />

          {editMode && editable ? (
            <FileUploadContainer
              files={wallAssets}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
              field_name="wall_text"
            />
          ) : (
            <ImageGallery files={wallAssets} />
          )}
        </div>
      )}

      {fieldVisible('opening') && (editable || entry.opening || openingAssets.length > 0) && (
        <div>
          <h4>Öffnungen</h4>
          <InlineEditLongText
            entry={entry}
            entity="entries.opening"
            entity_id={entry.id}
            value={entry.opening}
            editable={editable}
            hint="z.B. 'Öffnungen...'"
            title="Öffnungen"
            historyKey="opening"
          />
          <div style={{ marginBottom: 16 }} />

          {editMode && editable ? (
            <FileUploadContainer
              files={openingAssets}
              entity_name="entries.entry"
              entity_id={entry.id}
              display_mode="image"
              category="image"
              field_name="opening"
            />
          ) : (
            <ImageGallery files={openingAssets} />
          )}
        </div>
      )}
      {entry_has_findings && (editable || (entry.entryfindings && entry.entryfindings?.length > 0)) && (
        <div>
          <h4>Befunde</h4>
          {editMode && editable && (
            <CreateFinding
              isCreatingFinding={creatingEntryfinding}
              onCreate={onCreate}
              onReset={() => {
                onResetLoadingState('creating_entry_finding');
              }}
              parentId={entry.id}
            />
          )}
          <EntryFindingList
            findings={entry.entryfindings}
            entry={entry}
            editable={editable}
            editMode={editMode}
            onDeleteFinding={onDeleteFinding}
          />
        </div>
      )}
    </div>
  );
};

export default BaseDataRight;
