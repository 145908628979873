// eslint-disable-next-line @typescript-eslint/ban-types
export const reorder = <T extends {}>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getFileDescription = (desc: string) => {
  return desc.length > 80 ? desc.slice(0, 150) + ' ...' : desc;
};
