import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { AuthActionType } from 'actions/actionTypes';
import * as api from 'api/WebApi';
import { LoadUserAction, LoginAction, SearchUserAction } from 'typings/actions';

import {
  loadedUser,
  loadedUsers,
  loadingUser,
  loadingUsers,
  loadUserFailed,
  loadUsersFailed,
  loggedIn,
  loggingIn,
  loginFailed,
  normalizeUser,
  normalizeUsers,
  searchedUser,
  searchingUser,
  searchUserFailed,
} from 'actions/AuthActions';

import { setToken } from 'actions/AuthActions';
import { loadPrivacyNoteVisible, showModal } from 'actions/GuiActions';
import { ModalType } from 'typings/enums';

export function* workflowLogin(action: LoginAction) {
  yield put(loggingIn());

  const { email, password } = action.payload;
  const response = yield call(api.login, email, password);
  const json = yield response.json();

  if (json.key) {
    yield put(setToken(json.key, json.user_id));
    yield put(loggedIn(json));
    yield put(loadPrivacyNoteVisible());
    yield put(push('/'));
  } else {
    yield put(loginFailed());
  }
}

export function* workflowLoadUser(action: LoadUserAction) {
  yield put(loadingUser());
  try {
    const response = yield call(api.loadUser, action.payload.userId);
    const json = yield response.json();
    yield put(normalizeUser(json));
    yield put(loadedUser(json));
  } catch (err) {
    yield put(loadUserFailed());
  }
}

export function* workflowSearchUser(action: SearchUserAction) {
  yield put(searchingUser());
  try {
    const response = yield call(api.searchUser, action.payload);
    const json = yield response.json();
    yield put(searchedUser(json.results));
  } catch (err) {
    yield put(showModal(ModalType.WARNING, `Konnte Benutzersuche nicht ausführen`));
    yield put(searchUserFailed());
  }
}

export function* workflowLoadUsers() {
  yield put(loadingUsers());
  try {
    const response = yield call(api.loadUsers);
    const json = yield response.json();
    yield put(normalizeUsers(json.results));
    yield put(loadedUsers(json.results));
  } catch (err) {
    yield put(showModal(ModalType.WARNING, `Konnte Benutzer nicht ausrufen`));
    yield put(loadUsersFailed());
  }
}

export function* watchLogin() {
  yield takeLatest(AuthActionType.LOGIN, workflowLogin);
}

export function* watchLoadUser() {
  yield takeLatest([AuthActionType.LOAD_USER, AuthActionType.REFETCH_USER], workflowLoadUser);
}

export function* watchLoadUsers() {
  yield takeLatest(AuthActionType.LOAD_USERS, workflowLoadUsers);
}

export function* watchSearchUser() {
  yield takeLatest(AuthActionType.SEARCH_USER, workflowSearchUser);
}
