import * as React from 'react';

import InlineEditBaseWidget, { BaseProps } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: string | number | null;
  maxLength?: number;
};

class InlineEditTextWidget extends InlineEditBaseWidget<Props> {
  protected refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;

  public getEditWidget = () => {
    const { inputProps, maxLength } = this.props;
    return (
      <span>
        <input
          ref={this.refElement}
          type="text"
          data-original={this.props.value}
          defaultValue={
            this.props.editing_entity_field && this.props.editing_entity_field.value
              ? this.props.editing_entity_field.value
              : this.props.value
          }
          onKeyDown={this.handleKeyDown}
          maxLength={maxLength}
          {...(inputProps || {})}
        />{' '}
        {this.props.suffix}
      </span>
    );
  };

  protected getValueReadable = () => this.props.value || '';
}

export default InlineEditTextWidget;
