import React from 'react';
import { NormalizedNotification } from 'typings/models';
import { useHistory } from 'react-router-dom';
import { getLinks } from 'components/Notification/Notification.util';

const NotificationLinks: React.FC<{
  notification: NormalizedNotification;
  // eslint-disable-next-line react/prop-types
}> = ({ notification }) => {
  const history = useHistory();
  const links = getLinks(notification);

  const gotoEntry = (link: string) => {
    history.push(link);
    // FIXME: No reload
    !!window && window.location.reload();
  };

  return (
    <div className="notification-links">
      {links.map((link) => {
        if (link.internal) {
          return (
            <a href="#" onClick={() => gotoEntry(link.hyperlink)} className="notification-download">
              {link.label}
            </a>
          );
        } else {
          return (
            <a className="notification-download" href={link.hyperlink}>
              {link.label}
            </a>
          );
        }
      })}
    </div>
  );
};

export default NotificationLinks;
