import React, { useRef, useState } from 'react';

import { ITileFloormapROProps } from './TileFloormapComposer.types';

import L from 'leaflet';
import {
  useAddCenterCirclesToMap,
  useAddEventListeners,
  useAddHoverEvents,
  useAddShapesToMap,
  useAddTooltips,
  useAddTooltipsOnZoom,
  useCreateCenterCircles,
  useHandleActiveItem,
  useMountMap,
  usePopulateShapes,
} from './TileFloormap.utils';

export const TileFloormapRO = ({
  entry,
  entries,
  facilityLevel,
  floormap,
  onGotoEntity,
  onSetTitle,
  mapId,
}: ITileFloormapROProps) => {
  const tileLayer = L.tileLayer(floormap, {
    noWrap: true,
    bounds: [
      [-256, 0],
      [0, 256],
    ],
    maxZoom: 4,
    minZoom: 2,
    subdomains: '',
  });

  const mapRef = useRef<L.Map | null>(null);
  const shapeRef = useRef<Map<number, L.Polygon | L.CircleMarker>>(new Map());
  const circleRef = useRef<Map<number, L.CircleMarker>>(new Map());
  const activeCircleRef = useRef<[number, L.CircleMarker] | null>(null);
  const tooltipRef = useRef<Map<number, L.Tooltip>>(new Map());

  const [mapInstance, setMapInstance] = useState<L.Map | null>(null);
  const [polygons, setPolygons] = useState<Map<number, L.Polygon>>(new Map());
  const [circles, setCircles] = useState<Map<number, L.CircleMarker>>(new Map());
  const [activeCircle, setActiveCircle] = useState<[number, L.CircleMarker] | null>(null);
  const [points, setPoints] = useState<Map<number, L.CircleMarker>>(new Map());
  const [tooltips, setTooltips] = useState<Map<number, L.Tooltip>>(new Map());

  const goToEntityWithVersion = (id: number) => {
    onGotoEntity(id, entry?.projectversion.id);
  };

  useMountMap(mapRef, mapId, tileLayer, 'LARGE', setMapInstance);
  usePopulateShapes(
    mapInstance,
    shapeRef,
    goToEntityWithVersion,
    setPolygons,
    setPoints,
    facilityLevel?.geoJSON,
    entry?.id,
    mapId === 'plan-2'
  );

  useAddShapesToMap(mapInstance, polygons, points);
  useHandleActiveItem(mapInstance, polygons, points, activeCircleRef, setActiveCircle, entry?.id);
  useCreateCenterCircles(mapInstance, polygons, circleRef, setCircles, onGotoEntity, entry?.id);
  useAddCenterCirclesToMap(mapInstance, circles, activeCircle);
  useAddTooltips(points, circles, activeCircle, tooltipRef, setTooltips, entries);

  useAddHoverEvents(polygons, points, circles, activeCircle, entry?.id, entries, onSetTitle);
  useAddTooltipsOnZoom(mapInstance, points, circles, activeCircle, tooltips, entry?.id);
  useAddEventListeners(
    polygons,
    tooltips,
    goToEntityWithVersion,
    onSetTitle,
    entries,
    entry?.id,
    mapId === 'plan-2'
  );

  const styles = {
    width: 'auto',
    height: 'auto',
    padding: 0,
    backgroundColor: 'white',
  };

  return (
    <div className="map-container" style={{ margin: '5px 10px' }}>
      <div style={{ flexGrow: 1 }}>
        <div id={mapId} className="map-navigation-map" style={styles}></div>
      </div>
    </div>
  );
};
