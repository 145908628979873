import fetch from 'isomorphic-fetch';
import { CopyProjectActionMembers, ExportProjectActionOptions } from 'typings/actions/projects';
import { Request, RequestMethod } from 'typings/api';
import { PermissionLevel } from 'typings/enums';
import { store } from '../index';
import { getToken } from '../reducers/app';
import { CatalogCategory, UpsertDepartmentEntry } from '../typings/models';

const request = (
  method: RequestMethod = 'GET',
  path = '/',
  data = {},
  jsonify = true,
  extraHeaders = {}
): Promise<any> => {
  // TODO: Pass token by setter. Maybe convert to class.
  const token = getToken(store.getState());
  const requestStruct: Request = {
    headers: {
      Accept: 'application/json',
      ...extraHeaders,
      ...(token ? { Authorization: `Token ${token}` } : ''),
    },
    method,
  };
  if (jsonify) {
    requestStruct.headers['Content-Type'] = 'application/json';
  }

  if (['POST', 'PATCH', 'PUT', 'DELETE'].find((x) => x === method)) {
    requestStruct.body = jsonify ? JSON.stringify(data) : data;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  return fetch(window._env_.REACT_APP_API_URL + path, requestStruct);
};

export const loadUser = (id: number): Promise<any> => request('GET', `user/${id}/`);

export const loadUsers = (): Promise<any> => request('GET', `user/`);

export const createEntry = (
  signature: string,
  projectversion: number,
  entryType: string,
  title: string
): Promise<any> =>
  request('POST', 'entries/', {
    entry_type: entryType,
    projectversion,
    signature,
    title,
  });

export const loadEntries = (projectId: string): Promise<any> =>
  request('GET', `entries/?project=${projectId}`);

export const loadEntry = (id: number): Promise<any> => request('GET', `entries/${id}/`);

export const loadEntryHistory = (uuid: string): Promise<any> =>
  request('GET', `entryhistory/${uuid}`);

export const searchUser = (q: string): Promise<any> => request('GET', `search-user/?q=${q}`);

export const saveEntity = (entityName: string, id: number, values: any) =>
  request('PATCH', `${entityName}/${id}/`, values);

export const loadChoices = (choicesName: string, query = ''): Promise<any> =>
  request('GET', `choices/${choicesName}/${query ? `?${query}` : ''}`);

export const loadFacilityGeoJSON = (id: number): Promise<any> =>
  request('GET', `facilitylevel-geojson/${id}/`);

export const deleteEntry = (id: number): Promise<any> => request('DELETE', `entries/${id}/`);

export const createWall = (wallToken, entryId) =>
  request('POST', 'walls/', { token: wallToken, entry: entryId });

export const deleteWall = (id: number): Promise<any> => request('DELETE', `walls/${id}/`);

export const createDepartmentEntry = (
  data: UpsertDepartmentEntry,
  entryId: number,
  departmentId: number
): Promise<any> =>
  request('POST', 'department_entries/', {
    ...data,
    department_id: departmentId,
    entry_id: entryId,
  });

export const deleteDepartmentEntry = (id: number): Promise<any> =>
  request('DELETE', `department_entries/${id}/`);

export const createDoor = (
  token: string,
  material: string,
  type: number,
  wall: number,
  catalog_entry?: number
): Promise<any> => request('POST', 'doors/', { token, material, type, wall, catalog_entry });

export const deleteDoor = (id: number): Promise<any> => request('DELETE', `doors/${id}/`);

export const createWindow = (
  windowToken: string,
  text: string,
  typeId: number,
  wallId: number,
  catalog_entry?: number
): Promise<any> =>
  request('POST', 'windows/', {
    material: text,
    token: windowToken,
    type: typeId,
    wall: wallId,
    catalog_entry,
  });

export const deleteWindow = (id: number): Promise<any> => request('DELETE', `windows/${id}/`);

export const createFinding = (
  token: string,
  description: string,
  notes: string,
  wallId: number,
  catalog_entry?: number
): Promise<any> => {
  return request('POST', 'findings/', {
    description,
    token,
    notes,
    wall: wallId,
    catalog_entry,
  });
};

export const deleteFinding = (id: number): Promise<any> => request('DELETE', `findings/${id}/`);

export const uploadAsset = (
  entityName: string,
  entityId: number,
  category: string,
  file: File,
  fieldName = ''
): Promise<any> => {
  const data = new FormData();
  data.append('file', file);
  return request(
    'POST',
    `upload/${entityName}/${entityId}/${category}/?original_filename=${file.name}&field_name=${fieldName}`,
    data,
    false
  );
};

export const moveAsset = (assetId: number, toPosition: number): Promise<any> =>
  request('POST', `move-asset/${assetId}/${toPosition}`, '', false);

export const deleteAsset = (id: number): Promise<any> => request('DELETE', `assets/${id}/`);

export const searchEntries = (versionId: number, query: string, operatorMode): Promise<any> =>
  request('GET', `search/?projectversion=${versionId}&q=${encodeURIComponent(query)}&operator=${operatorMode}`);

export const loadProjectVersion = (id: number): Promise<any> =>
  request('GET', `projectversions/${id}/`);

export const addProjectMember = (
  versionId: number,
  userId,
  permissions: PermissionLevel
): Promise<any> => request('POST', `projectversions/${versionId}/member/`, { userId, permissions });

export const removeProjectMember = (versionId: number, membershipId: number): Promise<any> =>
  request('DELETE', `projectversions/${versionId}/member/`, { membershipId });

export const uploadFloormap = (
  versionId: number,
  facilityLevel: number,
  files: File[]
): Promise<any> => {
  const data = new FormData();
  const fileBit = files[0];
  data.append('file', fileBit);
  data.append('facilityLevel', facilityLevel.toString());
  return request('POST', `projectversions/${versionId}/upload-floormap/`, data, false);
};

export const importFloormap = (
  token: string,
  versionId: number,
  facilityLevel: number,
  rooms: any,
  creator: string,
  creationDate: string,
): Promise<any> =>
  request('POST', `projectversions/${versionId}/import-floormap/`, {
    facility_level: facilityLevel,
    rooms,
    token,
    creator,
    creation_date: creationDate,
  });

export const login = (email: string, password: string): Promise<any> =>
  request('POST', 'auth/login/', { username: email, password });

export const exportProjectVersionPdf = ({
  projectVersionId,
  entryIds = [],
  options = {},
}: {
  projectVersionId: number;
  entryIds: number[];
  options: ExportProjectActionOptions;
}): Promise<any> => {
  return request('POST', `projectversions/${projectVersionId}/export/`, { entryIds, options });
};

export const copyProjectVersion = ({
  versionId,
  title,
  members = [],
}: {
  versionId: number;
  title: string;
  members: CopyProjectActionMembers[];
}): Promise<any> => {
  return request('POST', `projectversions/${versionId}/copy/`, { title, members });
};

export const loadDashboard = (versionId: number): Promise<any> =>
  request('GET', `dashboard/${versionId}/`);

export const requestPasswordReset = (email: string): Promise<any> =>
  request('POST', 'password-reset/', { email });

export const setNewPasswordViaToken = (password: string, token: string): Promise<any> =>
  request('POST', 'password-reset/confirm/', { password, token });

export const loadNotifications = (): Promise<any> => request('GET', `notifications/`);

export const markNotificationRead = (id: number): Promise<any> =>
  request('GET', `notifications/${id}/mark-as-read/`);

export const markAllNotificationsRead = (): Promise<any> =>
  request('GET', `notifications/mark-all-read/`);

export const createEntryFinding = (
  token: string,
  description: string,
  notes: string,
  projectversion: string,
  entry: number
): Promise<any> =>
  request('POST', 'entryfindings/', {
    description,
    token,
    notes,
    projectversion,
    entry,
  });

export const updateEntryFinding = (id: number): Promise<any> =>
  request('PATCH', `entryfindings/${id}/`);

export const deleteEntryFinding = (id: number): Promise<any> =>
  request('DELETE', `entryfindings/${id}/`);

export const sendErrorData = (error: any): Promise<any> => request('POST', `fe_error/`, error);

export const moveAssetToEntity = (
  assetId: number,
  entityName: string,
  entityId: number,
  fieldName = ''
): Promise<any> =>
  request(
    'POST',
    `move-asset-to-entity/${assetId}/${entityName}/${entityId}/${fieldName || 'none'}/`,
    '',
    false
  );

export const createCatalog = (
  projectVersionId: number,
  title: string,
  category: CatalogCategory
): Promise<any> =>
  request('POST', 'catalogs/', {
    projectversion: projectVersionId,
    title,
    category,
  });

export const deleteCatalog = (id: number): Promise<any> => request('DELETE', `catalogs/${id}/`);

export const createCatalogEntry = (
  catalogId: number,
  title: string,
  signature: string,
  description?: string
): Promise<any> =>
  request('POST', 'catalog_entries/', {
    catalog: catalogId,
    title,
    signature,
    description,
  });

export const deleteCatalogEntry = (id: number): Promise<any> =>
  request('DELETE', `catalog_entries/${id}/`);

export const initiateProjectExport = ({
  projectId,
}: {
  projectId: number;
}): Promise<any> => {
  return request('POST', `projects/${projectId}/initiate-export/`, {});
};

export const abortProjectExport = ({
  projectId,
}: {
  projectId: number;
}): Promise<any> => {
  return request('POST', `projects/${projectId}/abort-export/`, {});
};

export const exportProject = ({
  projectId,
  generateNewFilenames
}: {
  projectId: number;
  generateNewFilenames: boolean;
}): Promise<any> => {
  return request('POST', `projects/${projectId}/export/`, { generateNewFilenames });
};

export const moveDepartmentEntry = (departmentEntryId: number, direction: 'up'|'down'): Promise<any> =>
  request('POST', `move-departmententry/${departmentEntryId}/${direction}/`, '', false);