import * as React from 'react';

import SimpleLoader from 'components/SimpleLoader';
import { IdTitle, ListResponse } from 'typings/models';
import InlineEditBaseWidget, { BaseProps, DISPLAY_MODE } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: IdTitle;
  loaded_choices: {
    [key: string]: 'LOADING' | 'FAILED' | ListResponse<IdTitle>;
  };
  choices: string | Array<[number | string, string]>;
  choices_get_params?: string;
  onLoadChoices?: (choice_type: string, choices_get_params?: string) => void;
};

class InlineEditSelectWidget extends InlineEditBaseWidget<Props> {
  public radios: { [key: string]: any } = {};
  public choices: Array<[number | string, string]> = [];
  protected refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;

  public getValueReadable = () => {
    const value = this.props.value ? this.props.value.title : '';
    return value;
  };

  public getChoices = () => {
    const { choices, onLoadChoices, choices_get_params } = this.props;
    if (Array.isArray(choices)) {
      this.choices = choices;
      return;
    }
    if (!this.isLoadingChoices()) {
      onLoadChoices && onLoadChoices(choices, choices_get_params);
    }
  };

  public isLoadingChoices = () => {
    const { choices } = this.props;
    if (Array.isArray(choices)) {
      return false;
    }

    return (
      typeof this.props.choices === 'string' &&
      this.props.loaded_choices[this.props.choices] === 'LOADING'
    );
  };

  public setChoicesFromResponse = (json) =>
    json.results.map((item) => {
      return [item.id, item.title];
    });

  public onBeginEdit = () => this.getChoices();

  public componentWillReceiveProps(nextProps) {
    if (
      this.determineDisplayMode() === DISPLAY_MODE.EDITING &&
      !nextProps.loaded_choices &&
      !this.isLoadingChoices()
    ) {
      this.getChoices();
    }
    if (
      typeof this.props.choices === 'string' &&
      nextProps.loaded_choices &&
      nextProps.loaded_choices[this.props.choices]
    ) {
      const listResponse = nextProps.loaded_choices[this.props.choices];
      if (!['LOADING', 'FAILED'].includes(listResponse)) {
        this.choices = this.setChoicesFromResponse(listResponse);
      }
    }
  }

  public setRef = (ref) => {
    !!ref && (this.radios[ref.id] = ref);
  };

  public getEditWidget = () => {
    if (this.isLoadingChoices()) {
      return <SimpleLoader />;
    }

    return (
      <ul>
        {!this.props.prevent_empty ? (
          <li key={''}>
            <label>
              <input
                name={this.props.entity}
                ref={this.setRef}
                id="empty-choice"
                type="radio"
                value=""
                defaultChecked={!this.props.value || !this.props.value.id}
              />
              <i>(Leer)</i>
            </label>
          </li>
        ) : null}
        {this.choices.map((choice) => (
          <li key={choice[0]}>
            <input
              name={this.props.entity}
              id={choice[1]}
              ref={this.setRef}
              type="radio"
              value={choice[0]}
              defaultChecked={this.props.value ? this.props.value.id === choice[0] : false}
            />
            <label htmlFor={choice[1]}>{choice[1]}</label>
          </li>
        ))}
      </ul>
    );
  };

  public saveChanges = () => {
    const checked_id = Object.keys(this.radios).reduce((acc, key) => {
      return acc ? acc : this.radios[key].checked ? key : '';
    }, '');
    const checked_item = checked_id ? this.radios[checked_id] : null;

    if (!checked_item && this.props.prevent_empty) {
      return;
    }

    this.props.onSaveEntityField({
      entity_id: this.props.entity_id,
      entity_name: this.entity_name,
      fieldname: this.fieldname,
      value: checked_item.value,
    });
  };
}

export default InlineEditSelectWidget;
