import { ProjectActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { PermissionLevel } from 'typings/enums';
import { ProjectVersion } from 'typings/models';
import { createAction } from 'redux-actions';
import { ExportProjectActionOptions, CopyProjectActionMembers } from 'typings/actions/projects';

export const loadProject = createAction<actions.LoadProjectAction, number>(
  ProjectActionType.LOAD_PROJECT
);

export const loadingProject = createAction<actions.LoadingProjectAction, number>(
  ProjectActionType.LOADING_PROJECT
);

export const loadedProject = createAction<actions.LoadedProjectAction, number, ProjectVersion>(
  ProjectActionType.LOADED_PROJECT,
  (projectId, projectversion) => ({
    projectversion,
    projectId,
  })
);

export const loadProjectFailed = createAction<actions.LoadProjectFailedAction>(
  ProjectActionType.LOAD_PROJECT_FAILED
);

export const normalizeProject = createAction<actions.NormalizeProjectAction, any>(
  ProjectActionType.NORMALIZE_PROJECT
);

export const loadFloormap = createAction<actions.LoadFloormapAction, number>(
  ProjectActionType.LOAD_FLOORMAP
);

export const loadingFloormap = createAction<actions.LoadingFloormapAction, number>(
  ProjectActionType.LOADING_FLOORMAP
);

export const loadedFloormap = createAction<actions.LoadedFloormapAction, number, ProjectVersion>(
  ProjectActionType.LOADED_FLOORMAP,
  (projectId, projectversion) => ({
    projectversion,
    projectId,
  })
);

export const loadFloormapFailed = createAction<actions.LoadFloormapFailedAction>(
  ProjectActionType.LOAD_FLOORMAP_FAILED
);

export const exportProjectVersionPdf = createAction<
  actions.ExportProjectVersionPdfAction,
  number,
  number[],
  ExportProjectActionOptions
>(ProjectActionType.EXPORT_PROJECTVERSION_PDF, (projectVersionId, entryIds, options) => ({
  entryIds,
  projectVersionId,
  options,
}));

export const initiateProjectExport = createAction<
  actions.InitiateProjectExportAction,
  number
>(ProjectActionType.INITIATE_PROJECT_EXPORT, (projectId) => ({
  projectId,
}));

export const abortProjectExport = createAction<
  actions.AbortProjectExportAction,
  number
>(ProjectActionType.ABORT_PROJECT_EXPORT, (projectId) => ({
  projectId,
}));

export const exportProject = createAction<
  actions.ExportProjectAction,
  number,
  boolean
>(ProjectActionType.EXPORT_PROJECT, (projectId, generateNewFilenames) => ({
  projectId, generateNewFilenames
}));

export const copyProjectVersion = createAction<
  actions.CopyProjectAction,
  number,
  string,
  CopyProjectActionMembers[]
>(ProjectActionType.COPY_PROJECT, (versionId, title, members) => ({
  versionId,
  title,
  members,
}));

export const copyingProjectVersion = createAction<actions.CopyingProjectAction>(
  ProjectActionType.COPYING_PROJECT
);
export const copiedProjectVersion = createAction<actions.CopiedProjectAction>(
  ProjectActionType.COPIED_PROJECT
);

export const uploadFloormap = createAction<actions.UploadFloormapAction>(
  ProjectActionType.UPLOAD_FLOORMAP,
  (versionId: number, facilityLevel: number, files: File[]) => ({
    files,
    facilityLevel,
    versionId,
  })
);
export const uploadFloormapFailed = createAction<actions.UploadFloormapFailedAction>(
  ProjectActionType.UPLOAD_FLOORMAP_FAILED
);

export const uploadingFloormap = createAction<actions.UploadingFloormapAction>(
  ProjectActionType.UPLOADING_FLOORMAP
);

export const uploadedFloormap = createAction<actions.UploadedFloormapAction>(
  ProjectActionType.UPLOADED_FLOORMAP
);

export const resetFloormapImport = createAction<actions.ResetFloormapAction>(
  ProjectActionType.RESET_FLOORMAP_IMPORT
);

export const importFloormap = createAction<actions.ImportFloormapAction>(
  ProjectActionType.IMPORT_FLOORMAP,
  (token: string, projectId: number, versionId: number, facility_level, rooms, creator: string, creationDate: string) => ({
    facility_level,
    projectId,
    versionId,
    rooms,
    token,
    creator,
    creationDate
  })
);
export const importingFloormap = createAction(ProjectActionType.IMPORTING_FLOORMAP);
export const importedFloormap = createAction(ProjectActionType.IMPORTED_FLOORMAP);

export const addProjectMember = createAction<actions.AddProjectMember, number>(
  ProjectActionType.ADD_PROJECT_MEMBER,
  (versionId: number, userId: number, permissions: PermissionLevel) => ({
    permissions,
    versionId,
    userId,
  })
);

export const addingProjectMember = createAction<actions.AddingProjectMember, number>(
  ProjectActionType.ADDING_PROJECT_MEMBER
);

export const addedProjectMember = createAction<actions.AddedProjectMember, number>(
  ProjectActionType.ADDED_PROJECT_MEMBER
);

export const addProjectMemberFailed = createAction<actions.AddProjectMemberFailed, number>(
  ProjectActionType.ADD_PROJECT_MEMBER_FAILED
);

export const removeProjectMember = createAction<actions.RemoveProjectMember, number>(
  ProjectActionType.REMOVE_PROJECT_MEMBER,
  (versionId: number, membershipId: number) => ({
    membershipId,
    versionId,
  })
);

export const removingProjectMember = createAction<actions.RemovingProjectMember, number>(
  ProjectActionType.REMOVING_PROJECT_MEMBER
);

export const removedProjectMember = createAction<actions.RemovedProjectMember, number>(
  ProjectActionType.REMOVED_PROJECT_MEMBER
);

export const removeProjectMemberFailed = createAction<actions.RemoveProjectMemberFailed, number>(
  ProjectActionType.REMOVE_PROJECT_MEMBER_FAILED
);

export const loadFacilityGeoJSON = createAction<actions.LoadFacilityGeoJSONAction, number>(
  ProjectActionType.LOAD_FACILITY_GEOJSON,
  (facility_level_id?: number) => ({ facility_level_id })
);

export const loadedFacilityGeoJSON = createAction<actions.LoadedFacilityGeoJSONAction, number, any>(
  ProjectActionType.LOADED_FACILITY_GEOJSON,
  (facility_level_id?: number, value?: any) => ({ facility_level_id, value })
);

export const loadingFacilityGeoJSONFailed = createAction<
  actions.LoadFacilityGeoJSONFailedAction,
  number,
  any
>(ProjectActionType.LOAD_FACILITY_GEOJSON_FAILED);
