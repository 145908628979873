import { handleActions } from 'redux-actions';

import { AuthActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { AuthState } from 'typings/state/auth';

import userSchema from 'api/user_schema';
import { userList as userListSchema } from 'api/user_schema';
import { User, UserShort } from 'typings/models';
import { denormalize, normalize } from 'normalizr';

const initialState: AuthState = {
  logging_in: false,
  search_user_results: [],
  users: {},
};

const authReducer = handleActions<AuthState, actions.Action>(
  {
    [AuthActionType.NORMALIZE_USER]: (
      state: AuthState,
      action: actions.NormalizeUserAction
    ): AuthState => ({
      ...state,
      ...normalize(action.payload, userSchema).entities,
    }),

    [AuthActionType.NORMALIZE_USERS]: (
      state: AuthState,
      action: actions.NormalizeUsersAction
    ): AuthState => ({
      ...state,
      ...normalize(action.payload, userListSchema).entities,
    }),

    [AuthActionType.SEARCH_USER]: (state: AuthState): AuthState => ({
      ...state,
      search_user_results: [],
    }),

    [AuthActionType.SEARCHED_USER]: (
      state: AuthState,
      action: actions.SearchedUserAction
    ): AuthState => ({
      ...state,
      search_user_results: action.payload,
    }),

    [AuthActionType.LOGGING_IN]: (state: AuthState): AuthState => ({
      ...state,
      logging_in: true,
      login_failed: false,
    }),

    [AuthActionType.LOGGED_IN]: (state: AuthState): AuthState => ({
      ...state,
      logging_in: false,
    }),

    [AuthActionType.LOGIN_FAILED]: (state: AuthState): AuthState => ({
      ...state,
      logging_in: false,
      login_failed: true,
    }),
  },
  { ...initialState }
);

export default authReducer;

export const getUser = (state: AuthState, userId: number): any => {
  if (!state.users) {
    return null;
  }
  return denormalize(state.users[userId], userSchema, state);
};

export const getUsers = (state: AuthState): User[] => {
  if (!state.users) {
    return [];
  }
  return Object.values(state.users).map((u) => denormalize(u, userSchema, state));
};

export const loggingIn = (state: AuthState): boolean => !!state.logging_in;
export const loginFailed = (state: AuthState): boolean => !!state.login_failed;

export const getUserSearchResult = (state: AuthState): UserShort[] => state.search_user_results;
