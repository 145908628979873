import * as React from 'react';

import { Department } from 'typings/models';

type Props = {
  currentValue: number;
  departments: Department[];
  allowEmpty: boolean;
  change: (number) => void;
};

class DepartmentSelect extends React.Component<Props> {
  public static defaultProps = {
    allowEmpty: true,
    currentValue: '',
    departments: [],
  };

  public setDepartment = (e: React.FormEvent<HTMLSelectElement>) =>
    this.props.change(parseInt(e.currentTarget.value, 10));

  public render() {
    const { allowEmpty, currentValue, departments } = this.props;
    return (
      <select size={1} defaultValue={String(currentValue)} onChange={this.setDepartment}>
        {allowEmpty && <option value="">Alle Fachbereiche</option>}
        {departments.map((department) => (
          <option value={String(department.id)} key={department.id}>
            {department.title}
          </option>
        ))}
      </select>
    );
  }
}

export default DepartmentSelect;
