import * as React from 'react';

type Props = {
  editMode: boolean;
  label?: boolean;
  onSetEditMode: (boolean) => void;
};

const EditModeButton: React.FunctionComponent<Props> = (props: Props) => {
  const { onSetEditMode, editMode, label } = props;

  return (
    <button
      title={editMode ? 'Bearbeitung beenden' : 'Bearbeiten'}
      className="edit-button"
      onClick={() => onSetEditMode(!editMode)}
    >
      {editMode ? (
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" stroke="#FFF">
            <path d="M7.6 10.4h8.8V7a4.4 4.4 0 1 0-8.8 0v3.4z" strokeWidth="1.2" />
            <path d="M5.5 10.5h13v10h-13z" />
          </g>
        </svg>
      ) : (
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" stroke="#FFF">
            <path d="M18.5 6.184V3.5h-13v17h13V9.763" />
            <path strokeWidth="1.2" d="M20.5 4.5l2 2-8 8-2-2zM11 15.5l.5.5-1 .5z" />
          </g>
        </svg>
      )}
      {label && (
        <span style={{ paddingLeft: 8 }}>{editMode ? 'Bearbeitung beenden' : 'Bearbeiten'}</span>
      )}
    </button>
  );
};

EditModeButton.defaultProps = {
  label: false,
};

export default EditModeButton;
