import React from 'react';
import { InlineEditLongText, InlineEditText } from 'containers/InlineEditContainer';
import {
  IExistingCatalogEntryDetailsContentProps,
  INewCatalogEntryDetailsContentProps,
} from './CatalogEntryDetailsContent.types';
import FileUploadContainer from 'containers/FileUploadContainer';
import ImageGallery from 'components/ImageGallery';

export const ExistingEntryDetailsContent: React.FC<IExistingCatalogEntryDetailsContentProps> = ({
  edit_mode,
  catalogEntry,
}) => {
  const images = catalogEntry.assets?.filter((asset) => asset.category === 'image') ?? [];

  return (
    <table className={`catalog-entry-details-table ${edit_mode ? 'editing' : ''}`}>
      <tbody>
        <tr>
          <th>Signatur</th>
          <td>
            <InlineEditText
              entity="catalog_entries.signature"
              entity_id={catalogEntry.id}
              value={catalogEntry.signature}
              editable={edit_mode}
              maxLength={35}
              hint="max. 35 Zeichen"
            />
          </td>
        </tr>
        <tr>
          <th>Titel</th>
          <td>
            <InlineEditText
              entity="catalog_entries.title"
              entity_id={catalogEntry.id}
              value={catalogEntry.title}
              editable={edit_mode}
              maxLength={75}
              hint="max. 75 Zeichen"
            />
          </td>
        </tr>
        <tr>
          <th>Beschreibung</th>
          <td>
            <InlineEditLongText
              entity="catalog_entries.description"
              entity_id={catalogEntry.id}
              value={catalogEntry.description}
              editable={edit_mode}
              hint="z.B. Eintragsbeschreibung"
            />
          </td>
        </tr>
        {(!!images.length || edit_mode) && (
          <tr>
            <th>Übersichtsbild</th>
            <td>
              {edit_mode ? (
                <FileUploadContainer
                  files={images}
                  entity_name="entries.catalogentry"
                  entity_id={catalogEntry.id}
                  display_mode="image"
                  category="image"
                />
              ) : (
                <ImageGallery files={images} />
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export const NewCatalogEntryDetailsContent: React.FC<INewCatalogEntryDetailsContentProps> = ({
  catalogEntry,
  onChange,
}) => (
  <table className={`catalog-entry-details-table editing`}>
    <tbody>
      <tr>
        <th>Signatur</th>
        <td>
          <input
            onChange={(e) => onChange({ ...catalogEntry, signature: e.currentTarget.value })}
            type="text"
            maxLength={35}
            placeholder="max. 35 Zeichen"
          />
        </td>
      </tr>
      <tr>
        <th>Titel</th>
        <td>
          <input
            onChange={(e) => onChange({ ...catalogEntry, title: e.currentTarget.value })}
            type="text"
            maxLength={75}
            placeholder="max. 75 Zeichen"
          />
        </td>
      </tr>
      <tr>
        <th>Beschreibung</th>
        <td>
          <textarea
            onChange={(e) => onChange({ ...catalogEntry, description: e.currentTarget.value })}
            placeholder="z.B. Eintragsbeschreibung"
          />
        </td>
      </tr>
    </tbody>
  </table>
);
