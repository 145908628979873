import posed from 'react-pose';

const Fade = posed.div({
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 200 },
  },
});

export default Fade;
