import * as React from 'react';

type Props = {
  left: any;
  right: any;
  middle?: any;
};

const Row: React.FunctionComponent<Props> = (props: Props) => {
  const { left, middle, right } = props;
  return (
    <tr>
      <th>{left}</th>
      {!!middle && <th>{middle}</th>}
      <td>{right}</td>
    </tr>
  );
};

export default Row;
