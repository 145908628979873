import * as React from 'react';
// taken from https://github.com/yosuke-furukawa/react-nl2br/blob/master/index.js
const newlineRegex = /(\r\n|\r|\n)/g;

export const nl2br = (str: string | null): string | React.ReactNode => {
  if (typeof str === 'number') {
    return str;
  } else if (typeof str !== 'string') {
    return '';
  }

  return str.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

export const randomString = (maxLength = 10) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < maxLength; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

  export const getTruncatedSearchString = (countEntries, countWalls, countDepartmentEntries) => {
    const arr: string[] = [];
    countEntries > 300 && arr.push('Einträge');
    countWalls > 300 && arr.push('Wände');
    countDepartmentEntries > 300 && arr.push('Fachbereichseinträge');
    if (arr.length === 1) {
      return arr[0];
    }
    const last = arr.pop();
    return arr.join(', ') + ' und ' + last;
  };
