import {
  AccountActionType,
  AssetActionType,
  AuthActionType,
  DashboardActionType,
  EntryActionType,
  GuiActionType,
  LoadingStatesActionType,
  ProjectActionType,
  SearchActionType,
} from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { LoadRequestState, RequestStatus } from 'typings/enums';
import { MovingAssetStatus, UploadingAssetStatus } from 'typings/models';
import { LoadingStatesState } from 'typings/state';
import omit from 'lodash/omit';
import { handleActions } from 'redux-actions';

const initialState: LoadingStatesState = {
  adding_project_member: {},
  creating_catalog_entry: {},
  creating_department_entry: {},
  creating_door: {},
  creating_entry: {},
  creating_entry_finding: {},
  creating_finding: {},
  creating_wall: {},
  creating_window: {},
  deleting_department_entry: {},
  edit_mode: false,
  editing_entity_field: null,
  importing_floormap: {},
  loading_dashboard: {},
  loading_entries: {},
  loading_entry: {},
  loading_entry_history: {},
  loading_projectversion: {},
  moving_asset: {},
  moving_asset_to_entity: {},
  searching_entries: {},
  searching_users: {},
  uploading_file: {},
  uploading_floormap: {},
  password_reset_request: RequestStatus.NONE,
  password_set_request: RequestStatus.NONE,
  moving_department_entry: 'SAVED',
};

const loadingStatesReducer = handleActions<LoadingStatesState, actions.Action>(
  {
    [DashboardActionType.LOADING_DASHBOARD]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      loading_dashboard: { status: LoadRequestState.LOADING },
    }),

    [DashboardActionType.LOADED_DASHBOARD]: (
      state: LoadingStatesState,
      action: actions.LoadedDashboardAction
    ): LoadingStatesState => ({
      ...state,
      loading_dashboard: {
        status: LoadRequestState.LOADED,
        values: action.payload.values,
      },
    }),

    [DashboardActionType.LOAD_DASHBOARD_FAILED]: (
      state: LoadingStatesState,
      action: actions.LoadProjectFailedAction
    ): LoadingStatesState => ({
      ...state,
      loading_dashboard: {
        errors: action.payload.errors,
        status: LoadRequestState.FAILED,
      },
    }),

    [ProjectActionType.LOADING_PROJECT]: (): LoadingStatesState => ({
      ...initialState,
      loading_projectversion: { status: LoadRequestState.LOADING },
    }),

    [ProjectActionType.LOADED_PROJECT]: (
      state: LoadingStatesState,
      action: actions.LoadedProjectAction
    ): LoadingStatesState => {
      return {
        ...state,
        loading_projectversion: { status: LoadRequestState.LOADED, values: action.payload.values },
      };
    },

    [ProjectActionType.LOAD_PROJECT_FAILED]: (
      state: LoadingStatesState,
      action: actions.LoadProjectFailedAction
    ): LoadingStatesState => ({
      ...state,
      loading_projectversion: {
        errors: action.payload.errors,
        status: LoadRequestState.FAILED,
      },
    }),

    [EntryActionType.LOADING_ENTRIES]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      loading_entries: {
        status: LoadRequestState.LOADING,
      },
    }),

    [EntryActionType.LOADED_ENTRIES]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      loading_entries: {
        status: LoadRequestState.LOADED,
      },
    }),

    [EntryActionType.LOAD_ENTRIES_FAILED]: (
      state: LoadingStatesState,
      action: actions.LoadEntriesFailedAction
    ): LoadingStatesState => ({
      ...state,
      loading_entries: {
        errors: action.payload.errors,
        status: LoadRequestState.FAILED,
      },
    }),

    [SearchActionType.SEARCHING_ENTRIES]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      searching_entries: {
        status: LoadRequestState.LOADING,
      },
    }),

    [SearchActionType.SEARCHED_ENTRIES]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      searching_entries: {
        status: LoadRequestState.LOADED,
      },
    }),

    [SearchActionType.SEARCH_ENTRIES_FAILED]: (
      state: LoadingStatesState,
      action: actions.SearchEntriesFailedAction
    ): LoadingStatesState => ({
      ...state,
      searching_entries: {
        errors: action.payload.errors,
        status: LoadRequestState.FAILED,
      },
    }),

    [SearchActionType.RESET_SEARCH_ENTRIES]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      searching_entries: {},
    }),

    [EntryActionType.LOADING_ENTRY]: (
      state: LoadingStatesState,
      action: actions.LoadingEntryAction
    ): LoadingStatesState => ({
      ...state,
      loading_entry: {
        id: action.payload,
        status: LoadRequestState.LOADING,
      },
    }),

    [EntryActionType.LOADED_ENTRY]: (
      state: LoadingStatesState,
      action: actions.LoadedEntryAction
    ): LoadingStatesState => ({
      ...state,
      loading_entry: {
        id: action.payload.id,
        status: LoadRequestState.LOADED,
        values: action.payload.values,
      },
    }),

    [EntryActionType.LOAD_ENTRY_FAILED]: (
      state: LoadingStatesState,
      action: actions.LoadEntryFailedAction
    ): LoadingStatesState => ({
      ...state,
      loading_entry: {
        errors: action.payload.errors,
        id: action.payload.id,
        status: LoadRequestState.FAILED,
      },
    }),

    [EntryActionType.LOADING_ENTRY_HISTORY]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      loading_entry_history: {
        status: LoadRequestState.LOADING,
      },
    }),

    [EntryActionType.LOADED_ENTRY_HISTORY]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      loading_entry_history: {
        status: LoadRequestState.LOADED,
      },
    }),

    [EntryActionType.LOAD_ENTRY_HISTORY_FAILED]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      loading_entry_history: {
        status: LoadRequestState.FAILED,
      },
    }),

    [AuthActionType.SEARCHING_USER]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      searching_users: {
        status: LoadRequestState.LOADING,
      },
    }),

    [AuthActionType.SEARCHED_USER]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      searching_users: {
        status: LoadRequestState.LOADED,
      },
    }),

    [AuthActionType.SEARCH_USER_FAILED]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      searching_users: { status: LoadRequestState.FAILED },
    }),

    [ProjectActionType.ADDING_PROJECT_MEMBER]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      adding_project_member: { status: LoadRequestState.LOADING },
    }),

    [ProjectActionType.ADDED_PROJECT_MEMBER]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      adding_project_member: { status: LoadRequestState.LOADED },
    }),

    [ProjectActionType.ADD_PROJECT_MEMBER_FAILED]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      adding_project_member: { status: LoadRequestState.FAILED },
    }),

    [EntryActionType.EDITING_ENTITY_FIELD]: (
      state: LoadingStatesState,
      action: actions.EditingEntityFieldAction
    ): LoadingStatesState => ({
      ...state,
      editing_entity_field: {
        entity_id: action.payload.entity_id,
        entity_name: action.payload.entity_name,
        errors: action.payload.errors || null,
        fieldname: action.payload.fieldname,
        status: action.payload.status,
        value: action.payload.value,
      },
    }),

    [EntryActionType.CANCEL_EDIT_ENTITY_FIELD]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      editing_entity_field: null,
    }),

    [EntryActionType.SAVED_ENTITY_FIELD]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      editing_entity_field: null,
    }),

    [GuiActionType.SET_EDIT_MODE]: (
      state: LoadingStatesState,
      action: actions.SetEditModeAction
    ): LoadingStatesState => ({
      ...state,
      edit_mode: action.payload,
      editing_entity_field: null,
    }),

    [GuiActionType.DISABLE_EDIT_MODE]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      edit_mode: false,
      editing_entity_field: null,
    }),

    [GuiActionType.TOGGLE_EDIT_MODE]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      edit_mode: !state.edit_mode,
      editing_entity_field: null,
    }),

    [AssetActionType.UPLOADING_ASSET]: (
      state: LoadingStatesState,
      action: actions.UploadingAssetAction
    ) => ({
      ...state,
      uploading_file: {
        ...state.uploading_file,
        [action.payload.uploadKey]: 'PENDING',
      },
    }),

    [AssetActionType.UPLOADED_ASSET]: (
      state: LoadingStatesState,
      action: actions.UploadedAssetAction
    ): LoadingStatesState => ({
      ...state,
      uploading_file: {
        ...state.uploading_file,
        [action.payload.uploadKey]: 'SAVED',
      },
    }),

    [AssetActionType.UPLOAD_ASSET_FAILED]: (
      state: LoadingStatesState,
      action: actions.UploadAssetFailedAction
    ) => ({
      ...state,
      uploading_file: {
        ...state.uploading_file,
        [action.payload.uploadKey]: 'SAVED',
      },
    }),

    [AssetActionType.RESET_ASSET_UPLOAD]: (
      state: LoadingStatesState,
      action: actions.ResetAssetUpload
    ) => ({
      ...state,
      uploading_file: omit(
        {
          ...state.uploading_file,
        },
        action.payload
      ),
    }),

    [AssetActionType.MOVING_ASSET]: (
      state: LoadingStatesState,
      action: actions.MovingAssetAction
    ) => ({
      ...state,
      moving_asset: {
        ...state.moving_asset,
        [action.payload.assetId]: 'PENDING',
      },
    }),

    [AssetActionType.MOVED_ASSET]: (
      state: LoadingStatesState,
      action: actions.MovedAssetAction
    ) => ({
      ...state,
      moving_asset: {
        ...state.moving_asset,
        [action.payload.assetId]: 'SAVED',
      },
    }),

    [AssetActionType.MOVE_ASSET_FAILED]: (
      state: LoadingStatesState,
      action: actions.MoveAssetFailedAction
    ) => ({
      ...state,
      moving_asset: {
        ...state.moving_asset,
        [action.payload.assetId]: 'SAVED',
      },
    }),

    [AssetActionType.MOVING_ASSET_TO_ENTITY]: (
      state: LoadingStatesState,
      action: actions.MovingAssetToEntityAction
    ) => ({
      ...state,
      moving_asset: {
        ...state.moving_asset_to_entity,
        [action.payload.assetId]: 'PENDING',
      },
    }),

    [AssetActionType.MOVED_ASSET_TO_ENTITY]: (
      state: LoadingStatesState,
      action: actions.MovedAssetToEntityAction
    ) => ({
      ...state,
      moving_asset: {
        ...state.moving_asset_to_entity,
        [action.payload.assetId]: 'SAVED',
      },
    }),

    [AssetActionType.MOVE_ASSET_TO_ENTITY_FAILED]: (
      state: LoadingStatesState,
      action: actions.MoveAssetToEntityFailedAction
    ) => ({
      ...state,
      moving_asset: {
        ...state.moving_asset_to_entity,
        [action.payload.assetId]: 'ERROR',
      },
    }),

    [ProjectActionType.UPLOADING_FLOORMAP]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      uploading_floormap: {
        status: 'SAVING',
      },
    }),

    [ProjectActionType.UPLOAD_FLOORMAP_FAILED]: (
      state: LoadingStatesState,
      action: actions.UploadFloormapFailedAction
    ): LoadingStatesState => ({
      ...state,
      uploading_floormap: {
        errors: action.payload,
        status: 'FAILED',
      },
    }),

    [ProjectActionType.UPLOADED_FLOORMAP]: (
      state: LoadingStatesState,
      action: actions.UploadedFloormapAction
    ): LoadingStatesState => ({
      ...state,
      uploading_floormap: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [ProjectActionType.RESET_FLOORMAP_IMPORT]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      importing_floormap: {},
      uploading_floormap: {},
    }),

    [ProjectActionType.IMPORTING_FLOORMAP]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      importing_floormap: {
        status: 'SAVING',
      },
    }),

    [ProjectActionType.IMPORTED_FLOORMAP]: (
      state: LoadingStatesState,
      action: actions.ImportedFloormapAction
    ): LoadingStatesState => ({
      ...state,
      importing_floormap: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATING_ENTRY]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      creating_entry: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_ENTRY]: (
      state: LoadingStatesState,
      action: actions.CreatedEntryAction
    ): LoadingStatesState => ({
      ...state,
      creating_entry: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATE_ENTRY_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateEntryFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_entry: {
        messages: action.payload.errors,
        status: 'FAILED',
      },
    }),

    [EntryActionType.CREATING_WALL]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      creating_wall: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_WALL]: (
      state: LoadingStatesState,
      action: actions.CreatedWallAction
    ): LoadingStatesState => ({
      ...state,
      creating_wall: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATE_WALL_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateWallFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_wall: {
        messages: action.payload.errors,
        status: 'FAILED',
      },
    }),

    [EntryActionType.CREATING_DOOR]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      creating_door: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_DOOR]: (
      state: LoadingStatesState,
      action: actions.CreatedDoorAction
    ): LoadingStatesState => ({
      ...state,
      creating_door: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATE_DOOR_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateDoorFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_door: {
        messages: action.payload.errors,
        status: 'FAILED',
        values: action.payload.values,
      },
    }),

    [LoadingStatesActionType.RESET]: (
      state: LoadingStatesState,
      action: actions.ResetLoadingStateAction
    ): LoadingStatesState => {
      return { ...state, [action.payload]: initialState[action.payload] };
    },

    [EntryActionType.CREATING_WINDOW]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      creating_window: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_WINDOW]: (
      state: LoadingStatesState,
      action: actions.CreatedWindowAction
    ): LoadingStatesState => ({
      ...state,
      creating_window: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATE_WINDOW_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateWindowFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_window: {
        messages: action.payload.errors,
        status: 'FAILED',
        values: action.payload.values,
      },
    }),

    [EntryActionType.CREATING_FINDING]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      creating_finding: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_FINDING]: (
      state: LoadingStatesState,
      action: actions.CreatedFindingAction
    ): LoadingStatesState => ({
      ...state,
      creating_finding: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATE_FINDING_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateFindingFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_finding: {
        messages: action.payload.errors,
        status: 'FAILED',
        values: action.payload.values,
      },
    }),

    [EntryActionType.CREATING_ENTRY_FINDING]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      creating_entry_finding: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_ENTRY_FINDING]: (
      state: LoadingStatesState,
      action: actions.CreatedEntryFindingAction
    ): LoadingStatesState => ({
      ...state,
      creating_entry_finding: {
        status: 'SAVED',
        values: action.payload.entryfinding,
      },
    }),

    [EntryActionType.CREATE_ENTRY_FINDING_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateEntryFindingFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_entry_finding: {
        messages: action.payload.errors,
        status: 'FAILED',
        values: action.payload.values,
      },
    }),

    [EntryActionType.CREATING_DEPARTMENT_ENTRY]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      creating_department_entry: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_DEPARTMENT_ENTRY]: (
      state: LoadingStatesState,
      action: actions.CreatedDepartmentEntryAction
    ): LoadingStatesState => ({
      ...state,
      creating_department_entry: {
        status: 'SAVED',
        values: action.payload,
      },
    }),

    [EntryActionType.CREATE_DEPARTMENT_ENTRY_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateDepartmentEntryFailedAction
    ): LoadingStatesState => ({
      ...state,
      creating_department_entry: {
        messages: action.payload.errors,
        status: 'FAILED',
        values: action.payload.values,
      },
    }),

    [EntryActionType.DELETING_DEPARTMENT_ENTRY]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      deleting_department_entry: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.DELETE_DEPARTMENT_ENTRY_FAILED]: (
      state: LoadingStatesState,
      action: actions.DeleteDepartmentEntryFailedAction
    ): LoadingStatesState => ({
      ...state,
      deleting_department_entry: {
        messages: action.payload.messages,
        status: 'FAILED',
      },
    }),

    [EntryActionType.DELETED_DEPARTMENT_ENTRY]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      deleting_department_entry: {
        status: 'SAVED',
      },
    }),

    [EntryActionType.LOAD_CHOICES_FAILED]: (state: LoadingStatesState): LoadingStatesState => {
      return {
        ...state,
        editing_entity_field: null,
      };
    },

    [AccountActionType.REQUESTING_PASSWORD_RESET]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      password_reset_request: RequestStatus.REQUESTED,
    }),

    [AccountActionType.REQUESTED_PASSWORD_RESET]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      password_reset_request: RequestStatus.REQUEST_GRANTED,
    }),

    [AccountActionType.REQUEST_PASSWORD_RESET_FAILED]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      password_reset_request: RequestStatus.REQUEST_FAILED,
    }),

    [AccountActionType.REQUEST_PASSWORD_RESET_RESET]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      password_reset_request: RequestStatus.NONE,
    }),

    [AccountActionType.SETTING_NEW_PASSWORD]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      password_set_request: RequestStatus.REQUESTED,
    }),

    [AccountActionType.SET_NEW_PASSWORD]: (state: LoadingStatesState): LoadingStatesState => ({
      ...state,
      password_set_request: RequestStatus.REQUEST_GRANTED,
    }),

    [AccountActionType.SET_NEW_PASSWORD_FAILED]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      password_set_request: RequestStatus.REQUEST_FAILED,
    }),

    [AccountActionType.SET_NEW_PASSWORD_RESET]: (
      state: LoadingStatesState
    ): LoadingStatesState => ({
      ...state,
      password_set_request: RequestStatus.NONE,
    }),

    [EntryActionType.MOVING_DEPARTMENT_ENTRY]: (state: LoadingStatesState) => ({
      ...state,
      moving_department_entry: 'PENDING',
    }),

    [EntryActionType.MOVED_DEPARTMENT_ENTRY]: (state: LoadingStatesState) => ({
      ...state,
      moving_department_entry: 'SAVED',
    }),

    [EntryActionType.MOVE_DEPARTMENT_ENTRY_FAILED]: (state: LoadingStatesState) => ({
      ...state,
      moving_department_entry: 'ERROR',
    }),

    [EntryActionType.CREATING_CATALOG_ENTRY]: (state: LoadingStatesState) => ({
      ...state,
      creating_catalog_entry: {
        status: 'SAVING',
      },
    }),

    [EntryActionType.CREATED_CATALOG_ENTRY]: (
      state: LoadingStatesState,
      action: actions.CreatedCatalogEntryAction
    ) => ({
      ...state,
      creating_catalog_entry: {
        status: 'SAVED',
        values: action.payload,
      },
    }),
    [EntryActionType.CREATE_CATALOG_ENTRY_FAILED]: (
      state: LoadingStatesState,
      action: actions.CreateCatalogEntryFailedAction
    ) => ({
      ...state,
      creating_catalog_entry: {
        status: 'FAILED',
        values: action.payload,
      },
    }),
  },
  { ...initialState }
);

export const getAssetUploads = (state: LoadingStatesState): UploadingAssetStatus =>
  state.uploading_file;

export const getAssetMovings = (state: LoadingStatesState): MovingAssetStatus => state.moving_asset;
export const isSearchingUsers = (state: LoadingStatesState): boolean =>
  state.searching_users.status === LoadRequestState.LOADING;

export const isAddingProjectMember = (state: LoadingStatesState): boolean =>
  state.adding_project_member.status === LoadRequestState.LOADING;

export const getPasswordResetRequestStatus = (state: LoadingStatesState): RequestStatus =>
  state.password_reset_request;
export const getPasswordSetRequestStatus = (state: LoadingStatesState): RequestStatus =>
  state.password_set_request;

export default loadingStatesReducer;
