import {
  IResetPasswordFormProps,
  ResetPasswordFormData,
  errorProps,
  innerFormProps,
} from 'components/ResetPasswordForm/ResetPasswordForm.types';
import { RequestStatus } from 'typings/enums';
import SimpleLoader from 'components/SimpleLoader';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useForm } from 'react-hook-form';

const ResetPasswordForm: React.FunctionComponent<IResetPasswordFormProps> = ({
  requestStatus,
  onSubmit,
  onReset,
}: IResetPasswordFormProps) => {
  return (
    <div className="login-window" style={{ width: 300 }}>
      <h4>Passwort zurücksetzen</h4>
      {
        {
          [RequestStatus.NONE]: <InnerForm onSubmit={onSubmit} />,
          [RequestStatus.REQUESTED]: <SimpleLoader />,
          [RequestStatus.REQUEST_FAILED]: <Error onReset={onReset} />,
          [RequestStatus.REQUEST_GRANTED]: <Success />,
        }[requestStatus]
      }
    </div>
  );
};

const InnerForm: React.FunctionComponent<innerFormProps> = ({ onSubmit }: innerFormProps) => {
  const { register, handleSubmit, errors } = useForm<ResetPasswordFormData>();
  const onSubmitHandler = handleSubmit(({ email }) => {
    onSubmit(email);
  });

  return (
    <form onSubmit={onSubmitHandler}>
      <input
        name="email"
        ref={register({
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: 'Ungültige E-Mail Adresse',
          },
        })}
        placeholder="Ihre E-Mail Adresse"
        autoFocus={true}
      />
      {errors.email && (
        <span style={{ color: 'red' }}>
          {' '}
          {errors.email.message || 'Bitte E-Mail Adresse eingeben'}
        </span>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/" className="button" style={{ margin: '0.3em 0 0.3em 0' }}>
          Abbrechen
        </Link>
        <button className="primary" type="button" onClick={onSubmitHandler}>
          Senden
        </button>
      </div>
    </form>
  );
};

const Error: React.FunctionComponent<errorProps> = ({ onReset }: errorProps) => {
  return (
    <div>
      <p className="error">Der Vorgang ist fehlgeschlagen. Bitte versuchen Sie es später erneut.</p>
      <button className="primary button-home" type="button" onClick={onReset}>
        Zurück
      </button>
    </div>
  );
};

const Success: React.FunctionComponent = () => {
  return (
    <p>
      Bitte schauen Sie in ihr E-Mail Postfach, um mit dem Zurücksetzen Ihres Passwortes
      fortzufahren
    </p>
  );
};

export default ResetPasswordForm;
