import EntrySelect from 'components/EntrySelect';
import IdTitleSelect from 'components/IdTitleSelect';
import { EntryShort, EntryType } from 'typings/models';
import React, { Component } from 'react';
import { ExistingEntry, NewEntry, RoomUpload } from './ImportFloormap.types';

type Props = {
  layer: string;
  rooms: RoomUpload;
  entries: EntryShort[];
  entryTypes: EntryType[];
  removeRoom: (layerName: string) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setEntry: (layerName: string, entry: NewEntry | ExistingEntry | {}) => void;
};

type State = {
  type?: 'existing' | 'new';
};

class LayerForm extends Component<Props, State> {
  public state = {
    type: undefined,
  };

  public selectExistingEntry = (e: React.FormEvent<HTMLSelectElement>) => {
    this.props.setEntry(this.props.layer, { roomId: parseInt(e.currentTarget.value, 10) });
  };

  public setEntryProp = (
    propName: 'roomId' | 'entryType' | 'title' | 'signature',
    value: string | number
  ) => {
    const { setEntry, layer, rooms } = this.props;
    setEntry(layer, {
      ...(rooms[layer] || {}),
      [propName]: value,
    });
  };

  public selectNoEntry = () =>
    this.setState({ type: undefined }, () => this.props.removeRoom(this.props.layer));

  public setTempExistingRoom = () => {
    const { setEntry, layer } = this.props;
    this.setState({ type: 'existing' }, () => {
      setEntry(layer, { roomId: null });
    });
  };

  public setTempNewRoom = () => {
    const { setEntry, layer } = this.props;
    this.setState({ type: 'new' }, () => {
      setEntry(layer, { signature: '' });
    });
  };

  public render() {
    const { entryTypes, layer, rooms, entries } = this.props;
    const room = rooms[layer];

    const modeNone = !this.state.type;
    const modeExisting =
      /* eslint-disable no-prototype-builtins */
      this.state.type === 'existing' || (!!room && room.hasOwnProperty('roomId'));
    const modeNew = this.state.type === 'new' || (!!room && room.hasOwnProperty('signature'));
    /* eslint-enable no-prototype-builtins */
    const error = !!room && 'error' in room ? room.error : '';

    return (
      <div>
        <h3>{layer}</h3>
        <div>
          <label>
            <input onChange={this.selectNoEntry} type="radio" checked={modeNone} /> Nicht
            importieren
          </label>
        </div>
        <div>
          <label>
            <input onChange={this.setTempExistingRoom} type="radio" checked={modeExisting} />{' '}
            Bestehendem Eintrag zuordnen
          </label>
        </div>
        <div>
          <label>
            <input onChange={this.setTempNewRoom} type="radio" checked={modeNew} /> Neuen Eintrag
            anlegen
          </label>
        </div>
        {modeExisting && (
          <>
            {!!error && <div className="error">{error}</div>}
            <EntrySelect
              entries={entries}
              defaultValue={
                !!room && !!(room as ExistingEntry).roomId
                  ? (room as ExistingEntry).roomId || undefined
                  : undefined
              }
              onSelect={this.selectExistingEntry}
            />
          </>
        )}
        {modeNew && (
          <div>
            {!!error && <div className="error">{error}</div>}
            <IdTitleSelect
              options={entryTypes}
              onSelect={(e) => this.setEntryProp('entryType', e.currentTarget.value)}
            />
            <input
              placeholder="Signatur"
              onChange={(e) => this.setEntryProp('signature', e.currentTarget.value)}
            />
            <input
              placeholder="Titel"
              onChange={(e) => this.setEntryProp('title', e.currentTarget.value)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default LayerForm;
