import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import { checkResponseStatus } from 'sagas/utils';
import {
  createDoor,
  createWall,
  createWindow,
  createFinding,
  deleteFinding,
  deleteDoor,
  deleteWall,
  deleteWindow,
} from 'api/WebApi';

import { EntryActionType } from 'actions/actionTypes';
import {
  createdDoor,
  createDoorFailed,
  createdFinding,
  createFindingFailed,
  createdWall,
  createdWindow,
  createWallFailed,
  createWindowFailed,
  creatingDoor,
  creatingFinding,
  creatingWall,
  creatingWindow,
  deletedDoor,
  deletedFinding,
  deletedWall,
  deletedWindow,
} from 'actions/EntryActions';
import { showModal } from 'actions/GuiActions';
import { ModalType } from 'typings/enums';

function* workflowCreateWall(action) {
  yield put(creatingWall());

  try {
    const response = yield call(createWall, action.payload.token, action.payload.entry_id);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(createdWall(json));
  } catch (error) {
    // @ts-ignore
    const json = yield error.response.json();
    yield put(createWallFailed({ errors: json, values: action.payload }));
  }
}

function* workflowDeleteWall(action) {
  try {
    const response = yield call(deleteWall, action.payload.wall_id);
    checkResponseStatus(response);
    yield put(deletedWall(action.payload));
    yield put(
      push(
        `/project/${action.payload.project_id}/version/${action.payload.version_id}/entry/${action.payload.entry_id}/base`
      )
    );
  } catch (error) {
    yield put(showModal(ModalType.WARNING, `Beim Löschen der Wand ist ein Fehler aufgetreten`));
  }
}

function* workflowCreateDoor(action) {
  yield put(creatingDoor());
  const { token, material, type, wall, catalogEntryId } = action.payload;

  try {
    const response = yield call(createDoor, token, material, type, wall, catalogEntryId);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(createdDoor(json));
  } catch (error) {
    // @ts-ignore
    const json = yield error.response.json();
    yield put(createDoorFailed({ errors: json, values: action.payload }));
  }
}

function* workflowDeleteDoor(action) {
  try {
    const response = yield call(deleteDoor, action.payload);
    checkResponseStatus(response);
    yield put(deletedDoor(action.payload));
  } catch (error) {
    // @ts-ignore
    yield error.response.json();
  }
}

function* workflowCreateFinding(action) {
  yield put(creatingFinding());
  const { token, notes, description, wall, catalogEntryId } = action.payload;
  try {
    const response = yield call(createFinding, token, description, notes, wall, catalogEntryId);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(createdFinding(json));
  } catch (error) {
    // @ts-ignore
    const json = yield error.response.json();
    yield put(createFindingFailed({ errors: json, values: action.payload }));
  }
}

function* workflowDeleteFiding(action) {
  try {
    const response = yield call(deleteFinding, action.payload);
    checkResponseStatus(response);
    yield put(deletedFinding(action.payload));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    // @ts-ignore
    if (error.response) {
      // @ts-ignore
      yield error.response.json();
    }
  }
}

function* workflowCreateWindow(action) {
  yield put(creatingWindow());
  const { token, material, type, wall, catalogEntryId } = action.payload;

  try {
    const response = yield call(createWindow, token, material, type, wall, catalogEntryId);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(createdWindow(json));
  } catch (error) {
    // @ts-ignore
    if (error.response) {
      // @ts-ignore
      const json = yield error.response.json();
      yield put(createWindowFailed({ errors: json, values: action.payload }));
    }
  }
}

function* workflowDeleteWindow(action) {
  try {
    const response = yield call(deleteWindow, action.payload);
    checkResponseStatus(response);
    yield put(deletedWindow(action.payload));
  } catch (error) {
    // @ts-ignore
    yield error.response.json();
  }
}

// Watchers
export function* watchCreateWall() {
  yield takeLatest(EntryActionType.CREATE_WALL, workflowCreateWall);
}

export function* watchDeleteWall() {
  yield takeLatest(EntryActionType.DELETE_WALL, workflowDeleteWall);
}

export function* watchCreateDoor() {
  yield takeLatest(EntryActionType.CREATE_DOOR, workflowCreateDoor);
}

export function* watchDeleteDoor() {
  yield takeLatest(EntryActionType.DELETE_DOOR, workflowDeleteDoor);
}

export function* watchCreateFinding() {
  yield takeLatest(EntryActionType.CREATE_FINDING, workflowCreateFinding);
}

export function* watchDeleteFinding() {
  yield takeLatest(EntryActionType.DELETE_FINDING, workflowDeleteFiding);
}

export function* watchCreateWindow() {
  yield takeLatest(EntryActionType.CREATE_WINDOW, workflowCreateWindow);
}

export function* watchDeleteWindow() {
  yield takeLatest(EntryActionType.DELETE_WINDOW, workflowDeleteWindow);
}
