import * as React from 'react';

type Props = {
  onReset: () => void;
};

const ResetFilters = (props: Props) => {
  return (
    <div>
      <a href="#" className="reset-filter button inverse" onClick={props.onReset}>
        Filter zurücksetzen
      </a>
    </div>
  );
};

export default ResetFilters;
