import { AuthActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { User } from 'typings/models';
import { createAction } from 'redux-actions';

export const login = createAction<actions.LoginAction>(AuthActionType.LOGIN, (email, password) => ({
  email,
  password,
}));

export const logout = createAction<actions.LogoutAction>(AuthActionType.LOGOUT);
export const loggingIn = createAction<actions.LoggingInAction>(AuthActionType.LOGGING_IN);
export const loggedIn = createAction<actions.LoggedInAction>(AuthActionType.LOGGED_IN);
export const loginFailed = createAction<actions.LoginFailedAction>(AuthActionType.LOGIN_FAILED);
export const loggingOut = createAction<actions.LoggingOutAction>(AuthActionType.LOGGING_OUT);

export const loadUser = createAction<actions.LoadUserAction, number>(
  AuthActionType.LOAD_USER,
  (userId) => ({ userId })
);

export const loadingUser = createAction<actions.LoadingUserAction, number>(
  AuthActionType.LOADING_USER,
  (userId) => ({ userId })
);

export const loadedUser = createAction<actions.LoadedUserAction, User>(AuthActionType.LOADED_USER);

export const loadUserFailed = createAction<actions.LoadUserFailedAction>(
  AuthActionType.LOAD_USER_FAILED
);

export const normalizeUser = createAction<actions.NormalizeUserAction, User>(
  AuthActionType.NORMALIZE_USER
);

export const loadUsers = createAction<actions.LoadUsersAction>(AuthActionType.LOAD_USERS);

export const loadingUsers = createAction<actions.LoadingUsersAction>(AuthActionType.LOADING_USERS);

export const loadedUsers = createAction<actions.LoadedUsersAction, User[]>(
  AuthActionType.LOADED_USERS
);

export const loadUsersFailed = createAction<actions.LoadUsersFailedAction>(
  AuthActionType.LOAD_USERS_FAILED
);

export const normalizeUsers = createAction<actions.NormalizeUsersAction, User[]>(
  AuthActionType.NORMALIZE_USERS
);

export const setToken = createAction<actions.SetTokenAction>(
  AuthActionType.SET_TOKEN,
  (token, user_id) => ({
    token,
    user_id,
  })
);

export const clearToken = createAction<actions.ClearTokenAction>(AuthActionType.CLEAR_TOKEN);

export const searchUser = createAction<actions.SearchUserAction>(AuthActionType.SEARCH_USER);
export const searchingUser = createAction<actions.SearchingUserAction>(
  AuthActionType.SEARCHING_USER
);
export const searchedUser = createAction<actions.SearchedUserAction>(AuthActionType.SEARCHED_USER);
export const searchUserFailed = createAction<actions.SearchUserFailedAction>(
  AuthActionType.SEARCH_USER_FAILED
);

export const refetchUser = createAction<actions.RefetchUserAction, number>(
  AuthActionType.REFETCH_USER,
  (userId) => ({ userId })
);