import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { Link, withRouter } from 'react-router-dom';

import ErrorPage from 'components/ErrorPage';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { LoadRequestState } from 'typings/enums';
import { formatDateReadable } from 'utils/date';
import { Child, Parent } from './Animated';
import { IProjectDashboardProps } from './ProjectDashboard.types';

const ProjectDashboard = ({
  dashboard,
  projectversion,
  onLoadDashboard,
  loadingDashboard,
}: IProjectDashboardProps) => {
  useEffect(() => {
    onLoadDashboard();
  }, []);

  if (loadingDashboard.status === LoadRequestState.LOADING) {
    return <SimpleLoader size="huge" center text="Lade Übersicht..." />;
  }

  return (
    <DocumentTitle title={`Historie: ${projectversion.project.title}`}>
      <ErrorPage
        isError={loadingDashboard.status === LoadRequestState.FAILED}
        onRetry={onLoadDashboard}
      >
        <div className="room-content flex-container vertical">
          <div className="room-title">
            <h2>
              <span>Historie </span>
              <span className="light"> {projectversion.project.title}</span>
            </h2>
          </div>
          <div className="dashboard flex-container horizontal">
            <div className="content-box">
              <div>
                <h4>Zuletzt von mir bearbeitet</h4>
              </div>
              <div style={{ overflow: 'hidden' }}>
                {dashboard.lastOwnEdited.length ? (
                  <Parent initialPose="closed" pose="open">
                    {dashboard.lastOwnEdited.map((entry) => (
                      <Child key={entry.id}>
                        <Link
                          to={`/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/base`}
                        >
                          <span className="dashboard--list-id">{entry.signature}</span>
                          <span className="dashboard--list-entry">{entry.title}</span>
                          <span className="dashboard--list-meta">
                            <span className="dashboard--list-date">
                              {!!entry.history_date && formatDateReadable(entry.history_date, 'de')}
                            </span>
                          </span>
                        </Link>
                      </Child>
                    ))}
                  </Parent>
                ) : (
                  <div>Keine Einträge</div>
                )}
              </div>
            </div>
            <div className="content-box">
              <div>
                <h4>Neue Einträge</h4>
              </div>
              <div>
                <div style={{ overflow: 'hidden' }}>
                  {dashboard.newest.length ? (
                    <Parent initialPose="closed" pose="open">
                      {dashboard.newest.map((entry) => {
                        const assets = entry.assets || [];
                        return (
                          <Child key={entry.id}>
                            <Link
                              to={`/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/base`}
                            >
                              <span className="dashboard--list-id">{entry.signature}</span>
                              <span className="dashboard--list-entry">
                                {assets.length > 0 && <img alt="preview" src={assets[0].small} />}
                                {entry.title}
                              </span>
                              <span className="dashboard--list-meta">
                                <span className="dashboard--list-date">
                                  {!!entry.created_at && formatDateReadable(entry.created_at, 'de')}
                                </span>
                                <span className="dashboard--editor">
                                  {!!entry.user && entry.user.name}
                                </span>
                              </span>
                            </Link>
                          </Child>
                        );
                      })}
                    </Parent>
                  ) : (
                    <div>Keine Einträge</div>
                  )}
                </div>
              </div>
            </div>
            <div className="content-box">
              <div>
                <h4>Zuletzt aktualisiert</h4>
              </div>
              <div>
                <div style={{ overflow: 'hidden' }}>
                  {dashboard.lastEdited.length ? (
                    <Parent initialPose="closed" pose="open">
                      {dashboard.lastEdited.map((entry) => (
                        <Child key={entry.id}>
                          <Link
                            to={`/project/${projectversion.project.id}/version/${projectversion.id}/entry/${entry.id}/base`}
                          >
                            <span className="dashboard--list-id">{entry.signature}</span>
                            <span className="dashboard--list-entry">{entry.title}</span>
                            <span className="dashboard--list-meta">
                              <span className="dashboard--list-date">
                                {!!entry.history_date &&
                                  formatDateReadable(entry.history_date, 'de')}
                              </span>
                              <span className="dashboard--editor">
                                {!!entry.user && entry.user.name}
                              </span>
                            </span>
                          </Link>
                        </Child>
                      ))}
                    </Parent>
                  ) : (
                    <div>Keine Einträge</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ErrorPage>
    </DocumentTitle>
  );
};

export default withRouter(ProjectDashboard);
