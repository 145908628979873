import { handleActions } from 'redux-actions';

import { DashboardActionType, ProjectActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { DashboardState } from 'typings/state';

const initialState: DashboardState = {
  lastEdited: [],
  lastOwnEdited: [],
  newest: [],
};

const dashboardReducer = handleActions<DashboardState, actions.Action>(
  {
    [ProjectActionType.LOADING_PROJECT]: (): DashboardState => {
      return { ...initialState };
    },

    [DashboardActionType.LOADED_DASHBOARD]: (
      state: DashboardState,
      action: actions.LoadedDashboardAction
    ): DashboardState => ({ ...action.payload.values }),
  },
  { ...initialState }
);

export default dashboardReducer;

export const getDashboard = (state: DashboardState) => state;
