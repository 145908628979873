import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

import { SlideInTop } from 'components/Animated';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { LoadRequestState } from 'typings/enums';
import { ISearchEntriesProps } from './SearchEntries.types';
import { Child, Parent } from './Animated';
import { performSearch } from './SearchEntries.util';
import useDebounce from 'utils/hooks';
import { getTruncatedSearchString } from 'utils/strings';

const SearchEntries = (props: ISearchEntriesProps) => {
  const query = props.query ? props.query : '';
  const {
    onExportProjectVersionPdf,
    onSearch,
    onSetText,
    onResetSearch,
    results,
    projectId,
    versionId,
    searchingStatus,
    countEntries,
    countWalls,
    countDepartmentEntries,
  } = props;
  const [isInterrupted, setIsInterrupted] = useState<boolean>(false);
  type operator = 'and' | 'or';
  const [operatorMode, setOperatorMode] = useState<operator>('and');

  useEffect(() => {
    onResetSearch();
    performSearch({ query, operatorMode, onSearch });
    setIsInterrupted(false);
  }, [useDebounce(query, 1000, isInterrupted), operatorMode]);

  return (
    <DocumentTitle title="Suche">
      <SlideInTop initialPose="hidden" pose="visible" className="flex-container vertical">
        <div className="room-title">
          <h2>
            <span>Suche</span>
          </h2>
        </div>
        <div className="room-content search-page">
          <div className="dashboard">
            <div className="content-box" style={{ maxWidth: '100%' }}>
              <div className="flex-container vertical">
                <div className="flex-container vertical" style={{ maxWidth: '40%' }}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsInterrupted(true);
                    }}
                    style={{ paddingLeft: 0, display: 'flex', gap: '12px' }}
                  >
                    <input
                      className="mousetrap"
                      type="text"
                      placeholder="Freitext"
                      value={query}
                      autoFocus={true}
                      onChange={(e) => onSetText(e.currentTarget.value)}
                    />
                    <select
                      defaultValue={operatorMode}
                      onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                        setOperatorMode(e.currentTarget.value as operator)
                      }
                      size={1}
                      style={{ fontSize: '24px', maxWidth: '150px' }}
                    >
                      <option value="and">und</option>
                      <option value="or">oder</option>
                    </select>
                  </form>
                  <div style={{ margin: '-8px 0 20px 4px' }}>
                    <span id="search-help">Hilfe</span>
                    <div id="search-help-hover">
                      <p>
                        <b>Informationen zur Benutzung der Suche:</b>
                      </p>
                      <p>
                        Der Suchumfang schließt alle Felder ein. In der Ergebnisliste erscheinen die
                        Treffer je Eintrag in der Reihenfolge: Basisdaten, Wände, Fachbereiche.
                        Maßgeblich für die Trefferliste sind die Wortanfänge (Präfix-Suche).
                      </p>
                      <p>
                        Es können mehrere, durch Leerzeichen getrennte Suchbegriffe eingegeben
                        werden. Bei der Schreibweise des Anfangsbuchstabens wird Groß- und
                        Kleinschreibung gleichermaßen berücksichtigt.
                      </p>
                      <p>
                        Der Suchverlauf kann durch die Auswahl der sogenannten UND-Suche präzisiert
                        werden, weil dann nur Treffer angezeigt werden, die alle Begriffe enthalten.
                        Die Funktion der ODER-Suche erweitert das Trefferfeld, weil dann auch
                        Treffer angezeigt werden, die nur einen Teil der Suchbegriffe enthalten.
                      </p>
                    </div>
                  </div>
                  <div style={{ marginBottom: '0.3em' }}>
                    {searchingStatus.status === LoadRequestState.LOADED && results.length === 0 ? (
                      <h3>Keine Suchergebnisse</h3>
                    ) : results.length > 0 ? (
                      <h3>
                        {results.length} {results.length === 1 ? 'Suchergebnis' : 'Suchergebnisse'}{' '}
                        gefunden.
                        {(countEntries > 300 || countWalls > 300 || countDepartmentEntries > 300) &&
                          ` (über 300 ${getTruncatedSearchString(
                            countEntries,
                            countWalls,
                            countDepartmentEntries
                          )})`}
                      </h3>
                    ) : (
                      <></>
                    )}
                  </div>
                  {results.length > 0 && (
                    <button
                      className="button export-results-as-pdf"
                      onClick={() => {
                        onExportProjectVersionPdf(
                          versionId,
                          results
                            .filter((result) => result.url.includes('/base'))
                            .map((result) => result.id),
                          results
                            .filter((result) => result.url.includes('/wall'))
                            .map((result) => result.id),
                          results
                            .filter((result) => result.url.includes('/department'))
                            .map((result) => result.id),
                          query
                        );
                      }}
                    >
                      Suchergebnisse als PDF exportieren
                    </button>
                  )}
                </div>
                {searchingStatus.status === LoadRequestState.LOADING && <SimpleLoader size="big" />}
                <Parent
                  className="entry-list"
                  initialPose="closed"
                  pose="open"
                  style={{ overflow: 'hidden' }}
                >
                  {results
                    .sort((a, b) => a.signature.localeCompare(b.signature))
                    .map((result) => {
                      return (
                        <Child key={`search-${result.url}`} initialPose="closed" pose="open">
                          <Link
                            to={`/project/${projectId}/version/${versionId}/entry/${result.url}`}
                          >
                            <div className="flex-container vertical" style={{ padding: '0 10px' }}>
                              <h4>
                                {result.signature} {result.title ? `– ${result.title}` : ''}
                              </h4>
                              {!!result.snippet && (
                                <blockquote dangerouslySetInnerHTML={{ __html: result.snippet }} />
                              )}
                            </div>
                          </Link>
                        </Child>
                      );
                    })}
                </Parent>
              </div>
            </div>
          </div>
        </div>
      </SlideInTop>
    </DocumentTitle>
  );
};

export default SearchEntries;
