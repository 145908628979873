import { AccountActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { createAction } from 'redux-actions';

export const requestingPasswordReset = createAction<actions.RequestingPasswordResetAction, string>(
  AccountActionType.REQUESTING_PASSWORD_RESET,
  (email) => ({ email })
);

export const requestedPasswordReset = createAction<actions.RequestedPasswordResetAction>(
  AccountActionType.REQUESTED_PASSWORD_RESET
);

export const requestPasswordResetFailed = createAction<
  actions.RequestPasswordResetFailedAction,
  any
>(AccountActionType.REQUEST_PASSWORD_RESET_FAILED, (errors?) => ({ errors }));

export const requestPasswordResetReset = createAction<actions.RequestPasswordResetReset>(
  AccountActionType.REQUEST_PASSWORD_RESET_RESET
);

export const settingNewPassword = createAction<actions.SettingNewPasswordAction, string, string>(
  AccountActionType.SETTING_NEW_PASSWORD,
  (password, token) => ({ password, token })
);

export const setNewPassword = createAction<actions.SetNewPasswordAction>(
  AccountActionType.SET_NEW_PASSWORD
);

export const setNewPasswordFailed = createAction<actions.SetNewPasswordFailedAction, any>(
  AccountActionType.SET_NEW_PASSWORD_FAILED,
  (errors?) => ({ errors })
);

export const setNewPasswordReset = createAction<actions.SetNewPasswordResetAction>(
  AccountActionType.SET_NEW_PASSWORD_RESET
);
