import * as React from 'react';

import { Facility } from 'typings/models';

type Props = {
  currentValue: number;
  facilities: Facility[];
  allowEmpty: boolean;
  change: (number) => void;
};

class FacilityLevelSelect extends React.Component<Props> {
  public static defaultProps = {
    allowEmpty: true,
    currentValue: '',
    facilities: [],
  };

  public changeEvent = (e: React.FormEvent<HTMLSelectElement>) =>
    this.props.change(parseInt(e.currentTarget.value, 10));

  public render() {
    const { allowEmpty, currentValue, facilities } = this.props;
    return (
      <select size={1} defaultValue={String(currentValue)} onChange={this.changeEvent}>
        {allowEmpty && <option value="">Alle Einrichtungs-Ebenen</option>}
        {facilities.map((facility) =>
          facility.facility_levels.map((level, levelIndex) => (
            <option value={level.id} key={levelIndex}>
              {facility.title}: {level.title}
            </option>
          ))
        )}
      </select>
    );
  }
}

export default FacilityLevelSelect;
