import { schema } from 'normalizr';
import userSchema from './user_schema';
import projectSchema from './project_schema';
import facilitySchema from './facility_schema';
import catalogSchema from './catalog_schema';
import fieldConfigurationSchema from './field_configuration_schema';

const projectVersionMemberSchema = new schema.Entity('projectmember', { user: userSchema });

const projectVersion = new schema.Entity('projectversions', {
  members: [projectVersionMemberSchema],
  project: projectSchema,
  facilities: [facilitySchema],
  field_configurations: [fieldConfigurationSchema],
  catalogs: [catalogSchema],
});

export default projectVersion;
