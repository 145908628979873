import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as projectActions from 'actions/ProjectActions';
import ProjectWrapper from 'components/ProjectWrapper';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'projectId',
    'versionId',
  ]);
  return {
    loadingProject: state.loadingStates.loading_projectversion,
    projectversion: selectors.getProjectVersion(state, versionId),
    projectId,
    versionId,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['versionId']);

  return {
    onLoadProject: () => dispatch(projectActions.loadProject(versionId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectWrapper));
