import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import Help from 'components/Help';
import NavBarAnonymous from 'components/NavBarAnonymous/NavBarAnonymous';
import Privacy from 'components/Privacy';
import PrivateRoute from 'components/PrivateRoute';
import AppContainer from 'containers/AppContainer';
import DepartmentDetailsContainer from 'containers/DepartmentDetailsContainer';
import EntryBaseContainer from 'containers/EntryBaseContainer';
import EntryDetailsContainer from 'containers/EntryDetailsContainer';
import ImportFloormapContainer from 'containers/ImportFloormapContainer';
import LoginFormContainer from 'containers/LoginFormContainer';
import LogoutContainer from 'containers/LogoutContainer';
import NavBarContainer from 'containers/NavBarContainer';
import ProjectDashboardContainer from 'containers/ProjectDashboardContainer';
import ProjectFrameContainer from 'containers/ProjectFrameContainer';
import ProjectSettingsContainer from 'containers/ProjectSettingsContainer';
import ProjectWrapperContainer from 'containers/ProjectWrapperContainer';
import SearchEntriesContainer from 'containers/SearchEntriesContainer';
import UserDashboardContainer from 'containers/UserDashboardContainer';
import WallDetailsContainer from 'containers/WallDetailsContainer';
import ResetPasswordFormContainer from 'containers/ResetPasswordFormContainer';
import NewPasswordFormContainer from 'containers/NewPasswordFormContainer';
import CopyProjectContainer from 'containers/CopyProjectContainer';
import CatalogListContainer from 'containers/CatalogListContainer';
import CatalogDetailsContainer from 'containers/CatalogDetailsContainer';
import CatalogEntryDetailsContainer from 'containers/CatalogEntryDetailsContainer';

type Props = RouteComponentProps<any> & {
  token: string;
  location: any;
};

const Routes: React.FunctionComponent<Props> = (props: Props) => {
  const { token } = props;
  const isAuthenticated = !!token;

  return (
    <AppContainer>
      {!isAuthenticated && <NavBarAnonymous />}
      <Route path="/dashboard" component={NavBarContainer} />
      <Route path="/project/:projectId/version/:versionId" component={NavBarContainer} />
      <Switch>
        <Route path="/privacy" component={Privacy} />
        <Route path="/login" component={LoginFormContainer} />
        <Route path="/help" component={Help} />
        <Route exact path="/reset-password" component={ResetPasswordFormContainer} />
        <Route exact path="/new-password" component={NewPasswordFormContainer} />

        <Route path="/project/:projectId/version/:versionId">
          <ProjectWrapperContainer>
            <Switch>
              <PrivateRoute
                path="/project/:projectId/version/:versionId/copy/"
                component={CopyProjectContainer}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/project/:projectId/version/:versionId/import-floormap/"
                component={ImportFloormapContainer}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/project/:projectId/version/:versionId/:action?/:entryId?"
                isAuthenticated={isAuthenticated}
              >
                <ProjectFrameContainer>
                  <Switch>
                    <Route
                      exact
                      path="/project/:projectId/version/:versionId"
                      component={ProjectSettingsContainer}
                    />
                    <PrivateRoute
                      path="/project/:projectId/version/:versionId/catalogs"
                      isAuthenticated={isAuthenticated}
                      component={CatalogListContainer}
                    />
                    <PrivateRoute
                      path="/project/:projectId/version/:versionId/catalog/:catalogId/catalogEntry/:catalogEntryId"
                      isAuthenticated={isAuthenticated}
                      component={CatalogEntryDetailsContainer}
                    />
                    <PrivateRoute
                      path="/project/:projectId/version/:versionId/catalog/:catalogId"
                      isAuthenticated={isAuthenticated}
                      component={CatalogDetailsContainer}
                    />
                    <PrivateRoute
                      path="/project/:projectId/version/:versionId/history"
                      component={ProjectDashboardContainer}
                      isAuthenticated={isAuthenticated}
                    />
                    <PrivateRoute
                      path="/project/:projectId/version/:versionId/search/:query?"
                      component={SearchEntriesContainer}
                      isAuthenticated={isAuthenticated}
                    />

                    <PrivateRoute
                      path="/project/:projectId/version/:versionId/entry/:entryId"
                      isAuthenticated={isAuthenticated}
                      render={({ location }) => (
                        <EntryDetailsContainer>
                          <Switch location={location}>
                            <PrivateRoute
                              path="/project/:projectId/version/:versionId/entry/:entryId/department/:departmentId/"
                              isAuthenticated={isAuthenticated}
                              component={DepartmentDetailsContainer}
                            />
                            <PrivateRoute
                              path="/project/:projectId/version/:versionId/entry/:entryId/wall/:wallId"
                              isAuthenticated={isAuthenticated}
                              component={WallDetailsContainer}
                            />
                            <PrivateRoute
                              path="/project/:projectId/version/:versionId/entry/:entryId"
                              isAuthenticated={isAuthenticated}
                              component={EntryBaseContainer}
                            />
                          </Switch>
                        </EntryDetailsContainer>
                      )}
                    />
                  </Switch>
                </ProjectFrameContainer>
              </PrivateRoute>
            </Switch>
          </ProjectWrapperContainer>
        </Route>
        <PrivateRoute
          path="/logout"
          component={LogoutContainer}
          isAuthenticated={isAuthenticated}
        />

        <Redirect exact from="/" to="/dashboard" />
        <PrivateRoute
          path="/dashboard"
          component={UserDashboardContainer}
          isAuthenticated={isAuthenticated}
        />
      </Switch>
    </AppContainer>
  );
};

Routes.defaultProps = {
  token: '',
};

export default withRouter(Routes);
