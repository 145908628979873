import React from 'react';
import { IHeadlineProps } from './Headline.types';

const Headline = ({
  title,
  subtitle,
  metaText,
  onMoveDepartmentEntryUp,
  onMoveDepartmentEntryDown,
}: IHeadlineProps) => {
  return (
    <div key={title} className="headline">
      <h3>
        {title} {subtitle}
      </h3>
      <div className="metaText">
        <p style={{ paddingRight: '1rem' }}>{metaText}</p>
        {onMoveDepartmentEntryUp && <button onClick={onMoveDepartmentEntryUp}>↑</button>}
        {onMoveDepartmentEntryDown && <button onClick={onMoveDepartmentEntryDown}>↓</button>}
      </div>
    </div>
  );
};

export default Headline;
