import Root from 'components/Root/Root';
import { getToken } from 'reducers/app';
import { State } from 'typings/state';
import { connect } from 'react-redux';

const mapStateToProps = (state: State) => ({
  token: getToken(state),
});

export const RootContainer = connect(mapStateToProps)(Root);
