import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import ReactModal from 'react-modal';
import ModalContainer from 'components/ModalContainer';
import { ModalType } from 'typings/enums';
import { IAppProps } from './App.types';
import { closeModal, usePrevious } from './App.util';
import { ToastContainer } from 'react-toastify';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#content');
}

const App = (props: IAppProps) => {
  const { modal, children } = props;
  const { title = '', content = '', type = ModalType.INFO } = modal || {};
  const {
    userId,
    onLoadPrivacyNoteVisible,
    onHideModal,
    onLoadUser,
    modal: { onClose = null } = {},
  } = props;

  const [token, setToken] = useState<string>(props.token);
  const previousToken = usePrevious(token);

  useEffect(() => {
    onLoadPrivacyNoteVisible();
  }, [onLoadPrivacyNoteVisible, userId]);

  useEffect(() => {
    if (previousToken !== token) {
      !!userId && onLoadUser(userId);
    }
  }, [token, userId, onLoadUser]);

  useEffect(() => {
    setToken(props.token);
  }, [props.token]);

  return (
    <DocumentTitle title="raumbuch.digital">
      <div className="app-container flex-container vertical">
        <ModalContainer
          closeModal={() => closeModal({ onClose, onHideModal })}
          content={content}
          isOpen={!!modal}
          title={title}
          type={type}
        />
        {children}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnHover
          draggable
        />
      </div>
    </DocumentTitle>
  );
};

export default App;
