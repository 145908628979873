import React, { useEffect, useState } from 'react';

import ErrorPanel from 'components/Error/Error';
import IdTitleSelect from 'components/IdTitleSelect';
import { ICreateEntryProps } from './CreateEntry.types';

const CreateEntry = ({
  projectVersionId,
  entryTypes,
  creatingEntry,
  onCreate,
}: ICreateEntryProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [entryType, setEntryType] = useState<string>('');
  const [signature, setSignature] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (creatingEntry.status === 'SAVED') {
      setIsEditing(false);
      setSignature('');
      setEntryType('');
      setTitle('');
    }
  }, [creatingEntry.status]);

  if (isEditing) {
    return (
      <div className="create-room shadow">
        {creatingEntry.status === 'FAILED' &&
          creatingEntry.messages &&
          !!creatingEntry.messages.entryType && (
            <ErrorPanel message={creatingEntry.messages.entryType} />
          )}
        <IdTitleSelect
          allowEmpty
          options={entryTypes}
          onSelect={(e) => setEntryType(e.currentTarget.value)}
        />
        <br />
        <input placeholder="Titel" type="text" onChange={(e) => setTitle(e.currentTarget.value)} />
        <input
          placeholder="Signatur"
          type="text"
          onChange={(e) => setSignature(e.currentTarget.value)}
        />
        <br />
        <button
          className="primary"
          onClick={() => onCreate(signature, entryType, title)}
          disabled={!entryType}
        >
          OK
        </button>
        <button className="secondary" onClick={() => setIsEditing(false)}>
          Abbrechen
        </button>
      </div>
    );
  }
  return (
    <button className="primary add-button" id="create_entry" onClick={() => setIsEditing(true)}>
      Eintrag hinzufügen
    </button>
  );
};

export default CreateEntry;
