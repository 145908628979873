import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as authActions from 'actions/AuthActions';
import * as projectActions from 'actions/ProjectActions';
import * as guiActions from 'actions/GuiActions';
import CopyProject from 'components/CopyProject/CopyProject';
import * as selectors from 'reducers/app';
import { Action, CopyProjectActionMembers } from 'typings/actions';
import { getIntsFromParams } from 'utils/objects';
import { push } from 'connected-react-router';
import { ModalType } from 'typings/enums';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state, ownProps: Props) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['versionId']);

  return {
    projectVersionId: versionId,
    isSearchingUsers: selectors.isSearchingUsers(state),
    ownUser: selectors.getUser(state),
    userSearchResult: selectors.getUserSearchResult(state),
    users: selectors.getUsers(state),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { versionId } = getIntsFromParams(ownProps.match.params, ['projectId', 'versionId']);

  return {
    onCopy: (title: string, members: CopyProjectActionMembers[]) =>
      dispatch(projectActions.copyProjectVersion(versionId, title, members)),
    onSearchUser: (query: string) => dispatch(authActions.searchUser(query)),
    onReturnToDashboard: () => dispatch(push('/dashboard')),
    onShowModal: (type: ModalType, title: string, text: any) =>
      dispatch(guiActions.showModal(type, text, title)),
    onHideModal: () => dispatch(guiActions.hideModal()),
    onGetUsers: () => dispatch(authActions.loadUsers()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CopyProject));
