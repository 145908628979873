import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as selectors from 'reducers/app';
import * as searchActions from 'actions/SearchActions';
import * as projectActions from 'actions/ProjectActions';
import * as guiActions from 'actions/GuiActions';
import ExportProjectOptions from 'components/ExportProjectOptions';
import { Action, ExportProjectActionOptions } from 'typings/actions';
import { State } from 'typings/state';
import { getSearchQueryFromURL, getProjectVersionIdFromURL } from 'utils/url';

type Props = {
  location: { pathname: string };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const query = getSearchQueryFromURL(ownProps.location.pathname);
  const versionId = getProjectVersionIdFromURL(ownProps.location.pathname);
  return {
    entries: selectors.getEntries(state, versionId ?? undefined, false),
    facilities: selectors.getFacilities(state, versionId || 0),
    versionId,
    query,
    results: selectors.getFilteredFulltextResults(state),
    countEntries: state.entries.search_results.count_entries,
    countWalls: state.entries.search_results.count_walls,
    countDepartmentEntries: state.entries.search_results.count_department_entries,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const versionId = getProjectVersionIdFromURL(ownProps.location.pathname);

  return {
    onCloseModal: () => dispatch(guiActions.hideModal()),
    onExportProjectVersionPdf: (projectversion, ids, exportOptions: ExportProjectActionOptions) =>
      dispatch(projectActions.exportProjectVersionPdf(projectversion, ids, exportOptions)),
    onResetSearch: () => dispatch(searchActions.resetSearchEntries()),
    onSearch: (query: string) => dispatch(searchActions.searchEntries(versionId, query, "and")),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportProjectOptions));
