// Core libs
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/ErrorBoundary';
import { PersistGate } from 'redux-persist/integration/react';

import { RootContainer } from './containers/RootContainer';
import { configureStore } from './store';

import './sass/main.scss';

export const { store, persistor } = configureStore();

const container = document.getElementById('content');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <PersistGate loading={<span>One moment...</span>} persistor={persistor}>
        <RootContainer />
      </PersistGate>
    </ErrorBoundary>
  </Provider>
);
