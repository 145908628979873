enum AssetActionType {
  UPLOAD_ASSETS = 'assets/UPLOAD_ASSETS',
  UPLOAD_ASSET = 'assets/UPLOAD_ASSET',
  UPLOADING_ASSET = 'assets/UPLOADING_ASSET',
  UPLOADED_ASSET = 'assets/UPLOADED_ASSET',
  UPLOAD_ASSET_FAILED = 'assets/UPLOAD_ASSET_FAILED',
  DELETE_ASSET = 'assets/DELETE_ASSET',
  DELETING_ASSET = 'assets/DELETING_ASSET',
  DELETED_ASSET = 'assets/DELETED_ASSET',
  RESET_ASSET_UPLOAD = 'assets/RESET_ASSET_UPLOAD',
  MOVE_ASSET = 'assets/MOVE_ASSET',
  MOVING_ASSET = 'assets/MOVING_ASSET',
  MOVED_ASSET = 'assets/MOVED_ASSET',
  MOVE_ASSET_FAILED = 'assets/MOVE_ASSET_FAILED',
  MOVE_ASSET_TO_ENTITY = 'assets/MOVE_ASSET_TO_ENTITY',
  MOVING_ASSET_TO_ENTITY = 'assets/MOVING_ASSET_TO_ENTITY',
  MOVED_ASSET_TO_ENTITY = 'assets/MOVED_ASSET_TO_ENTITY',
  MOVE_ASSET_TO_ENTITY_FAILED = 'assets/MOVE_ASSET_TO_ENTITY_FAILED',
  MARK_ASSET_TO_MOVE = 'assets/MARK_ASSET_TO_MOVE',
}

export default AssetActionType;
