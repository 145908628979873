interface IResponseError {
  response: any;
}

class ResponseError extends Error implements IResponseError {
  public response: any;
}

export const checkResponseStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new ResponseError();
  error.response = response;
  throw error;
};
