import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

type Props = RouteComponentProps<any> & {
  visible: boolean;
  onDismiss: () => void;
};

const PrivacyNote = (props: Props) => {
  const { onDismiss, visible } = props;
  return visible ? (
    <div className="privacy-hint">
      Das raumbuch.digital verwendet Cookies. Durch die Nutzung erklären Sie sich damit
      einverstanden.&nbsp;
      <a
        href="#"
        onClick={(e) => {
          onDismiss();
          e.preventDefault();
        }}
      >
        Ich bin einverstanden
      </a>
      &nbsp; |&nbsp;
      <Link to="/privacy">Datenschutzerklärung</Link>
    </div>
  ) : null;
};
export default withRouter(PrivacyNote);
