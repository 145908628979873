import { MutableRefObject, useEffect } from 'react';
import L from 'leaflet';
import { isSafari } from 'utils/device';
import { EntryShort } from 'typings/models';


export function useMountMap(mapRef: MutableRefObject<L.Map | null>, id: string, layer: L.TileLayer, type: "SMALL"|"LARGE", setMapInstance: React.Dispatch<React.SetStateAction<L.Map | null>>) {
  useEffect(() => {
    mapRef.current = L.map(id, {
      attributionControl: false,
      zoomControl: false,
      layers: [layer], 
      zoom: type === "SMALL" ? 1 : 2,
      zoomSnap: 0.04,
      center: [-128, 128],
      crs: L.CRS.Simple,
      renderer: isSafari(window.navigator.userAgent) ? L.svg() : L.canvas(),
    });
    
    if (type === "LARGE"){
      L.control.zoom({ position: 'topright' }).addTo(mapRef.current!!);
      L.control.attribution({ prefix: false }).addTo(mapRef.current!!);
    }

    mapRef.current.keyboard.disable();

    setMapInstance(mapRef.current)

    return () => {
      setMapInstance(null);
      mapRef.current?.remove();
    }
  }, []);
}

function getRadiusByZoom(mapInstance: L.Map | null){
  if (!mapInstance) {
    return 4
  }
  return mapInstance.getZoom() >= 3 ? 8 * mapInstance.getZoom() : 4 * mapInstance.getZoom(); 
}

function getStateAndColor(featureId: number, entryId?: number) {
  const active = featureId === entryId || false
  const color = active ? '#fd6a6a' : 'lightGrey';
  return {active, color}
}

export function usePopulateShapes(
  mapInstance: L.Map | null,
  shapeRef: MutableRefObject<Map<number, L.Polygon | L.CircleMarker>>,
  clickFunction: (number) => void,
  setPolygons: React.Dispatch<React.SetStateAction<Map<number, L.Polygon>>>,
  setPoints: React.Dispatch<React.SetStateAction<Map<number, L.CircleMarker>>>,
  geoJson?: any,
  entryId?: number,
  alwaysActive?: boolean,
) {
  useEffect(() => {
    geoJson?.features?.forEach((feature: any) => {
      const { active, color } = getStateAndColor(feature.properties.id, entryId);
      const isPolygon = feature.geometry.type === 'Polygon'
      const coordinates =
        isPolygon
          ? feature.geometry.coordinates[0].map((c: any[]) => [c[1], c[0]])
          : [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
      const baseRadius = getRadiusByZoom(mapInstance);
      const shape =
        isPolygon
          ? L.polygon(coordinates, {
              fillOpacity: 0.2,
              stroke: false,
              fillColor: color,
              color,
            })
          : L.circleMarker(coordinates, {
              radius: baseRadius,
              fillOpacity: 1,
              stroke: false,
              fillColor: color,
              color,
            });
      
      shape.addEventListener('click', () => {
        (!active || alwaysActive) && clickFunction(feature.properties.id);
      });
      shapeRef.current.set(feature.properties.id, shape);
      isPolygon ? setPolygons(p => p.set(feature.properties.id, shape as L.Polygon)) : setPoints(p => p.set(feature.properties.id, shape as L.CircleMarker))
    });
    return () => {
      setPolygons(new Map())
      setPoints(new Map())
      shapeRef.current = new Map()
    };
  }, [geoJson]);
}

export function useAddShapesToMap(
  mapInstance: L.Map | null,
  polygons: Map<number, L.Polygon>,
  points: Map<number, L.CircleMarker>,
  ) {
  useEffect(() => {
    const featureGroup = L.featureGroup();
    if (mapInstance) {
      polygons.forEach(shape => {
        shape.addTo(featureGroup)
      })
      points.forEach(shape => {
        shape.addTo(featureGroup)
      })
      featureGroup.addTo(mapInstance)
    }
    return () => {
      if (mapInstance?.hasLayer(featureGroup)) {
        mapInstance?.removeLayer(featureGroup)
      }
    };
  }, [mapInstance, polygons, points]);
}

export function useHandleActiveItem(
  mapInstance: L.Map | null,
  polygons: Map<number, L.Polygon>,
  points: Map<number, L.CircleMarker>,
  activeCircleRef: MutableRefObject<[number, L.CircleMarker] | null>,
  setActiveCircle: React.Dispatch<React.SetStateAction<[number, L.CircleMarker] | null>>,
  entryId?: number,
){
  useEffect(() => {
    if (entryId) {
      const activeShape = polygons.get(entryId) ? polygons.get(entryId) : points.get(entryId);
      if (activeShape && activeShape instanceof L.Polygon && mapInstance?.hasLayer(activeShape)) {
        const { color } = getStateAndColor(entryId, entryId);
        const centerCoords = activeShape.getCenter();
        const centerCircle = L.circleMarker(centerCoords, {
          radius: getRadiusByZoom(mapInstance),
          fillOpacity: 1,
          fillColor: color,
          stroke: false,
        });

        activeCircleRef.current = [entryId, centerCircle];
        setActiveCircle(activeCircleRef.current)
      }
    }
    return () => {
      setActiveCircle(null)
      activeCircleRef.current = null
    }
  }, [mapInstance, polygons, points, entryId]);
}

export function useCreateCenterCircles(
  mapInstance: L.Map | null,
  polygons: Map<number, L.Polygon>,
  circleRef: MutableRefObject<Map<number, L.CircleMarker>>,
  setCircles: React.Dispatch<React.SetStateAction<Map<number, L.CircleMarker>>>,
  clickFunction: (number) => void,
  entryId?: number,
){
  useEffect(() => {
    polygons.forEach((polygon, key) => {
      if (key === entryId || !mapInstance?.hasLayer(polygon)) {
        return
      }
      const { color } = getStateAndColor(key, entryId);
      const centerCircle = L.circleMarker(polygon.getCenter(), {
        radius: getRadiusByZoom(mapInstance),
        fillOpacity: 1,
        fillColor: color,
        stroke: false,
      });
      centerCircle.addEventListener('click', () => {
        clickFunction(key);
      });

      circleRef.current.set(key, centerCircle);
      setCircles(circleRef.current)
    })
    return () => {
      setCircles(new Map())
      circleRef.current = new Map()
    }
  }, [mapInstance, polygons, entryId]);
}

export function useAddCenterCirclesToMap(
  mapInstance: L.Map | null,
  circles: Map<number, L.CircleMarker>,
  activeCircle: [number, L.CircleMarker] | null,
  ) {
  useEffect(() => {
    const featureGroup = L.featureGroup();
    mapInstance &&
    circles.forEach(circle => {
      circle.addTo(featureGroup);
    })
    activeCircle?.[1].addTo(featureGroup);
    mapInstance && featureGroup.addTo(mapInstance)
    featureGroup.setZIndex(700);
    return () => {
      mapInstance?.removeLayer(featureGroup)
    };
  }, [mapInstance, circles, activeCircle]);
}

export function useAddTooltips(
  points: Map<number, L.CircleMarker>,
  circles: Map<number, L.CircleMarker>,
  activeCircle: [number, L.CircleMarker] | null,
  tooltipRef: MutableRefObject<Map<number, L.Tooltip>>,
  setTooltips: React.Dispatch<React.SetStateAction<Map<number, L.Tooltip>>>,
  entries?: EntryShort[],
) {
  useEffect(() => {
    const allPoints = new Map([...points, ...circles])
    activeCircle && allPoints.set(activeCircle?.[0], activeCircle?.[1])

    allPoints.forEach((point, key) => {
      const tooltip = L.tooltip(
        {
          permanent: true,
          className: `circle-tooltip`,
          interactive: true,
          direction: 'center',
          offset: [0, 0],
        }
      ).setContent(
        entries?.find((e) => e.id === key)?.signature || '',
      )

      tooltipRef.current.set(key, tooltip)
    })
    setTooltips(tooltipRef.current)
    return () => {
      tooltipRef.current = new Map()
      setTooltips(new Map())
    };
  }, [points, circles, activeCircle, entries])
}

export function useAddHoverEvents(
  polygons: Map<number, L.Polygon>,
  points: Map<number, L.CircleMarker>,
  circles: Map<number, L.CircleMarker>,
  activeCircle: [number, L.CircleMarker] | null,
  entryId?: number,
  entries?: EntryShort[],
  hoverFunction?: (string) => void,
){
  useEffect(() => {
    const allPoints = new Map([...points, ...circles])
    activeCircle && allPoints.set(activeCircle?.[0], activeCircle?.[1])

    polygons.forEach((shape, key) => {
      shape.addEventListener('mouseover', () => {
        shape.setStyle({ fillOpacity: 0.3 });
        hoverFunction && hoverFunction(entries?.find((e) => e.id === key)?.signature || '');
      })
      shape.addEventListener('mouseout', () => {
        shape.setStyle({ fillOpacity: 0.2 });
        hoverFunction && hoverFunction('');
      })
    })
    allPoints.forEach((shape, key) => {
      if (key === entryId) {
        return
      }
      shape.addEventListener('mouseover', () => {
        polygons.get(key)?.setStyle({ fillOpacity: 0.3 });
        hoverFunction && hoverFunction(entries?.find((e) => e.id === key)?.signature || '');
      })
      shape.addEventListener('mouseout', () => {
        hoverFunction && hoverFunction('');
      })
    })
    activeCircle?.[1].addEventListener('mouseover', () => {
      polygons.get(activeCircle[0])?.setStyle({ fillOpacity: 0.3 });
      hoverFunction && hoverFunction(entries?.find((e) => e.id === activeCircle[0])?.signature || '');
    })
    activeCircle?.[1].addEventListener('mouseout', () => {
      hoverFunction && hoverFunction('');
    })
    return () => {
      polygons.forEach(p => p.removeEventListener("mouseover"))
      polygons.forEach(p => p.removeEventListener("mouseout"))
      allPoints.forEach(p => p.removeEventListener("mouseover"))
      allPoints.forEach(p => p.removeEventListener("mouseout"))
      activeCircle?.[1].removeEventListener("mouseover")
      activeCircle?.[1].removeEventListener("mouseout")
    };
  }, [polygons, circles, entryId, entries, activeCircle]);
}

const setCircleTooltips = (
  mapInstance: L.Map, 
  points: Map<number, L.CircleMarker>,
  tooltips: Map<number, L.Tooltip>,
  entryId?: number,
) => {
  if (mapInstance.getZoom() >= 3) {
    points.forEach((shape, key) => {
      if (!mapInstance.getBounds().contains(shape.getLatLng())){
        return
      }
      shape.getTooltip() === undefined ?
      shape.bindTooltip(tooltips.get(key)!!).openTooltip() :
      !shape.isTooltipOpen() && shape.openTooltip();
    })
  } else {
    points.forEach((shape, key) => {
      shape.getTooltip() && shape.isTooltipOpen() && shape.closeTooltip()
    })
  }
  const newRadius = getRadiusByZoom(mapInstance);
  points.get(entryId!!)?.bringToFront().setRadius(newRadius)
}

export function useAddTooltipsOnZoom(
  mapInstance: L.Map | null,
  points: Map<number, L.CircleMarker>,
  circles: Map<number, L.CircleMarker>,
  activeCircle: [number, L.CircleMarker] | null,
  tooltips: Map<number, L.Tooltip>,
  entryId?: number,
){
  useEffect(() => {
    if (mapInstance) {
      const allPoints = new Map([...points, ...circles])
      activeCircle && allPoints.set(activeCircle[0], activeCircle[1])
      
      setCircleTooltips(mapInstance, allPoints, tooltips, entryId);
      mapInstance.addEventListener('move', () => {
        setCircleTooltips(mapInstance, allPoints, tooltips, entryId);
        const newRadius = getRadiusByZoom(mapInstance);
        allPoints.forEach((feature) => {
          return feature.setRadius(newRadius)
        });
      })
      mapInstance.addEventListener('keypress', (e) => {
        if (e.originalEvent.key === "+") {
          mapInstance.zoomIn()
        }
        if (e.originalEvent.key === "-") {
          mapInstance.zoomOut()
        }
      });
    }
    return () => {
      mapInstance?.removeEventListener('move'); 
      mapInstance?.removeEventListener('keypress'); 
    }
  }, [mapInstance, points, circles, activeCircle, tooltips]);
}

export function useAddEventListeners(
  polygons: Map<number, L.Polygon>,
  tooltips: Map<number, L.Tooltip>,
  clickFunction: (number) => void,
  hoverFunction: (string) => void,
  entries?: EntryShort[],
  entryId?: number,
  alwaysActive?: boolean,
){
  useEffect(() => {
    tooltips.forEach((tooltip, key) => {
      tooltip.addEventListener("click", () => {
        if (key === entryId && !alwaysActive) {
          return
        }
        clickFunction(key)
      })
      tooltip.addEventListener('mouseover', () => {
        hoverFunction(entries?.find((e) => e.id === key)?.signature || '');
        polygons.get(key)?.setStyle({ fillOpacity: 0.3 });
      })
      tooltip.addEventListener('mouseout', () => {
        hoverFunction('');
        polygons.get(key)?.setStyle({ fillOpacity: 0.2 });
      })
    })
    return () => {
      tooltips.forEach((tooltip) => {
        tooltip.removeEventListener("click")
        tooltip.removeEventListener("mouseover")
        tooltip.removeEventListener("mouseout")
      })
    }
  }, [entries, polygons, tooltips]);
}