import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import React, { createRef } from 'react';
import { UserShort } from 'typings/models';
import { IUserSelectProps } from './UserSelect.types';

const UserSelect = (props: IUserSelectProps) => {
  const ref = createRef<any>();
  const inputRef = createRef<any>();

  const performSearch = debounce((query: string) => {
    props.onSearch(query);
  }, 300);

  const selectUser = (user: UserShort) => {
    props.onSelectUser(user);
  };

  const itemToString = (item: UserShort): string =>
    item.email
      ? `${item.last_name}, ${item.first_name} – ${item.email}`
      : `${item.last_name}, ${item.first_name}`;

  const { users } = props;

  return (
    <Downshift
      ref={ref}
      onSelect={selectUser}
      onInputValueChange={performSearch}
      itemToString={(item) => (item ? itemToString(item) : '')}
    >
      {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => (
        <div className="autocomplete">
          <input className="mousetrap" type="text" ref={inputRef} {...getInputProps()} />
          <ul className="dropdown" {...getMenuProps()}>
            {isOpen
              ? users.map((item, index) => (
                  <li
                    key={index}
                    className={highlightedIndex === index ? 'highlighted' : ''}
                    {...getItemProps({
                      index,
                      item,
                      key: item.id,
                    })}
                  >
                    {itemToString(item)}
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

export default UserSelect;
