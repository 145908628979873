import Cookies from 'js-cookie';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { GuiActionType } from 'actions/actionTypes';
import { setPrivacyNoteVisible } from 'actions/GuiActions';
import { toast } from 'react-toastify';

export function* workflowLoadPrivacyNoteVisible() {
  const isVisible = !Cookies.get('privacyNoteAccepted');
  yield put(setPrivacyNoteVisible(isVisible));
}

export function* workflowDismissPrivacyNote() {
  Cookies.set('privacyNoteAccepted', true, { expires: 365 });
  yield put(setPrivacyNoteVisible(false));
}

export function* workflowShowToast(action) {
  const { message, options } = action.payload;
  yield call(toast, message, options);
}

export function* watchLoadPrivacyNoteVisible() {
  yield takeLatest(GuiActionType.LOAD_PRIVACY_NOTE_VISIBLE, workflowLoadPrivacyNoteVisible);
}

export function* watchDismissPrivacyNote() {
  yield takeLatest(GuiActionType.DISMISS_PRIVACY_NOTE, workflowDismissPrivacyNote);
}

export function* watchShowToast() {
  yield takeEvery(GuiActionType.SHOW_TOAST, workflowShowToast);
}
