enum AccountActionType {
  REQUESTING_PASSWORD_RESET = 'account/REQUESTING_PASSWORD_RESET',
  REQUESTED_PASSWORD_RESET = 'account/REQUESTED_PASSWORD_RESET',
  REQUEST_PASSWORD_RESET_FAILED = 'account/REQUEST_PASSWORD_RESET_FAILED',
  REQUEST_PASSWORD_RESET_RESET = 'account/REQUEST_PASSWORD_RESET_RESET',

  SETTING_NEW_PASSWORD = 'account/SETTING_NEW_PASSWORD',
  SET_NEW_PASSWORD = 'account/SET_NEW_PASSWORD',
  SET_NEW_PASSWORD_FAILED = 'account/SET_NEW_PASSWORD_FAILED',
  SET_NEW_PASSWORD_RESET = 'account/SET_NEW_PASSWORD_RESET',
}

export default AccountActionType;
