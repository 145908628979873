import * as React from 'react';

import { formatDateReadable } from 'utils/date';
import InlineEditBaseWidget, { BaseProps } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: string | null;
};

class InlineEditDateWidget extends InlineEditBaseWidget<Props> {
  public refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;

  public getValueReadable = () =>
    this.props.value ? formatDateReadable(this.props.value, 'de') : '';

  public getEditWidget = () => {
    const { editing_entity_field } = this.props;
    const value = this.getValueReadable();
    const defaultValue =
      editing_entity_field && editing_entity_field.value
        ? String(editing_entity_field.value)
        : value;

    return (
      <span>
        <input
          ref={this.refElement}
          type="date"
          data-original={this.props.value}
          defaultValue={defaultValue}
          onKeyDown={this.handleKeyDown}
        />{' '}
        {this.props.suffix}
      </span>
    );
  };
}

export default InlineEditDateWidget;
