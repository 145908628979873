import * as React from 'react';

import Logo from 'components/Logo';

const NavBarAnonymous = () => {
  return (
    <nav className="head shadow">
      <Logo to={'/'} />
      <div className="navbar" />
    </nav>
  );
};

export default NavBarAnonymous;
