import omit from 'lodash/omit';
import { handleActions } from 'redux-actions';

import { EntryActionType, GuiActionType, ProjectActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { Modal } from 'typings/models';
import { GuiState } from 'typings/state';

const initialState: GuiState = {
  show_notifications: false,
};

const settingsReducer = handleActions<GuiState, actions.Action>(
  {
    [GuiActionType.SHOW_MODAL]: (state: GuiState, action: actions.ShowModalAction): GuiState => ({
      ...state,
      modal: action.payload,
      show_notifications: false,
    }),

    [GuiActionType.HIDE_MODAL]: (state: GuiState): GuiState => omit(state, 'modal'),
    [GuiActionType.UPDATE_MODAL_TITLE]: (
      state: GuiState,
      action: actions.UpdateModalTitleAction
    ): GuiState =>
      state.modal ? { ...state, modal: { ...state.modal, title: action.payload.title } } : state,

    [GuiActionType.SHOW_FLYOUT]: (state: GuiState, action: actions.ShowFlyoutAction): GuiState => ({
      ...state,
      flyout: action.payload,
      show_notifications: false,
    }),

    [GuiActionType.HIDE_FLYOUT]: (state: GuiState): GuiState => omit(state, 'flyout'),
    [ProjectActionType.LOAD_PROJECT]: (state: GuiState): GuiState => omit(state, 'flyout'),
    [EntryActionType.LOAD_ENTRY]: (state: GuiState): GuiState => omit(state, 'flyout'),

    [GuiActionType.TOGGLE_NOTIFICATIONS]: (state: GuiState): GuiState => ({
      ...state,
      show_notifications: !state.show_notifications,
    }),
  },
  { ...initialState }
);

export default settingsReducer;

export const getModal = (state: GuiState): Modal | undefined => state.modal;
export const getFlyout = (state: GuiState) => state.flyout;
export const getNotificationsShown = (state: GuiState) => state.show_notifications;
