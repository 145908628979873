export const utcToDate = (utc: string) => new Date(utc);

export const formatDateReadable = (date: Date | string | null, locale = 'de'): string => {
  const obj = typeof date === 'string' ? utcToDate(date) : date;

  if (obj === null || isNaN(obj.getDate())) {
    return (date as string) ?? '';
  }

  const formatted =
    obj &&
    obj.toLocaleDateString(locale, {
      day: '2-digit',
      month: '2-digit',
      timeZone: 'UTC',
      year: 'numeric',
    });

  return formatted || '';
};
