import DeleteButton from 'components/DeleteButton';
import React, { useState } from 'react';
import { ICatalogEntryListProps } from './CatalogEntryList.types';

export const CatalogEntryList = ({
  catalogEntries = [],
  onSelectCatalogEntry,
  onDeleteCatalogEntry,
  editable,
}: ICatalogEntryListProps) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const filteredCatalogEntries = searchTerm
    ? catalogEntries.filter(
        (catalogEntry) =>
          catalogEntry.title.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          catalogEntry.signature.toLowerCase().includes(searchTerm?.toLowerCase())
      ).sort((a,b) => a.signature.toLowerCase() < b.signature.toLowerCase() ? -1 : 1)
    : catalogEntries.sort((a,b) => a.signature.toLowerCase() < b.signature.toLowerCase() ? -1 : 1);
  return (
    <span className="catalog-entries-list">
      <span>
        <h4>Katalogeinträge</h4>
      </span>
      <div>
        <div>
          <input
            autoFocus
            type="text"
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            placeholder="Freitextsuche..."
          />
        </div>
      </div>
      <table className="catalog-entry-list-table">
        <thead>
          <tr className="catalog-entries-header-row">
            <th scope="col">Signatur</th>
            <th scope="col">Titel</th>
            <th scope="col">Beschreibung</th>
          </tr>
        </thead>
        <tbody>
          {filteredCatalogEntries.map((catalogEntry) => {
            return (
              <tr
                className="catalog-list-row"
                key={catalogEntry.id}
                onClick={() => {
                  onSelectCatalogEntry(catalogEntry.id);
                }}
              >
                <td>{catalogEntry.signature}</td>
                <td>{catalogEntry.title}</td>
                <td className="catalog-entries-description-row">{catalogEntry.description}</td>
                {editable && (
                  <td>
                    <div onClick={(e) => e.stopPropagation()}>
                      <DeleteButton
                        onDelete={() => {
                          onDeleteCatalogEntry(catalogEntry.id);
                        }}
                      />
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </span>
  );
};
