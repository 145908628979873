import * as React from 'react';

type Props = {
  children: React.ReactNode;
};

const ListTable = (props: Props) => (
  <table className="room-data">
    <tbody>{props.children}</tbody>
  </table>
);

export default ListTable;
