import * as React from 'react';

import InlineEditBaseWidget, { BaseProps } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: boolean;
};

class InlineEditBooleanWidget extends InlineEditBaseWidget<Props> {
  public radios: { [key: string]: any } = {};
  protected refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;

  public getValueReadable = () =>
    this.props.value === false ? 'nein' : this.props.value ? 'ja' : '';

  public getChoices = () => [
    [true, 'ja'],
    [false, 'nein'],
  ];

  public setRef = (ref) => !!ref && (this.radios[ref.id] = ref);

  public getEditWidget = () => {
    return (
      <ul>
        {!this.props.prevent_empty ? (
          <li>
            <label>
              <input
                name={this.props.entity}
                ref={this.setRef}
                id="empty-choice"
                type="radio"
                value=""
                defaultChecked={this.props.value === undefined}
              />
              <i>(Leer)</i>
            </label>
          </li>
        ) : null}
        {this.getChoices().map((choice, index) => (
          <li key={index}>
            <input
              name={this.props.entity}
              id={choice[1] as string}
              ref={this.setRef}
              type="radio"
              value={choice[0] ? '1' : '0'}
              defaultChecked={this.props.value === choice[0]}
            />
            <label htmlFor={choice[1] as string}>{choice[1]}</label>
          </li>
        ))}
      </ul>
    );
  };

  public saveChanges = () => {
    const checked_id = Object.keys(this.radios).reduce((acc, key) => {
      return acc ? acc : this.radios[key].checked ? key : '';
    }, '');
    const checked_item = checked_id ? this.radios[checked_id] : null;

    if (!checked_item && this.props.prevent_empty) {
      return;
    }

    this.props.onSaveEntityField({
      entity_id: this.props.entity_id,
      entity_name: this.entity_name,
      fieldname: this.fieldname,
      value: checked_item.value === '1' ? true : checked_item.value === '0' ? false : null,
    });
  };
}

export default InlineEditBooleanWidget;
