import * as React from 'react';

type Props = {
  offset: number;
  total: number;
  pageSize: number;
  label?: string;
};

const AmountDisplay: React.FunctionComponent<Props> = (props: Props) => {
  const { offset, total, pageSize, label } = props;

  return total === 0 ? (
    <span>Keine Ergebnisse</span>
  ) : (
    <span>
      {label} {offset === 0 ? 1 : offset + 1} -{' '}
      {offset + pageSize > total ? total : offset + pageSize} von {total}
    </span>
  );
};

AmountDisplay.defaultProps = {
  label: 'Einträge',
};

export default AmountDisplay;
