import React, { useRef, useState } from 'react';

import { ITileFloormapSmallProps } from './TileFloormapSmall.types';

import L from 'leaflet';
import {
  useAddCenterCirclesToMap,
  useAddEventListeners,
  useAddHoverEvents,
  useAddShapesToMap,
  useAddTooltips,
  useAddTooltipsOnZoom,
  useCreateCenterCircles,
  useHandleActiveItem,
  useMountMap,
  usePopulateShapes,
} from 'components/TileFloormapComposer/TileFloormap.utils';

const TileFloormapSmall = ({
  entry,
  entries,
  floormapURL,
  geoJSON,
  onGotoEntity,
}: ITileFloormapSmallProps) => {
  const tileLayer = L.tileLayer(floormapURL, {
    noWrap: true,
    bounds: [
      [-256, 0],
      [0, 256],
    ],
    maxZoom: 4,
    minZoom: 1,
    subdomains: '',
  });

  const mapRef = useRef<L.Map | null>(null);
  const shapeRef = useRef<Map<number, L.Polygon | L.CircleMarker>>(new Map());
  const circleRef = useRef<Map<number, L.CircleMarker>>(new Map());
  const activeCircleRef = useRef<[number, L.CircleMarker] | null>(null);
  const tooltipRef = useRef<Map<number, L.Tooltip>>(new Map());

  const [mapInstance, setMapInstance] = useState<L.Map | null>(null);
  const [polygons, setPolygons] = useState<Map<number, L.Polygon>>(new Map());
  const [circles, setCircles] = useState<Map<number, L.CircleMarker>>(new Map());
  const [activeCircle, setActiveCircle] = useState<[number, L.CircleMarker] | null>(null);
  const [points, setPoints] = useState<Map<number, L.CircleMarker>>(new Map());
  const [tooltips, setTooltips] = useState<Map<number, L.Tooltip>>(new Map());

  useMountMap(mapRef, 'plan-small', tileLayer, 'SMALL', setMapInstance);
  usePopulateShapes(mapInstance, shapeRef, onGotoEntity, setPolygons, setPoints, geoJSON, entry.id);

  useAddShapesToMap(mapInstance, polygons, points);
  useHandleActiveItem(mapInstance, polygons, points, activeCircleRef, setActiveCircle, entry.id);
  useCreateCenterCircles(mapInstance, polygons, circleRef, setCircles, onGotoEntity, entry?.id);
  useAddCenterCirclesToMap(mapInstance, circles, activeCircle);
  useAddTooltips(points, circles, activeCircle, tooltipRef, setTooltips, entries);

  useAddHoverEvents(polygons, points, circles, activeCircle, entry.id, entries);
  useAddTooltipsOnZoom(mapInstance, points, circles, activeCircle, tooltips, entry.id);
  useAddEventListeners(polygons, tooltips, onGotoEntity, () => {}, entries, entry.id);

  const styles = {
    width: 'auto',
    height: '550px', //550px is a hardcoded value that plays nice with "DIN A4" floormaps, may need to be changed for larger floormaps
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  return (
    <div className="map-navigation-container">
      <div id={'plan-small'} className="map-navigation-map" style={styles} />
    </div>
  );
};

export default TileFloormapSmall;
