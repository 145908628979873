import React, { useEffect, useMemo, useState } from 'react';
import ErrorList from 'components/ErrorList';
import SelectCatalogButton from 'components/SelectCatalogButton/SelectCatalogButtonContainer';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { CatalogEntry } from 'typings/models';
import { ICreateDoorProps } from './CreateDoor.types';

const CreateDoor = ({
  choices,
  isCreatingDoor,
  wallId,
  has_catalogs,
  onCreate,
  onReset,
  onFindCatalogEntry,
}: ICreateDoorProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [material, setMaterial] = useState<string>();
  const [token, setToken] = useState<string>();
  const [type, setType] = useState<number>();
  const [catalogEntry, setCatalogEntry] = useState<CatalogEntry>();
  const ref = React.createRef<HTMLInputElement>();
  const focus = () => !!ref.current && ref.current.focus();

  useEffect(() => {
    !isEditing && onReset();
  }, [isEditing]);

  useEffect(() => {
    if (isCreatingDoor.status === 'SAVED') {
      setMaterial(undefined);
      setToken(undefined);
      setType(undefined);
      setIsEditing(false);
      setCatalogEntry(undefined);
      focus();
    }
  }, [isCreatingDoor.status]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      create();
    }
    if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const create = useMemo(() => {
    return () =>
      onCreate(token || '', material || '', type || null, wallId, catalogEntry?.id || null);
  }, [token, material, type, wallId, catalogEntry]);

  if (isCreatingDoor.status === 'SAVING') {
    return <SimpleLoader />;
  }

  if (isEditing) {
    return (
      <div>
        <ErrorList errors={isCreatingDoor.messages} errorField="token" />
        <input
          defaultValue={undefined}
          placeholder="Signatur"
          type="text"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setToken(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
        <div>
          Tür-Art:&nbsp;
          <ErrorList errors={isCreatingDoor.messages} errorField="type" />
          <select
            defaultValue={undefined}
            size={1}
            onChange={(e: React.FormEvent<HTMLSelectElement>) =>
              setType(parseInt(e.currentTarget.value, 10))
            }
          >
            <option value="">--- Bitte wählen ---</option>
            {choices.map((item) => (
              <option value={item[0]} key={item[1]}>
                {item[1]}
              </option>
            ))}
          </select>
        </div>
        <div></div>
        <ErrorList errors={isCreatingDoor.messages} errorField="material" />
        {has_catalogs && (
          <div className="create-entity-select-catalog-button-container">
            <SelectCatalogButton
              editable={true}
              onClick={() => onFindCatalogEntry(setCatalogEntry, "Door")}
              onDelete={() => setCatalogEntry(undefined)}
              catalogEntryId={catalogEntry?.id}
            />
          </div>
        )}
        <textarea
          defaultValue={undefined}
          placeholder="Beschreibung"
          onKeyDown={handleKeyDown}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => setMaterial(e.currentTarget.value)}
        />
        <br />
        <button className="primary" onClick={create}>
          OK
        </button>
        <button className="secondary" onClick={() => setIsEditing(false)}>
          Abbrechen
        </button>
      </div>
    );
  }

  return (
    <button className="primary add-button" onClick={() => setIsEditing(true)}>
      Tür hinzufügen
    </button>
  );
};

export default CreateDoor;
