import merge from 'lodash/merge';
import set from 'lodash/set';
import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';

import { EntryActionType } from 'actions/actionTypes';
import departmentEntrySchema from 'api/departmententry_schema';
import * as actions from 'typings/actions';
import { EntityState } from 'typings/state';

import { getDepartmentPermissions } from './entities';
import { initialState } from './entities.initial';

const departmentEntriesReducer = handleActions<EntityState, actions.Action>(
  {
    [EntryActionType.CREATED_DEPARTMENT_ENTRY]: (
      state: EntityState,
      action: actions.CreatedDepartmentEntryAction
    ): EntityState => {
      const {
        payload,
        payload: { id, entry },
      } = action;
      const newState = { ...state };

      set(
        newState,
        `department_entries.${id}`,
        normalize(payload, departmentEntrySchema).entities.department_entries?.[id]
      );
      newState.entries[entry].department_entries = [
        id,
        ...(newState.entries[entry].department_entries || []),
      ];
      return newState;
    },

    [EntryActionType.DELETED_DEPARTMENT_ENTRY]: (
      state: EntityState,
      action: actions.DeletedDepartmentEntryAction
    ): EntityState => {
      const {
        payload: { entryId, departmentEntryId },
      } = action;
      const newState = { ...state };

      newState.entries[entryId].department_entries = (
        newState.entries[entryId].department_entries || []
      ).filter((de) => de !== departmentEntryId);

      delete newState.department_entries[departmentEntryId];
      return newState;
    },

    [EntryActionType.MOVED_DEPARTMENT_ENTRY]: (
      state: EntityState,
      action: actions.MovedDepartmentEntryAction,
    ): EntityState => {
      let newState = { ...state.department_entries };
      newState = []
      action.payload.json.department_entries.forEach((de) => {
        newState = merge({}, newState, normalize(de, departmentEntrySchema).entities.department_entries)
      })
      return { ...state, department_entries: newState };
    },

  },

  { ...initialState }
);

export default departmentEntriesReducer;

export const canWriteDepartmentEntry = (state: EntityState, departmentId) =>
  getDepartmentPermissions(state, departmentId) > 0;

export const canAdminDepartmentEntry = (state: EntityState, departmentId) =>
  getDepartmentPermissions(state, departmentId) > 1;
