import * as React from 'react';

import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import ExportProjectContainer from 'containers/ExportProjectContainer';
import { ModalType } from 'typings/enums';

type Props = {
  isExporting: boolean;
  className?: string;
  onShowModal: (type: ModalType, title: string, content: any) => void;
};

const ExportProjectButton = (props: Props) => {
  const { isExporting, onShowModal, ...rest } = props;
  if (isExporting) {
    return (
      <button className="pdf-button pdf-button-active" {...rest}>
        <SimpleLoader size="small" isWhite />
      </button>
    );
  }
  return (
    <button
      title={`Projekt exportieren`}
      className="pdf-button"
      disabled={isExporting}
      onClick={() =>
        onShowModal(
          ModalType.INFO,
          'Projekt exportieren',
          <ExportProjectContainer></ExportProjectContainer>
        )
      }
      {...rest}
    >
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke="#FFF">
          <path d="M17.5 10.21V3.5h-13v17h13v-7.605" />
          <path
            d="M10.5 15.5h.5c1.344 0 1.5.146 1.5 1.125v.75c0 .964-.169 1.125-1.5 1.125h-.5M6.5 15.5H7c1.344 0 1.5.02 1.5 1 0 .964-.17 1-1.5 1h-.5M6.5 15v4M16.5 17.5h-2M14.5 15.5h2M10.5 15v4M14.5 15v4"
            strokeLinejoin="round"
          />
          <path strokeWidth="1.2" d="M20.5 10v-.5l2 2-2 2V13" />
          <path d="M20.5 10.5h-5v2h5" />
        </g>
      </svg>
    </button>
  );
};

export default ExportProjectButton;
