import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';

import { EntryActionType } from 'actions/actionTypes';

import doorSchema from 'api/door_schema';
import wallSchema from 'api/wall_schema';
import windowSchema from 'api/window_schema';

import * as actions from 'typings/actions';
import { EntityState } from 'typings/state';
import { initialState } from './entities.initial';
import findingSchema from 'api/finding_schema';

const wallsReducer = handleActions<EntityState, actions.Action>(
  {
    [EntryActionType.CREATED_WALL]: (
      state: EntityState,
      action: actions.CreatedWallAction
    ): EntityState => {
      const {
        payload,
        payload: { id, entry },
      } = action;
      const newState = { ...state };
      newState.walls[id] = normalize(payload, wallSchema).entities.walls?.[id];
      if (!newState.entries[entry]) {
        return newState;
      }
      newState.entries[entry].walls = [...(newState.entries[entry].walls || []), id];
      return newState;
    },

    [EntryActionType.DELETED_WALL]: (
      state: EntityState,
      action: actions.DeletedWallAction
    ): EntityState => {
      const newState = { ...state };
      newState.entries[action.payload.entry_id].walls = (
        newState.entries[action.payload.entry_id].walls || []
      ).filter((wall) => wall !== action.payload.wall_id);

      delete newState.walls[action.payload.wall_id];
      return newState;
    },

    [EntryActionType.CREATED_DOOR]: (
      state: EntityState,
      action: actions.CreatedDoorAction
    ): EntityState => {
      const {
        payload: { wall, id },
      } = action;
      const newState = { ...state };
      newState.doors[id] = normalize(action.payload, doorSchema).entities.doors?.[id];
      newState.walls[wall].doors.push(id);
      return newState;
    },

    [EntryActionType.DELETED_DOOR]: (
      state: EntityState,
      action: actions.DeletedDoorAction
    ): EntityState => {
      const { payload } = action;
      const newState = { ...state };
      Object.keys(newState.walls).forEach((key) => {
        if (newState.walls[key].doors.includes(payload)) {
          newState.walls[key].doors = newState.walls[key].doors.filter((d) => d !== payload);
        }
      });
      delete newState.doors[payload];
      return newState;
    },

    [EntryActionType.CREATED_WINDOW]: (
      state: EntityState,
      action: actions.CreatedWindowAction
    ): EntityState => {
      const {
        payload: { wall, id },
      } = action;
      const newState = { ...state };
      newState.windows[id] = normalize(action.payload, windowSchema).entities.windows?.[id];
      newState.walls[wall].windows.push(id);
      return newState;
    },

    [EntryActionType.DELETED_WINDOW]: (
      state: EntityState,
      action: actions.DeletedWindowAction
    ): EntityState => {
      const { payload } = action;
      const newState = { ...state };
      Object.keys(newState.walls).forEach((key) => {
        if (newState.walls[key].windows.includes(payload)) {
          newState.walls[key].windows = newState.walls[key].windows.filter((d) => d !== payload);
        }
      });
      delete newState.windows[payload];
      return newState;
    },

    [EntryActionType.CREATED_FINDING]: (
      state: EntityState,
      action: actions.CreatedFindingAction
    ): EntityState => {
      const { payload } = action;
      const newState = { ...state };
      newState.findings[payload.id] = normalize(action.payload, findingSchema).entities.findings?.[
        payload.id
      ];
      newState.walls[payload.wall].findings.push(payload.id);
      return newState;
    },

    [EntryActionType.DELETED_FINDING]: (
      state: EntityState,
      action: actions.DeletedFindingAction
    ): EntityState => {
      const { payload } = action;
      const newState = { ...state };
      Object.keys(newState.walls).forEach((key) => {
        if (newState.walls[key].findings.includes(payload)) {
          newState.walls[key].findings = newState.walls[key].findings.filter((f) => f !== payload);
        }
      });
      delete newState.findings[payload];
      return newState;
    },
  },
  { ...initialState }
);

export default wallsReducer;
