enum GuiActionType {
  SHOW_MODAL = 'gui/SHOW_MODAL',
  HIDE_MODAL = 'gui/HIDE_MODAL',
  UPDATE_MODAL_TITLE = 'gui/UPDATE_MODAL_TITLE',

  SHOW_FLYOUT = 'gui/SHOW_FLYOUT',
  HIDE_FLYOUT = 'gui/HIDE_FLYOUT',

  LOAD_PRIVACY_NOTE_VISIBLE = 'gui/LOAD_PRIVACY_NOTE_VISIBLE',
  DISMISS_PRIVACY_NOTE = 'gui/DISMISS_PRIVACY_NOTE',
  SET_PRIVACY_NOTE_VISIBLE = 'gui/SET_PRIVACY_NOTE_VISIBLE',

  SET_EDIT_MODE = 'gui/SET_EDIT_MODE',
  TOGGLE_EDIT_MODE = 'gui/TOGGLE_EDIT_MODE',
  DISABLE_EDIT_MODE = 'gui/DISABLE_EDIT_MODE',

  ENTRYLIST_UP = 'gui/ENTRYLIST_UP',
  ENTRYLIST_DOWN = 'gui/ENTRYLIST_DOWN',
  SET_OBJECT_TYPE_FILTER = 'gui/SET_OBJECT_TYPE_FILTER',
  SET_FACILITY_LEVEL_FILTER = 'gui/SET_FACILITY_LEVEL_FILTER',
  SET_DEPARTMENT_FILTER = 'gui/SET_DEPARTMENT_FILTER',

  CLEAR_ENTRYLIST_FILTERS = 'gui/CLEAR_ENTRYLIST_FILTERS',
  TOGGLE_NOTIFICATIONS = 'gui/TOGGLE_NOTIFICATIONS',

  SHOW_TOAST = 'gui/SHOW_TOAST',
}

export default GuiActionType;
