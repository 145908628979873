import { PermissionLevel } from 'typings/enums';
import React from 'react';

type Props = {
  allowEmpty?: boolean;
  onChange: (level?: PermissionLevel) => void;
};

const toPermissionLevel = (e: React.FormEvent<HTMLSelectElement>) =>
  parseInt(e.currentTarget.value, 10) as PermissionLevel;

const PermissionSelect: React.FunctionComponent<Props> = (props: Props) => {
  const { allowEmpty, onChange } = props;
  return (
    <select onChange={(e) => onChange(toPermissionLevel(e))}>
      {allowEmpty && <option value="">Bitte wählen</option>}
      <option value={0}>Lesen</option>
      <option value={1}>Schreiben</option>
      <option value={2}>Administrieren</option>
    </select>
  );
};

export default PermissionSelect;
