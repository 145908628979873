import React, { useState, useEffect, ChangeEvent } from 'react';

import ErrorList from 'components/ErrorList';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { IDepartmentEntryProps } from './CreateDepartmentEntry.types';
import { UpsertDepartmentEntry } from '../../typings/models';

const CreateDepartmentEntry = (props: IDepartmentEntryProps) => {
  const {
    department,
    onCreate,
    onReset,
    measure_classifications,
    measure_prioritizations,
    creatingDepartmentEntry: { messages, status },
  } = props;

  const ref = React.createRef<HTMLTextAreaElement>();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<UpsertDepartmentEntry>({ title: '', content: '' });

  useEffect(() => {
    if (!isEditing) {
      setValue({ title: '', content: '' });
      onReset();
    }
  }, [isEditing]);

  useEffect(() => {
    !!ref.current && ref.current.focus();
    if (status === 'SAVED') {
      setIsEditing(false);
      setValue({ title: '', content: '' });
    }
  }, [status]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onCreate(value);
    }
    if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const [targetValue, key] = [e.currentTarget.value, e.currentTarget.name];
    const typedValue = ['number', 'radio'].includes(e.currentTarget.type)
      ? parseFloat(e.currentTarget.value)
      : targetValue;
    setValue((oldState) => ({ ...oldState, [key]: typedValue }));
  };

  if (status === 'SAVING') {
    return <SimpleLoader />;
  } else if (isEditing) {
    return (
      <div className="room-details flex-container horizontal departmententry-container">
        {status === 'FAILED' && <ErrorList errors={messages} errorField="content" />}
        <input
          type="text"
          name="title"
          value={value.title}
          placeholder="Titel"
          onChange={handleChange}
        />
        {!department.preset_concept_of_measures ? (
          <textarea
            ref={ref}
            value={value.content}
            name="content"
            placeholder="Fachbereichs-Eintrag"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <>
            <br />
            <div className="departmententry-content">
              <div className="content-box left">
                <table className="department-entry-data">
                  <tbody>
                    <tr>
                      <th>Zyklus d. nächsten Wartung</th>
                      <td>
                        <textarea
                          //placeholder="test"
                          name="interval_next_maintenance"
                          value={value.interval_next_maintenance}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Zyklus d. nächsten Revision</th>
                      <td>
                        <textarea
                          name="interval_next_revision"
                          value={value.interval_next_revision}
                          //placeholder="test"
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Maßnahmenpriorisierung</th>
                      <td className="selection-data">
                        <ul>
                          <li key={''}>
                            <label>
                              <input
                                name="measure_prioritization"
                                id="empty-choice"
                                type="radio"
                                value=""
                                defaultChecked
                                onChange={handleChange}
                              />
                              <i>(Leer)</i>
                            </label>
                          </li>
                          {measure_prioritizations.map((choice) => (
                            <li key={choice[0]}>
                              <input
                                name="measure_prioritization"
                                id={choice[1]}
                                type="radio"
                                value={choice[0]}
                                onChange={handleChange}
                              />
                              <label htmlFor={choice[1]}>{choice[1]}</label>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>Maßnahmenklassifizierung</th>
                      <td className="selection-data">
                        <ul>
                          <li key={''}>
                            <label>
                              <input
                                name="measure_classification"
                                id="empty-choice"
                                type="radio"
                                value=""
                                defaultChecked
                                onChange={handleChange}
                              />
                              <i>(Leer)</i>
                            </label>
                          </li>
                          {measure_classifications.map((choice) => (
                            <li key={choice[0]}>
                              <input
                                name="measure_classification"
                                id={choice[1]}
                                type="radio"
                                value={choice[0]}
                                onChange={handleChange}
                              />
                              <label htmlFor={choice[1]}>{choice[1]}</label>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>Maßnahme</th>
                      <td>
                        <textarea
                          //placeholder="test"
                          name="measure"
                          value={value.measure}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Abbau (in Werkstatt) nötig</th>
                      <td className="selection-data">
                        <ul>
                          <li>
                            <label>
                              <input
                                name="decomposition_needed"
                                id="empty-choice"
                                type="radio"
                                defaultChecked
                                onChange={handleChange}
                              />
                              <i>(Leer)</i>
                            </label>
                          </li>
                          {[
                            [true, 'ja'],
                            [false, 'nein'],
                          ].map((choice, index) => (
                            <li key={index}>
                              <input
                                name="decomposition_needed"
                                id={choice[1] as string}
                                type="radio"
                                value={choice[0] ? '1' : '0'}
                                onChange={handleChange}
                              />
                              <label htmlFor={choice[1] as string}>{choice[1]}</label>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>Std. Ab- & Aufbau</th>
                      <td>
                        <input
                          type="number"
                          name="hours_construction_deconstruction"
                          value={value.hours_construction_deconstruction ?? 0}
                          onChange={handleChange}
                        />{' '}
                        h
                      </td>
                    </tr>
                    <tr>
                      <th>Aufwand Maßnahme (geschätzt)</th>
                      <td>
                        <input
                          type="number"
                          name="effort_measure"
                          value={value.effort_measure ?? 0}
                          onChange={handleChange}
                        />{' '}
                        h
                      </td>
                    </tr>
                    <tr>
                      <th>Besonderheiten / Sondermaßnahmen</th>
                      <td>
                        <textarea
                          //placeholder="test"
                          name="special_measures"
                          value={value.special_measures}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </div>
          </>
        )}
        <div
          className={
            department.preset_concept_of_measures
              ? 'content-box left'
              : 'department-entry-buttons-wrapper'
          }
        >
          <div className="button-group">
            <button className="secondary" onClick={() => setIsEditing(false)}>
              Abbrechen
            </button>
            <button className="primary" onClick={() => onCreate(value)}>
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <button className="primary add-button" onClick={() => setIsEditing(true)}>
      Eintrag hinzufügen
    </button>
  );
};

export default CreateDepartmentEntry;
