import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { hotkeys } from 'config/hotkeys';
import { getFieldConfigurationByEntryType } from 'reducers/app';
import { useMousetraps } from 'utils/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { IEntryNavigatorProps } from './EntryNavigator.types';
import * as util from './EntryNavigator.util';

const EntryNavigator = (props: IEntryNavigatorProps) => {
  const { projectversion, entry, location } = props;
  const [linkLists, setLinkLists] = useState(util.makeLinkLists(projectversion, entry));
  const getNavigationLinks = useMemo(
    () => util.getNavigationLinks({ list: linkLists.linkList, currentPath: location.pathname }),
    [linkLists.linkList]
  );

  useEffect(() => {
    setLinkLists(util.makeLinkLists(projectversion, entry));
  }, [projectversion, entry]);

  useMousetraps(
    {
      [hotkeys.ENTRY_BACK.hotkey]: () => props.onNavigate(getNavigationLinks.previous),
      [hotkeys.ENTRY_FORWARD.hotkey]: () => props.onNavigate(getNavigationLinks.next),
    },
    [linkLists.walls]
  );

  if (!linkLists || !projectversion || !entry) {
    return <SimpleLoader />;
  }

  const prevLink = getNavigationLinks.previous;
  const nextLink = getNavigationLinks.next;
  const fc = getFieldConfigurationByEntryType(projectversion, entry.entry_type.id);

  return (
    <nav className="column-nav flex-container horizontal">
      <div className="navigate navigate-prev">
        <Link key={prevLink} to={prevLink}>
          Room nav prev
        </Link>
      </div>
      <ul id="navigation" className="indicators flex-container horizontal">
        <li className="indicators--basis">
          <a href="#" onClick={() => props.onNavigate(linkLists.base.to)}>
            Basis
          </a>
        </li>
        {!!fc && fc.entry_type.has_walls && (
          <li>
            {util.renderDropdown(
              'Wände / Flächen',
              linkLists.walls || [],
              linkLists.base.to,
              location.pathname,
              props.onNavigate
            )}
          </li>
        )}
        <li>
          {util.renderDropdownWithEntryCount({
            title: 'Fachbereiche',
            entries: linkLists.departments,
            fallbackUrl: linkLists.base.to,
            pathname: location.pathname,
            onNavigate: props.onNavigate,
            departmentEntries: entry.department_entries || [],
          })}
        </li>
      </ul>
      <div className="navigate navigate-next">
        <Link key={nextLink} to={nextLink}>
          Room nav next
        </Link>
      </div>
    </nav>
  );
};

export default withRouter(EntryNavigator);
