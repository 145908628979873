import ErrorPanel from 'components/Error/Error';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import {
  EditingStatus,
  Entry,
  HistoricalEntry,
  HistoryListItem,
  LoadStatus,
  StringIndexedRecord,
} from 'typings/models';
import React from 'react';
import ReactModal from 'react-modal';
import { LoadRequestState } from 'typings/enums';

export type BaseProps = {
  edit_mode: boolean;
  editable: boolean;
  entity: string;
  editing_entity_field: EditingStatus;
  empty_display_value: any;
  entity_id: number;

  prevent_empty?: boolean;
  inputProps?: any;
  hint?: string;
  suffix?: string;
  title?: string;

  loadingEntryHistory: LoadStatus<HistoricalEntry>;

  history?: StringIndexedRecord<HistoricalEntry[]>;
  historyKey?: string;
  entry?: Entry;

  onSaveEntityField: ({
    entity_name,
    entity_id,
    fieldname,
    value,
  }: {
    entity_name: string;
    entity_id: number;
    fieldname: string;
    value: any;
  }) => void;

  onEditingEntityField: ({
    entity_name,
    entity_id,
    fieldname,
  }: {
    entity_name: string;
    entity_id: number;
    fieldname: string;
  }) => void;
  onCancelEditEntityField: () => void;
};

export enum DISPLAY_MODE {
  DISPLAY,
  DISPLAY_EDIT,
  EDITING,
  SAVING,
}

type State = {
  isFullScreenOpen: boolean;
};

abstract class InlineEditBaseWidget<T extends BaseProps> extends React.Component<T, State> {
  public static defaultProps = {
    suffix: '',
  };
  public entity_name = '';
  public fieldname = '';
  public tmpValue = null;

  public editWidgetRef = null;
  public state = {
    isFullScreenOpen: false,
  };

  public _refModal = React.createRef<any>();
  public _refElementInnerWrapper = React.createRef<any>();
  public _refElementOuterWrapper = React.createRef<any>();
  protected canFullscreen = false;
  protected refElement = React.createRef<any>();
  public onBeginEdit?(): void;

  public componentDidMount() {
    [this.entity_name, this.fieldname] = this.props.entity.split('.');
  }

  public componentDidUpdate() {
    this._focusInputElement();
  }

  public shouldSave = (e: React.KeyboardEvent<HTMLElement>) => e.key === 'Enter';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public shouldCancel = (e: React.KeyboardEvent<HTMLElement>) => false; // e.key === 'Escape';

  // tslint:disable-next-line:variable-name
  public _beginEdit = (event) => {
    event.preventDefault();
    this.props.onEditingEntityField({
      entity_id: this.props.entity_id,
      entity_name: this.entity_name,
      fieldname: this.fieldname,
    });
    !!this.onBeginEdit && this.onBeginEdit();
  };

  public _getSavingWidget = () => <SimpleLoader />;
  public _focusInputElement = () =>
    this.determineDisplayMode() === DISPLAY_MODE.EDITING &&
    !!this.refElement.current &&
    this.refElement.current.focus();

  public handleKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (this.shouldSave(e)) {
      this.saveChanges();
      return;
    }
    if (this.shouldCancel(e)) {
      this.cancel();
      return;
    }
  };

  public saveChanges = () => {
    if (this.refElement.current) {
      const value = this.refElement.current.value;
      this.props.onSaveEntityField({
        entity_id: this.props.entity_id,
        entity_name: this.entity_name,
        fieldname: this.fieldname,
        value,
      });
      this.setState({ isFullScreenOpen: false });
    }
  };

  public _closeFullScreen = () => {
    this.setState({ isFullScreenOpen: false });
  };

  public _openFullScreen = () => {
    this.setState({ isFullScreenOpen: true });
  };

  public renderEditWidget = () => {
    const { title } = this.props;
    return (
      <div ref={this._refElementOuterWrapper} className="modal-edit-outer">
        <span className="edit_mode inline stretch" ref={this._refElementInnerWrapper}>
          <ReactModal
            closeTimeoutMS={150}
            className="modal inline-edit"
            overlayClassName="overlay"
            isOpen={this.state.isFullScreenOpen}
            onRequestClose={this._closeFullScreen}
            contentLabel="Bearbeiten"
          >
            <h2>{title || 'Bearbeiten'}</h2>
            {this.renderEditWidgetModal(this.refElement.current?.value)}
          </ReactModal>
          {!!this.props.editing_entity_field.errors && (
            <ErrorPanel message={this.props.editing_entity_field.errors} />
          )}
          {this.getEditWidget()}
          {this.canFullscreen && (
            <button
              className="primary"
              onClick={this._openFullScreen}
            >
              {this.state.isFullScreenOpen ? 'Verkleinern' : 'Vergrößern'}
            </button>
          )}
          <button className="primary" onClick={this.saveChanges}>
            OK
          </button>
          <button className="secondary" onClick={this.cancel}>
            Abbrechen
          </button>
        </span>
      </div>
    );
  };

  public renderEditWidgetModal = (value?: string) => {
    return (
      <span className="edit_mode inline stretch" ref={this._refElementInnerWrapper}>
        {!!this.props.editing_entity_field.errors && (
          <ErrorPanel message={this.props.editing_entity_field.errors} />
        )}
        {this.getEditWidget(value)}
        <button className="primary" onClick={this.saveChanges}>
          OK
        </button>
        <button className="secondary" onClick={this.cancel}>
          Abbrechen
        </button>
      </span>
    );
  }

  public render() {
    const display_mode = this.determineDisplayMode();
    // Not in edit mode? Just show plain value
    if (display_mode === DISPLAY_MODE.DISPLAY) {
      return this.getDisplayWidget();
    }

    // Saving this instance? Show "Saving" message
    if (display_mode === DISPLAY_MODE.SAVING) {
      return this._getSavingWidget();
    }

    // Editing this field? Show edit widget
    if (display_mode === DISPLAY_MODE.EDITING) {
      return this.renderEditWidget();
    }

    // Global edit mode on, but not editing widget
    if (display_mode === DISPLAY_MODE.DISPLAY_EDIT) {
      return this.getDisplayWidgetEditMode();
    }
  }
  protected abstract getEditWidget(value?: string): React.ReactNode;
  protected abstract getValueReadable(): string | number | React.ReactNode;

  protected generateDisplayLinkEditMode = (content, cssClassName) => {
    return (
      <a href="#" onClick={this._beginEdit} className={cssClassName} type="text">
        {content} {this.props.suffix}
      </a>
    );
  };

  protected getDisplayWidgetEditMode = () => {
    const { editable, hint } = this.props;
    const value = this.getValueReadable();

    if (editable) {
      const display_value = value || hint || '---';
      const class_name = hint && !value ? 'hint' : '';
      return this.generateDisplayLinkEditMode(display_value, class_name);
    }
    return this.getDisplayWidget();
  };

  protected getDisplayWidget = () => {
    const { empty_display_value, suffix } = this.props;
    const value = this.getValueReadable();

    return value ? (
      <React.Fragment>
        <span className="history-on-hover">
          {value} {suffix}
          {this.getEntryHistory()}
        </span>
      </React.Fragment>
    ) : (
      empty_display_value || <span />
    );
  };

  protected getEntryHistory = () => {
    if (!this.props.history || !this.props.entry || !this.props.historyKey) {
      return <></>;
    }

    const { entry, history, historyKey, suffix } = this.props;

    const res: Array<HistoryListItem> = [];
    history &&
      entry &&
      historyKey &&
      history[entry.uuid] &&
      history[entry.uuid]
        .sort((a, b) => {
          return a.projectversion.created_at > b.projectversion.created_at ? 0 : 1;
        })
        .map((e, index) => {
          if (index === 0) {
            return;
          }
          const value = this.getHistoryValue(e, historyKey.toString(), suffix);
          if (value && res.length === 0) {
            res.push({
              versionTitle: e.projectversion.title,
              value,
            });
          } else if (
            value &&
            res[res.length - 1].value !== this.getHistoryValue(e, historyKey.toString(), suffix)
          ) {
            res.push({
              versionTitle: e.projectversion.title,
              value,
            });
          }
        });

    return (
      <>
        <i className={res.length > 0 ? 'fas fa-history' : 'fas fa-history disabled'}></i>
        {res.length > 0 && (
          <span>
            <h4 style={{ paddingTop: '12px' }}>Historie</h4>
            {this.props.loadingEntryHistory &&
              this.props.loadingEntryHistory.status === LoadRequestState.LOADING ? (
              <SimpleLoader center />
            ) : (
              <>
                {res.map((e, index) => {
                  return (
                    <p key={index}>
                      <b>{e.versionTitle}: </b>
                      {e.value}
                    </p>
                  );
                })}
              </>
            )}
          </span>
        )}
      </>
    );
  };

  protected getHistoryValue = (entry: HistoricalEntry, key: string, suffix?: any): string => {
    let res = key.includes('.title') ? entry[key.substr(0, key.indexOf('.'))]?.title : entry[key];
    res = res === true ? (res = 'Ja') : res === false ? (res = 'Nein') : res;
    return res ? this.formatHistoryResult(res, suffix) : '';
  };

  public formatHistoryResult = (res: string, suffix?: any): string => {
    return suffix ? `${res} ${suffix}` : res;
  };

  protected cancel = () => {
    this.props.onCancelEditEntityField();
    this.setState({ isFullScreenOpen: false });
  };

  /**
   * Determines the component's display mode
   */
  protected determineDisplayMode = (): DISPLAY_MODE => {
    const { editing_entity_field, edit_mode, entity_id } = this.props;
    if (
      editing_entity_field &&
      editing_entity_field.entity_name === this.entity_name &&
      editing_entity_field.entity_id === entity_id &&
      editing_entity_field.fieldname === this.fieldname
    ) {
      if (editing_entity_field.status === 'EDITING') {
        return DISPLAY_MODE.EDITING;
      }
      if (editing_entity_field.status === 'SAVING') {
        return DISPLAY_MODE.SAVING;
      }
    } else {
      if (edit_mode) {
        return DISPLAY_MODE.DISPLAY_EDIT;
      }
    }
    return DISPLAY_MODE.DISPLAY;
  };
}

export default InlineEditBaseWidget;
