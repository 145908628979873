import * as actions from 'typings/actions';
import { NotificationState } from 'typings/state';
import { handleActions } from 'redux-actions';
import { NotificationsActionType } from 'actions/actionTypes';
import { NormalizedNotification } from 'typings/models';
import sortBy from 'lodash/sortBy';
import { normalize } from 'normalizr';
import { notificationList } from 'api/notification_schema';

const initialState: NotificationState = {
  notifications: {},
};

const notificationsReducer = handleActions<NotificationState, actions.Action>(
  {
    [NotificationsActionType.NORMALIZE_NOTIFICATION]: (
      state: NotificationState,
      action: actions.LoadedNotificationsAction
    ): NotificationState => ({
      ...state,
      ...normalize(action.payload.results, notificationList).entities,
    }),
    [NotificationsActionType.MARKED_AS_READ]: (
      state: NotificationState,
      action: actions.MarkedAsReadAction
    ): NotificationState => {
      const notifications = { ...state.notifications };
      delete notifications[action.payload];
      return {
        ...state,
        notifications,
      };
    },
    [NotificationsActionType.MARKED_ALL_READ]: (state: NotificationState): NotificationState => {
      const notifications = {};
      return {
        ...state,
        notifications,
      };
    },
  },
  { ...initialState }
);

export const getNotifications = (state: NotificationState) =>
  sortByCreated(Object.values(state.notifications));
export const getUnreadNotificationsNumber = (state: NotificationState) =>
  Object.values(state.notifications).filter((obj: any) => !obj.read).length;

const sortByCreated = (items: NormalizedNotification[]): NormalizedNotification[] =>
  sortBy(items, (item: NormalizedNotification) => item.created_at).reverse();

export { notificationsReducer as default, initialState };
