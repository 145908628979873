import * as React from 'react';

type Props = {
  title: string;
  question: string;
  onDelete: () => void;
};

class DeleteButton extends React.Component<Props> {
  public static defaultProps = {
    question: null,
    title: null,
  };

  public state = {
    asking: false,
  };

  public cancelAsking = () => this.setState({ asking: false });
  public beginAsking = () => this.setState({ asking: true });

  public deleteObject = (e: React.MouseEvent<HTMLElement>) => {
    const { onDelete } = this.props;
    e.preventDefault();
    this.cancelAsking();
    onDelete();
  };

  public render() {
    if (!this.state.asking) {
      return (
        <button className="tertiary delete-button" onClick={this.beginAsking}>
          {this.props.title || 'löschen'}
        </button>
      );
    }

    return (
      <div className="interstition shadow">
        <div className="question">{this.props.question || 'Wirklich löschen?'}</div>
        <div className="delete-buttons">
          <button className="tertiary" onClick={this.deleteObject}>
            Ja
          </button>
          <button className="primary" onClick={this.cancelAsking}>
            Nein
          </button>
        </div>
      </div>
    );
  }
}

export default DeleteButton;
