import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import CreateEntry from 'components/CreateEntry/CreateEntry';
import EditModeButton from 'components/EditModeButton';
import ExportProjectButton from 'components/ExportProjectButton';
import Logo from 'components/Logo';
import Notification from 'components/Notification';
import PrivacyNote from 'components/PrivacyNote';
import OwnProfileLink from 'components/UserLink/UserLink';
import { hotkeys } from 'config/hotkeys';
import { ModalType } from 'typings/enums';
import {
  Entry,
  EntryType,
  NormalizedNotification,
  ProjectVersion,
  SaveStatus,
  User,
} from 'typings/models';
import { useMousetraps } from 'utils/hooks';
import { getNewEntry, getUpdatedPathById } from './NavBar.util';

type Props = {
  edit_mode: boolean;
  user: User;
  projectversion: ProjectVersion;
  exporting: boolean;
  creatingEntry: SaveStatus<Entry>;
  privacyNoteVisible: boolean;
  entryTypes: EntryType[];
  canEditAnything: boolean;
  canEditEntry: boolean;
  canAdminEntry: boolean;
  notificationsOpen: boolean;
  notifications: NormalizedNotification[];
  unreadNotificationsNumber: number;
  onDismissPrivacyNote: () => void;
  onToggleEditMode: () => void;
  onGotoSearch: () => void;
  onGotoHome: () => void;
  onGotoDashboard: () => void;
  onGotoHelp: () => void;
  onHideFlyout: () => void;
  onLogout: () => void;
  onCreateEntry: (string) => void;
  onSetEditMode: () => void;
  onToggleNotifications: () => void;
  onLoadNotifications: () => void;
  onMarkNotificationRead: (number) => void;
  onMarkAllNotificationsRead: () => void;
  onShowModal: (type: ModalType, title: string, content: any) => void;
  onSwitchVersion: (path: string) => void;
  history?: Record<string, any>;
  hasCatalogs: Boolean;
};

const NavBar = (props: Props) => {
  const map = {
    [hotkeys.TOGGLE_EDIT_MODE.hotkey]: props.onToggleEditMode,
    [hotkeys.GOTO_HOME.hotkey]: props.onGotoHome,
    [hotkeys.GOTO_DASHBOARD.hotkey]: props.onGotoDashboard,
    [hotkeys.LOGOUT.hotkey]: props.onLogout,
    [hotkeys.GOTO_SEARCH.hotkey]: props.onGotoSearch,
    [hotkeys.GOTO_HELP.hotkey]: props.onGotoHelp,
    [hotkeys.CLOSE_FLYOUT.hotkey]: props.onHideFlyout,
  };

  useMousetraps(map);

  const {
    user,
    exporting,
    entryTypes,
    canEditAnything,
    canEditEntry,
    canAdminEntry,
    edit_mode,
    history = {},
    projectversion,
    privacyNoteVisible,
    creatingEntry,
    onDismissPrivacyNote,
    onCreateEntry,
    onSetEditMode,
    notificationsOpen,
    onToggleNotifications,
    notifications,
    unreadNotificationsNumber,
    onLoadNotifications,
    onMarkNotificationRead,
    onMarkAllNotificationsRead,
    onShowModal,
    onSwitchVersion,
    hasCatalogs,
  } = props;

  const linkImportFloormap = projectversion
    ? `/project/${projectversion.project.id}/version/${projectversion.id}/import-floormap/`
    : '/';
  const linkProject = projectversion
    ? `/project/${projectversion.project.id}/version/${projectversion.id}/`
    : '/';
  const linkProjectHistory = projectversion
    ? `/project/${projectversion.project.id}/version/${projectversion.id}/history`
    : '/';
  const linkCatalogs = projectversion
    ? `/project/${projectversion.project.id}/version/${projectversion.id}/catalogs`
    : '/';

  const location = useLocation();

  const handleProjectVersionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProjectVersionId = e.target.value;
    const projectVersionRegExp = new RegExp('/version/([0-9]*)');

    const basePath = location.pathname.replace(
      projectVersionRegExp,
      `/version/${selectedProjectVersionId}`
    );
    const entryIdRegExp = new RegExp('(entry\\/)([0-9]*)');
    const regexMatch = location.pathname.match(entryIdRegExp);

    const entryRegExp = new RegExp('/entry.*');
    const newBaseParh = basePath.replace(entryRegExp, '');
    if (history && regexMatch) {
      const entryId = regexMatch[regexMatch.length - 1];
      const newEntryId = getNewEntry(selectedProjectVersionId, parseInt(entryId), history);
      if (newEntryId) {
        const newEntryPath = getUpdatedPathById(basePath, newEntryId);
        onSwitchVersion(newEntryPath);
        return;
      }
    }
    onSwitchVersion(newBaseParh);
  };

  return (
    <nav className="head shadow">
      <PrivacyNote visible={privacyNoteVisible} onDismiss={onDismissPrivacyNote} />
      <Logo to={linkProject} />
      {projectversion && (
        <div className="navbar--project">
          <ul>
            <li className="dropdown-nav">
              <Link className="project-title" to={linkProject}>
                {projectversion.project.title}
              </Link>
              <ul className="subnav shadow">
                <li>
                  <Link to={linkProject}>Übersicht</Link>
                </li>
                {hasCatalogs && (
                  <li>
                    <Link to={linkCatalogs}>Kataloge</Link>
                  </li>
                )}
                <li>
                  <Link to={linkProjectHistory}>Historie</Link>
                </li>
                {canAdminEntry && (
                  <li>
                    <Link to={linkImportFloormap}>Raumplan importieren</Link>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      )}
      <div className="edit-tool">
        {canEditAnything && <EditModeButton onSetEditMode={onSetEditMode} editMode={edit_mode} />}
        {!!projectversion && (
          <ExportProjectButton isExporting={exporting} onShowModal={onShowModal} />
        )}
        {canEditEntry && (
          <CreateEntry
            entryTypes={entryTypes}
            creatingEntry={creatingEntry}
            projectVersionId={projectversion.id}
            onCreate={onCreateEntry}
          />
        )}
      </div>

      {!!user && !!projectversion && (
        <div style={{ paddingLeft: '10px' }}>
          <select defaultValue={projectversion.id} onChange={handleProjectVersionChange}>
            {user.projectversions
              .filter((version) => version.project.id === projectversion.project.id)
              .sort((a, b) => a.id - b.id)
              .map((version) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const date = version.created_at
                  ? new Date(version.created_at).toLocaleDateString('de', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                  : null;
                return (
                  <option value={version.id} key={version.id}>
                    {version.title} {!!date && `- (${date})`}
                  </option>
                );
              })}
          </select>
        </div>
      )}
      {!!user && (
        <div className="navbar--user">
          <ul>
            <li className="notifications">
              <Notification
                notifications={notifications}
                unreadNotificationsNumber={unreadNotificationsNumber}
                isOpen={notificationsOpen}
                onLoadNotifications={onLoadNotifications}
                onToggleNotifications={onToggleNotifications}
                onMarkNotificationRead={(number) => onMarkNotificationRead(number)}
                onMarkAllNotificationsRead={onMarkAllNotificationsRead}
              />
            </li>
            <li className="dropdown-nav">
              <OwnProfileLink username={`${user.first_name} ${user.last_name}`} />
            </li>
            <li className="help">
              <Link to="/help">Hilfe</Link>
            </li>
            <li>
              <Link to="/privacy">Datenschutz</Link>
            </li>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};
export default NavBar;
