import { AssetActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import {
  AssetsUploadPayload,
  AssetUploadFailedPayload,
  AssetUploadingPayload,
  AssetUploadPayload,
  AssetUploadResponsePayload,
  MovedPayload,
} from 'typings/models';
import { createAction } from 'redux-actions';

export const moveAsset = createAction<actions.MoveAssetAction, number, number>(
  AssetActionType.MOVE_ASSET,
  (assetId, toPosition) => ({ assetId, toPosition })
);

export const movingAsset = createAction<actions.MovingAssetAction, number, number>(
  AssetActionType.MOVING_ASSET,
  (assetId, toPosition) => ({ assetId, toPosition })
);

export const movedAsset = createAction<actions.MovedAssetAction, number, number, MovedPayload>(
  AssetActionType.MOVED_ASSET,
  (assetId, toPosition, json) => ({ assetId, toPosition, json })
);

export const moveAssetFailed = createAction<actions.MoveAssetFailedAction, number, number>(
  AssetActionType.MOVE_ASSET_FAILED,
  (assetId, toPosition) => ({ assetId, toPosition })
);

export const uploadAssets = createAction<actions.UploadAssetsAction, AssetsUploadPayload>(
  AssetActionType.UPLOAD_ASSETS
);

export const uploadAsset = createAction<actions.UploadAssetsAction, AssetUploadPayload>(
  AssetActionType.UPLOAD_ASSET
);

export const uploadingAsset = createAction<actions.UploadingAssetAction, AssetUploadingPayload>(
  AssetActionType.UPLOADING_ASSET
);
export const uploadedAsset = createAction<
  actions.UploadedAssetAction,
  string,
  AssetUploadResponsePayload
>(AssetActionType.UPLOADED_ASSET);

export const uploadAssetFailed = createAction<
  actions.UploadedAssetAction,
  AssetUploadFailedPayload
>(AssetActionType.UPLOAD_ASSET_FAILED);

export const resetAssetUpload = createAction<actions.ResetAssetUpload>(
  AssetActionType.RESET_ASSET_UPLOAD
);

export const deleteAsset = createAction<actions.DeleteAssetAction, string, number, number>(
  AssetActionType.DELETE_ASSET,
  ({ entity_name, entity_id, asset_id }) => ({ entity_name, entity_id, asset_id })
);

export const deletedAsset = createAction<actions.DeletedAssetAction, number, string, number>(
  AssetActionType.DELETED_ASSET
);

export const moveAssetToEntity = createAction<
  actions.MoveAssetToEntityAction,
  number,
  string,
  number,
  string
>(AssetActionType.MOVE_ASSET_TO_ENTITY, (assetId, entityName, entityId, fieldName) => ({
  assetId,
  entityName,
  entityId,
  fieldName,
}));

export const movingAssetToEntity = createAction<
  actions.MovingAssetToEntityAction,
  number,
  string,
  number,
  string
>(AssetActionType.MOVING_ASSET_TO_ENTITY, (assetId, entityName, entityId, fieldName) => ({
  assetId,
  entityName,
  entityId,
  fieldName,
}));

export const movedAssetToEntity = createAction<
  actions.MovedAssetToEntityAction,
  number,
  string,
  number,
  string,
  any
>(AssetActionType.MOVED_ASSET_TO_ENTITY, (assetId, entityName, entityId, fieldName, json) => ({
  assetId,
  entityName,
  entityId,
  fieldName,
  json,
}));

export const moveAssetToEntityFailed = createAction<
  actions.MoveAssetToEntityFailedAction,
  number,
  string,
  number,
  string
>(AssetActionType.MOVE_ASSET_TO_ENTITY_FAILED, (assetId, entityName, entityId, fieldName) => ({
  assetId,
  entityName,
  entityId,
  fieldName,
}));

export const markAssetToMove = createAction<actions.MarkAssetToMoveAction, number>(
  AssetActionType.MARK_ASSET_TO_MOVE,
  (assetId) => ({ assetId })
);
