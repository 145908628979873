import { GuiActionType } from 'actions/actionTypes';
import { Action, SetPrivacyNoteVisibleAction } from 'typings/actions';
import { SettingsState } from 'typings/state';
import { handleActions } from 'redux-actions';

const initialState: SettingsState = {
  privacy_note_visible: false,
};

const settingsReducer = handleActions<SettingsState, Action>(
  {
    [GuiActionType.SET_PRIVACY_NOTE_VISIBLE]: (
      state: SettingsState,
      action: SetPrivacyNoteVisibleAction
    ): SettingsState => ({
      ...state,
      privacy_note_visible: action.payload,
    }),
  },
  { ...initialState }
);

export default settingsReducer;

export const getPrivacyNoteVisible = (state: SettingsState) => state.privacy_note_visible;
