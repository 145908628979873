import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as authActions from 'actions/AuthActions';
import * as projectActions from 'actions/ProjectActions';
import * as guiActions from 'actions/GuiActions';
import ProjectSettings from 'components/ProjectSettings/ProjectSettings';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { ModalType, PermissionLevel } from 'typings/enums';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';
import { push } from 'connected-react-router';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, ['projectId', 'versionId']);
  return {
    canAdminEntry: selectors.canAdminEntry(state, versionId),
    editMode: state.loadingStates.edit_mode,
    loadingProjectVersion: state.loadingStates.loading_projectversion,
    isAddingProjectMember: selectors.isAddingProjectMember(state),
    isSearchingUsers: selectors.isSearchingUsers(state),
    project: selectors.getProject(state, projectId),
    projectversion: selectors.getProjectVersion(state, versionId),
    user: selectors.getUser(state),
    userSearchResult: selectors.getUserSearchResult(state),
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, ['projectId', 'versionId']);
  return {
    onAddProjectMember: (userId: number, permissions: PermissionLevel) =>
      dispatch(projectActions.addProjectMember(versionId, userId, permissions)),
    onRemoveProjectMember: (userId: number) =>
      dispatch(projectActions.removeProjectMember(versionId, userId)),
    onSearchUser: (query: string) => dispatch(authActions.searchUser(query)),
    onShowModal: (title: string, text: any) =>
      dispatch(guiActions.showModal(ModalType.WARNING, text, title)),
    onHideModal: () => dispatch(guiActions.hideModal()),
    onExportProject: (generateNewFilenames?: boolean) => dispatch(projectActions.exportProject(projectId, generateNewFilenames)),
    onInitiateProjectExport: () => dispatch(projectActions.initiateProjectExport(projectId)),
    onAbortProjectExport: () => dispatch(projectActions.abortProjectExport(projectId)),
    onReturnToDashboard: () => dispatch(push('/dashboard')),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectSettings));
