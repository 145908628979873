import DeleteButton from 'components/DeleteButton';
import PermissionSelect from 'components/PermissionSelect';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import UserSelect from 'components/UserSelect';
import { PermissionLevel } from 'typings/enums';
import { ProjectVersion, User, UserShort } from 'typings/models';
import { permissionReadable } from 'utils/objects';
import React, { Component } from 'react';

type Props = {
  projectversion: ProjectVersion;
  user: User;
  isSearchingUsers: boolean;
  isAddingProjectMember: boolean;
  userSearchResult: UserShort[];
  onRemoveProjectMember: (membershipId: number) => void;
  onAddProjectMember: (userId: number, permissions: PermissionLevel) => void;
  onSearchUser: (query: string) => void;
};

type State = {
  user?: UserShort;
  permissions: PermissionLevel;
};

class TableEdit extends Component<Props> {
  public state: State = {
    permissions: PermissionLevel.READ,
    user: undefined,
  };

  public selectUser = (user: UserShort) => this.setState({ user });
  public setPermissionLevel = (permissions: PermissionLevel | undefined) =>
    this.setState({ permissions });
  public addProjectMember = () => {
    const { user: { id = null } = { id: undefined }, permissions } = this.state;
    !!id && this.props.onAddProjectMember(id as unknown as number, permissions);
    this.setState({ user: undefined, permissions: undefined });
  };

  public render() {
    const {
      user,
      projectversion: { members = [] },
      isSearchingUsers,
      isAddingProjectMember,
      userSearchResult,
      onSearchUser,
      onRemoveProjectMember,
    } = this.props;
    const memberList = members.map((member) => member.user.id);
    return (
      <>
        <div>
          Mitarbeiter:in hinzufügen:{' '}
          {isAddingProjectMember ? (
            <SimpleLoader />
          ) : (
            <>
              <div className="add-member-container">
                <div>
                  <UserSelect
                    isLoading={isSearchingUsers}
                    users={userSearchResult
                      .filter((u) => u.id !== user.id)
                      .filter((u) => !memberList.includes(u.id))}
                    onSearch={onSearchUser}
                    onSelectUser={this.selectUser}
                  />
                  <PermissionSelect onChange={this.setPermissionLevel} />
                </div>
                <button className="big" onClick={this.addProjectMember}>
                  Hinzufügen
                </button>
              </div>
            </>
          )}
        </div>
        <table className="room-data">
          <tbody>
            {members.map((membership) => (
              <tr key={membership.id}>
                <th>
                  {membership.user.last_name}, {membership.user.first_name}
                </th>
                <td>
                  <a className="plain" href={`mailto:${membership.user.email}`}>
                    {membership.user.email}
                  </a>
                </td>
                <td>{permissionReadable(membership.permissions)}</td>
                <td>
                  {membership.user.id !== user.id && (
                    <DeleteButton
                      onDelete={() => onRemoveProjectMember(membership.id)}
                      question={`${membership.user.first_name} ${membership.user.last_name} entfernen?`}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default TableEdit;
