import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import * as selectors from 'reducers/app';
import * as searchActions from 'actions/SearchActions';
import * as projectActions from 'actions/ProjectActions';
import SearchEntries from 'components/SearchEntries';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
      query: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'projectId',
    'versionId',
  ]);
  const {
    match: {
      params: { query },
    },
  } = ownProps;

  const decodedQuery = query ? decodeURIComponent(query) : '';

  return {
    projectId,
    versionId,
    query: decodedQuery,
    results: selectors.getFilteredFulltextResults(state),
    countEntries: state.entries.search_results.count_entries,
    countWalls: state.entries.search_results.count_walls,
    countDepartmentEntries: state.entries.search_results.count_department_entries,
    searchingStatus: state.loadingStates.searching_entries,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'projectId',
    'versionId',
  ]);

  return {
    onExportProjectVersionPdf: (version, ids, wallIds, departmentEntryIds, searchTerm) =>
      dispatch(
        projectActions.exportProjectVersionPdf(version, ids, {
          searchTerm,
          wallIds,
          departmentEntryIds,
          downscaleImageQuality: true,
        })
      ),
    onResetSearch: () => dispatch(searchActions.resetSearchEntries()),
    onSearch: (query: string, operatorMode: string) => dispatch(searchActions.searchEntries(versionId, query, operatorMode)),
    onSetText: (query: string) =>
      dispatch(
        push(`/project/${projectId}/version/${versionId}/search/${encodeURIComponent(query)}`)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchEntries);
