import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as entryActions from 'actions/EntryActions';
import * as guiActions from 'actions/GuiActions';
import * as projectActions from 'actions/ProjectActions';
import ImportFloormap from 'components/ImportFloormap/ImportFloormap';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { ModalType } from 'typings/enums';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

type Props = {
  match: {
    params: {
      projectId: string;
      versionId: string;
    };
  };
};

const mapStateToProps = (state: State, ownProps: Props) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'projectId',
    'versionId',
  ]);

  const uploadingStatus = state.loadingStates.uploading_floormap.status;
  const importingStatus = state.loadingStates.importing_floormap.status;
  const reloadProjectStatus = state.loadingStates.loading_projectversion.status;
  const loadingEntriesStatus = state.loadingStates.loading_entries.status;

  const isUploading = uploadingStatus === 'SAVING';
  const isImporting = importingStatus === 'SAVING';
  const isReloadingProject = reloadProjectStatus === 'LOADING';
  const isLoadingEntries = loadingEntriesStatus === 'LOADING';
  const uploadDone = uploadingStatus === 'SAVED';
  const importDone = importingStatus === 'SAVED';
  const hasUploadError = uploadingStatus === 'FAILED';
  const hasLoadEntriesError = loadingEntriesStatus === 'FAILED';

  return {
    projectId,
    entries: selectors.getEntries(state, versionId),
    entryTypes: selectors.getEntryTypes(state, versionId),
    facility_levels: uploadDone
      ? state.loadingStates.uploading_floormap.values.json.facility_levels
      : null,
    hasUploadError,
    importDone,
    importResult: importDone ? state.loadingStates.importing_floormap.values.json : null,
    isLoadingEntries,
    isImporting,
    isUploading,
    isReloadingProject,
    hasLoadEntriesError,
    layers: uploadDone ? state.loadingStates.uploading_floormap.values.json.layers : [],
    preview: uploadDone ? state.loadingStates.uploading_floormap.values.json.preview : null,
    projectversion: selectors.getProjectVersion(state, versionId),
    token: uploadDone ? state.loadingStates.uploading_floormap.values.json.token : null,
    type: uploadDone ? state.loadingStates.uploading_floormap.values.json.type : null,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void, ownProps) => {
  const { projectId, versionId } = getIntsFromParams(ownProps.match.params, [
    'entryId',
    'projectId',
    'versionId',
  ]);
  return {
    onImportFloormap: (token, facilityLevel, rooms, creator, creationDate) =>
      dispatch(projectActions.importFloormap(token, projectId, versionId, facilityLevel, rooms, creator, creationDate)),
    onLoadEntries: () => dispatch(entryActions.loadEntries(projectId)),
    onResetFloormapImport: () => dispatch(projectActions.resetFloormapImport()),
    onShowModal: (type: ModalType, title: string, text: string) =>
      dispatch(guiActions.showModal(type, text, title)),
    onUploadFloormap: (files: File[], facilityLevel: number) =>
      dispatch(projectActions.uploadFloormap(versionId, facilityLevel, files)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ImportFloormap));
