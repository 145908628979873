import { push } from 'connected-react-router';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as entryActions from 'actions/EntryActions';
import * as projectActions from '../actions/ProjectActions';
import * as api from 'api/WebApi';
import { checkResponseStatus } from './utils';

import {
  createdEntry,
  creatingEntry,
  deletedEntry,
  createdEntryFinding,
  createEntryFindingFailed,
  creatingEntryFinding,
  deletedEntryFinding,
  editingEntityField,
  loadChoicesFailed,
  loadedChoices,
  loadedEntries,
  loadedEntry,
  loadEntries,
  loadEntriesFailed,
  loadEntryFailed,
  loadingChoices,
  loadingEntries,
  loadingEntry,
  normalizeEntry,
  savedEntityField,
} from 'actions/EntryActions';

import { EntryActionType } from 'actions/actionTypes';
import { showModal } from 'actions/GuiActions';
import { ModalType } from 'typings/enums';
import { workflowLoadFacilityGeoJSON } from './ProjectSagas';

export function* workflowLoadEntry(action) {
  const entryId = parseInt(action.payload, 10);
  yield put(loadingEntry(entryId));

  let json = null;
  try {
    const response = yield call(api.loadEntry, entryId);
    checkResponseStatus(response);
    json = yield response.json();
    yield put(normalizeEntry(json));
    yield put(loadedEntry(entryId, json));
  } catch (errors) {
    yield put(showModal(ModalType.WARNING, `Konnte Eintrag ${entryId} nicht laden`));
    yield put(loadEntryFailed({ id: entryId, errors }));
  }
}

export function* workflowLoadEntryHistory(action) {
  const {
    payload: { uuid },
  } = action;
  yield put(entryActions.loadingEntryHistory(uuid));
  try {
    const response = yield call(api.loadEntryHistory, uuid);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(entryActions.loadedEntryHistory(uuid, json));
  } catch (errors) {
    yield put(showModal(ModalType.WARNING, `Konnte Historie für Eintrag ${uuid} nicht laden`));
    yield put(entryActions.loadEntryHistoryFailed({ uuid, errors }));
  }
}

export function* workflowLoadEntries(action) {
  try {
    yield put(loadingEntries());
    const response = yield call(api.loadEntries, action.payload);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(loadedEntries(json));
  } catch (e) {
    yield put(showModal(ModalType.WARNING, `Konnte Eintragsliste nicht laden`));
    yield put(loadEntriesFailed(e));
  }
}

export function* workflowLoadChoices(action) {
  const {
    payload: { choices_name, choices_get_params },
  } = action;
  try {
    yield put(loadingChoices(choices_name));
    const response = yield call(api.loadChoices, choices_name, choices_get_params || '');
    checkResponseStatus(response);

    const json = yield response.json();
    yield put(loadedChoices({ choices_name, json }));
  } catch (e) {
    yield put(showModal(ModalType.WARNING, `Konnte Auswahl ${choices_name} nicht laden`));
    yield put(loadChoicesFailed(choices_name, choices_get_params));
  }
}

export function* workflowSaveEntityField(action) {
  const {
    payload: { entity_name, entity_id, fieldname, value, facility_level_id },
  } = action;
  const reducer_message = {
    entity_id,
    entity_name,
    errors: null,
    fieldname,
    status: 'SAVING',
    value,
    facility_level_id,
  };
  yield put(editingEntityField(reducer_message));
  try {
    const response = yield call(api.saveEntity, entity_name, entity_id, { [fieldname]: value });
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(savedEntityField({ ...reducer_message, json }));
  } catch (error) {
    try {
      // @ts-ignore
      const json = yield error.response.json();
      const errors = json["__all__"] !== undefined ? json["__all__"] : json[fieldname]
      yield put(
        editingEntityField({ ...reducer_message, errors: errors, status: 'EDITING' })
      );
    } catch (e2) {
      yield put(showModal(ModalType.WARNING, `Konnte Änderungen nicht speichern`));
      yield put(editingEntityField({ ...reducer_message, status: 'EDITING' }));
    }
  }
}

export function* workflowSavedEntityField(action) {
  const {
    payload: { entity_name, fieldname, entity_id, facility_level_id },
  } = action;
  if (entity_name === 'entries' && fieldname === 'entry_type') {
    yield workflowLoadEntry(entryActions.loadEntry(entity_id));
  } else if (entity_name === 'entries' && fieldname === 'feature') {
    yield workflowLoadFacilityGeoJSON(projectActions.loadFacilityGeoJSON(facility_level_id));
  }
}

export function* workflowCreateEntry(action) {
  const {
    payload,
    payload: { versionId, signature, entryType, title },
  } = action;
  try {
    yield put(creatingEntry());
    const response = yield call(api.createEntry, signature, versionId, entryType, title);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(normalizeEntry(json));
    yield put(createdEntry(json));
    yield put(loadEntries(json.project));
    yield put(
      push(`/project/${json.project}/version/${json.projectversion}/entry/${json.id}/base`)
    );
  } catch (error) {
    // @ts-ignore
    const json = yield error.response.json();
    yield put(showModal(ModalType.WARNING, `Konnte Eintrag nicht erstellen`));
    yield put(entryActions.createEntryFailed({ errors: json, values: payload }));
  }
}

function* workflowDeleteEntry(action) {
  const {
    payload: { entry_id, project_id, versionId },
  } = action;
  try {
    const response = yield call(api.deleteEntry, entry_id);
    checkResponseStatus(response);
    yield put(deletedEntry(entry_id));
    yield put(push(`/project/${project_id}/version/${versionId}`));
  } catch (error) {
    yield put(showModal(ModalType.WARNING, `Konnte Eintrag nicht löschen`));
  }
}

function* workflowCreateEntryFinding(action) {
  yield put(creatingEntryFinding());
  const { token, notes, description, projectversion, entry } = action.payload;
  try {
    const response = yield call(
      api.createEntryFinding,
      token,
      description,
      notes,
      projectversion,
      entry
    );
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(createdEntryFinding(entry, json));
  } catch (error) {
    // @ts-ignore
    const json = yield error.response.json();
    yield put(createEntryFindingFailed({ errors: json, values: action.payload }));
  }
}

function* workflowDeleteEntryFiding(action) {
  try {
    const response = yield call(api.deleteEntryFinding, action.payload);
    checkResponseStatus(response);
    yield put(deletedEntryFinding(action.payload));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

// Watchers

export function* watchLoadEntries() {
  yield takeLatest(EntryActionType.LOAD_ENTRIES, workflowLoadEntries);
}

export function* watchLoadEntry() {
  yield takeLatest(EntryActionType.LOAD_ENTRY, workflowLoadEntry);
}

export function* watchLoadChoices() {
  yield takeEvery(EntryActionType.LOAD_CHOICES, workflowLoadChoices);
}

export function* watchCreateEntry() {
  yield takeLatest(EntryActionType.CREATE_ENTRY, workflowCreateEntry);
}

export function* watchDeleteEntry() {
  yield takeLatest(EntryActionType.DELETE_ENTRY, workflowDeleteEntry);
}

export function* watchSaveEntityField() {
  yield takeLatest(EntryActionType.SAVE_ENTITY_FIELD, workflowSaveEntityField);
}

export function* watchSavedEntityField() {
  yield takeLatest(EntryActionType.SAVED_ENTITY_FIELD, workflowSavedEntityField);
}

export function* watchCreateEntryFinding() {
  yield takeLatest(EntryActionType.CREATE_ENTRY_FINDING, workflowCreateEntryFinding);
}

export function* watchDeleteEntryFinding() {
  yield takeLatest(EntryActionType.DELETE_ENTRY_FINDING, workflowDeleteEntryFiding);
}

export function* watchLoadEntryHistory() {
  yield takeLatest(EntryActionType.LOAD_ENTRY_HISTORY, workflowLoadEntryHistory);
}
