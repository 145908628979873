export const isTouchscreen = (): boolean => {
  if (window.PointerEvent && 'maxTouchPoints' in navigator) {
    if (navigator.maxTouchPoints > 0) {
      return true;
    }
  } else {
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
      return true;
    } else if (window.TouchEvent || 'ontouchstart' in window) {
      return true;
    }
  }
  return false;
};

export const isSafari = (userAgent: string) => /^((?!chrome|android).)*safari/i.test(userAgent);
