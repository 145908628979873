import React, { useState, useEffect } from 'react';

import { SlideInLeft } from 'components/Animated';
import TileFloormapComposer from 'components/TileFloormapComposer';
import { FacilityLevel } from 'typings/models';

import FacilityListItem from './FacilityListItem';
import FlyoutRoomList from './FlyoutRoomList';
import { IFlyoutProps } from './types/Flyout.types';

const Flyout = (props: IFlyoutProps) => {
  const {
    editMode,
    initialFacilityLevel,
    projectversion,
    entry,
    entries,
    projectEntries,
    history,
    onGotoEntity,
    onHide,
    onSaveEntityField,
    onSetEditMode,
  } = props;

  const [state, setState] = useState<{ currentLevel?: FacilityLevel; floormapVisible: boolean }>({
    currentLevel: initialFacilityLevel,
    floormapVisible: !!initialFacilityLevel,
  });
  const {
    currentLevel,
    currentLevel: { title = '', floormap = '', floormap_type = '' } = {},
    floormapVisible: floormapVisible,
  } = state;

  useEffect(() => {
    !state.floormapVisible && setState({ ...state, floormapVisible: true });
  }, [state.floormapVisible]);

  const setFacilityLevel = (facilityLevel: FacilityLevel) =>
    setState({ currentLevel: facilityLevel, floormapVisible: false });

  const gotoEntity = (entityId: number, otherVersionId?: number) => {
    onGotoEntity(entityId, otherVersionId);
    onHide();
  };

  return (
    <SlideInLeft initialPose="hidden" pose="visible" className="map-navigation-layer">
      <div className="map-navigation-list">
        <ul>
          {projectversion.facilities.map((facility) => (
            <FacilityListItem
              key={facility.id}
              facility={facility}
              currentLevel={currentLevel}
              onSetFacilityLevel={setFacilityLevel}
            />
          ))}
        </ul>
      </div>
      {!!floormap && !!floormap_type && floormapVisible ? (
        <TileFloormapComposer
          entry={entry}
          entries={entries}
          projectEntries={projectEntries}
          editMode={editMode}
          facilityLevel={currentLevel}
          history={history}
          onClose={onHide}
          onGotoEntity={gotoEntity}
          onSaveEntityField={onSaveEntityField}
          onSetEditMode={onSetEditMode}
        />
      ) : (
        !!currentLevel && (
          <FlyoutRoomList
            title={title}
            entries={entries}
            currentEntry={entry}
            onGotoEntity={gotoEntity}
            facilityLevel={currentLevel}
          />
        )
      )}
    </SlideInLeft>
  );
};

export default Flyout;
