import { ICatalogFormProps } from 'components/CatalogDialog/CatalogDialog.types';
import { NewCatalogEntryDetailsContent } from 'components/CatalogEntryDetailsContent';
import React from 'react';
import { CatalogEntry } from 'typings/models';

export const NewCatalogEntryForm: React.FC<ICatalogFormProps<CatalogEntry>> = ({
  onSubmit,
  value,
  onChange,
}) => {
  return (
    <>
      <NewCatalogEntryDetailsContent catalogEntry={value} onChange={onChange} />
      <p className="catalog-dialog-action-container left mr-5">
        <button onClick={() => onChange(null)} className="catalog-dialog-action-button">
          Abbrechen
        </button>
      </p>
      <p className="catalog-dialog-action-container right">
        <button
          onClick={onSubmit}
          className="catalog-dialog-action-button"
          disabled={!value.title || !value.signature}
        >
          Speichern
        </button>
      </p>
    </>
  );
};
