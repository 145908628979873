import merge from 'lodash/merge';
import { denormalize, normalize } from 'normalizr';
import { handleActions } from 'redux-actions';

import { EntryActionType } from 'actions/actionTypes';
import entrySchema from 'api/entry_schema';
import * as actions from 'typings/actions';
import { EntityState } from 'typings/state';

import { initialState } from './entities.initial';
import { getProjectVersionPermissions } from './projects';
import entryfindingSchema from 'api/finding_schema';

const entitiesReducer = handleActions<EntityState, actions.Action>(
  {
    [EntryActionType.NORMALIZE_ENTRY]: (
      state: EntityState,
      action: actions.NormalizeEntryAction
    ): EntityState => {
      return merge({}, state, normalize(action.payload, entrySchema).entities);
    },

    [EntryActionType.LOADED_ENTRY_HISTORY]: (
      state: EntityState,
      action: actions.LoadedEntryHistoryAction
    ): EntityState => {
      const newState = { ...state };
      newState.history[action.payload.uuid] = action.payload.json;
      return newState;
    },

    [EntryActionType.CREATED_ENTRY_FINDING]: (
      state: EntityState,
      action: actions.CreatedEntryFindingAction
    ): EntityState => {
      const { payload } = action;
      const newState = { ...state };
      newState.entryfindings[payload.entryfinding.id] = normalize(
        action.payload.entryfinding,
        entryfindingSchema
      ).entities.findings?.[payload.entryfinding.id];
      newState.entries[payload.entry].entryfindings?.push(payload.entryfinding.id);
      return newState;
    },

    [EntryActionType.DELETED_ENTRY_FINDING]: (
      state: EntityState,
      action: actions.DeletedEntryFindingAction
    ): EntityState => {
      const { payload } = action;
      const newState = { ...state };
      Object.keys(newState.entries).forEach((key) => {
        if (newState.entries[key].findings.includes(payload)) {
          newState.entries[key].findings = newState.entries[key].findings.filter(
            (f) => f !== payload
          );
        }
      });
      delete newState.entryfindings[payload];
      return newState;
    },
  },

  { ...initialState }
);

export default entitiesReducer;

export const canEditEntry = (state: EntityState, versionId: number) => {
  const permissions = getProjectVersionPermissions(state, versionId);
  return !!permissions && permissions > 0;
};

export const canAdminEntry = (state: EntityState, versionId: number) => {
  const permissions = getProjectVersionPermissions(state, versionId);
  return !!permissions && permissions > 1;
};

export const getEntry = (state: EntityState, entryId: number): any => {
  if (!state.entries) {
    return null;
  }
  return denormalize(state.entries[entryId], entrySchema, state);
};
