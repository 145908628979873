import * as entryActions from 'actions/EntryActions';
import * as loadingStateActions from 'actions/LoadingStates';

import CatalogDetails from 'components/CatalogDetails/CatalogDetails';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { State } from 'typings/state';
import { getIntsFromParams } from 'utils/objects';

const mapStateToProps = (state: State, ownProps) => {
  const { versionId, catalogId } = getIntsFromParams(ownProps.match.params, [
    'versionId',
    'catalogId',
  ]);
  return {
    ...ownProps,
    saveStatusCatalogEntry: state.loadingStates.creating_catalog_entry,
    projectversion: selectors.getProjectVersion(state, versionId),
    catalog: selectors.getCatalog(state, catalogId),
    catalogEntries: selectors.getCatalogEntries(state, catalogId),
    edit_mode: state.loadingStates.edit_mode,
  };
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  onAddCatalogEntry: (catalogId: number, signature: string, title?: string, description?: string) =>
    dispatch(entryActions.createCatalogEntry(catalogId, title, signature, description)),
  onResetCatalogEntryLoadingState: () =>
    dispatch(loadingStateActions.reset('creating_catalog_entry')),
  onDeleteCatalogEntry: (catalogId: number) => dispatch(entryActions.deleteCatalogEntry(catalogId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogDetails));
