import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';

import Routes from 'components/Routes';
import { history } from 'store';

type Props = {
  history: any;
  user: any;
  token: string;
};

class Root extends React.Component<Props> {
  public render() {
    const { token } = this.props;
    return (
      <ConnectedRouter history={history}>
        <Routes token={token} />
      </ConnectedRouter>
    );
  }
}

export default Root;
