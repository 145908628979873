import * as React from 'react';

import { EntryType } from 'typings/models';

type Props = {
  entryTypes: EntryType[];
  currentValue?: number;
  change: (number) => void;
};

class ObjectTypeFilter extends React.Component<Props> {
  public change = (e: React.FormEvent<HTMLSelectElement>) => {
    this.props.change(e.currentTarget.value);
  };

  public render() {
    const { entryTypes, currentValue = '' } = this.props;
    return (
      <select size={1} defaultValue={String(currentValue)} onChange={this.change}>
        <option value="">Alle Eintragstypen</option>
        {entryTypes.map((entryType) => (
          <option key={entryType.id} value={entryType.id}>
            {entryType.title}
          </option>
        ))}
      </select>
    );
  }
}

export default ObjectTypeFilter;
