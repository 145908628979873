import * as React from 'react';

import SimpleLoader from 'components/SimpleLoader';
import { IdTitle, ListResponse } from 'typings/models';
import InlineEditBaseWidget, { BaseProps, DISPLAY_MODE } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: IdTitle[];
  loaded_choices: {
    [key: string]: 'LOADING' | 'FAILED' | ListResponse<IdTitle>;
  };
  choices: string | Array<[number | string, string]>;
  choices_get_params?: string;
  onLoadChoices?: (choice_type: string, choices_get_params?: string) => void;
};

class InlineEditSelectMultipleWidget extends InlineEditBaseWidget<Props> {
  public checkboxes: { [key: string]: any } = {};
  public choices: Array<[number | string, string]> = [];
  protected refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;

  public getValueReadable = () =>
    this.props.value && this.props.value.length ? (
      <span>{this.props.value.map((value) => value.title).join(', ')}</span>
    ) : (
      ''
    );

  public getChoices = () => {
    const { choices, onLoadChoices, choices_get_params } = this.props;

    if (Array.isArray(choices)) {
      this.choices = choices;
      return;
    }

    if (!this.isLoadingChoices()) {
      onLoadChoices && onLoadChoices(choices, choices_get_params);
    }
  };

  public isLoadingChoices = () => {
    const { choices } = this.props;
    if (Array.isArray(choices)) {
      return false;
    }

    return (
      typeof this.props.choices === 'string' &&
      this.props.loaded_choices[this.props.choices] === 'LOADING'
    );
  };

  public setChoicesFromResponse = (json) =>
    json.results.map((item) => {
      return [item.id, item.title];
    });

  public onBeginEdit = () => this.getChoices();

  public componentWillReceiveProps(nextProps) {
    if (
      this.determineDisplayMode() === DISPLAY_MODE.EDITING &&
      !nextProps.loaded_choices &&
      !this.isLoadingChoices()
    ) {
      this.getChoices();
    }
    if (
      typeof this.props.choices === 'string' &&
      nextProps.loaded_choices &&
      nextProps.loaded_choices[this.props.choices]
    ) {
      const listResponse = nextProps.loaded_choices[this.props.choices];
      if (!['LOADING', 'FAILED'].includes(listResponse)) {
        this.choices = this.setChoicesFromResponse(listResponse);
      }
    }
  }

  public setRef = (ref) => {
    !!ref && (this.checkboxes[ref.id] = ref);
  };

  public getEditWidget() {
    if (this.isLoadingChoices()) {
      return <SimpleLoader />;
    }
    return (
      <ul>
        {this.choices.map((choice) => (
          <li key={choice[0]}>
            <label>
              <input
                ref={this.setRef}
                type="checkbox"
                id={`choice-${String(choice[0])}`}
                value={choice[0]}
                defaultChecked={Boolean(this.props.value.find((item) => item.id === choice[0]))}
              />
              {choice[1]}
            </label>
          </li>
        ))}
      </ul>
    );
  }

  public saveChanges = () => {
    const checked_ids = Object.keys(this.checkboxes)
      .reduce((acc: any[], key) => {
        return [
          ...acc,
          this.checkboxes[key].checked ? this.checkboxes[key].id.split('-')[1] : null,
        ];
      }, [])
      .filter((item) => item !== null);

    this.props.onSaveEntityField({
      entity_id: this.props.entity_id,
      entity_name: this.entity_name,
      fieldname: this.fieldname,
      value: checked_ids,
    });
  };
}

export default InlineEditSelectMultipleWidget;
