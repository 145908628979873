import { GuiActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { ModalType } from 'typings/enums';
import { FacilityLevel } from 'typings/models';
import { createAction } from 'redux-actions';
import { ToastOptions } from 'typings/options';

export const setEditMode = createAction<actions.SetEditModeAction, boolean>(
  GuiActionType.SET_EDIT_MODE
);
export const toggleEditMode = createAction<actions.ToggleEditModeAction>(
  GuiActionType.TOGGLE_EDIT_MODE
);
export const disableEditMode = createAction<actions.DisableEditModeAction>(
  GuiActionType.DISABLE_EDIT_MODE
);

// eslint-disable-next-line @typescript-eslint/ban-types
export const showModal = createAction<actions.ShowModalAction, ModalType, string, () => {}>(
  GuiActionType.SHOW_MODAL,
  (type, content, title?, onClose?) => ({
    content,
    onClose,
    title,
    type,
  })
);

export const updateModalTitle = createAction<actions.UpdateModalTitleAction, string>(
  GuiActionType.UPDATE_MODAL_TITLE,
  (title) => ({
    title,
  })
);

export const hideModal = createAction<actions.HideModalAction>(GuiActionType.HIDE_MODAL);

export const loadPrivacyNoteVisible = createAction<actions.LoadPrivacyNoteVisibleAction>(
  GuiActionType.LOAD_PRIVACY_NOTE_VISIBLE
);
export const dismissPrivacyNote = createAction<actions.DismissPrivacyNoteAction>(
  GuiActionType.DISMISS_PRIVACY_NOTE
);
export const setPrivacyNoteVisible = createAction<actions.SetPrivacyNoteVisibleAction>(
  GuiActionType.SET_PRIVACY_NOTE_VISIBLE
);

export const entrylistUp = createAction<actions.EntrylistUpAction>(GuiActionType.ENTRYLIST_UP);
export const entrylistDown = createAction<actions.EntrylistDownAction>(
  GuiActionType.ENTRYLIST_DOWN
);
export const setObjectTypeFilter = createAction<actions.SetObjectTypeFilterAction, string>(
  GuiActionType.SET_OBJECT_TYPE_FILTER
);
export const setFacilityLevelFilter = createAction<actions.SetFacilityLevelFilterAction, string>(
  GuiActionType.SET_FACILITY_LEVEL_FILTER
);
export const setDepartmentFilter = createAction<actions.SetDepartmentFilterAction, string>(
  GuiActionType.SET_DEPARTMENT_FILTER
);
export const clearFilters = createAction<actions.ClearEntrylistFiltersAction>(
  GuiActionType.CLEAR_ENTRYLIST_FILTERS
);

export const showFlyout = createAction<actions.ShowFlyoutAction, FacilityLevel, number>(
  GuiActionType.SHOW_FLYOUT,
  (initialFacilityLevel?, initialRoom?) => ({ initialFacilityLevel, initialRoom })
);

export const hideFlyout = createAction<actions.HideFlyoutAction>(GuiActionType.HIDE_FLYOUT);

export const showToast = createAction<actions.ShowToastAction, string, ToastOptions>(
  GuiActionType.SHOW_TOAST,
  (message, options) => ({ message, options })
);

export const toggleNotifications = createAction<actions.ToggleNotificationAction>(
  GuiActionType.TOGGLE_NOTIFICATIONS
);
