import { call, put, takeLatest } from 'redux-saga/effects';

import { showModal } from 'actions/GuiActions';
import * as api from 'api/WebApi';
import { checkResponseStatus } from './utils';

import { SearchActionType } from 'actions/actionTypes';
import { searchedEntries, searchEntriesFailed, searchingEntries } from 'actions/SearchActions';
import { SearchEntriesAction } from 'typings/actions';
import { ModalType } from 'typings/enums';

export function* workflowSearchEntries(action: SearchEntriesAction) {
  const {
    payload: { versionId, query, operatorMode },
  } = action;

  try {
    yield put(searchingEntries(versionId, query, operatorMode));
    const response = yield call(api.searchEntries, versionId, query, operatorMode);
    checkResponseStatus(response);
    const json = yield response.json();
    yield put(searchedEntries(versionId, query, json.results, json.count_entries, json.count_walls, json.count_department_entries));
  } catch (e) {
    yield put(showModal(ModalType.WARNING, `Konnte Suchergebnisse nicht laden`));
    yield put(searchEntriesFailed(e));
  }
}

export function* watchSearchEntries() {
  yield takeLatest(SearchActionType.SEARCH_ENTRIES, workflowSearchEntries);
}
