import * as React from 'react';

import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { Link } from 'react-router-dom';

type Props = {
  messages: string[];
  loggingIn: boolean;
  loginFailed: boolean;
  onSubmit: (username: string, password: string) => void;
};

const LoginForm: React.FunctionComponent<Props> = (props: Props) => {
  const { onSubmit, loggingIn, loginFailed } = props;

  let inputUsername;
  let inputPassword;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(inputUsername.value, inputPassword.value);
    }
  };

  return (
    <div className="login-window">
      <h4>Login</h4>
      {loginFailed ? <p className="error">Login fehlgeschlagen</p> : ''}
      {!loggingIn ? (
        <div>
          <form>
            <input
              ref={(node) => {
                inputUsername = node;
              }}
              placeholder="E-Mail-Adresse"
              onKeyDown={handleKeyDown}
              autoFocus={true}
              autoComplete="username"
            />
            <br />
            <input
              type="password"
              autoComplete="current-password"
              ref={(node) => {
                inputPassword = node;
              }}
              placeholder="Passwort"
              onKeyDown={handleKeyDown}
            />
            <br />
            <button
              className="primary"
              type="button"
              onClick={() => onSubmit(inputUsername.value, inputPassword.value)}
            >
              Login
            </button>
          </form>
          <Link to="/reset-password" id="forgot-password-link">
            Passwort vergessen?
          </Link>
        </div>
      ) : (
        <SimpleLoader />
      )}
    </div>
  );
};

LoginForm.defaultProps = {
  messages: [],
};

export default LoginForm;
