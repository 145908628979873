import React, { useState } from 'react';

import { ITileFloormapComposerProps } from './TileFloormapComposer.types';

import { TileFloormap } from './TileFloormap';
import { TileFloormapRO } from './TileFloormapRO';
import { Entry } from 'typings/models';

const TileFloormapComposer = ({
  entry,
  entries,
  projectEntries,
  editMode,
  facilityLevel,
  history = {},
  onClose,
  onGotoEntity,
  onSaveEntityField,
  onSetEditMode,
}: ITileFloormapComposerProps) => {
  const [secondFloormap, setSecondFloormap] = useState<Entry>();
  const [title1, setTitle1] = useState<string>('');
  const [title2, setTitle2] = useState<string>('');
  const formatDate = (input: string): string => {
    return new Date(input).toLocaleDateString('de');
  };

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') return;
    if (e.target.value === 'reset') {
      setSecondFloormap(undefined);
    } else {
      history &&
        entry &&
        setSecondFloormap(history[entry.uuid].find((e1) => e1.id === parseInt(e.target.value)));
    }
  };

  const selectProjectVersion = () => {
    if (history && entry) {
      return (
        <div
          style={{
            width: '50%',
            margin: '0 auto',
          }}
        >
          <p>Mit anderer Version vergleichen:</p>
          <select onChange={onChange} defaultValue="">
            {!secondFloormap ? (
              <option disabled value="">
                Bitte auswählen
              </option>
            ) : (
              <option value={'reset'}>Zurücksetzen</option>
            )}
            {history[entry.uuid]
              ?.filter((e) => e.id !== entry.id)
              .map((e) => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.projectversion.title} - {formatDate(e.projectversion.created_at)}
                  </option>
                );
              })}
          </select>
        </div>
      );
    }
  };

  return (
    <div className="map-navigation-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }}
      >
        {!!onClose && (
          <a href="#" className="button-close" onClick={onClose}>
            Close
          </a>
        )}
        <h2>{title1 === '' ? title2 : title1}</h2>
      </div>
      {selectProjectVersion()}
      {secondFloormap ? (
        <div style={{ flexDirection: 'row', height: '100%' }}>
          <TileFloormapRO
            entry={entry}
            entries={entries}
            facilityLevel={facilityLevel}
            floormap={facilityLevel?.floormap!!}
            onSetTitle={setTitle1}
            onGotoEntity={onGotoEntity}
            mapId="plan-1"
          />
          {secondFloormap.facility_level?.floormap ? (
            <TileFloormapRO
              entry={secondFloormap}
              entries={projectEntries}
              facilityLevel={secondFloormap.facility_level}
              floormap={secondFloormap.facility_level.floormap}
              onSetTitle={setTitle2}
              onGotoEntity={onGotoEntity}
              mapId="plan-2"
            />
          ) : (
            <div className="map-container" style={{ margin: '5px 10px' }}>
              <p>Kein Raumplan verfügbar</p>
            </div>
          )}
        </div>
      ) : (
        <TileFloormap
          entry={entry}
          entries={entries}
          editMode={editMode}
          facilityLevel={facilityLevel}
          onSetTitle={setTitle1}
          onClose={onClose}
          onGotoEntity={onGotoEntity}
          onSaveEntityField={onSaveEntityField}
          onSetEditMode={onSetEditMode}
        />
      )}
    </div>
  );
};

export default TileFloormapComposer;
