import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

type Props = RouteComponentProps<any> & {
  to?: string;
};

const Logo = (props: Props) => {
  const { to } = props;

  return (
    <div className="logo">
      {to ? (
        <Link to="/dashboard/">
          <strong>raumbuch.</strong>digital
        </Link>
      ) : (
        <span>
          <strong>raumbuch.</strong>digital
        </span>
      )}
    </div>
  );
};

export default withRouter(Logo);
