import * as React from 'react';
import { INotificationListItemProps } from 'components/Notification/NotificationListItem.types';
import NotificationLinks from 'components/Notification/NotificationLinks';

const NotificationListItem: React.FunctionComponent<INotificationListItemProps> = ({
  notification,
  onMarkNotificationRead,
  onToggleNotifications,
}: INotificationListItemProps) => {
  const date = new Date(notification.created_at);
  const onClick = React.useCallback(() => {
    onMarkNotificationRead(notification.id);
    onToggleNotifications();
  }, [onMarkNotificationRead, onToggleNotifications]);

  return (
    <li className="notification-box notification" onClick={onClick}>
      <div>
        {!!notification.title && (
          <h4 className={notification.read ? 'notice-read' : ''}>{notification.title}</h4>
        )}
        <div className="notification-date">{date.toLocaleString()}</div>
      </div>
      <div>{notification.message}</div>
      <NotificationLinks notification={notification} />
    </li>
  );
};
export default NotificationListItem;
