import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import AmountDisplay from 'components/AmountDisplay';
import ErrorPage from 'components/ErrorPage';
import PageDownLink from 'components/PageDownLink';
import PageUpLink from 'components/PageUpLink';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';

import { hotkeys } from 'config/hotkeys';
import { LoadRequestState } from 'typings/enums';
import { Entry, EntryShort, LoadStatus, ProjectVersion } from 'typings/models';
import { useMousetraps } from 'utils/hooks';
import { Child, Parent } from './Animated';
import ListItem from './ListItem';
import { PAGESIZE } from './types/EntryList.types';

type Props = RouteComponentProps<any> & {
  projectversion: ProjectVersion;
  entries: EntryShort[];
  entryId: number;
  currentPath: string;
  loadingEntry: LoadStatus<Entry>;
  loadingEntries: LoadStatus<Entry>;
  onGotoEntry: (number) => void;
  onLoadEntries: () => void;
  onHideFlyout: () => void;
};

const EntryList = (props: Props) => {
  const {
    currentPath,
    loadingEntries,
    loadingEntry,
    entries,
    entryId,
    projectversion,
    onGotoEntry,
    onLoadEntries,
    onHideFlyout,
  } = props;
  const [listOffset, setListOffset] = useState<number>(0);

  useEffect(() => {
    if (listOffset > entries.length) {
      setListOffset(0);
    } else if (loadingEntries.status === LoadRequestState.LOADED || !!entryId) {
      setOffsetByEntry();
    }
  }, [entryId, entries.length, loadingEntries]);

  const onPrevEntry = () => {
    const index = getEntryIndex(entryId);
    index > 0 && onGotoEntry(entries[index - 1].id);
  };

  const onNextEntry = () => {
    const index = getEntryIndex(entryId);
    index < entries.length - 1 && onGotoEntry(entries[index + 1].id);
  };

  const keyMap = {
    [hotkeys.NEXT_ENTRY.hotkey]: onNextEntry,
    [hotkeys.PREV_ENTRY.hotkey]: onPrevEntry,
  };

  useMousetraps(keyMap, [props]);

  const setOffsetByEntry = () => {
    const page = getPageForEntry(entryId);
    page >= 0 && setListOffset(page * PAGESIZE);
  };

  const onListUp = () => setListOffset(listOffset > 0 ? listOffset - PAGESIZE : listOffset);

  const onListDown = () =>
    setListOffset(listOffset + PAGESIZE < entries.length ? listOffset + PAGESIZE : listOffset);

  const getEntryIndex = (id: number) => entries.findIndex((entry) => entry.id === id);
  const getPageForEntry = (id: number): number => Math.floor(getEntryIndex(id) / PAGESIZE);

  if ((loadingEntries && loadingEntries.status === LoadRequestState.LOADING) || !projectversion) {
    return (
      <nav className="room-nav flex-container vertical">
        <div className="navigate center">
          <SimpleLoader isWhite />
        </div>
      </nav>
    );
  }

  const slicedEntries = entries.slice(listOffset, listOffset + PAGESIZE);

  return (
    <ErrorPage
      isError={loadingEntries.status === LoadRequestState.FAILED}
      onRetry={onLoadEntries}
      WrapperComponent={(children) => (
        <nav className="room-nav flex-container vertical">
          <div>{children}</div>
        </nav>
      )}
    >
      <div className="room-counter">
        <AmountDisplay offset={listOffset} total={entries.length} pageSize={PAGESIZE} />
      </div>

      <div className="navigate navigate-up">
        {slicedEntries.length > 0 && <PageUpLink onPageUp={onListUp} offset={listOffset} />}
      </div>
      {!!slicedEntries.length && (
        <Parent
          className="entry-list"
          initialPose="closed"
          pose="open"
          style={{ overflow: 'hidden' }}
        >
          {slicedEntries
            .filter((listitem) => !!listitem)
            .map((listitem) => {
              const isLoading =
                loadingEntry &&
                loadingEntry.status === LoadRequestState.LOADING &&
                listitem.id === loadingEntry.id;

              return (
                <Child key={listitem.id} initialPose="closed" pose="open">
                  <ListItem
                    currentPath={currentPath}
                    entry={listitem}
                    isLoading={isLoading}
                    projectversion={projectversion}
                    onHideFlyout={onHideFlyout}
                  />
                </Child>
              );
            })}
        </Parent>
      )}
      <div className="navigate navigate-down">
        {slicedEntries.length > 0 && (
          <PageDownLink
            onPageDown={onListDown}
            total={entries.length}
            offset={listOffset}
            page_size={PAGESIZE}
          />
        )}
      </div>
    </ErrorPage>
  );
};

export default withRouter(EntryList);
