import * as React from 'react';

type Props = {
  username: string;
};

const OwnProfileLink: React.FunctionComponent<Props> = (props: Props) => {
  const { username } = props;

  return (
    <a href="#">
      <span>{username}</span>
    </a>
  );
};

export default OwnProfileLink;
