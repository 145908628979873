import React from 'react';
import { CatalogCategory, catalogCategoryChoices } from 'typings/models';
import { INewCatalogDetailsContentProps } from './NewCatalogDetailsContent.types';

const NewCatalogDetailsContent: React.FC<INewCatalogDetailsContentProps> = ({
  catalog,
  onChange,
}) => (
  <>
    <table className="catalog-entry-list">
      <tbody>
        <tr>
          <th>Titel</th>
          <td>
            <input
              onChange={(e) => onChange({ ...catalog, title: e.currentTarget.value })}
              type="text"
              placeholder="Titel"
            />
          </td>
        </tr>
        <tr>
          <th>Kategorie</th>
          <td>
            <select
              style={{ margin: 0 }}
              defaultValue={catalogCategoryChoices[0][0]}
              onChange={(e) =>
                onChange({ ...catalog, category: e.currentTarget.value as CatalogCategory })
              }
            >
              {catalogCategoryChoices.map((choice, index) => (
                <option disabled={index === 0} key={choice[0]} value={choice[0]}>
                  {choice[1]}
                </option>
              ))}
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </>
);

export default NewCatalogDetailsContent;
