import { useCallback, useEffect, useState } from 'react';
import mousetrap from 'mousetrap';

interface IUseMouseTrapsHandlers {
  [handlerKey: string]: () => void;
}

export const useMousetraps = (handlers: IUseMouseTrapsHandlers, conditions?: any[]) => {
  useEffect(
    () => {
      Object.keys(handlers).forEach((key) => {
        mousetrap.bind(key, (e) => {
          e.preventDefault();
          handlers[key]();
        });
      });

      return () => {
        Object.keys(handlers).forEach((key) => {
          mousetrap.unbind(key);
        });
      };
    },
    conditions ? conditions : []
  );
};

export default function useDebounce(value: any, delay: number, isInterrupted?: boolean) {
  const [debouncedValue, setDebouncedValue] = useState<any>(value);

  useEffect(() => {
    const handler = setTimeout(
      () => {
        setDebouncedValue(value);
      },
      isInterrupted ? 0 : delay
    );
    return () => {
      clearTimeout(handler);
    };
  }, [value, isInterrupted]);

  return debouncedValue;
}

type UseTestId = (
  key?: string,
  fallbackKey?: string
) => (
  componentKey: string
) => {
  key: string;
  props: { testID: string } | Record<string, undefined>;
};

export const useTestId: UseTestId = (key?, fallbackKey?: string) => {
  const [fullKey] = useState(key || fallbackKey || null);
  return useCallback(
    (componentKey: string) => {
      if (!fullKey) {
        return {
          key: '',
          props: {},
        };
      }

      const key = `${fullKey}-${componentKey}`;
      return {
        key,
        props: fullKey ? { testID: key } : {},
      };
    },
    [fullKey]
  );
};
