import posed from 'react-pose';

const SlideInLeft = posed.div({
  hidden: { x: -30, opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 200 },
    x: 0,
  },
});

export default SlideInLeft;
