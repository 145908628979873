import * as React from 'react';
import { RequestStatus } from 'typings/enums';
import SimpleLoader from 'components/SimpleLoader';
import { Link } from 'react-router-dom';
import {
  errorProps,
  INewPasswordFormProps,
  innerFormProps,
  NewPasswordFormData,
} from 'components/NewPasswordForm/NewPasswordForm.types';
import { useForm } from 'react-hook-form';

const NewPasswordForm: React.FunctionComponent<INewPasswordFormProps> = ({
  requestStatus,
  token,
  onSubmit,
  onReset,
}: INewPasswordFormProps) => {
  return (
    <div className="login-window">
      <h4>Neues Passwort</h4>
      {
        {
          [RequestStatus.NONE]: <_innerForm token={token} onSubmit={onSubmit} />,
          [RequestStatus.REQUESTED]: <SimpleLoader />,
          [RequestStatus.REQUEST_FAILED]: <_error onReset={onReset} />,
          [RequestStatus.REQUEST_GRANTED]: <_success />,
        }[requestStatus]
      }
    </div>
  );
};

const _innerForm: React.FunctionComponent<innerFormProps> = ({
  token,
  onSubmit,
}: innerFormProps) => {
  const { register, handleSubmit, errors } = useForm<NewPasswordFormData>();
  const onSubmitHandler = handleSubmit(({ password }) => {
    onSubmit(password, token);
  });
  return (
    <form onSubmit={onSubmitHandler}>
      <input
        type="password"
        name="password"
        ref={register({
          required: true,
          minLength: {
            value: 6,
            message: 'Passwort zu kurz, bitte wählen Sie ein längeres Passwort',
          },
        })}
        placeholder="Ihr neues Passwort"
        autoFocus={true}
      />
      {errors.password && (
        <span style={{ color: 'red' }}>
          {' '}
          {errors.password.message || 'Bitte Passwort eingeben'}
        </span>
      )}
      <button className="primary" type="button" onClick={onSubmitHandler}>
        Neues Passwort abschicken
      </button>
    </form>
  );
};

const _error: React.FunctionComponent<errorProps> = ({ onReset }: errorProps) => {
  return (
    <div>
      <p className="error">Der Vorgang ist fehlgeschlagen. Bitte versuchen Sie es später erneut.</p>
      <button className="primary button-home" type="button" onClick={onReset}>
        Zurück
      </button>
    </div>
  );
};

const _success: React.FunctionComponent = () => {
  return (
    <p>
      Erfolg!
      <br />
      Sie können sich nun mit Ihrem neuen Passwort im Raumbuch anmelden
      <br />
      <Link to="/" className="button button-home" style={{ marginTop: 16 }}>
        Zurück zur Startseite
      </Link>
    </p>
  );
};

export default NewPasswordForm;
