import * as React from 'react';

import { Asset } from 'typings/models';

type Props = {
  files: Asset[];
};

const DocumentGallery: React.FunctionComponent<Props> = (props: Props) => {
  const { files } = props;
  return (
    <div>
      {!files.length ? (
        <span />
      ) : (
        <ul className="documentgallery-list">
          {files.map((obj) => (
            <li key={obj.id}>
              <a target="_blank" rel="noreferrer" href={obj.original}>
                {obj.original_filename}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

DocumentGallery.defaultProps = {
  files: [],
};

export default DocumentGallery;
