import { EntryActionType } from 'actions/actionTypes';
import catalog_entry_schema from 'api/catalog_entry_schema';
import catalog_schema from 'api/catalog_schema';
import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';
import * as actions from 'typings/actions';

import { EntityState } from 'typings/state';
import { initialState } from './entities.initial';

const catalogsReducer = handleActions<EntityState, actions.Action>(
  {
    [EntryActionType.CREATED_CATALOG]: (
      state: EntityState,
      action: actions.CreatedCatalogAction
    ): EntityState => {
      const {
        payload,
        payload: { id, projectversion },
      } = action;
      const newState = { ...state };
      newState.catalogs[id] = normalize(payload, catalog_schema).entities.catalogs?.[id];

      if (!newState.projectversions[projectversion].catalogs?.[id]) {
        return newState;
      }
      newState.projectversions[projectversion].catalogs = [
        ...(newState.projectversions[projectversion].catalogs ?? []),
        id,
      ];
      return newState;
    },
    [EntryActionType.CREATED_CATALOG_ENTRY]: (
      state: EntityState,
      action: actions.CreatedCatalogEntryAction
    ): EntityState => {
      const {
        payload,
        payload: { catalog, id },
      } = action;

      const newState = { ...state };
      newState.catalog_entries[id] = normalize(
        payload,
        catalog_entry_schema
      ).entities.catalog_entries?.[id];

      newState.catalogs[catalog].catalog_entries = [
        ...(newState.catalogs[catalog].catalog_entries ?? []),
        id,
      ];
      return newState;
    },
    [EntryActionType.DELETED_CATALOG]: (
      state: EntityState,
      action: actions.DeletedCatalogAction
    ): EntityState => {
      const { payload } = action;

      const newState = { ...state };
      delete newState.catalogs[payload];
      return newState;
    },
    [EntryActionType.DELETED_CATALOG_ENTRY]: (
      state: EntityState,
      action: actions.DeletedCatalogEntryAction
    ): EntityState => {
      const { payload } = action;

      const newState = { ...state };
      delete newState.catalog_entries[payload];
      return newState;
    },
  },
  { ...initialState }
);

export default catalogsReducer;
