import { ModalType } from 'typings/enums';

export const getModalTypeStr = (modalType?: ModalType): string => {
  switch (modalType) {
    case ModalType.INFO:
      return 'Hinweis';
    case ModalType.WARNING:
      return 'Warnung';
    case ModalType.ERROR:
      return 'Fehler';
    default:
      return '';
  }
};
