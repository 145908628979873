import * as React from 'react';

import { Fade } from 'components/Animated';
import ProjectLink from 'components/ProjectLink';
import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { ProjectVersion, User } from 'typings/models';

type Props = {
  user?: User;
};

const UserDashboard: React.FunctionComponent<Props> = (props: Props) => {
  const { user } = props;
  const [playAnimation, setPlayAnimation] = React.useState<boolean>(false)

  React.useEffect(() => {
    !!user && setTimeout(() => setPlayAnimation(true), 200);
  }, [user])

  const newestVersions =
    user &&
    user.projectversions.sort((a, b) => {
      return a.id - b.id;
    });

  const m = new Map<number, ProjectVersion>();
  newestVersions?.forEach((v) => {
    m.set(v.project.id, v);
  });

  return (
    <div className="plain-page projects-overview">
      <h2>Projekte</h2>
      <Fade pose={!user ? 'visible' : 'hidden'}>
        <SimpleLoader center size="big" />
      </Fade>

      {!!user && (
        <div className={`projectlist ${playAnimation ? 'animate' : ''}`}>
          {Array.from(m.values()).map((projectversion, index) => (
            <div className={`list-item ${playAnimation ? 'animate' : ''}`} style={{ transitionDelay: `${40 * index}ms` }} key={projectversion.id}>
              <ProjectLink projectversion={projectversion} projectActive={projectversion.active} projectExported={projectversion.exported} exportedBy={projectversion.exported_by} isStaff={user.is_staff} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
