import {
  ExistingEntry,
  NewEntry,
  RoomUpload,
} from 'components/ImportFloormap/ImportFloormap.types';
import { EntryShort, Facility, FacilityLevel, ProjectVersion } from 'typings/models';
import { ModalType } from 'typings/enums';

export const validate = (rooms): [RoomUpload, boolean] => {
  const initial: [RoomUpload, boolean] = [{}, false];
  const [newRooms, hasValidationErrors] = Object.keys(rooms).reduce(
    (acc: [RoomUpload, boolean], key) => {
      const value = getEntryWithError(rooms[key]);
      const newAcc: [RoomUpload, boolean] = [{ ...acc[0], [key]: value }, acc[1] || !!value.error];
      return newAcc;
    },
    initial
  );
  return [newRooms, hasValidationErrors];
};

export const getEntryWithError = (entry: NewEntry | ExistingEntry) => {
  const newEntry = entry as NewEntry;
  const existingEntry = entry as ExistingEntry;
  if (
    (!!newEntry.signature && (!newEntry.title || !newEntry.signature || !newEntry.entryType)) ||
    existingEntry.roomId === null
  ) {
    entry.error = 'Bitte alle Felder ausfüllen';
  }
  return entry;
};

export const getFilteredEntries = (
  facilityLevel: number | null,
  entries: EntryShort[]
): EntryShort[] => {
  const result = !facilityLevel
    ? entries
    : entries.filter((entry) => {
        if (typeof entry.facility_level !== 'number') {
          return !!entry.facility_level && entry.facility_level.id === facilityLevel;
        } else {
          return false;
        }
      });
  return result as EntryShort[];
};

export const getFlatLevel = (projectversion: ProjectVersion) => {
  return projectversion.facilities.reduce((acc: FacilityLevel[], facility: Facility) => {
    acc.push(
      ...facility.facility_levels.reduce((acc2: FacilityLevel[], l: FacilityLevel) => {
        acc2.push(l);
        return acc2;
      }, [])
    );
    return acc;
  }, []);
};

export const getFacilityLevel = (
  projectversion: ProjectVersion,
  facilityLevel: number | null
): { facility?: Facility; level?: FacilityLevel } => {
  if (!projectversion) {
    return {};
  }

  const flatLevels = getFlatLevel(projectversion);
  const level = flatLevels.find((l) => l.id === facilityLevel);
  return {
    facility: projectversion.facilities.find(
      (facility) => !!level && facility.id === level.facility
    ),
    level,
  };
};

export const getModalContent = (): [ModalType, string, string] => [
  ModalType.WARNING,
  'Ungültige Daten',
  'Bitte korrigieren Sie die eingegebenen Daten',
];
