import React from 'react';
import ReactModal from 'react-modal';
import { getModalTypeStr } from './ModalContainer.util';
import { IModalProps } from './ModalContainer.types';

export const ModalContainer = ({
  isOpen,
  closeModal,
  content,
  title,
  type,
  ...rest
}: IModalProps) => {
  return (
    <ReactModal
      closeTimeoutMS={150}
      className="modal"
      overlayClassName="overlay"
      onRequestClose={closeModal}
      contentLabel={getModalTypeStr(type)}
      isOpen={isOpen}
      {...rest}
    >
      <h2 className={`underline ${type}`}>
        <span>{title || getModalTypeStr(type)}</span>
      </h2>
      <div style={{ height: 30 }} />
      {content}
      <p style={{ marginTop: 35 }}>
        <button onClick={closeModal} className="no-float">
          Schließen
        </button>
      </p>
    </ReactModal>
  );
};

export default ModalContainer;
