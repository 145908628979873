import * as React from 'react';
import DocumentTitle from 'react-document-title';

import ImageGallery from 'components/ImageGallery';
import FileUploadContainer from 'containers/FileUploadContainer';
import { InlineEditLongText, InlineEditText } from 'containers/InlineEditContainer';
import { LoadRequestState, PermissionLevel } from 'typings/enums';
import { LoadStatus, Project, ProjectVersion, User, UserShort } from 'typings/models';
import TableDisplay from './TableDisplay';
import TableEdit from './TableEdit';
import { Link } from 'react-router-dom';
import SimpleLoader from 'components/SimpleLoader';
import ProjectExportModalContent from 'components/ProjectExportModalContent';

type Props = {
  project: Project;
  projectversion: ProjectVersion;
  user: User;
  canAdminEntry: boolean;
  editMode: boolean;
  userSearchResult: UserShort[];
  isSearchingUsers: boolean;
  isAddingProjectMember: boolean;
  loadingProjectVersion: LoadStatus<ProjectVersion>;
  onAddProjectMember: (userId: number, permissions: PermissionLevel) => void;
  onRemoveProjectMember: (userId: number) => void;
  onSearchUser: (query: string) => void;
  onShowModal: (title: string, text: any) => void;
  onHideModal: () => void;
  onExportProject: (generateNewFileNames?: boolean) => void;
  onInitiateProjectExport: () => void;
  onAbortProjectExport: () => void;
  onReturnToDashboard: () => void;
};

const ProjectSettings = (props: Props) => {
  const {
    canAdminEntry,
    editMode,
    user,
    project,
    projectversion,
    isSearchingUsers,
    isAddingProjectMember,
    userSearchResult,
    loadingProjectVersion,
    onAddProjectMember,
    onRemoveProjectMember,
    onSearchUser,
    onShowModal,
    onHideModal,
    onExportProject,
    onInitiateProjectExport,
    onAbortProjectExport,
    onReturnToDashboard,
  } = props;

  const copyProjectVersion = projectversion
    ? `/project/${projectversion.project.id}/version/${projectversion.id}/copy/`
    : '/';
  const projectVersionHistory = projectversion
    ? `/project/${projectversion.project.id}/version/${projectversion.id}/history`
    : '/';

  const handleAbortProjectExport = () => {
    onAbortProjectExport();
    onReturnToDashboard();
  };

  if (loadingProjectVersion.status === LoadRequestState.LOADING) {
    return <SimpleLoader size="huge" center text="Lade Übersicht..." />;
  }

  return (
    <DocumentTitle title={`Übersicht: ${projectversion.project.title}`}>
      <div className="plain-page font-normal project-settings">
        <h2>
          Übersicht:{' '}
          <InlineEditText
            entity="projects.title"
            entity_id={projectversion.project.id}
            value={projectversion.project.title}
            editable={canAdminEntry}
            inputProps={{ style: { minWidth: 400 } }}
          />
        </h2>

        <table className="room-data">
          <tbody>
            <tr style={{ display: editMode ? 'table-row' : 'inline-block' }}>
              <td colSpan={2}>
                {canAdminEntry && editMode ? (
                  <FileUploadContainer
                    files={(projectversion.project.assets || []).filter(
                      (asset) => asset.category === 'image'
                    )}
                    entity_name="projects.project"
                    entity_id={projectversion.project.id}
                    display_mode="image"
                    category="image"
                  />
                ) : (
                  <div className="image-container">
                    <ImageGallery
                      files={projectversion.project.assets.filter(
                        (asset) => asset.category === 'image'
                      )}
                    />
                  </div>
                )}
              </td>
            </tr>
            {(projectversion.project.subtitle || editMode) && (
              <tr>
                <th>Untertitel</th>
                <td>
                  <InlineEditText
                    entity="projects.subtitle"
                    entity_id={projectversion.project.id}
                    value={projectversion.project.subtitle}
                    editable={canAdminEntry}
                  />
                </td>
              </tr>
            )}
            {(projectversion.project.address || editMode) && (
              <tr>
                <th>Adresse Ersteller:in</th>
                <td>
                  <InlineEditLongText
                    entity="projects.address"
                    entity_id={projectversion.project.id}
                    value={projectversion.project.address}
                    editable={canAdminEntry}
                    title="Adresse Ersteller"
                  />
                </td>
              </tr>
            )}
            {(projectversion.project.time_period || editMode) && (
              <tr>
                <th>Erstellungs-Zeitraum</th>
                <td>
                  <InlineEditLongText
                    entity="projects.time_period"
                    entity_id={projectversion.project.id}
                    value={projectversion.project.time_period}
                    editable={canAdminEntry}
                    title="Erstellungs-Zeitraum"
                  />
                </td>
              </tr>
            )}
            {(projectversion.project.hints || editMode) && (
              <tr>
                <th>Eintragungshinweise</th>
                <td>
                  <InlineEditLongText
                    entity="projects.hints"
                    entity_id={projectversion.project.id}
                    value={projectversion.project.hints}
                    editable={canAdminEntry}
                    title="Eintragungshinweise"
                  />
                </td>
              </tr>
            )}
            {(projectversion.project.description || editMode) && (
              <tr>
                <th>Projektbeschreibung</th>
                <td>
                  <InlineEditLongText
                    entity="projects.description"
                    entity_id={projectversion.project.id}
                    value={projectversion.project.description}
                    editable={canAdminEntry}
                    title="Projektbeschreibung"
                  />
                </td>
              </tr>
            )}
            {(projectversion.project.included_runtime_admin || editMode) && canAdminEntry && (
              <tr>
                <th>Inkludierte Laufzeit</th>
                <td>
                  <InlineEditLongText
                    entity="projects.included_runtime"
                    entity_id={projectversion.project.id}
                    value={projectversion.project.included_runtime_admin}
                    editable={canAdminEntry}
                    title="Inkludierte Laufzeit"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!!canAdminEntry && (
          <>
            <h3>Mitarbeiter:innen</h3>
            {editMode && false ? (
              <TableEdit
                onAddProjectMember={onAddProjectMember}
                onRemoveProjectMember={onRemoveProjectMember}
                onSearchUser={onSearchUser}
                projectversion={projectversion}
                userSearchResult={userSearchResult}
                isSearchingUsers={isSearchingUsers}
                isAddingProjectMember={isAddingProjectMember}
                user={user}
              />
            ) : (
              <TableDisplay projectversion={projectversion} />
            )}
            <Link style={{ background: '#eeeee2', padding: '0.6em' }} to={copyProjectVersion}>
              Neue Projektversion anlegen
            </Link>
          </>
        )}

        <Link
          style={{
            background: '#224466',
            padding: '0.6em',
            color: 'white',
            float: 'right',
            borderRadius: '3px',
          }}
          to={projectVersionHistory}
        >
          Zur Historie
        </Link>
        {canAdminEntry && project.active && (
          <div style={{ display: 'flex' }}>
            <button
              style={{
                background: 'red',
                color: 'white',
                padding: '0.6em',
                marginLeft: '24px',
                fontSize: '1rem',
                fontWeight: 'normal',
              }}
              onClick={() =>
                onShowModal(
                  'Bitte Beachten',
                  <ProjectExportModalContent
                    enableNewFilenameCheck={false}
                    projectExportHandler={onInitiateProjectExport}
                    onReturnToDashboard={onReturnToDashboard}
                    onHideModal={onHideModal}
                  >
                    <p>
                      Das Projekt wird geschlossen. Dieser Vorgang ist nicht reversibel. Sie
                      erhalten von der Systemadministration eine Bestätigung per Email mit dem
                      Download-Link der komprimiert gespeicherten Daten. Die Archivierung des
                      Projektes kann einige Zeit in Anspruch nehmen.
                    </p>
                  </ProjectExportModalContent>
                )
              }
            >
              Finaler Projektabschluss
            </button>
          </div>
        )}
        {canAdminEntry && !project.active && (
          <div style={{ display: 'flex' }}>
            {!project.exported_by && (
              <button
                style={{
                  background: '#246',
                  color: 'white',
                  padding: '0.6em',
                  marginLeft: '24px',
                  fontSize: '1rem',
                  fontWeight: 'normal',
                }}
                onClick={handleAbortProjectExport}
              >
                Projektabschluss abbrechen
              </button>
            )}
            <button
              style={{
                background: project.exported_by ? '#eeeee2' : '#246',
                color: project.exported_by ? 'black' : 'white',
                padding: '0.6em',
                marginLeft: '24px',
                fontSize: '1rem',
                fontWeight: 'normal',
              }}
              onClick={() =>
                onShowModal(
                  'Bitte Beachten',
                  <ProjectExportModalContent
                    enableNewFilenameCheck={true}
                    projectExportHandler={onExportProject}
                    onReturnToDashboard={onReturnToDashboard}
                    onHideModal={onHideModal}
                  >
                    <p>
                      Durch Bestätigung des Vorgangs wird das Projekt final und irreversibel
                      abgeschlossen. Bitte vergewissern Sie sich, dass Sie dies wollen, bevor Sie
                      fortfahren.
                    </p>
                  </ProjectExportModalContent>
                )
              }
            >
              Projekt exportieren &amp; abschließen
            </button>
          </div>
        )}
        {project.exported_by && project.exported_at && (
          <p>
            Zuletzt exportiert von:{' '}
            {`${project.exported_by.academic_title} ${project.exported_by.first_name} ${project.exported_by.last_name} `}
            am{' '}
            {new Date(project.exported_at).toLocaleDateString('de', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </p>
        )}
      </div>
    </DocumentTitle>
  );
};

export default ProjectSettings;
