import { SearchActionType } from 'actions/actionTypes';
import * as actions from 'typings/actions';
import { SearchedEntriesPayload } from 'typings/models';
import { createAction } from 'redux-actions';

export const resetSearchEntries = createAction<actions.ResetSearchEntriesAction>(
  SearchActionType.RESET_SEARCH_ENTRIES
);

export const searchEntries = createAction<actions.SearchEntriesAction, number, string, string>(
  SearchActionType.SEARCH_ENTRIES,
  (versionId, query, operatorMode) => ({ versionId, query, operatorMode })
);

export const searchingEntries = createAction<actions.SearchingEntriesAction, number, string, string>(
  SearchActionType.SEARCHING_ENTRIES,
  (versionId, query, operatorMode) => ({ versionId, query, operatorMode })
);

export const searchedEntries = createAction<
  actions.SearchedEntriesAction,
  number,
  string,
  SearchedEntriesPayload[],
  number,
  number,
  number
>(SearchActionType.SEARCHED_ENTRIES, (versionId, query, results, count_entries, count_walls, count_department_entries) => ({
  versionId,
  query,
  results,
  count_entries,
  count_walls,
  count_department_entries
}));

export const searchEntriesFailed = createAction<actions.SearchEntriesFailedAction, any>(
  SearchActionType.SEARCH_ENTRIES_FAILED,
  (errors?: any) => ({ errors })
);
