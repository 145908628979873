import { LoadRequestState, ModalType, PermissionLevel } from './enums';
/******************************************************************************
 * MISC RESPONSE TYPES
 ******************************************************************************/
export type ChoiceRequest = {
  choices_name: string;
  choices_get_params?: string;
};

export type ListResponse<T> = {
  count: number;
  next: any;
  previous: any;
  results: T[];
};

export type DashboardResponse = {
  lastEdited: DashboardEntry[];
  lastOwnEdited: DashboardEntry[];
  newest: DashboardEntry[];
};

/******************************************************************************
 * API MODELS
 ******************************************************************************/

export type DashboardEntry = {
  id: number;
  title: string;
  signature: string;
  created_at?: string;
  history_date?: string;
  assets?: Asset[];
  user?: {
    name: string;
  };
};

export type EntryType = {
  id: number;
  title: string;
  has_walls: boolean;
};

export type FieldConfiguration = {
  id: number;
  projectversion: number;
  entry_type: EntryType;
  departments: Department[];
  fields: string[];
  entries_have_findings: boolean;
  walls_have_findings: boolean;
  has_catalogs: boolean;
};

export type NormalizedFieldConfiguration = {
  id: number;
  projectversion: number;
  entry_type: EntryType;
  departments: number[];
  fields: string[];
};

export type Project = {
  id: number;
  key: string;
  title: string;
  subtitle: string;
  address: string;
  time_period: string;
  assets: Asset[];
  description: string;
  included_runtime_admin: string;
  hints: string;
  active: boolean;
  exported_by?: UserShort;
  exported_at?: Date;
};

export type NormalizedProject = {
  id: number;
  key: string;
  title: string;
  subtitle: string;
  address: string;
  time_period: string;
  assets: number[];
  hints: string;
  active: boolean;
  exported_by?: number;
  exported_at?: Date;
};

export type ProjectVersion = {
  id: number;
  title: string;
  permissions: Permission;
  members?: ProjectMember[];
  project: Project;
  field_configurations: FieldConfiguration[];
  facilities: Facility[];
  created_at?: string;
  catalogs?: Catalog[];
  active: boolean;
  exported: boolean;
  exported_by?: string;
};

export type ProjectVersionSmall = {
  id: number;
  title: string;
  created_at: string;
  active: boolean;
  exported: boolean;
};

export type NormalizedProjectVersion = {
  id: number;
  title: string;
  permissions: Permission;
  members?: number[];
  project: number;
  field_configurations: number[];
  facilities: number[];
  created_at?: string;
  catalogs?: number[];
  active: boolean;
};

export type User = {
  id: number;
  email: string;
  academic_title?: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  projectversions: ProjectVersion[];
};

export type NormalizedUser = {
  id: number;
  email: string;
  academic_title?: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  projects: IdTitle[];
};

export type UserShort = {
  id: number;
  email: string;
  academic_title?: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
};

export type ProjectMember = {
  id: number;
  user: UserShort;
  permissions: PermissionLevel;
};

export type SearchedEntriesPayload = {
  id: number;
  title: string;
  signature: string;
  snippet: string;
  url: string;
  department?: number;
  faility_level: number;
};

export type AssetCategory = 'image' | 'document' | 'archive';

export type AssetUploadBasePayload = {
  entity_name: string;
  entity_id: number;
  field_name?: string;
  category: AssetCategory;
};

export type AssetsUploadPayload = AssetUploadBasePayload & {
  files: Array<{
    uploadKey: string;
    file: Partial<File>;
  }>;
};

export type AssetUploadPayload = AssetUploadBasePayload & {
  file: {
    uploadKey: string;
    file: Partial<File>;
  };
};

export type AssetUploadingPayload = AssetUploadBasePayload & {
  uploadKey: string;
};

export type AssetUploadResponsePayload = AssetUploadBasePayload & {
  uploadKey: string;
  response: Asset;
};

export type AssetUploadFailedPayload = AssetUploadBasePayload & {
  uploadKey: string;
};

export type MovedPayload = {
  content_type: string;
  assets: number[];
  object_id: number;
};

export type Asset = {
  id: number;
  category: AssetCategory;
  content_type: string;
  object_id: number;
  created_at: string;
  created_by: User;
  description: string | null;
  original_filename: string;
  author: string | null;
  shooting_date: string;
  field_name?: string;

  big: string;
  medium: string;
  small: string;
  original: string;
};

export type IdTitle = {
  id: number;
  title: string;
};

export type Permission = {
  title: string;
  id: PermissionLevel;
};

export type Department = IdTitle & {
  permissions: Permission;
  preset_concept_of_measures: boolean;
};

export type Category = IdTitle;
export type Appreciation = IdTitle;
export type ConstructionType = IdTitle;
export type DoorType = IdTitle;
export type SurfaceType = IdTitle;
export type WindowType = IdTitle;

export type DepartmentEntry = {
  assets: Asset[];
  title: string;
  content: string;
  created_at: string;
  created_by: User;
  department: Department;
  entry: number;
  id: number;
  decomposition_needed?: boolean;
  hours_construction_deconstruction?: number;
  interval_next_maintenance?: string;
  interval_next_revision?: string;
  measure?: IdTitle;
  measure_classification?: IdTitle;
  measure_prioritization?: IdTitle;
  special_measures?: string;
  effort_measure?: number;
  position: number;
};
export type UpsertDepartmentEntry = {
  title: string;
  content: string;
  decomposition_needed?: boolean;
  hours_construction_deconstruction?: number;
  interval_next_maintenance?: string;
  interval_next_revision?: string;
  measure?: string;
  measure_classification?: string;
  measure_prioritization?: string;
  special_measures?: string;
  effort_measure?: number;
};
export type NormalizedDepartmentEntry = {
  assets: number[];
  title: string;
  content: string;
  created_at: string;
  created_by: number;
  department: number;
  entry: number;
  id: number;
  position: number;
};

export type FacilityLevel = {
  id: number;
  title: string;
  level: number;
  facility: number;
  facility_part?: number;
  floormap?: string;
  floormap_type?: string;
  floormap_creator?: string;
  floormap_creation_date?: string;
  geoJSON?: any;
};

export type Facility = {
  id: number;
  title: string;
  project: number;
  facility_levels: FacilityLevel[];
};

export type NormalizedFacility = {
  id: number;
  title: string;
  project: number;
  levels: number[];
};

export type Door = {
  assets: Asset[];
  id: number;
  material: string;
  token: string;
  type: DoorType;
  wall: number;
  catalog_entry?: number;
};

export type NormalizedDoor = {
  assets: number[];
  id: number;
  material: string;
  token: string;
  type: number;
  wall: number;
  catalog_entry?: number;
};

export type Window = {
  assets: Asset[];
  id: number;
  material: string;
  token: string;
  type: DoorType;
  wall: number;
  catalog_entry?: number;
};

export type NormalizedWindow = {
  assets: number[];
  id: number;
  material: string;
  token: string;
  type: number;
  wall: number;
  catalog_entry?: number;
};

export type EntryFinding = {
  assets: Asset[];
  id: number;
  description: string;
  token: string;
  notes: string;
};

export type Finding = {
  assets: Asset[];
  id: number;
  description: string;
  token: string;
  notes: string;
  wall: number;
  catalog_entry?: number;
};

export type NormalizedFinding = {
  assets: number[];
  id: number;
  description: string;
  token: string;
  notes: string;
  wall?: number;
  catalog_entry?: number;
};

export type Direction = {
  id: 'N' | 'NE' | 'E' | 'SE' | 'S' | 'SW' | 'W' | 'NW';
  title: string;
};

export type Wall = {
  assets: Asset[];
  construction_period: string;
  construction_types: ConstructionType[];
  description: [];
  direction: Direction;
  doors: Door[];
  entry: number;
  findings: Finding[];
  id: number;
  surface_types: SurfaceType[];
  title: string;
  token: string;
  windows: Window[];
};

export type NormalizedWall = {
  assets: number[];
  construction_period: string;
  construction_types: number[];
  description: [];
  direction: Direction;
  doors: number[];
  findings: number[];
  entry: number;
  id: number;
  surface_types: number[];
  title: string;
  token: string;
  windows: number[];
};

export type Entry = {
  entry_type: EntryType;
  id: number;
  uuid: string;
  project: number;
  projectversion: IdTitle;
  signature: string;

  appreciation?: Appreciation;
  annotations_building_research?: string;
  annotations_wood_research?: string;
  assets?: Asset[];
  ceiling?: string;
  compositions?: IdTitle[];
  crack?: number;
  create_at?: string;
  current_usage?: string;
  damage_intensity?: IdTitle;
  damages?: string;
  department_entries?: DepartmentEntry[];
  description?: string;
  effort_measure?: number;
  earliest_year_of_death?: string;
  equipment?: string;
  examination_date?: string;
  examination_type?: IdTitle;
  examiner?: string;
  facility?: Facility;
  facility_level?: FacilityLevel;
  floor?: string;
  future_usage?: string;
  height?: number | null;
  history_text?: string;
  hours_construction_deconstruction?: number;
  interpretation?: string;
  inscriptions?: IdTitle[];
  interval_next_maintenance?: IdTitle;
  interval_next_revision?: IdTitle;
  inscription_text?: string;
  last_editing?: string;
  length?: number;
  locations?: IdTitle[];
  manufacturer?: string;
  materials?: IdTitle[];
  name_person?: string;
  off_sanding?: number;
  opening?: string;
  overgrowns?: IdTitle[];
  previous_room_numbers?: string;
  restoration?: string;
  scales?: number;
  shell_formation?: number;
  size?: number | null;
  special_measures?: string;
  stairs?: string;
  status_report?: string;
  storage_location?: string;
  stabilities?: IdTitle[];
  stability_result?: IdTitle;
  stability_examination_content?: string;
  stratifications?: IdTitle[];
  title?: string;
  wall_text?: string;
  walls?: Wall[];
  vandalism?: string;
  width?: number | null;
  entryfindings?: EntryFinding[] | null;
};

export type HistoricalEntry = Entry & {
  projectversion: ProjectVersionSmall;
};

export type NormalizedEntry = {
  entry_type: number;
  id: number;
  project: number;
  signature: string;

  appreciation?: Appreciation;
  annotations_building_research?: string;
  annotations_wood_research?: string;
  assets?: number[];
  ceiling?: string;
  compositions?: number[];
  crack?: number;
  create_at?: string;
  current_usage?: string;
  damage_intensity?: string;
  damages?: string;
  department_entries?: number[];
  description?: string;
  effort_measure?: number;
  earliest_year_of_death?: string;
  facility?: number;
  facility_level?: number;
  floor?: string;
  future_usage?: string;
  height?: number | null;
  history_text?: string;
  hours_construction_deconstruction?: number;
  interpretation?: string;
  inscriptions?: number[];
  inscription_text?: string;
  interval_next_maintenance?: number;
  interval_next_revision?: number;
  last_editing?: string;
  length?: number;
  locations?: number[];
  manufacturer?: string;
  materials?: number[];
  name_person?: string;
  off_sanding?: number;
  opening?: string;
  overgrowns?: number[];
  previous_room_numbers?: string;
  restoration?: string;
  scales?: number;
  size?: number | null;
  storage_location?: string;
  stabilities?: number[];
  stairs?: string;
  stratifications?: number[];
  title?: string;
  wall_text?: string;
  walls?: number[];
  vandalism?: string;
  width?: number | null;
  entryfindings?: number[];
};

export type EntryShort = {
  id: number;
  title: string;
  signature: string;
  entry_type: number;
  departments: IdTitle[];
  facility?: number;
  facility_level?: number | FacilityLevel;
  projectversion: number;
};

export type Modal = {
  type: ModalType;
  content: string;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose?: () => {};
};

export type Flyout = {
  initialFacilityLevel?: FacilityLevel;
  initialRoom?: number;
};

export type IndexedRecord<T> = {
  [id: number]: T;
};

export type StringIndexedRecord<T> = {
  [id: string]: T;
};

export type UploadingAssetStatus = {
  [uploadKey: string]: 'PENDING' | 'SAVED' | 'ERROR';
};

export type MovingAssetStatus = {
  [assetId: number]: 'PENDING' | 'SAVED' | 'ERROR';
};

export type MovingAssetToEntityStatus = {
  [assetId: number]: 'PENDING' | 'SAVED' | 'ERROR';
};

export type NotificationType =
  | 'EXPORT_SUCCESS'
  | 'IMPORT_SUCCESS'
  | 'GENERIC'
  | 'EXPORT_FAILED'
  | 'IMPORT_FAILED'
  | 'COPY SUCCESS';

export type NotificationLink = {
  hyperlink: string;
  label?: string;
  expiry_date?: string;
};

export type NormalizedNotification = {
  id: number;
  title: string;
  value: string;
  read: boolean;
  created_at: string;
  message: string;
  type: NotificationType;
  links: NotificationLink[];
  data?: { [key: string]: any };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ImportNotificationData = {};

const catalogCategories = ['None', 'Window', 'Door', 'Finding'] as const;

export type CatalogCategory = typeof catalogCategories[number];

export const catalogCategoryOptions = {
  None: 'Unkategorisiert',
  Window: 'Fenster',
  Door: 'Tür',
  Finding: 'Befund',
} as const;

export const catalogCategoryChoices = Object.entries(catalogCategoryOptions);

export type Catalog = {
  id: number;
  projectversion: number;
  title: string;
  category: CatalogCategory;
  catalog_entries: CatalogEntry[];
};

export type CatalogEntry = {
  id: number;
  title: string;
  description?: string;
  signature: string;
  catalog: number;
  assets?: Asset[];
};

export type NormalizedCatalogEntry = {
  id: number;
  title: string;
  description?: string;
  signature: string;
  catalog: number;
  assets?: number[];
};

export type NormalizedCatalog = Omit<Catalog, 'catalog_entries'> & {
  catalog_entries: number[];
};

/******************************************************************************
 * LOAD/SAVE STATUS
 ******************************************************************************/

export type SaveStatusType = 'SAVING' | 'EDITING' | 'SAVED' | 'FAILED';
export type SaveError = {
  [key: string]: string;
};

export type SaveStatus<T> = {
  status?: SaveStatusType;
  messages?: SaveError;
  values?: T;
};

export type LoadStatus<T> = {
  status?: LoadRequestState;
  id?: number | string;
  errors?: any;
  values?: T;
};

export type EditingStatusType = 'SAVING' | 'EDITING';
export type EditingStatus = {
  entity_name:
    | 'assets'
    | 'entries'
    | 'walls'
    | 'doors'
    | 'windows'
    | 'department_entries'
    | 'projects';
  entity_id: number;
  fieldname: string;
  status: EditingStatusType;
  value?: string | number;
  errors?: any;
  json?: any;
};

export type HistoryListItem = {
  versionTitle: string;
  value: string;
};
