import * as React from 'react';

type Props = {
  title: string;
  signature: string;
};

const RoomTitle = (props: Props) => {
  const { signature, title } = props;
  return (
    <h2>
      {signature ? <span>{signature}</span> : <span />}
      {title ? <span className="light">{title}</span> : <span />}
    </h2>
  );
};

export default RoomTitle;
