import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

type Props = {
  path: string;
  isAuthenticated: boolean;
  children?: React.ReactNode;
  component?: any;
  location?: any;
  render?: (any) => any;
};

const PrivateRoute = (props: Props) => {
  const { isAuthenticated } = props;

  if (!isAuthenticated) {
    return (
      <Redirect
        exact
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
