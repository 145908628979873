import React, { useEffect, useState } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import SimpleLoader from 'components/SimpleLoader/SimpleLoader';
import { Asset } from 'typings/models';
import { IAssetSortingProps, IDragDropResult } from './AssetSorting.types';
import { reorder, getFileDescription } from './AssetSorting.util';

// tslint:disable-next-line:variable-name
const SpinnerWrapper = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
});

const AssetSorting = (props: IAssetSortingProps) => {
  const [files, setFiles] = useState<Asset[]>(props.files);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  const onDragEnd = (dragDropResult: IDragDropResult) => {
    if (!dragDropResult || !dragDropResult.destination) {
      return;
    }
    const reorderedFiles = reorder<Asset>(
      files,
      dragDropResult.source.index,
      dragDropResult.destination.index
    );
    props.onDragEnd(dragDropResult);
    setFiles(reorderedFiles);
  };

  const { isMovingAsset } = props;
  return (
    <div>
      <h3>Bild an neue Position ziehen</h3>
      <PoseGroup>
        <SpinnerWrapper key="one" pose={isMovingAsset ? 'visible' : 'hidden'}>
          <SimpleLoader size="big" />
        </SpinnerWrapper>
      </PoseGroup>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`droppable${snapshot.isDraggingOver ? ' dragging-over' : ''}`}
            >
              {files.map((file, index) => (
                <Draggable key={file.id} draggableId={file.id} index={index}>
                  {/* tslint:disable-next-line:no-shadowed-variable */}
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`draggable${snapshot.isDragging ? ' dragging' : ''}`}
                    >
                      <img
                        key={file.id}
                        src={file.small}
                        style={{ width: 'auto', height: 200 }}
                        alt=""
                      />
                      <div>{file.original_filename}</div>
                      <p>{getFileDescription(file.description || '')}</p>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default AssetSorting;
