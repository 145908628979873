import * as React from 'react';

import InlineEditBaseWidget, { BaseProps } from './InlineEditBaseWidget';

type Props = BaseProps & {
  value: number;
};

class InlineEditIntegerWidget extends InlineEditBaseWidget<Props> {
  protected refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;
  private invalidKeys = ['-', ',', '.', '+', '*'];

  public handleInput = (e: React.KeyboardEvent<HTMLElement>) => {
    if (this.invalidKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  public getValueReadable = () => (this.props.value ? Math.floor(this.props.value) : '');

  public getEditWidget = () => {
    const { editing_entity_field: { value = null } = {} } = this.props;
    const defaultValue = value ? String(value) : String(this.props.value);

    return (
      <span>
        <input
          ref={this.refElement}
          type="number"
          min={0}
          step={1}
          data-original={this.props.value}
          defaultValue={defaultValue}
          onKeyDown={this.handleInput}
          onKeyUp={this.handleKeyDown}
        />{' '}
        {this.props.suffix}
      </span>
    );
  };
}

export default InlineEditIntegerWidget;
