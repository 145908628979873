import { randomString } from 'utils/strings';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import md5 from 'md5';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import Lightbox from 'react-image-lightbox';
import ReactModal from 'react-modal';

import AssetSorting from 'components/AssetSorting';
import { IDragDropResult } from 'components/AssetSorting/AssetSorting.types';
import DeleteButton from 'components/DeleteButton';
import ImageNavigation from 'components/ImageNavigation';
import ListTable from 'components/ListTable';
import Row from 'components/Row';
import SelectFileButton from 'components/SelectFileButton';
import { InlineEditLongText, InlineEditText } from 'containers/InlineEditContainer';
import {
  Asset,
  AssetCategory,
  AssetsUploadPayload,
  MovingAssetStatus,
  UploadingAssetStatus,
} from 'typings/models';
import DocumentList from './DocumentList';
import LoadingPanel from './LoadingPanel';

type Props = {
  accept?: string;
  entity_name: string;
  category: AssetCategory;
  entity_id: number;
  field_name?: string;
  files: Asset[];
  display_mode: 'list' | 'image';
  assetUploads: UploadingAssetStatus;
  assetMovings: MovingAssetStatus;
  assetMarkedToMove: number;
  onUploadAssets: (data: AssetsUploadPayload) => void;
  onDeleteAsset: (entityName: string, entityId: number, assetIid: number) => void;
  onResetAssetUpload: (string) => void;
  onMoveAsset: (assetId: number, toPosition: number) => void;
  onMoveAssetToEntity: (
    assetId: number,
    entityName: string,
    entityId: number,
    fieldName: string
  ) => void;
  onMarkAssetToMove: (assetId: number) => void;
};

type State = {
  index: number;
  lightboxIsOpen: boolean;
  uploadKeys: string[];
  sortingIsOpen: boolean;
};

export const getUploadKey = (file: File): string =>
  `${randomString()}-${md5([file.name, file.lastModified, file.size])}`;

class FileUpload extends React.Component<Props, State> {
  public refDropzone: Dropzone | undefined;
  public state = {
    index: 0,
    lightboxIsOpen: false,
    sortingIsOpen: false,
    uploadKeys: [],
  };

  public openLightbox = () => {
    this.setState({ lightboxIsOpen: true });
  };
  public closeLightbox = () => this.setState({ lightboxIsOpen: false });
  public openSorting = () => this.setState({ sortingIsOpen: true });
  public closeSorting = () => this.setState({ sortingIsOpen: false });

  public nextImage = () => {
    if (this.state.index < this.props.files.length - 1) {
      this.setState({ index: this.state.index + 1 });
    }
  };

  public prevImage = () => {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 });
    }
  };

  public onDrop = (files: File[]) => {
    const { entity_name, entity_id, field_name, category, onUploadAssets } = this.props;
    const filesPayload = files.map((file) => ({
      file,
      uploadKey: getUploadKey(file),
    }));

    this.setState({ uploadKeys: filesPayload.map((file) => file.uploadKey) }, () => {
      onUploadAssets({
        category,
        entity_id,
        entity_name,
        field_name,
        files: filesPayload,
      });
    });
  };

  public resetAssetUploadKeys = () => {
    this.state.uploadKeys.map((uploadKey) => {
      this.props.onResetAssetUpload(uploadKey);
    });
    this.setState({ uploadKeys: [] });
  };

  public getUploadingProgress = () => {
    const uploadStatusList = Object.values(this.getCurrentUploads());
    const totalUploads = uploadStatusList.length;
    const finishedUploads = uploadStatusList.filter((key) => key === 'SAVED').length;
    if (totalUploads === 0) {
      return 100;
    }
    return Math.ceil((100 / totalUploads) * finishedUploads);
  };

  public getCurrentUploads = () => pick(this.props.assetUploads, this.state.uploadKeys);

  public isMovingAsset = () =>
    Object.values(this.props.assetMovings).filter((status) => status !== 'SAVED').length > 0;

  public isUploading = () =>
    Object.values(this.getCurrentUploads()).filter((upload) => upload !== 'SAVED').length > 0;

  public deleteAsset = (id: number) => {
    const { entity_name, entity_id } = this.props;
    this.props.onDeleteAsset(entity_name, entity_id, id);
    this.prevImage();
  };

  public componentDidUpdate(prevProps) {
    if (this.state.index >= this.props.files.length && this.state.index > 0) {
      this.setState({
        index: 0,
      });
    }

    if (!this.isUploading() && !isEqual(prevProps.assetUploads, this.props.assetUploads)) {
      this.resetAssetUploadKeys();
      this.setState({ index: this.props.files.length ? this.props.files.length - 1 : 0 });
    }
  }

  public getDisplayWidgetImages = () => {
    const { files, category } = this.props;
    const { index } = this.state;
    if (index >= files.length) {
      // the render function is called before the componentDidUpdate on page change, we can just return here if the condition matches,
      // as a re-render will always follow once index gets updated
      return;
    }
    const file = files[index];

    return (
      <div>
        <div style={{ padding: '0 2px' }}>
          <ImageNavigation
            onPrev={this.prevImage}
            onNext={this.nextImage}
            index={this.state.index}
            total={files.length}
          />
        </div>
        <a className="lightbox-trigger" onClick={this.openLightbox}>
          {/* eslint-disable-next-line react/no-string-refs */}
          <img ref="img" src={file.medium} style={{ objectFit: 'contain', height: 300 }} />
        </a>
        <div>
          <ListTable>
            <Row
              left={
                <div style={{ display: 'flex' }}>
                  <DeleteButton
                    title="Datei löschen"
                    question="Datei wirklich löschen?"
                    onDelete={() => this.deleteAsset(file.id)}
                  />
                  {category === 'image' && this.props.assetMarkedToMove !== file.id && (
                    <button
                      className="primary"
                      onClick={() => this.props.onMarkAssetToMove(file.id)}
                    >
                      Bild verschieben
                    </button>
                  )}
                </div>
              }
              right={
                <div>
                  <SelectFileButton
                    onCapturePhoto={category === 'image' ? this.handleCapture : undefined}
                    onSelectFile={() => this.refDropzone?.open()}
                    title="Weitere Datei hochladen"
                  />
                  {files.length > 1 ? (
                    <button className="primary" onClick={this.openSorting}>
                      Umsortieren
                    </button>
                  ) : null}
                  {this.props.assetMarkedToMove != null && (
                    <button
                      className="primary"
                      onClick={() =>
                        this.props.onMoveAssetToEntity(
                          this.props.assetMarkedToMove,
                          this.props.entity_name,
                          this.props.entity_id,
                          this.props.field_name || ''
                        )
                      }
                    >
                      Bild hierher verschieben
                    </button>
                  )}
                </div>
              }
            />
            <Row
              left="Beschreibung"
              right={
                <InlineEditLongText
                  entity="assets.description"
                  entity_id={file.id}
                  value={file.description}
                  editable={true}
                  hint="z.B. Bildbeschreibung"
                  title="Beschreibung"
                />
              }
            />
            <Row
              left="Aufnahmedatum"
              right={
                <InlineEditText
                  entity="assets.shooting_date"
                  entity_id={file.id}
                  value={file.shooting_date}
                  editable={true}
                  hint="z.B. 10.02.2016"
                />
              }
            />
            <Row
              left="Autor:in"
              right={
                <InlineEditText
                  entity="assets.author"
                  entity_id={file.id}
                  value={file.author}
                  editable={true}
                  hint="z.B. Max Mustermann"
                />
              }
            />
            <Row left="Dateiname" right={file.original_filename} />
          </ListTable>
        </div>
      </div>
    );
  };

  public getDisplayWidgetEmpty = () => {
    return (
      <div>
        <SelectFileButton
          onSelectFile={() => this.refDropzone?.open()}
          onCapturePhoto={this.props.category === 'image' ? this.handleCapture : undefined}
        />
        {this.props.assetMarkedToMove != null && this.props.category === 'image' && (
          <button
            className="primary"
            onClick={() =>
              this.props.onMoveAssetToEntity(
                this.props.assetMarkedToMove,
                this.props.entity_name,
                this.props.entity_id,
                this.props.field_name || ''
              )
            }
          >
            Bild hierher verschieben
          </button>
        )}
      </div>
    );
  };

  public getUploadingWidget = () => {
    return (
      <LoadingPanel
        progress={this.state.uploadKeys.length > 1 ? this.getUploadingProgress() : null}
      />
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  public getDropzoneRef = () => this.refDropzone!;

  public getDisplayWidget = () => {
    const { files, display_mode = 'list' } = this.props;

    if (this.isUploading()) {
      return this.getUploadingWidget();
    }

    if (!this.props.files.length) {
      return this.getDisplayWidgetEmpty();
    }

    if (display_mode === 'image') {
      return this.getDisplayWidgetImages();
    }

    return (
      <DocumentList
        files={files}
        onDeleteAsset={this.deleteAsset}
        getDropRef={this.getDropzoneRef}
      />
    );
  };

  public onDragEnd = (result: IDragDropResult) =>
    !!result.destination &&
    this.props.onMoveAsset(this.props.files[result.source.index].id, result.destination.index);

  public renderSortingModal = () => {
    const { files } = this.props;
    const { sortingIsOpen } = this.state;

    return (
      <ReactModal
        closeTimeoutMS={150}
        className="modal dragdrop"
        overlayClassName="overlay"
        isOpen={sortingIsOpen}
        onRequestClose={this.closeSorting}
        contentLabel="Sortierung ändern"
      >
        <AssetSorting
          files={files}
          onDragEnd={this.onDragEnd}
          isMovingAsset={this.isMovingAsset()}
        />
        <button style={{ marginTop: 12 }} onClick={this.closeSorting}>
          Schließen
        </button>
      </ReactModal>
    );
  };

  public handleCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      this.onDrop(Array.from(e.currentTarget.files));
    }
  };

  public render() {
    const { accept, files } = this.props;
    const { index, lightboxIsOpen } = this.state;
    const file = files[index];
    const displayWidget = this.getDisplayWidget();

    if (lightboxIsOpen) {
      return (
        <Lightbox
          mainSrc={file.big}
          imageTitle={file.description}
          prevSrc={index > 0 ? files[index - 1] : ''}
          nextSrc={index < files.length - 1 ? files[index + 1] : ''}
          onCloseRequest={this.closeLightbox}
          onMovePrevRequest={this.prevImage}
          onMoveNextRequest={this.nextImage}
        />
      );
    }

    return (
      <Dropzone
        accept={accept}
        disableClick={true}
        ref={(node) => {
          !!node && (this.refDropzone = node);
        }}
        // id={this.props.entity_name + '.' + this.props.entity_id}
        className="dropzone"
        onDrop={this.onDrop}
      >
        {this.renderSortingModal()}

        {displayWidget}
      </Dropzone>
    );
  }
}

export default FileUpload;
