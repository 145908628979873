import identity from 'lodash/identity';
import * as React from 'react';

type Props = {
  offset: number;
  onPageUp: () => void;
};

const PageUpLink = (props: Props) => {
  const { onPageUp, offset } = props;
  const is_enabled = offset > 0;
  return <a onClick={is_enabled ? onPageUp : identity}>Up</a>;
};

PageUpLink.defaultProps = {
  onPageUp: identity,
};

export default PageUpLink;
