import * as React from 'react';

import InlineEditBaseWidget, { BaseProps } from './InlineEditBaseWidget';

type Props = BaseProps & {
  step_size?: number;
  value: number;
};

class InlineEditFloatWidget extends InlineEditBaseWidget<Props> {
  protected refElement = React.createRef<HTMLInputElement>();
  protected canFullscreen = false;

  public formatHistoryResult = (res: string, suffix?: any): string => {
    return suffix ? `${res},00 ${suffix}` : res;
  };

  public getValueReadable = () =>
    this.props.value ? this.props.value.toFixed(2).toString().replace('.', ',') : '';

  public getEditWidget = () => {
    const defaultValue =
      this.props.editing_entity_field && this.props.editing_entity_field.value
        ? String(this.props.editing_entity_field.value)
        : String(this.props.value);

    return (
      <span>
        <input
          ref={this.refElement}
          type="number"
          step={this.props.step_size || 0.01}
          data-original={this.props.value}
          defaultValue={defaultValue}
          onKeyDown={this.handleKeyDown}
        />
        {this.props.suffix}
      </span>
    );
  };
}

export default InlineEditFloatWidget;
