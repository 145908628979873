import { createAction } from 'redux-actions';
import {
  LoadedNotificationsAction,
  LoadingNotificationsAction,
  LoadNotificationsAction,
  LoadNotificationsFailedAction,
  MarkAllReadAction,
  MarkAllReadFailedAction,
  MarkAsReadAction,
  MarkAsReadFailedAction,
  MarkedAllReadAction,
  MarkedAsReadAction,
  MarkingAllReadAction,
  MarkingAsReadAction,
  NormalizeNotificationAction,
  StopWatcher,
} from 'typings/actions';
import NotificationActionType from 'actions/actionTypes/notifications';
import { NormalizedNotification } from 'typings/models';

export const loadNotifications = createAction<LoadNotificationsAction>(
  NotificationActionType.LOAD_NOTIFICATIONS
);
export const loadingNotifications = createAction<LoadingNotificationsAction>(
  NotificationActionType.LOADING_NOTIFICATIONS
);
export const loadedNotifications = createAction<LoadedNotificationsAction, NormalizedNotification>(
  NotificationActionType.LOADED_NOTIFICATIONS
);
export const loadNotificationsFailed = createAction<LoadNotificationsFailedAction>(
  NotificationActionType.LOAD_NOTIFICATIONS_FAILED
);
export const normalizeNotification = createAction<
  NormalizeNotificationAction,
  NormalizedNotification
>(NotificationActionType.NORMALIZE_NOTIFICATION);
export const stopWatcher = createAction<StopWatcher>(NotificationActionType.STOP_WATCHER);

export const markAsRead = createAction<MarkAsReadAction, number>(
  NotificationActionType.MARK_AS_READ
);
export const markingAsRead = createAction<MarkingAsReadAction>(
  NotificationActionType.MARKING_AS_READ
);
export const markedAsRead = createAction<MarkedAsReadAction, number>(
  NotificationActionType.MARKED_AS_READ
);
export const markAsReadFailed = createAction<MarkAsReadFailedAction>(
  NotificationActionType.MARK_AS_READ_FAILED
);

export const markAllRead = createAction<MarkAllReadAction>(NotificationActionType.MARK_ALL_READ);
export const markingAllRead = createAction<MarkingAllReadAction>(
  NotificationActionType.MARKING_ALL_READ
);
export const markedAllRead = createAction<MarkedAllReadAction>(
  NotificationActionType.MARKED_ALL_READ
);
export const markAllReadFailed = createAction<MarkAllReadFailedAction>(
  NotificationActionType.MARK_ALL_READ_FAILED
);
