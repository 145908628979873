import { connect } from 'react-redux';

import * as selectors from 'reducers/app';
import { Action } from 'typings/actions';
import { AssetsUploadPayload } from 'typings/models';
import {
  deleteAsset,
  markAssetToMove,
  moveAsset,
  moveAssetToEntity,
  resetAssetUpload,
  uploadAssets,
} from 'actions/AssetActions';
import FileUpload from 'components/FileUpload';
import { State } from 'typings/state';

const mapStateToProps = (state: State) => ({
  assetMarkedToMove: selectors.getAssetMarkedToMove(state),
  assetMovings: selectors.getAssetMovings(state),
  assetUploads: selectors.getAssetUploads(state),
});

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  onDeleteAsset: (entity_name, entity_id, asset_id) => {
    dispatch(deleteAsset({ entity_name, entity_id, asset_id }));
  },
  onMarkAssetToMove: (assetId: number) => dispatch(markAssetToMove(assetId)),
  onMoveAsset: (assetId: number, toPosition: number) => dispatch(moveAsset(assetId, toPosition)),
  onMoveAssetToEntity: (assetId: number, entityName: string, entityId: number, fieldName: string) =>
    dispatch(moveAssetToEntity(assetId, entityName, entityId, fieldName)),
  onResetAssetUpload: (uploadKey: string) => dispatch(resetAssetUpload(uploadKey)),
  onUploadAssets: (payload: AssetsUploadPayload) => dispatch(uploadAssets(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
