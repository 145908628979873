import { useEffect, useRef } from 'react';
import { ICloseModalProps } from './App.types';

export const closeModal = ({ onClose, onHideModal }: ICloseModalProps) => {
  onClose && onClose();
  onHideModal();
};

export const usePrevious = <T>(value) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
