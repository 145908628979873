enum AuthActionType {
  LOGIN = 'auth/LOGIN',
  LOGOUT = 'auth/LOGOUT',
  LOGGING_IN = 'auth/LOGGING_IN',
  LOGGING_OUT = 'auth/LOGGING_OUT',
  LOGGED_IN = 'auth/LOGGED_IN',
  LOGGED_OUT = 'auth/LOGGED_OUT',
  LOGIN_FAILED = 'auth/LOGIN_FAILED',
  NORMALIZE_USER = 'auth/NORMALIZE_USER',

  SET_TOKEN = 'auth/SET_TOKEN',
  CLEAR_TOKEN = 'auth/CLEAR_TOKEN',

  LOAD_USER = 'auth/LOAD_USER',
  LOADING_USER = 'auth/LOADING_USER',
  LOADED_USER = 'auth/LOADED_USER',
  LOAD_USER_FAILED = 'auth/LOAD_USER_FAILED',

  LOAD_USERS = 'auth/LOAD_USERS',
  LOADING_USERS = 'auth/LOADING_USERS',
  LOADED_USERS = 'auth/LOADED_USERS',
  LOAD_USERS_FAILED = 'auth/LOAD_USERS_FAILED',
  NORMALIZE_USERS = 'auth/NORMALIZE_USERS',

  SEARCH_USER = 'auth/SEARCH_USER',
  SEARCHING_USER = 'auth/SEARCHING_USER',
  SEARCHED_USER = 'auth/SEARCHED_USER',
  SEARCH_USER_FAILED = 'auth/SEARCH_USER_FAILED',

  REFETCH_USER = 'auth/REFETCH_USER'
}

export default AuthActionType;
